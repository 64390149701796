import {create} from 'zustand'
import {devtools} from 'zustand/middleware'

import {
    fetchWeatherDefinitions,
    deleteWeatherDefinition,
    fetchWeatherDefinition,
    postWeatherDefinition,
    putWeatherDefinition, fetchWeatherIcons,
} from 'pages/alerts/api/FetchAlerts'

export const useWeatherDefinitionsStore = create(devtools((set, get) => ({
    weatherDefinitionIcons: [],
    weatherDefinitions: [],
    selectedWeatherDefinitions: [],
    openedWeatherDefinition: null,
    editingWeatherDefinition: null,
    isLoading: false,

    fetchWeatherDefinitions: async () => {
        set({
            selectedWeatherDefinitions: [],
            openedWeatherDefinition: null,
            editingWeatherDefinition: null,
        })

        const weatherDefinitions = await fetchAndPrepareWeatherDefinitions()
        set({
            weatherDefinitions: weatherDefinitions,
        })

        return weatherDefinitions
    },

    fetchWeatherDefinition: async (id) => {
        const weatherDefinition = await fetchWeatherDefinition(id)
        weatherDefinition.groups = weatherDefinition.rules

        return weatherDefinition
    },

    createWeatherDefinition: async (weatherDefinition) => {
        set({isLoading: true})

        try {
            const response = await postWeatherDefinition(weatherDefinition)
            const weatherDefinitions = await fetchAndPrepareWeatherDefinitions()

            set({
                weatherDefinitions,
            })

            return response
        } catch (error) {
            console.error('Error during weather definition creation:', error)
        } finally {
            set({isLoading: false})
        }
    },

    editWeatherDefinition: async (weatherDefinition) => {
        const state = get()
        set({isLoading: true})

        try {
            await putWeatherDefinition(weatherDefinition)
            const weatherDefinitions = await fetchAndPrepareWeatherDefinitions()

            if (state.openedWeatherDefinition) {
                const openedWeatherDefinition = weatherDefinitions.find(wd => wd.id === state.openedWeatherDefinition.id)
                set({
                    weatherDefinitions,
                    selectedWeatherDefinitions: [],
                    openedWeatherDefinition,
                })
            } else {
                set({
                    weatherDefinitions,
                    selectedWeatherDefinitions: [],
                })
            }
        } catch (error) {
            console.error('Error during weather definition creation:', error)
        } finally {
            set({isLoading: false})
        }
    },

    deleteWeatherDefinition: async (weatherDefinition) => {
        const {weatherDefinitions, selectedWeatherDefinitions} = get()
        set({isLoading: true})

        try {
            await deleteWeatherDefinition(weatherDefinition.id)
            set({
                weatherDefinitions: weatherDefinitions.filter((wd) => wd.id !== weatherDefinition.id),
                selectedWeatherDefinitions: selectedWeatherDefinitions.filter((wd) => wd.id !== weatherDefinition.id),
                openedWeatherDefinition: null,
            })
        } catch (error) {
            console.error('Error during deletion:', error)
        } finally {
            set({isLoading: false})
        }
    },

    deleteSelectedWeatherDefinitions: async () => {
        const {selectedWeatherDefinitions} = get()
        set({isLoading: true})

        try {
            await Promise.all(selectedWeatherDefinitions.map((wd) => deleteWeatherDefinition(wd.id)))
            set((state) => ({
                weatherDefinitions: state.weatherDefinitions.filter((wd) => !selectedWeatherDefinitions.includes(wd)),
                selectedWeatherDefinitions: state.selectedWeatherDefinitions.filter((wd) => !selectedWeatherDefinitions.includes(wd)),
            }))
        } catch (error) {
            console.error('Error during deletion:', error)
        } finally {
            set({isLoading: false})
        }
    },

    selectWeatherDefinition: (weatherDefinition) => {
        set((state) => {
            const isSelected = state.selectedWeatherDefinitions.some((item) => item.id === weatherDefinition.id)
            return {
                selectedWeatherDefinitions: isSelected
                    ? state.selectedWeatherDefinitions.filter((item) => item.id !== weatherDefinition.id)
                    : [...state.selectedWeatherDefinitions, weatherDefinition],
            }
        })
    },

    selectSingleWeatherDefinition: (weatherDefinition) => {
        set({
            selectedWeatherDefinitions: [weatherDefinition]
        })
    },

    selectWeatherDefinitions: (weatherDefinitions) => {
        set((state) => {
            const newIds = weatherDefinitions.map(item => item.id);
            const remainingSelected = state.selectedWeatherDefinitions.filter(
                item => !newIds.includes(item.id)
            );

            const newSelections = weatherDefinitions.filter(
                item => !state.selectedWeatherDefinitions.some(selected => selected.id === item.id)
            );

            return {
                selectedWeatherDefinitions: [...remainingSelected, ...newSelections],
            };
        });
    },

    selectAllWeatherDefinitions: () => {
        set((state) => {
            const allSelected = state.weatherDefinitions.length === state.selectedWeatherDefinitions.length
            return {
                selectedWeatherDefinitions: allSelected ? [] : [...state.selectedWeatherDefinitions, ...state.weatherDefinitions.filter((item) => !state.selectedWeatherDefinitions.some((selected) => selected.id === item.id))],
            }
        })
    },

    openWeatherDefinition: (weatherDefinition) => {
        set((state) => ({
            selectedWeatherDefinitions: [],
            openedWeatherDefinition: weatherDefinition
        }))
    },

    toggleEditingWeatherDefinition: (weatherDefinition) => {
        set((state) => ({
            editingWeatherDefinition: weatherDefinition
        }))
    },

    fetchWeatherDefinitionIcons: async () => {
        const weatherDefinitionIcons = await fetchWeatherIcons()
        set({
            weatherDefinitionIcons: weatherDefinitionIcons
        })

        return weatherDefinitionIcons
    },
})))

async function fetchAndPrepareWeatherDefinitions() {
    const wds = await fetchWeatherDefinitions()
    return prepareWeatherDefinitions(wds)
}

function prepareWeatherDefinitions(weatherDefinitions) {
    return weatherDefinitions
        .sort((a, b) => a.name.localeCompare(b.name))
        .map(wd => ({
            ...wd,
            groups: wd.rules,
        }))
}
