import {Box, Button, Chip} from '@mui/material'
import React, {useState, useEffect} from 'react'
import WeatherDefinitionLine from 'widgets/weatherDefinition/WeatherDefinitionLine/WeatherDefinitionLine'
import {SearchInput} from 'shared/ui/SearchInput'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import InfoPanel from '../../../../../shared/ui/InfoPanel/InfoPanel'
import {Spacer} from '../../../../../shared/ui/Spacer'
import {SeveritySelector} from '../../../../../features/weatherDefinition/SeveritySelector/SeveritySelector'
import {useLocationsStore} from '../../../../../app/store/LocationsStore'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'

export default function WeatherDefinitionStepScreen({onChange, selectedDefinitions, isEditingMode, setShowSidebar}) {
    const {
        alertConfigurations,
        alertConfigGroups,
        // fetchAllInfoAboutAlertConfigurations,
    } = useAlertConfigurationsStore((state) => state)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const {
        weatherDefinitionIcons,
        weatherDefinitions,
        fetchWeatherDefinitionIcons,
        fetchWeatherDefinitions,
        toggleEditingWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const [searchFilter, setSearchFilter] = useState('')
    const [severityFilter, setSeverityFilter] = useState('All')

    useEffect(() => {
        fetchWeatherDefinitionIcons()
        fetchWeatherDefinitions()
        fetchLocations()
        // fetchAllInfoAboutAlertConfigurations()
    }, [])

    useEffect(() => {
        if (!weatherDefinitions || !weatherDefinitionIcons) return
        const icons_obj = {}
        weatherDefinitionIcons.forEach((icon) => {
            icons_obj[icon.id] = icon
        })
        const definitions_obj = {}
        weatherDefinitions.forEach((definition) => {
            definition.icon_url = icons_obj[definition.icon]
            definitions_obj[definition.id] = definition
            definition.configurations = {}
        })
        const locations_obj = {}
        locations.forEach((location) => {
            locations_obj[location.id] = location
        })
        const configs_obj = {}
        alertConfigurations.forEach((config) => {
            configs_obj[config.id] = config
        })
        alertConfigGroups.forEach((relation) => {
            const config = configs_obj[relation[1]]
            if (!config) return
            const definition = definitions_obj[config.weather_definition_id]
            const location = locations_obj[config.location_id]
            if (!definition || !location) return
            if (!definition.configurations[relation[0]]) definition.configurations[relation[0]] = []
            definition.configurations[relation[0]].push(location)
        })
    }, [weatherDefinitions, weatherDefinitionIcons, locations, alertConfigurations, alertConfigGroups])

    const onAction = (action, definition) => {
        if (action === 'select') onChange('select', definition)
    }

    const filterName = (name) => {
        if (searchFilter === '') return true
        const normName = name.toLowerCase()
        const normFilter = searchFilter.toLowerCase()
        if (normName.indexOf(normFilter) >= 0) return true
        return false
    }

    const handleSeverityChange = (event) => {
        setSeverityFilter(event.target.value)
    }

    const handleNewWeatherDefinition = () => {
        setShowSidebar(false)
        toggleEditingWeatherDefinition({})
    }

    const handleDeselectWeatherDefinition = (wd) => {
        onAction('select', wd)
    }

    return (
        <Box
            className={'WeatherDefinitionStepScreen column gap16'}
            style={{width: 'auto', height: '100%'}}
        >
            {!isEditingMode &&
                <InfoPanel
                    title={'Multiple weather definitions'}
                    text={'Choosing multiple weather definitions creates separate alerts, one per definition, all applying to your locations. For specific settings (reports, notifications), create an alert with separate definitions and desired settings.'}
                    isOpen={true}
                />
            }
            <Box
                className={'row'}
                style={{padding: '0 2px'}}
            >
                <Box style={{display: 'flex', gap: '8px'}}>
                    <SearchInput
                        style={{width: '304px'}}
                        placeholder='Search for weather definitions'
                        onChange={(event) => setSearchFilter(event.target.value)}
                    />
                    <SeveritySelector
                        severity={severityFilter}
                        onChange={handleSeverityChange}
                    />
                </Box>
                <Spacer/>
                <Button
                    data-cy={'edit-alert-new-definition-button'}
                    onClick={handleNewWeatherDefinition}
                >
                    Create custom definition
                </Button>
            </Box>
            <div
                className={'column gap16'}
                style={{width: 'auto', height: '100%'}}
            >
                <div
                    data-cy={'selected-wds'}
                    style={{
                        backgroundColor: 'var(--palette-grey-background)',
                        padding: '16px',
                        color: 'var(--palette-grey-400)',
                        fontWeight: '500',
                        fontSize: '12px',
                        borderRadius: '12px',
                    }}
                >
                    <span style={{display: 'flex', gap: '8px', alignItems: 'center', flexWrap: 'wrap'}}>
                        {selectedDefinitions.length > 0 ? `SELECTED DEFINITIONS (${selectedDefinitions.length}):` : 'NO SELECTED DEFINITIONS'}
                        {selectedDefinitions.map((wd) => (
                            <Chip
                                data-cy={'selected-wd'}
                                onClick={() => handleDeselectWeatherDefinition(wd)}
                                onDelete={() => handleDeselectWeatherDefinition(wd)}
                                label={wd.name}
                                variant={'grey'}
                            />
                        ))}
                    </span>
                </div>
                <div style={{height: '100%', overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    {weatherDefinitions.map((wd) => (filterName(wd.name) && (wd.severity === severityFilter || severityFilter === 'All') &&
                        <WeatherDefinitionLine
                            definition={wd}
                            selectedDefinitions={selectedDefinitions}
                            isAlertEditingMode={isEditingMode}
                            onChange={onAction}
                            setShowSidebar={setShowSidebar}
                        />
                    ))}
                </div>
            </div>
        </Box>
    )
}
