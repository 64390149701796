import React from 'react';
import {Box, Button} from "@mui/material";
import MapPinIcon from "../../../../../shared/assets/icons/MapPin";
import {Spacer} from "../../../../../shared/ui/Spacer";
import PenIcon from "../../../../../shared/assets/icons/Pen";
import TrashIcon from "../../../../../shared/assets/icons/Trash";
import styles from "./LastCreatedLocation.module.scss"

const LastCreatedLocation = ({location, onEdit, onDelete}) => {
    return (
        <Box className={styles.wrapper}>
            <Box className={styles.labelWrapper}>
                <MapPinIcon/>
                <Box className={styles.label}>{location.label}</Box>
            </Box>
            <Spacer/>
            <Box className={styles.buttonsWrapper}>
                <Box className={styles.groupLabel}>
                    {location.location_group}
                </Box>
                <Button
                    data-cy={'edit-button'}
                    variant={'outlined'}
                    color={'primary'}
                    size={'small'}
                    startIcon={<PenIcon size={'small'}/>}
                    onClick={onEdit}
                >
                    Edit
                </Button>
                <Button
                    color={'error'}
                    size={'small'}
                    startIcon={<TrashIcon size={'small'}/>}
                    onClick={onDelete}
                >
                    Remove
                </Button>
            </Box>
        </Box>
    );
};

export default LastCreatedLocation;