import {createSvgIcon} from '@mui/material/utils'

export default function MapMarkerIcon(props) {
    const Icon = createSvgIcon(
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.0013 3.9987C6.53064 3.9987 5.33464 5.1947 5.33464 6.66536C5.33464 8.13603 6.53064 9.33203 8.0013 9.33203C9.47197 9.33203 10.668 8.13603 10.668 6.66536C10.668 5.1947 9.47197 3.9987 8.0013 3.9987ZM8.0013 7.9987C7.26597 7.9987 6.66797 7.4007 6.66797 6.66536C6.66797 5.93003 7.26597 5.33203 8.0013 5.33203C8.73664 5.33203 9.33464 5.93003 9.33464 6.66536C9.33464 7.4007 8.73664 7.9987 8.0013 7.9987ZM7.99864 1.33203C4.9593 1.33203 2.66797 3.6247 2.66797 6.66536C2.66797 10.8827 5.87597 13.5054 7.2553 14.4367C7.48063 14.5894 7.73997 14.666 7.99864 14.666C8.25664 14.666 8.51464 14.59 8.73997 14.438C10.1213 13.5053 13.334 10.8814 13.334 6.66536C13.3346 3.6247 11.0406 1.33203 7.99864 1.33203ZM8.00197 13.332C6.79931 12.5194 4.0013 10.2434 4.0013 6.66536C4.0013 4.34736 5.68197 2.66536 7.99864 2.66536C10.2806 2.66536 12.0013 4.3847 12.0013 6.66536C12.0013 10.2414 9.19931 12.5194 8.00197 13.332Z"
                fill="#677A8E"
            />
        </svg>, 'MapMarker')
    return (
        <Icon
            {...props}
            data-cy="map-marker-icon"
        />
    )
}
