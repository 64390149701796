import React, {useEffect, useState} from 'react'
import {Alert, Button, IconButton, Snackbar, Typography} from '@mui/material'
import {PasswordField} from 'shared/ui/PasswordField'
import {Spacer} from 'shared/ui/Spacer'
import {api, logoutUser} from 'shared/libs/Auth'
import {Portal} from '@mui/base'
import CloseIcon from 'shared/assets/icons/Close'
import {PasswordBar} from '../../../../shared/ui/PasswordBar/PasswordBar'
import {PasswordStrengthIndicator} from '../../../../shared/ui/PasswordStrengthIndicator/PasswordStrengthIndicator'
import {isPasswordValid} from '../../../../shared/libs/Utils'
import styles from "./ChangePasswordForm.module.scss"
import {clsx} from "clsx";

const ChangePasswordForm = ({onClose}) => {
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [newConfirmPassword, setNewConfirmPassword] = useState('')
    const [oldPasswordMessage, setOldPasswordMessage] = useState('')
    const [newPasswordMessage, setNewPasswordMessage] = useState('')
    const [confirmNewPasswordMessage, setConfirmNewPasswordMessage] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [disableSubmitButton, setDisableSubmitButton] = useState(true)
    const [passwordStrength, setPasswordStrength] = useState({})

    useEffect(() => {
        const isValid = isPasswordValid(newPassword)
        const isNotEmpty = oldPassword !== '' && newPassword !== '' && newConfirmPassword !== ''
        const isMatching = newPassword === newConfirmPassword
        const isSame = oldPassword === newPassword

        setPasswordStrength(isValid)

        if (!isValid.isValid || !isNotEmpty || !isMatching || isSame) {
            setDisableSubmitButton(true)
        } else {
            setDisableSubmitButton(false)
        }
    }, [oldPassword, newPassword, newConfirmPassword])

    const timeout = 2000 // 2sec
    const handleSnackbarClick = () => {
        setOpenSnackbar(true)
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSnackbar(false)
    }

    const onSubmitHandle = () => {
        setOldPasswordMessage('')
        setNewPasswordMessage('')
        setConfirmNewPasswordMessage('')

        api.put('/user/change_password', {
            old_password: oldPassword,
            new_password: newPassword,
        }).then(() => {
            handleSnackbarClick()
            setTimeout(() => {
                logoutUser()
            }, timeout)
        }).catch((reason) => {
            if (reason.response) {
                if (reason.response.data.old_password) {
                    setOldPasswordMessage(reason.response.data.old_password)
                }
                if (reason.response.data.non_field_errors) {
                    setNewPasswordMessage(reason.response.data.non_field_errors)
                }
                if (reason.response.data.new_password) {
                    setNewPasswordMessage(() => (
                        <>
                            {reason.response.data.new_password.map((str) => (<p key={str}>
                                {str}
                            </p>))}
                        </>
                    ))
                    setConfirmNewPasswordMessage(() => (
                        <>
                            {reason.response.data.new_password.map((str) => (<p key={str}>
                                {str}
                            </p>))}
                        </>
                    ))
                }
            } else if (reason.request) {
                console.error(reason.request)
            } else {
                console.log('Error', reason.message)
            }
        })
    }

    return (
        <div className={clsx('column gap24', styles.wrapper)}>
            <div className={'row fullWidth'}>
                <h3>
Edit profile
                </h3>
                <Spacer />
                <IconButton
                    variant={'outlined'}
                    onClick={onClose}
                    style={{margin: '2px 2px 0 0'}}
                >
                    <CloseIcon size={'small'} />
                </IconButton>
            </div>
            <div className={'column gap8 fullWidth'}>
                <Typography
                    className={clsx('fullWidth', styles.title)}
                    variant={'label'}
                >
                    Old password
                    <PasswordField
                        data-cy={'old-password-input'}
                        helperText={oldPasswordMessage}
                        onChange={(event) => setOldPassword(event.target.value)}
                        error={!!oldPasswordMessage}
                    />
                </Typography>
                <Typography
                    className={clsx('fullWidth', styles.title)}
                    variant={'label'}
                >
                    New password
                    <PasswordField
                        data-cy={'new-password-input'}
                        helperText={newPasswordMessage}
                        onChange={(event) => setNewPassword(event.target.value)}
                        error={!!newPasswordMessage}
                    />
                </Typography>
                <Typography
                    className={clsx('fullWidth', styles.title)}
                    variant={'label'}
                >
                    Confirm new password
                    <PasswordField
                        data-cy={'confirm-new-password-input'}
                        helperText={confirmNewPasswordMessage}
                        onChange={(event) => setNewConfirmPassword(event.target.value)}
                        error={!!confirmNewPasswordMessage}
                    />
                </Typography>
                <PasswordBar fillPercent={passwordStrength.percent}/>
                <PasswordStrengthIndicator
                    minLength={passwordStrength.minLength}
                    hasNumber={passwordStrength.hasNumber}
                    hasSpecialChar={passwordStrength.hasSpecialChar}
                    hasUppercaseChar={passwordStrength.hasUppercaseChar}
                />
            </div>
            <div
                className={'row'}
                style={{justifyContent: 'center', padding: '2px'}}
            >
                <Button
                    data-cy={'change-password-button'}
                    className={'spacer'}
                    disabled={disableSubmitButton}
                    onClick={onSubmitHandle}
                >
                    Submit
                </Button>
                <Button
                    className={'spacer'}
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </div>

            <Portal>
                <Snackbar
                    open={openSnackbar}
                    onClose={handleSnackbarClose}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={'success'}
                        sx={{width: '100%', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    >
                        Password has been changed.
                    </Alert>
                </Snackbar>
            </Portal>
        </div>
    )
}
export default ChangePasswordForm
