import {
    Box,
    Button,
    IconButton,
    FormHelperText,
} from '@mui/material'
import React, {useState} from 'react'
import ModalComponent from 'shared/ui/ModalComponent'
import CloseIcon from '../../../../../shared/assets/icons/Close'
import TextField from '@mui/material/TextField'
import {useContactsStore} from '../../../../../app/store/ContactsStore'
import {useLocationsStore} from '../../../../../app/store/LocationsStore'
import styles from "./EditLocationGroupName.module.scss"

export function EditGroupName({editGroupState, onChange, isContact= false}) {
    const {
        contacts,
        editGroupName,
    } = useContactsStore((state) => state)

    const {
        locations,
        editLocationGroupName,
    } = useLocationsStore((state) => state)

    const [editGroup, setEditGroup] = useState({...editGroupState})

    const saveContactGroupName = async () => {
        if (!editGroup || editGroup.original === editGroup.new || !editGroup.new) {
            onChange()
            return
        }

        const contactsToUpdate = contacts.filter((contact) => contact.group === editGroup.original)
        editGroupName(contactsToUpdate, editGroup.new)
        onChange()
    }

    const saveLocationGroupName = async () => {
        if (!editGroup || editGroup.original === editGroup.new || !editGroup.new) {
            onChange()
            return
        }

        const locationsToUpdate = locations.filter((loc) => loc.location_group === editGroup.original)
        if (locationsToUpdate.length === 0) {
            onChange()
            return
        }

        try {
            await editLocationGroupName(locationsToUpdate.map((loc) => loc.id), {location_group: editGroup.new})
            onChange(editGroup)
        } catch (error) {
            console.error('Error updating locations:', error)
        }
    }

    return (
        <ModalComponent visible={true}>
            <Box className={styles.wrapper}>
                <Box className={styles.titleWrapper}>
                    <h3>
                        {isContact ? 'Edit group name' : 'Edit location group'}
                    </h3>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        className={styles.buttonWrapper}
                        onClick={() => onChange(false)}
                    >
                        <CloseIcon className={styles.button}/>
                    </IconButton>
                </Box>
                <Box className={styles.contentWrapper}>
                    <FormHelperText className={styles.subtitle}>
                        {isContact ? 'Group name' : 'Location group name'}
                    </FormHelperText>
                    <TextField
                        className={styles.textField}
                        type='text'
                        placeholder={isContact ? 'Group name' : 'Location group name'}
                        value={editGroup.new}
                        onChange={(e) => setEditGroup({original: editGroup.original, new: e.target.value})}
                    >
                    </TextField>
                </Box>
                <Box className={styles.buttonsWrapper}>
                    <Button
                        className='spacer regular'
                        onClick={isContact ? saveContactGroupName : saveLocationGroupName}
                    >
                            Save
                    </Button>
                    <Button
                        className='spacer regular'
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={() => {
                            onChange(false)
                        }}
                    >
                            Cancel
                    </Button>
                </Box>
            </Box>
        </ModalComponent>
    )
}
