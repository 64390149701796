import {Box} from '@mui/material';
import PrivateRoute from 'app/PrivateRoute';
import {checkLogon} from 'pages/alerts/api/FetchAlerts';
import {AlertsPage} from 'pages/alerts/ui/AlertsPage/AlertsPage';
import ApproveUser from 'pages/infoPages/ApproveUser';
import CreatePassword from 'pages/createPassword/CreatePassword';
import EmailConfirmed from 'pages/infoPages/EmailConfirmed';
import {Map} from 'pages/map/ui/Map';
import NotFoundPage from 'pages/notFound/NotFoundPage';
import RegisterPage from 'pages/register/RegisterPage';
import ResetPassword from 'pages/resetPassword/ResetPassword';
import SettingsPage from 'pages/settings/ui/SettingsPage/SettingsPage';
import TestDesign from 'pages/testDesign/TestDesign';
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ManageAlertConfigurationPanel
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel';
import Header from 'widgets/header/Header';
import Login from '../pages/login/Login';
import TimeLine from '../pages/timeline/timeline/TimeLine';
import {AppWrapper} from './AppWrapper';
import ApproveUserList from 'pages/infoPages/ApproveUserList';
import PrivacyPolicy from "../pages/infoPages/PrivacyPolicy";
import TermsAndConditions from "../pages/infoPages/TermsAndConditions";


// TODO move it from here
const path = new URL(document.URL).pathname;
const checkLogonBlacklist = [
    /\/login/,
    /\/signup/,
    /\/createpassword/,
    /\/resetpassword/,
    /\/activate.*/,
    /\/test-design/,
    /\/emailconfirmed/,
    /\/approve-user\/.*/,
    /\/approve-user-list/,
    /\/terms-and-conditions/,
    /\/privacy-policy/,
];

let isBlacklisted = false;
for (const regex of checkLogonBlacklist) {
    if (regex.test(path)) {
        isBlacklisted = true;
        break;
    }
}

if (!isBlacklisted) {
    checkLogon();
}

// eslint-disable-next-line require-jsdoc
function App() {
    return (
        <AppWrapper>
            <Box className='app' sx={{overflow: 'hidden'}}>
                <Header/>
                <Routes>
                    <Route element={<PrivateRoute/>}>
                        <Route path="/" index element={<AlertsPage/>}/>
                        <Route path="alerts" element={<AlertsPage/>}/>
                        <Route path="alerts/newalert" element={<ManageAlertConfigurationPanel/>}/>
                        <Route
                            path="alerts/edit-alert"
                            element={<ManageAlertConfigurationPanel defaultTab={'Notifications'}/>}
                        />
                        <Route path="timeline" element={<TimeLine/>}/>
                        <Route path="settings" element={<SettingsPage/>}/>
                        <Route path="map" element={<Map/>}/>
                    </Route>

                    <Route path='*' element={<NotFoundPage/>}/>
                    <Route path="signup" element={<RegisterPage/>}/>
                    <Route path="login" element={<Login/>}/>
                    <Route path="resetpassword" element={<ResetPassword/>}/>
                    <Route path="createpassword" element={<CreatePassword/>}/>
                    <Route path="emailconfirmed" element={<EmailConfirmed/>}/>
                    <Route path="approve-user/:pk" element={<ApproveUser/>}/>
                    <Route path="approve-user-list" element={<ApproveUserList/>}/>
                    <Route path="terms-and-conditions" element={<TermsAndConditions/>}/>
                    <Route path="privacy-policy" element={<PrivacyPolicy/>}/>
                    {process.env.REACT_APP_NODE_ENV === 'development' &&
                        <Route path="test-design" element={<TestDesign/>}/>
                    }
                </Routes>
            </Box>
        </AppWrapper>
    );
}

export default App;
