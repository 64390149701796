import React, {useEffect, useState} from 'react'
import {Button, Chip, Typography} from '@mui/material'
import {SearchInput} from '../../../../../shared/ui/SearchInput'
import PlusIcon from '../../../../../shared/assets/icons/Plus'
import CheckIcon from '../../../../../shared/assets/icons/Check'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import ActionItemsAdvancedLine from '../ActionItemsAdvancedLine/ActionItemsAdvancedLine'
import styles from "./ActionItemsAdvancedList.module.scss"

const ActionItemsAdvancedList = ({chosenActionItems, onChangeActionItem}) => {
    const {
        actionItems,
        fetchActionItems,
        toggleEditingActionItem,
    } = useActionItemsStore((state) => state)

    const [searchQuery, setSearchQuery] = useState('')
    const [selectedUrgency, setSelectedUrgency] = useState(null)

    useEffect(() => {
        fetchActionItems()
    }, [])

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase())
    }

    const handlePressChip = (label) => {
        setSelectedUrgency(label === selectedUrgency ? null : label)
    }

    const handleNewActionItem = () =>{
        toggleEditingActionItem({})
    }

    const chips = [
        {icon: <CheckIcon size="small"/>, variant: 'red', label: 'High'},
        {icon: <CheckIcon size="small"/>, variant: 'yellow', label: 'Medium'},
        {icon: <CheckIcon size="small"/>, variant: 'green', label: 'Low'},
    ]

    return (
        <>
            <div className={styles.wrapper}>
                <div className={styles.buttonWrapper}>
                    <Typography className={styles.button}>
                        ACTION ITEMS
                    </Typography>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        startIcon={<PlusIcon/>}
                        onClick={handleNewActionItem}
                    >
                        New action item
                    </Button>
                </div>

                <div className={styles.mainWrapper}>
                    <SearchInput
                        className={'fullWidth'}
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder={'Search for action item'}
                    />
                    <div className={styles.chipsWrapper}>
                        {chips.map(({icon, variant, label}) => (
                            <Chip
                                key={label}
                                icon={icon}
                                variant={selectedUrgency === null || selectedUrgency === label ? variant : 'grey'}
                                label={label}
                                size="small"
                                onClick={() => handlePressChip(label)}
                            />
                        ))}
                    </div>
                    <div className={styles.listWrapper}>
                        {actionItems.map((ai) => (
                            (!selectedUrgency || ai.urgency === selectedUrgency) && (!searchQuery || ai.name.toLocaleLowerCase().indexOf(searchQuery) >= 0) &&
                            <ActionItemsAdvancedLine
                                key={ai.id}
                                actionItem={ai}
                                chosenActionItems={chosenActionItems}
                                onChangeActionItem={onChangeActionItem}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActionItemsAdvancedList
