import {Box, Chip, IconButton, MenuItem, Paper, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import {fetchWeatherIcons} from 'pages/alerts/api/FetchAlerts'
import {Select} from 'shared/ui/Select/Select'
import {Spacer} from 'shared/ui/Spacer'
import 'widgets/weatherDefinition/definitionRender/DefinitionRenderPreview.css'
import CheckIcon from 'shared/assets/icons/Check'
import PlusIcon from 'shared/assets/icons/Plus'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import React, {useEffect, useState, useRef} from 'react'
import theme from 'app/Theme'
import {useWeatherDefinitionsStore} from '../../../app/store/WeatherDefinitionsStore'
import {productsDict} from '../../../entities/weatherDefinition/api/Products'
import {WeatherDefinitionGroupCreator} from '../WeatherDefinitionGroupCreator'
import AgreeModal from 'shared/ui/AgreeModal'
import {DefinitionRenderPreview} from '../definitionRender/DefinitionRenderPreview'
import DefinitionConfirmationModal from '../../../shared/ui/DefinitionConfirmationModal/DefinitionConfirmationModal'
import styles from './EditWeatherDefinitionPage.module.scss'
import {clsx} from 'clsx'

const DICTIONARY_UNIONS = {
    AND: 'AND',
    OR: 'OR',
}

export const IconMenuItem = styled(MenuItem)(() => ({
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    padding: 0,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: 'var(--palette-grey-100)',
    background: 'var(--palette-grey-background)',
}))

export default function EditWeatherDefinitionPage({
    onSave = (wd) => {}, setShowSidebar = () =>{},
}) {
    const {
        editingWeatherDefinition,
        openWeatherDefinition,
        toggleEditingWeatherDefinition,
        createWeatherDefinition,
        editWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const [groups, setGroups] = useState(editingWeatherDefinition.groups || [createNewGroup()])
    const [name, setName] = useState(editingWeatherDefinition.name || '')
    const [severity, setSeverity] = useState(editingWeatherDefinition.severity || '')
    const [definition, setDefinition] = useState({})
    const [icons, setIcons] = useState([])
    const [icon, setIcon] = useState({})
    const [disableSave, setDisableSave] = useState(false)

    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showLeaveModal, setShowLeaveModal] = useState(false)
    const isNew = useRef(!editingWeatherDefinition.id)

    useEffect(() => {
        if (name === '' || severity === '' || !validateGroups()) {
            setDisableSave(true)
        } else {
            setDisableSave(false)
        }
        setDefinition({
            groups,
            name,
            severity,
            icon: icon.id,
        })
    }, [groups, name, severity, icon])

    useEffect(() => {
        fetchWeatherIcons().then((data) => {
            const iconsWithNames = data.map((icon) => {
                const formatedName = icon.url.replace('_', ' ').split('/').pop().split('.')[0]
                const name = formatedName.charAt(0).toUpperCase() + formatedName.slice(1)
                return {...icon, name}
            })
            setIcons(iconsWithNames)
            if (editingWeatherDefinition.icon) {
                setIcon(iconsWithNames.find((icon) => icon.id === editingWeatherDefinition.icon))
            }
        })
    }, [])

    const validateGroups = () => {
        let state = 'empty'
        groups.forEach((group) => {
            group.lines.forEach((line) => {
                if (!line.product_name || state === 'invalid') return
                if (!line.values.length) {
                    state = 'invalid'
                    return
                }
                if (line.values[0] === '') {
                    state = 'invalid'
                    return
                }
                if (line.relation.indexOf('><') >= 0 && (line.values.length !== 2 || !line.values[1])) {
                    state = 'invalid'
                    return
                }
                const product = productsDict[line.product_name]
                if (!product) { // wl-326
                    state = 'invalid'
                    return
                }
                if (product.result_type !== 'numeric') {
                    if (line.values[0] === 'none' && line.relation === '=') {
                        state = 'invalid'
                        return
                    }
                    if (product.allowed_text_values[0] === 'none' && line.values[0]) { // wl-294
                        if (line.values[0] === 'none' && (line.relation === '<=' || line.relation === '>=')) {
                            state = 'invalid'
                            return
                        }
                        if (product.allowed_text_values.indexOf(line.values[0]) <= 1 && line.relation === '<') {
                            state = 'invalid'
                            return
                        }
                        if (product.allowed_text_values.indexOf(line.values[0]) === product.allowed_text_values.length - 1 && line.relation === '>') {
                            state = 'invalid'
                            return
                        }
                        if (line.relation === '><' && (line.values[0] === 'none' || line.values[1] === 'none')) {
                            state = 'invalid'
                            return
                        }
                    }
                    if (state !== 'invalid' && product.result_type !== 'time') state = 'valid'
                    return
                }
                if (isNaN(parseFloat(line.values[0]))) {
                    state = 'invalid'
                    return
                }
                if (line.values.length === 2 && isNaN(parseFloat(line.values[1]))) {
                    state = 'invalid'
                    return
                }
                if (state !== 'invalid') state = 'valid'
            })
        })
        return (state === 'valid')
    }

    const handleChangeSeverity = (event) => {
        setSeverity(event.target.value)
    }

    function addNewGroup() {
        setGroups([...groups, createNewGroup()])
    }

    function createNewGroup() {
        return {
            lines: [],
            union: 'AND',
        }
    }

    const removeGroup = (g) => {
        const newGroups = []
        groups.forEach((group) => {
            if (group !== g) newGroups.push(group)
        })
        setGroups(newGroups)
    }

    const unionSelectOptions = []

    for (const key in DICTIONARY_UNIONS) {
        unionSelectOptions.push(
            <MenuItem
                key={key}
                value={key}
            >
                {DICTIONARY_UNIONS[key]}
            </MenuItem>,
        )
    }
    const handleClose = () => {
        setShowSidebar(false)
        setShowSuccessModal(false)
        toggleEditingWeatherDefinition(null)
    }

    const handleLeaveModalClose = (agreed) => {
        if (agreed) {
            handleClose()
        }
        setShowLeaveModal(false)
    }

    const handleGoToSettings = () => {
        openWeatherDefinition(null)
        window.location.href = '/settings#weather-definitions'
        handleClose()
    }

    const handleSaveWeatherDefinition = async () => {
        setDisableSave(true)
        if (isNew.current) {
            const response = await createWeatherDefinition(definition)
            setShowSuccessModal(true)
            if (!response.id) {
                return
            }
            definition.id = response.id
            onSave(definition)
        } else {
            definition.id = editingWeatherDefinition.id
            await editWeatherDefinition(definition)
            setShowSuccessModal(true)
            onSave(definition)
        }
        setDisableSave(false)
    }

    return (
        <>
            <div
                data-cy={'wd-edit-page'}
                className={'fullHeight fullWidth'}
            >
                <div className={clsx('settings', styles.wrapper)}>
                    <div className={clsx('settings-toolbar', styles.header)}>
                        <IconButton
                            data-cy={'wd-edit-back-button'}
                            onClick={() => setShowLeaveModal(true)}
                            variant={'outlined'}
                            size={'small'}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <h3>
                            {isNew.current ? 'New' : 'Edit'}
                            {' '}
                            weather definition
                        </h3>
                        <Spacer/>
                        <Button
                            data-cy={'save-wd'}
                            disabled={disableSave}
                            onClick={handleSaveWeatherDefinition}
                        >
                            Save weather definition
                        </Button>
                    </div>
                    <Box className={clsx('row gap16 fullWidth', styles.mainWrapper)}>
                        <Box className={styles.main}>
                            <Paper className={clsx('column gap16', styles.paper)}>
                                <Box className={'row fullWidth'}>
                                    <Typography
                                        variant={'label'}
                                        sx={{flexGrow: 1}}
                                    >
                                        Title
                                        <TextField
                                            data-cy={'wd-creating-title-input'}
                                            placeholder='Enter weather definition title'
                                            value={name}
                                            onChange={(event) => setName(event.target.value)}
                                        />
                                    </Typography>
                                    <Typography variant={'label'}>
                                        Icon
                                        <Select
                                            defaultValue={icon}
                                            value={icon}
                                            placeholder={''}
                                            className={styles.select}
                                            renderValue={(icon) => (
                                                <Box className={styles.selectContentWrapper}>
                                                    <Box className={clsx('row iconBackground', styles.selectContent)}>
                                                        <svg className={clsx('customSvg', styles.selectContentIcon)}>
                                                            <use
                                                                height="16"
                                                                width="16"
                                                                href={`${icon.url}#svg2`}
                                                            />
                                                        </svg>
                                                    </Box>
                                                    <Box
                                                        className={clsx(styles.iconName, {
                                                            [styles.selectedIconName]: icon.name,
                                                            [styles.unSelectedIconName]: !icon.name,
                                                        })}
                                                    >
                                                        {icon.name || 'Select icon'}
                                                    </Box>
                                                </Box>
                                            )}
                                            MenuProps={{
                                                sx: {
                                                    '& ul': {
                                                        padding: 0,
                                                        display: 'grid',
                                                        gridTemplateColumns: 'repeat(7, auto)',
                                                    },
                                                },
                                            }}
                                        >
                                            {icons.map((iconItem) => (
                                                <IconMenuItem
                                                    key={`icon_${iconItem.id}`}
                                                    value={iconItem.id}
                                                    onClick={() => setIcon({...iconItem})}
                                                >
                                                    <svg
                                                        className={'customSvg'}
                                                        style={icon.id === iconItem.id ? {fill: 'var(--palette-info-dark)'} : {}}
                                                    >
                                                        <use
                                                            height="16"
                                                            width="16"
                                                            href={`${iconItem.url}#svg2`}
                                                        />
                                                    </svg>
                                                </IconMenuItem>
                                            ))}
                                        </Select>
                                    </Typography>
                                </Box>
                                <Typography variant={'label'}>
                                    Severity
                                    <Select
                                        defaultValue={severity}
                                        onChange={handleChangeSeverity}
                                        renderValue={(value) => (
                                            <Chip
                                                label={value}
                                                variant={theme.palette.weather.variant[value]}
                                                size={'small'}
                                            />
                                        )}
                                        data-cy={'wd-creating-severity-selector'}
                                        sx={{width: '212px'}}
                                    >
                                        <MenuItem
                                            key={'Critical'}
                                            value={'Critical'}
                                            component={({onClick}) => (
                                                <Chip
                                                    onClick={onClick}
                                                    data-cy={'wd-creating-severity-critical'}
                                                    label={'Critical'}
                                                    size={'small'}
                                                    icon={severity === 'Critical' && <CheckIcon size={'small'}/>}
                                                    variant={theme.palette.weather.variant.Critical}
                                                />
                                            )}
                                        />
                                        <MenuItem
                                            key={'Severe'}
                                            value={'Severe'}
                                            component={({onClick}) => (
                                                <Chip
                                                    onClick={onClick}
                                                    data-cy={'wd-creating-severity-severe'}
                                                    label={'Severe'}
                                                    size={'small'}
                                                    icon={severity === 'Severe' && <CheckIcon size={'small'}/>}
                                                    variant={theme.palette.weather.variant.Severe}
                                                />
                                            )}
                                        />
                                        <MenuItem
                                            key={'Moderate'}
                                            value={'Moderate'}
                                            component={({onClick}) => (
                                                <Chip
                                                    onClick={onClick}
                                                    data-cy={'wd-creating-severity-moderate'}
                                                    label={'Moderate'}
                                                    size={'small'}
                                                    icon={severity === 'Moderate' && <CheckIcon size={'small'}/>}
                                                    variant={theme.palette.weather.variant.Moderate}
                                                />
                                            )}
                                        />
                                    </Select>
                                </Typography>
                            </Paper>
                            <Box style={{width: '40%'}}>
                                <DefinitionRenderPreview definition={definition}/>
                            </Box>
                        </Box>
                        <Paper className={clsx('column gap24 fullWidth', styles.bottomPaper)}>
                            {groups.map((group, i) => (
                                <Box
                                    className={'column gap24 fullWidth'}
                                    key={i}
                                    sx={{borderRadius: '12px'}}
                                >
                                    {(!!i) &&
                                            <Select
                                                value={group.union}
                                                onChange={({target}) => {
                                                    group.union = target.value
                                                    setGroups([...groups])
                                                }}
                                                sx={{width: 'max-content'}}
                                            >
                                                {unionSelectOptions}
                                            </Select>
                                    }
                                    <Paper
                                        className={clsx('column fullWidth', styles.containedPaper)}
                                        key={i}
                                    >
                                        <WeatherDefinitionGroupCreator
                                            groups={groups}
                                            defaultValue={group.lines}
                                            onChange={(newGroup) => {
                                                group.lines = newGroup
                                                setGroups([...groups])
                                            }}
                                            onRemove={() => removeGroup(group)}
                                        />
                                    </Paper>
                                </Box>
                            ),
                            )}
                            <Button
                                data-cy={'new-parameter-group-button'}
                                variant={'outlined'}
                                onClick={addNewGroup}
                                startIcon={<PlusIcon/>}
                                sx={{width: 'max-content'}}
                                disabled={groups[0].lines.some((line) => !line.isValid) || groups[0]?.lines[0]?.product_name === ''}
                            >
                                New Parameter Group
                            </Button>
                        </Paper>
                    </Box>
                </div>
            </div>
            {showSuccessModal && (
                <DefinitionConfirmationModal
                    definition={definition}
                    agreeFunc={handleClose}
                    goToSettingsFunc={handleGoToSettings}
                />
            )}
            {showLeaveModal && (
                <AgreeModal
                    data={{
                        agreeFunc: handleLeaveModalClose,
                        title: 'Discard weather definition',
                        message: 'Are you sure you want discard your changes?',
                        agreeMsg: 'Discard',
                        disAgreeMsg: 'Go back',
                    }}
                />
            )}
        </>
    )
};
