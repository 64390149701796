import React, {useState, useEffect} from 'react'
import {Menu, MenuItem, IconButton, Box, Typography} from '@mui/material'
import {CheckBox} from 'shared/ui/CheckBox'
import ModalComponent from 'shared/ui/ModalComponent'
import avatar from 'shared/assets/svg/avatar.svg'
import UserDetails from 'entities/user/UserDetails/UserDetails'
import EditContactPopup from 'features/contact/EditContactPopup/EditContactPopup'
import DragVerticalIcon from 'shared/assets/icons/DragVertical'
import MoreIcon from 'shared/assets/icons/More'
import TrashIcon from 'shared/assets/icons/Trash'
import {formatPhoneNumber} from 'shared/libs/Utils'
import PenIcon from 'shared/assets/icons/Pen'
import StarIcon from 'shared/assets/icons/Star'
import CloseIcon from 'shared/assets/icons/Close'
import BellSlashIcon from 'shared/assets/icons/BellSlashIcon'
import LinkIcon from 'shared/assets/icons/Link'
import CircularArrowIcon from 'shared/assets/icons/CircularArrowIcon'
import AgreeModal from 'shared/ui/AgreeModal'
import ContactStatus from '../../../../widgets/contact/ContactStatus'
import {useContactsStore} from '../../../../app/store/ContactsStore'
import CircleIcon from '../../../../shared/assets/icons/Circle'
import styles from './DraggableContactLine.module.scss'

export default function DraggableContactLine({contact, isChecked, onDrag}) {
    const {
        newestContactsIds,
        selectedContacts,
        selectContacts,
        deleteContactByID,
        deleteNewestContactIdFromSessionStorage,
    } = useContactsStore((state) => state)

    const [openModalUserPopUp, setOpenModalUserPopUp] = useState(false)
    const [currentTypeOfModalBox, setCurrentTypeOfModalBox] = useState('')
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    useEffect(() => {
        setContactLine({
            id: contact.id,
            // checked: false,
            avatar: avatar,
            userName: contact.name,
            email: contact.email,
            phone: contact.phone,
            status: contact.status,
            group: contact.group,
        })
    }, [contact])

    const handleCheckboxChange = (event) => {
        event.stopPropagation()
        const updatedCheckedContacts = {...selectedContacts, [contact.id]: !isChecked}
        if (isChecked) {
            delete updatedCheckedContacts[contact.id]
        }
        const filteredCheckedContacts = Object.fromEntries(
            Object.entries(updatedCheckedContacts).filter(([_, val]) => val),
        )
        selectContacts(filteredCheckedContacts)
    }

    // TODO: use actual data for edit contact form
    const arrStatus = {
        'No subscription': {menu: [MenuItemEditContact, MenuItemRemoveContact]},
        // 'No subscription': { menu: [MenuItemEditContact, MenuItemSendInvite, MenuItemRemoveContact] },
        Admin: {menu: [MenuItemRevokeAdminRights, MenuItemCancelSubscription, MenuItemRemoveUser]},
        'Invite sent': {menu: [MenuItemResendInvite, MenuItemGetInviteLink, MenuItemCancelInvite, MenuItemRemoveContact]},
        'Active subscription': {menu: [MenuItemGiveAdminRights, MenuItemCancelSubscription, MenuItemRemoveUser]},
        'Subscription expired': {menu: [MenuItemRenewSubscription, MenuItemRemoveUser]},
    }

    const [contactLine, setContactLine] = useState({
        id: contact.id,
        checked: false,
        avatar: avatar,
        userName: contact.name,
        email: contact.email,
        phone: contact.phone,
        status: contact.status,
        group: contact.group,
    })

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDeleteContact = (agree) => {
        if (agree) {
            deleteContactByID(contact.id)
        }
        handleClose()
        setIsDeleteModalOpen(false)
    }

    function MenuItemEditContact() {
        return (
            <MenuItem onClick={() => setCurrentTypeOfModalBox('editContact')}>
                <PenIcon size={'small'}/>
                Edit contact
            </MenuItem>
        )
    }

    // function MenuItemSendInvite() {
    //     return (
    //         <MenuItem onClick={handleClose}>
    //             <EnvelopeIcon size={'small'}/>
    //             Send invite
    //         </MenuItem>
    //     );
    // }

    function MenuItemRemoveContact() {
        return (
            <Box>
                <MenuItem
                    color={'error'}
                    onClick={() => setIsDeleteModalOpen(true)}
                >
                    <TrashIcon size={'small'}/>
                    Remove contact
                </MenuItem>
                {isDeleteModalOpen &&
                    <AgreeModal
                        data={{
                            message: <>
                                Are you sure you want delete contact
                                {' '}
                                <span className={styles.boldFont}>
"
                                    {contact.name}
                                    "
                                </span>
                                ?
                            </>,
                            title: 'Delete contact',
                            agreeMsg: 'Delete',
                            mode: 'deleting',
                            agreeFunc: handleDeleteContact,
                        }}
                    />}
            </Box>
        )
    }

    function MenuItemRevokeAdminRights() {
        return (
            <MenuItem onClick={handleClose}>
                <StarIcon size={'small'}/>
                Revoke admin rights
            </MenuItem>
        )
    }

    function MenuItemCancelSubscription() {
        return (
            <MenuItem onClick={handleClose}>
                <BellSlashIcon size={'small'}/>
                Cancel subscription
            </MenuItem>
        )
    }

    function MenuItemRemoveUser() {
        return (
            <MenuItem
                onClick={handleClose}
                color={'error'}
            >
                <TrashIcon size={'small'}/>
                Remove user
            </MenuItem>
        )
    }

    function MenuItemResendInvite() {
        return (
            <MenuItem onClick={handleClose}>
                <CircularArrowIcon size={'small'}/>
                Resend invite
            </MenuItem>
        )
    }

    function MenuItemGetInviteLink() {
        return (
            <MenuItem onClick={handleClose}>
                <LinkIcon size={'small'}/>
                Get invite link
            </MenuItem>
        )
    }

    function MenuItemCancelInvite() {
        return (
            <MenuItem onClick={handleClose}>
                <CloseIcon size={'small'}/>
                Cancel invite
            </MenuItem>
        )
    }

    function MenuItemGiveAdminRights() {
        return (
            <MenuItem onClick={handleClose}>
                <StarIcon size={'small'}/>
                Give admin rights
            </MenuItem>
        )
    }

    function MenuItemRenewSubscription() {
        return (
            <MenuItem onClick={handleClose}>
                <CircularArrowIcon size={'small'}/>
                Renew subscription
            </MenuItem>
        )
    }

    return (
        <Box
            className={'contact-line'}
            style={{backgroundColor: newestContactsIds?.includes(contact.id) ? '#EDF1FC' : 'white'}}
        >
            <Box
                className={'row gap0'}
                onClick={() => {
                    setOpenModalUserPopUp(true)
                    deleteNewestContactIdFromSessionStorage(contact.id)
                }}
                sx={{cursor: 'pointer', flexGrow: 2}}
            >
                <IconButton
                    variant={'outlined'}
                    size={'tiny'}
                    sx={{marginRight: '8px'}}
                    onMouseDown={onDrag}
                    onTouchStart={onDrag}
                >
                    <DragVerticalIcon size={'small'}/>
                </IconButton>
                <Box style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                    <CheckBox
                        id={`checkbox-${contact.id}`}
                        checked={isChecked || false}
                        onChange={(event) => handleCheckboxChange(event)}
                        onClick={(event) => event.stopPropagation()}
                        sx={{marginRight: '4px'}}
                    />
                    {newestContactsIds?.includes(contact.id) &&
                        <CircleIcon
                            size={'tiny'}
                            style={{color: 'var(--palette-primary-dark)'}}
                        />}
                    <Typography className={'textEllipsis medium'}>
                        {contactLine.userName}
                    </Typography>
                </Box>
            </Box>
            <Typography
                className={'textEllipsis'}
                sx={{width: '100px', flexGrow: 2}}
            >
                {contactLine.email}
            </Typography>
            <Typography sx={{width: '160px'}}>
                {formatPhoneNumber(contactLine.phone)}
            </Typography>
            <ContactStatus status={contactLine.status}/>
            <IconButton
                variant={'outlined'}
                size={'tiny'}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreIcon size={'small'}/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {arrStatus[contactLine.status] && arrStatus[contactLine.status].menu.map((menuItemFunction, index) => {
                    const MenuItemElement = menuItemFunction()
                    return React.cloneElement(MenuItemElement, {key: index})
                })}
            </Menu>

            <ModalComponent
                visible={currentTypeOfModalBox === 'editContact'}
                style={{padding: '0px'}}
            >
                <EditContactPopup
                    contactData={contactLine}
                    onClose={() => setCurrentTypeOfModalBox('')}
                />
            </ModalComponent>

            <ModalComponent visible={openModalUserPopUp}>
                <UserDetails
                    user={contactLine}
                    onClose={() => setOpenModalUserPopUp(false)}
                />
            </ModalComponent>
        </Box>
    )
}
