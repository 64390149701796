import {Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Typography} from '@mui/material'
import React from 'react'
import {useStoreWithEqualityFn} from 'zustand/traditional'
import {useMapStore} from '../../../app/store/MapStore'
import MapPinIcon from '../../../shared/assets/icons/MapPin'
import {CheckBox} from '../../../shared/ui/CheckBox'
import {Spacer} from '../../../shared/ui/Spacer'

const LocationGroup = ({groupName, group, groups, FIELDS}) => {
    console.log('render LocationGroup')
    const toggleLocationGroup = useMapStore((state) => state.toggleLocationGroup)
    const toggleLocation = useMapStore((state) => state.toggleLocation)
    const enabledLocations = useStoreWithEqualityFn(
        useMapStore,
        (state) => state.enabledLocations,
        (prev, next) => {
            const isPrevGroup = group.some((location) => (
                prev.includes(location.id)
            ))
            const isNextGroup = group.some((location) => (
                next.includes(location.id)
            ))

            return !(isPrevGroup || isNextGroup)
        },
    )

    const isLocationGroupEnabled = () => group.some((el) => enabledLocations.includes(el.id))

    const isLocationGroupIndeterminate = () => (
        group.some((el) => !enabledLocations.includes(el.id)) &&
        group.some((el) => enabledLocations.includes(el.id))
    )

    const isLocationEnabled = (location) => enabledLocations.includes(location.id)

    return (
        <Accordion
            variant={'dark'}
            defaultExpanded
            key={`locations-${groupName}`}
            data-cy={groupName}
        >
            <AccordionSummary
                variant={'dark'}
                sx={{height: '32px'}}
            >
                <FormControlLabel
                    onClick={(event) => {
                        event.stopPropagation()
                    }}
                    label={
                        <Typography style={{color: 'var(--palette-grey-900)'}}>
                            {`${groupName} (${group.length})`}
                        </Typography>
                    }
                    control={
                        <CheckBox
                            checked={isLocationGroupEnabled()}
                            indeterminate={isLocationGroupIndeterminate()}
                            onChange={() => {
                                toggleLocationGroup(group)
                            }}
                        />
                    }
                />
            </AccordionSummary>
            <AccordionDetails variant={'dark'}>
                {groups[groupName].map((location) =>
                    (<Box
                        className={'row gap4 fullWidth'}
                        sx={{paddingLeft: '24px', paddingRight: '8px'}}
                        key={`${FIELDS.nameLine}${location.id}`}
                    >
                        <FormControlLabel
                            label={location[FIELDS.nameLine]}
                            control={
                                <CheckBox
                                    checked={isLocationEnabled(location)}
                                    onChange={() => toggleLocation(location.id)}
                                />
                            }
                        />
                        <Spacer/>
                        <MapPinIcon size={'small'}/>
                    </Box>),
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default LocationGroup
