import {Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Typography} from '@mui/material'
import React from 'react'
import {useStoreWithEqualityFn} from 'zustand/traditional'
import {useMapStore} from '../../../../app/store/MapStore'
import {CheckBox} from '../../../../shared/ui/CheckBox'
import CurrentConditions from '../currentconditions/CurrentConditions'

const MapOverlays = ({
    FIELDS,
    groupName,
    group,
}) => {
    const toggleProduct = useMapStore((state) => state.toggleProduct)
    const enabledProducts = useStoreWithEqualityFn(
        useMapStore,
        (state) => state.enabledProducts,
        (prev, next) => {
            const isPrevGroup = prev.some((product) => product.product_group === groupName)
            const isNextGroup = next.some((product) => product.product_group === groupName)

            return !(isPrevGroup || isNextGroup)
        },
    )

    const isProductEnabled = (product) => !!enabledProducts.find((enabledProduct) => enabledProduct.id === product.id)

    return (
        <Accordion
            variant={'dark'}
            defaultExpanded
        >
            <AccordionSummary
                variant={'dark'}
            >
                <Typography>
                    {groupName}
                    {' '}
                    (
                    {group.length}
                    )
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                variant={'dark'}
                style={{display: 'flex', gap: '4px'}}
            >
                {group.map((product) => {
                    if (product.name === 'Current Conditions') {
                        return (
                            <CurrentConditions
                                product={product}
                                enabledProducts={enabledProducts}
                            />
                        )
                    }

                    return (
                        <Box
                            className={'row gap4 fullWidth'}
                            sx={{paddingLeft: '24px', paddingRight: '8px'}}
                        >
                            <FormControlLabel
                                label={product[FIELDS.nameLine]}
                                control={
                                    <CheckBox
                                        checked={isProductEnabled(product)}
                                        onChange={() => toggleProduct(product)}
                                    />
                                }
                            />
                        </Box>
                    )
                })}
            </AccordionDetails>
        </Accordion>
    )
}

export default MapOverlays
