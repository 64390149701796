import TextField from '@mui/material/TextField'
import React, {useEffect, useState} from 'react'
import {
    Box,
    Button,
    MenuItem,
    Typography,
    ToggleButtonGroup, ToggleButton, DialogContent, Autocomplete,
} from '@mui/material'
import MapPinIcon from '../../../shared/assets/icons/MapPin'
import PlusSquareIcon from '../../../shared/assets/icons/PlusSquare'
import RadiusIcon from '../../../shared/assets/icons/Radius'
import {NumberInput} from 'shared/ui/NumberInput'
import InfoCircleIcon from '../../../shared/assets/icons/InfoCircle'
import styles from "./AddLocation.module.scss"
import {clsx} from "clsx";

const POINT_MODE = {
    movePoint: 'movePoint',
    changeRadius: 'changeRadius',
}

export function AddLocation({
    onSave,
    onClose,
    onMove,
    defaultValue = {},
    groups = [],
    freeze = false,
    chosenCoords,
    locations,
    isEditingMode,
}) {
    const [newGroups, setNewGroups] = useState([...groups])
    const allGroupsOptions = newGroups.filter((group) => group.group !== '').map((group) => ({
        group: group.group,
        locations: group.locations,
    }))
    const groupNames = newGroups.map((item) => item.group.toLowerCase())

    const [title, setTitle] = useState(defaultValue.title || '')
    const [radius, setRadius] = useState(defaultValue.radius || '0')
    const [group, setGroup] = useState(allGroupsOptions.find((group) => group.group === defaultValue.group) || null)
    const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false)
    const [pointMode, setPointMode] = useState('')
    const [showErrorMessage, setShowErrorMessage] = useState(false)

    useEffect(() => {
        setTitle(defaultValue.title)
        setNewGroups([...groups])
        const updatedGroups = [...groups]
        const allGroupsOptions = updatedGroups.filter((group) => group.group !== '').map((group) => ({
            group: group.group,
            locations: group.locations,
        }))
        setGroup(allGroupsOptions.find((group) => group.group === defaultValue.group) || null)
        setShowErrorMessage(false)
    }, [defaultValue])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && isAutocompleteFocused) {
                event.stopPropagation()
                handleAddGroupClick(group.group)
                document.removeEventListener('keydown', handleKeyDown)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [group, isAutocompleteFocused])

    const handleAddGroupClick = (inputValue) => {
        if (inputValue && !groupNames.includes(inputValue)) {
            const newOption = {group: inputValue, locations: []}
            const localGroups = [...newGroups]
            localGroups.push(newOption)
            setNewGroups(localGroups)
            setGroup(newOption)
        }
    }

    const handleGroupChange = (event, newValue) => {
        setGroup(newValue)
    }

    const filterOptions = (options, {inputValue}) => {
        const filtered = options.filter((option) => option.group.toLowerCase().includes(inputValue.toLowerCase()))
        if (inputValue && !groupNames.includes(inputValue.toLowerCase())) {
            filtered.unshift({
                group: `${inputValue}`,
                inputValue,
            })
        }
        return filtered
    }

    const renderGroupOption = (props, option) => {
        // Add new group
        if (option.inputValue) {
            return (
                <Button
                    variant={'outlined'}
                    size={'small'}
                    startIcon={<PlusSquareIcon size={'small'}/>}
                    onClick={(event) => {
                        event.stopPropagation()
                        handleAddGroupClick(option.inputValue)
                    }}
                    style={{width: 'max-content'}}
                >
                    {option.inputValue}
                </Button>
            )
        }
        // general option
        return (
            <MenuItem
                {...props}
                style={{minHeight: '28px'}}
            >
                {option.group}
            </MenuItem>
        )
    }

    const handleCancel = () => {
        onClose()
        setTitle('')
        setRadius('0')
        setGroup('')
    }

    const handleSaveLocation = () => {
        const isLocationExists = (title, locations) => locations.find((loc) => loc.label.trim().toLowerCase() === title.trim().toLowerCase())

        if (!isEditingMode && isLocationExists(title, locations)) {
            setShowErrorMessage(true)
            return
        }

        if (title) {
            onSave({title, radius, group: group?.group, newGroups, id: defaultValue.id})
        }
    }

    return (
        <Box className={styles.wrapper}>
            <DialogContent className={clsx('column gap16', styles.contentWrapper)}>
                <Box className={styles.namePartWrapper}>
                    <Typography
                        className={clsx('fullWidth', styles.title)}
                        variant={'label'}
                    >
                        Name
                        <TextField
                            data-cy={'title-input'}
                            value={title}
                            onChange={({target}) => {
                                setTitle(target.value)
                                setShowErrorMessage(false)
                            }}
                            sx={{width: '100%'}}
                            InputProps={{
                                startAdornment: <MapPinIcon size={'small'}/>,
                            }}
                        />
                    </Typography>
                    {showErrorMessage &&
                        <Typography className={styles.errorWrapper}>
                            <span className={styles.errorMessage}>
                                <InfoCircleIcon className={styles.errorIcon}/>
                                Location with the same name already exists.
                            </span>
                        </Typography>}
                </Box>
                <Typography
                    variant={'label'}
                    className={styles.title}
                >
                    Point
                    <ToggleButtonGroup
                        className={'fullWidth'}
                        value={pointMode}
                        exclusive
                    >
                        {typeof onMove === 'function' &&
                            <ToggleButton
                                className={'spacer'}
                                variant={'secondary light'}
                                value={POINT_MODE.movePoint}
                                size={'small'}
                                onClick={() => {
                                    onMove({title, radius, group: group?.group, newGroups, id: defaultValue.id})
                                }}
                            >
                                <MapPinIcon size={'small'}/>
                                <Typography>
                                    Move point
                                </Typography>
                            </ToggleButton>
                        }
                        <ToggleButton
                            className={'spacer'}
                            value={POINT_MODE.changeRadius}
                            variant={'secondary light'}
                            onClick={() => {
                                setPointMode(POINT_MODE.changeRadius)
                            }}
                            disabled
                        >
                            <RadiusIcon size={'small'}/>
                            <Typography sx={{color: '#AFB8C0'}}>
                                Change radius
                            </Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Typography>
                {pointMode === POINT_MODE.changeRadius &&
                    <Typography
                        variant={'label'}
                        className={styles.pointPartWrapper}
                    >
                        Radius
                        <NumberInput
                            placeholder={''}
                            value={radius}
                            onChange={(value) => setRadius(value)}
                            format={(value) => `${value} km`}
                            min={0}
                        />
                    </Typography>
                }
                <Typography variant={'label'} className={styles.title}>
                    Location group
                    <Autocomplete
                        freeSolo
                        value={group}
                        onChange={handleGroupChange}
                        onInputChange={(event, newInputGroupValue) => {
                            setGroup({group: newInputGroupValue, locations: []})
                        }}
                        onFocus={() => setIsAutocompleteFocused(true)}
                        onBlur={() => setIsAutocompleteFocused(false)}
                        options={allGroupsOptions}
                        getOptionLabel={(option) => (option ? option.group : '')}
                        filterOptions={filterOptions}
                        renderOption={renderGroupOption}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='No group'
                            />
                        )}
                        className={styles.locationGroupWrapper}
                        renderTags={() => null}
                        noOptionsText="Start typing to create a new group"
                    />
                </Typography>
                <Box className={clsx('row fullWidth', styles.footerWrapper)}>
                    <Button
                        data-cy={'save-button'}
                        className={'spacer regular'}
                        disabled={freeze || !chosenCoords || title.trim() === ''}
                        onClick={handleSaveLocation}
                    >
                        Save
                    </Button>
                    <Button
                        className={'spacer regular'}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                </Box>
            </DialogContent>
        </Box>
    )
}
