import CloseIcon from 'shared/assets/icons/Close'
import React, {useEffect, useState} from 'react'
import {
    Button,
    Box,
    Typography,
    IconButton,
    Divider,
    AccordionSummary,
    Accordion,
    AccordionDetails
} from '@mui/material'
import ModalComponent from 'shared/ui/ModalComponent'
import EntityGroupSelector from 'features/entityGroupSelector/EntityGroupSelector'
import UploadIcon from '../../assets/icons/Upload'
import FileIcon from '../../assets/icons/File'
import DownloadIcon from '../../assets/icons/Download'
import InfoCircleIcon from '../../assets/icons/InfoCircle'
import TrashIcon from "../../assets/icons/Trash";
import ProgressModal from "../ProgressModal";
import styles from "./ImportCSVModal.module.scss"

export default function ImportCSVModal({
                                           actionHandler,
                                           initialGroups,
                                           typeOfImportingFiles = 'locations' || 'contacts',
                                           buttonTitle,
                                           modalTitle,
                                           isImporting = false
                                       }) {
    const [isOpenImportModal, setIsOpenImportModal] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedGroup, setSelectedGroup] = useState({})
    const [errorMessage, setErrorMessage] = useState('')
    const [duplicateMessages, setDuplicateMessages] = useState([])
    const [otherErrorsMessages, setOtherErrorsMessages] = useState([])
    const [availableGroups, setAvailableGroups] = useState(null)
    const [isDragInArea, setIsDragInArea] = useState(false)

    useEffect(() => {
        if (typeOfImportingFiles === 'locations') {
            setAvailableGroups(initialGroups.filter((group) => group.group && group.group.trim() !== '').map((group) => ({group: group.group})))
        } else if (typeOfImportingFiles === 'contacts') {
            setAvailableGroups(initialGroups.map((group) => ({group: group.groupName})))
        }
    }, [typeOfImportingFiles])

    const handleDragOver = (event) => {
        event.preventDefault()
        event.stopPropagation()
    }

    const handleFileDrop = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setIsDragInArea(false)
        const files = event.dataTransfer.files
        if (files && files.length > 0) {
            const file = files[0]
            setSelectedFile(file)
        }
    }

    const handleFileChange = (event) => {
        const files = event.target.files
        if (files.length > 0) {
            setSelectedFile(files[0])
        }
    }

    const handleImport = () => {
        if (selectedFile) {
            const groupName = selectedGroup.group || ''
            // console.log('Upload successful:', selectedFile.name, groupName);
            // actionHandler(selectedFile, groupName);
            actionHandler(selectedFile, groupName, handleSuccess, handleError)
        } else {
            console.error('No file or group selected')
            setSelectedGroup(false)
        }
    }

    const handleSuccess = (response) => {
        console.log('File uploaded successfully:', response)
        handleCloseModal()
    }

    const handleError = (errorResponse) => {
        let errorMessage = '';

        if (errorResponse && errorResponse.status === "Errors") {
            errorMessage = errorResponse.errors.sort((a, b) => a.reason > b.reason ? -1 : 1)
            errorMessage.forEach((message) => {
                if (message.reason === "duplicate") {
                    setDuplicateMessages(prevState => [...prevState, message])
                } else setOtherErrorsMessages(prevState => [...prevState, message])
            })
        } else if (errorResponse && errorResponse.status === "Error") {
            errorMessage = errorResponse.errors[0].error
            setErrorMessage(errorMessage)
        }

        console.error('Upload error:', errorMessage)
    }

    console.log("duplicateMessages", duplicateMessages)
    console.log("otherErrorsMessages", otherErrorsMessages)

    // const handleError = (errorResponse) => {
    // 	let errorMessage = "Failed to upload file. Please check the format and try again.";
    // 	if (errorResponse && errorResponse.error) {
    // 		errorMessage = errorResponse.error;
    // 	} else if (typeof errorResponse === 'string') {
    // 		errorMessage = errorResponse;
    // 	}
    // 	console.error("Upload error:", errorMessage);
    // 	setErrorMessage(errorMessage);
    // };

    const handleGroupChange = (value) => {
        setSelectedGroup(value)
    }

    const handleAddGroupClick = (inputValue) => {
        if (inputValue) {
            const inputValueLowerCase = inputValue.toLowerCase()
            if (!availableGroups.some((group) => group.group.toLowerCase() === inputValueLowerCase)) {
                const newOption = {group: inputValue}
                setAvailableGroups((prevGroups) => [...prevGroups, newOption])
                setSelectedGroup(newOption)
            }
        }
    }

    const handleResetFields = () => {
        setSelectedFile(null)
        setDuplicateMessages([])
        setOtherErrorsMessages([])
        setErrorMessage('')
    }

    const handleCloseModal = () => {
        handleResetFields()
        setIsOpenImportModal(false)
    }

    const contactsErrorsParsing = (errorReason, errorText) => {
        const patternName = /(name)/;
        const patternNumber = /(number)/;
        const patternEmail = /(email)/;

        if (errorReason === "format") {
            return errorText.replace(/Row \d+: /g, '')
        } else {
            if (patternName.test(errorText)) {
                return "Name: This field may not be blank."
            }
            if (patternNumber.test(errorText)) {
                return "Phone: Enter a valid phone number."
            }
            if (patternEmail.test(errorText)) {
                return "Email: Enter a valid email address."
            }
        }
    }

    const locationsErrorsParsing = (errorText) => {
        const patternLabel = /(label)/;
        const patternCoordinates = /(coordinates)/;
        const patternFormat = /(format)/;

        if (patternLabel.test(errorText)) {
            return "Label: This field may not be blank."
        }
        if (patternCoordinates.test(errorText)) {
            return "Coordinates: Enter a valid coordinates."
        }
        if (patternFormat.test(errorText)) {
            return "Type: Enter a valid location type."
        }
    }

    return (
        <>
            <Button
                startIcon={<UploadIcon size={'medium'}/>}
                variant={'outlined'}
                color={typeOfImportingFiles === 'locations' ? 'primary' : 'secondary'}
                style={{
                    width: typeOfImportingFiles === 'locations' ? '174px' : '97px',
                    fontWeight: '500',
                    fontSize: '15px'
                }}
                onClick={() => setIsOpenImportModal(true)}
            >
                {buttonTitle}
            </Button>
            <ModalComponent visible={isOpenImportModal}>
                <Box
                    className={styles.wrapper}
                    onDragOver={handleDragOver}
                    onDrop={handleFileDrop}
                >

                    <Box className={styles.contentWrapper}>
                        <Box className={styles.headerWrapper}>
                            <h3>
                                {modalTitle}
                            </h3>
                            <IconButton
                                variant={'outlined'}
                                size={'small'}
                                sx={{height: '32px'}}
                                onClick={handleCloseModal}
                            >
                                <CloseIcon sx={{width: '16px', height: '16px'}}/>
                            </IconButton>
                        </Box>

                        <Box className={styles.containerWrapper}>
                            <Box className={styles.container}>
                                {selectedFile && (
                                    <Box className={styles.selectedFileWrapper}
                                         style={{
                                            backgroundColor: errorMessage ? 'var(--palette-error-light)' : 'var(--palette-grey-background)',
                                            border: errorMessage ? '1.5px dashed var(--palette-error-dark)' : '1.5px dashed var(--palette-grey-100)',
                                        }}>
                                        <Box className={styles.selectedFile}>
                                            <FileIcon/>
                                            <Typography className={styles.selectedFileTitle}>
                                                {selectedFile.name}
                                            </Typography>
                                        </Box>
                                        <IconButton
                                            variant={'outlined'}
                                            size={"small"}
                                            onClick={handleResetFields}
                                        >
                                            <TrashIcon size={"small"}/>
                                        </IconButton>
                                    </Box>
                                )}

                                {!selectedFile && (
                                    <Box
                                        className={styles.wrapperArea}
                                        style={{
                                            backgroundColor: duplicateMessages.length > 0 || otherErrorsMessages.length > 0 ? 'var(--palette-warning-light)' : (isDragInArea ? 'var(--palette-primary-light)' : 'var(--palette-grey-background)'),
                                            border: duplicateMessages.length > 0 || otherErrorsMessages.length > 0 ? 'var(--palette-warning-dark)' : (isDragInArea ? '1.5px dashed var(--palette-primary-dark)' : '1.5px dashed var(--palette-grey-100)'),
                                        }}
                                         onDragEnter={() => setIsDragInArea(true)}
                                         onDragLeave={(e) => {
                                             if (!e.currentTarget.contains(e.relatedTarget)) {
                                                 setIsDragInArea(false);
                                             }
                                         }}
                                    >
                                        <Box className={styles.area}>
                                            <FileIcon/>
                                            <Typography className={styles.areaTitle}>
                                                Drag and drop a CSV file here or
                                            </Typography>
                                        </Box>

                                        <Button
                                            variant="outlined"
                                            color={'secondary'}
                                            component="label"
                                            style={{width: '118px', height: '40px'}}
                                        >
                                            <span style={{fontWeight: '500', fontSize: '15px'}}>
                                                Browse files
                                            </span>
                                            <input
                                                type="file"
                                                hidden
                                                onChange={handleFileChange}
                                                accept=".csv"
                                            />
                                        </Button>
                                    </Box>
                                )}
                                {errorMessage && (
                                    <Typography className={styles.errorWrapper}>
                                            <span className={styles.errorMessage}>
                                                <InfoCircleIcon className={styles.errorIcon}/>
                                                {errorMessage}
                                            </span>
                                    </Typography>
                                )}
                                {(duplicateMessages.length > 0 || otherErrorsMessages.length > 0) && (
                                    <Box className={styles.warningsWrapper}>
                                        <Typography className={styles.warningMessageWrapper}>
                                                <span className={styles.warningMessage}>
                                                    <InfoCircleIcon className={styles.warningIcon}/>
                                                    Your import file contains errors. They will be skipped during import.
                                                </span>
                                        </Typography>
                                        {duplicateMessages.length > 0 &&
                                            <Accordion className={styles.accordion}>
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Box>
                                                        <Typography
                                                            className={styles.mainTitle}
                                                            sx={{
                                                                lineHeight: "24px",
                                                            }}>
                                                            Duplications ({duplicateMessages.length})
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails className={styles.accordionDetails}>
                                                    <Box className={styles.accordionDetailsContent}>
                                                        {duplicateMessages.map((message, count) => (
                                                            <Box key={message} style={{width: "100%"}}>
                                                                <Typography className={styles.mainTitle}>
                                                                    Duplicate Entry {count + 1}:
                                                                </Typography>
                                                                {typeOfImportingFiles === 'contacts' ?
                                                                    <ul className={styles.accordionDetailsContentItem}>
                                                                        <li> Name: {message.name} </li>
                                                                        <li> Phone
                                                                            (Mobile): {message.number || "_"} </li>
                                                                        <li> Email: {message.email || "_"} </li>
                                                                        (Contact with name "{message.name}", phone
                                                                        "{message.number || "_"}" and email
                                                                        "{message.email || "_"}" already exists)
                                                                    </ul> :
                                                                    <ul className={styles.accordionDetailsContentItem}>
                                                                        <li> Label: {message.label} </li>
                                                                        <li> Coordinates:
                                                                            [{message.coordinates[0]}, {message.coordinates[1]}]
                                                                        </li>
                                                                        <li> Type: {message.location_type} </li>
                                                                        (Location with label "{message.label}", and
                                                                        coordinates
                                                                        [{message.coordinates[0]}, {message.coordinates[1]}]
                                                                        already exists)
                                                                    </ul>
                                                                }
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                        {otherErrorsMessages.length > 0 &&
                                            <Accordion className={styles.accordion}>
                                                <AccordionSummary
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Box>
                                                        <Typography
                                                            className={styles.mainTitle}
                                                            sx={{
                                                                lineHeight: "24px"
                                                            }}>
                                                            Other ({otherErrorsMessages.length})
                                                        </Typography>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails className={styles.accordionDetails}>
                                                    <Box className={styles.accordionDetailsContent}>
                                                        {otherErrorsMessages.map((message, count) => (
                                                            <Box key={message} style={{width: "100%"}}>
                                                                <Typography className={styles.mainTitle}>
                                                                    Error {count + 1}: {message.reason} error
                                                                </Typography>
                                                                {typeOfImportingFiles === 'contacts' ?
                                                                    <ul className={styles.accordionDetailsContentItem}>
                                                                        <li> Name: {message.name || "_"} </li>
                                                                        <li> Phone
                                                                            (Mobile): {message.number || "_"} </li>
                                                                        <li> Email: {message.email || "_"} </li>
                                                                        {contactsErrorsParsing(message.reason, message.error)}
                                                                    </ul> :
                                                                    <ul className={styles.accordionDetailsContentItem}>
                                                                        <li> Label: {message.label || "_"} </li>
                                                                        <li> Coordinates:
                                                                            [{message.coordinates[0]}, {message.coordinates[1]}]
                                                                        </li>
                                                                        <li> Type: {message.location_type} </li>
                                                                        {locationsErrorsParsing(message.error)}
                                                                    </ul>
                                                                }
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        }
                                    </Box>
                                )}
                            </Box>
                            <Typography className={styles.helpMessageWrapper}>
                                Need help? Download
                                <a
                                    className={styles.helpMessage}
                                    href={typeOfImportingFiles === 'locations' ? 'locations-examples.csv' : 'contacts-examples.csv'}
                                    download
                                >
                                    a sample CSV file
                                    {' '}
                                    <DownloadIcon
                                        size={'small'}
                                        className={styles.helpMessageIcon}
                                    />
                                </a>
                            </Typography>
                        </Box>

                        <Box className={styles.groupWrapper}>
                            <Typography className={styles.groupWrapperTitle}>
                                {typeOfImportingFiles === 'locations' ? 'Location' : 'User'}
                                {' '}
                                group
                            </Typography>
                            <EntityGroupSelector
                                value={selectedGroup}
                                onChange={handleGroupChange}
                                options={availableGroups}
                                onInputChange={(newInputGroupValue) => {
                                    if (selectedGroup?.group !== newInputGroupValue) {
                                        setSelectedGroup({group: newInputGroupValue})
                                    }
                                }}
                                onAddOption={handleAddGroupClick}
                            />
                        </Box>

                        <Box className={styles.footerWrapper}>
                            <Divider className={styles.divider}/>

                            <Box className={styles.buttonsWrapper}>
                                <Button
                                    className={'spacer regular'}
                                    style={{width: '50%'}}
                                    onClick={handleImport}
                                    color="primary"
                                    disabled={!selectedFile || duplicateMessages.length > 0 || otherErrorsMessages.length > 0 || isImporting}
                                >
                                    {modalTitle}
                                </Button>
                                <Button
                                    className={'spacer regular'}
                                    style={{width: '50%'}}
                                    onClick={handleCloseModal}
                                    variant={'outlined'}
                                    color={'secondary'}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </ModalComponent>
            <ProgressModal visible={isImporting}/>
        </>
    )
}
