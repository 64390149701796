import {Box, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {SearchInput} from 'shared/ui/SearchInput'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import {useMapStore} from '../../../app/store/MapStore'
import {GroupedFilter} from './GroupListFilter'

const filtersType = {
    layers: 'Layers',
    locations: 'Locations',
}

export const MapSidebar = (
    {
        reEnableWmsIfNeed,
    }) => {
    const locations = useLocationsStore((state) => state.locations)

    const products = useMapStore((state) => state.products)
    const enabledProducts = useMapStore((state) => state.enabledProducts)

    const [textFilter, setTextFilter] = useState('')
    const [filter, setFilter] = useState(filtersType.layers)
    const [displayedFilterList, setDisplayedFilterList] = useState(<></>)
    const filterListRef = useRef(null)

    useEffect(() => {
        const search = textFilter.toLowerCase()
        if (filter === filtersType.layers) {
            setDisplayedFilterList(
                <GroupedFilter
                    reEnableWmsIfNeed={reEnableWmsIfNeed}
                    objects={search ? products.filter((p) => (p.name.toLowerCase().indexOf(search) > -1)) : products}
                    key="productsFilter"
                    disableGroupCheckbox={true}
                />)
        }
        if (filter === filtersType.locations) {
            setDisplayedFilterList(
                <GroupedFilter
                    reEnableWmsIfNeed={reEnableWmsIfNeed}
                    objects={search ? locations.filter((p) => (p.label.toLowerCase().indexOf(search) > -1)) : locations}
                    typeFilter="locations"
                    key="locationsFilter"
                    disableGroupCheckbox={false}
                />)
        }
    }, [textFilter, filter, products])

    const handleFilterChange = (event, newFilter) => {
        if (!newFilter) {
            return
        }
        setFilter(newFilter)
    }

    console.log('render sidebar')
    return (
        <Box
            className={'column fullHeight'}
            sx={{minWidth: '288px'}}
        >
            <Box
                className={'column'}
                sx={{gap: '10px', padding: '10px 16px', width: '400px'}}
            >
                <SearchInput
                    placeholder={`Search for ${filter === filtersType.layers ? 'layers' : 'locations'}`}
                    onChange={(event) => setTextFilter(event.target.value)}
                />
                <ToggleButtonGroup
                    className={'fullWidth'}
                    value={filter}
                    exclusive
                    size={'small'}
                    onChange={handleFilterChange}
                >
                    <ToggleButton
                        variant={'secondary'}
                        value={filtersType.layers}
                        size={'small'}
                    >
                        Layers
                    </ToggleButton>
                    <ToggleButton
                        variant={'secondary'}
                        value={filtersType.locations}
                        size={'small'}
                    >
                        Locations
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box
                    ref={filterListRef}
                    className={'column gap8 fullWidth hint--top hint-rounded'}
                    sx={{height: `${window.innerHeight - 200}px`, textOverflow: 'ellipsis'}}
                >
                    {filter === filtersType.layers &&
                        <Box
                            style={{
                                minHeight: '52px',
                                color: 'var(--palette-grey-500)',
                                padding: '10px 16px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Box style={{fontSize: '14px', fontWeight: '400'}}>
                                Select up to 5 items
                            </Box>
                            <Box
                                style={{
                                    fontSize: '12px',
                                    fontWeight: '500',
                                    backgroundColor: 'white',
                                    height: '32px',
                                    width: '32px',
                                    borderRadius: '8px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                {enabledProducts.length}
/5
                            </Box>
                        </Box>
                    }
                    {displayedFilterList}
                </Box>
            </Box>
        </Box>
    )
}
