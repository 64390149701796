import React from 'react'
import {Box, IconButton} from '@mui/material'
import MapPinIcon from '../../../../../shared/assets/icons/MapPin'
import {Spacer} from '../../../../../shared/ui/Spacer'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import CloseIcon from '../../../../../shared/assets/icons/Close'
import styles from './SelectedLocation.module.scss'

const SelectedLocation = ({location, onEdit, onDelete}) => (
    <Box className={styles.wrapper}>
        <Box className={styles.locationItem}>
            <Box className={styles.titleWrapper}>
                <Box style={{marginTop: '1px'}}>
                    <MapPinIcon size={'small'}/>
                </Box>
                <Box className={styles.title}>
                    {location.label}
                </Box>
            </Box>
            <Box className={styles.group}>
                {location.location_group}
            </Box>
        </Box>
        <Spacer/>
        <Box className={styles.buttonsWrapper}>
            <IconButton
                className={styles.button}
                data-cy={'edit-button'}
                variant={'outlined'}
                color={'secondary'}
                size={'small'}
                onClick={onEdit}
            >
                <PenIcon size={'small'}/>
            </IconButton>
            <IconButton
                className={styles.button}
                variant={'contained'}
                color={'error'}
                size={'small'}
                onClick={onDelete}
            >
                <CloseIcon size={'small'}/>
            </IconButton>
        </Box>
    </Box>
)

export default SelectedLocation
