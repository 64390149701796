import {Box, Button, Divider, FormControlLabel, Link, TextField, Typography, useMediaQuery} from '@mui/material'
import React, {useEffect, useRef, useState} from 'react'
import {CSSTransition} from 'react-transition-group'

import {forgotPassword, loginUser} from '../../shared/libs/Auth'
import {CheckBox} from 'shared/ui/CheckBox'
import {Spacer} from 'shared/ui/Spacer'
import cloud from '../../shared/assets/svg/cloud.svg'
import cloud_1 from '../../shared/assets/svg/cloud_1.svg'
import logo from '../../shared/assets/png/logo.png'
import moon from '../../shared/assets/svg/moon.svg'
import 'pages/Auth.css'
import './Login.css'
import {isEmailValid} from 'shared/libs/Utils'
import {PasswordField} from 'shared/ui/PasswordField'

const SECTIONS = {
    LoginSection: 'LoginSection',
    ForgotPasswordSection: 'ForgotPasswordSection',
    PasswordResetSection: 'PasswordResetSection',
}

export default function Login() {
    const [section, setSection] = useState(SECTIONS.LoginSection)
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [rememberMe, setRememberMe] = useState(false)
    const [showErrorEmail, setShowErrorEmail] = useState(false)

    const [validated, setValidated] = React.useState(false)
    const [validateError, setValidateError] = useState({
        email: '',
    })
    const [loginError, setLoginError] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState('')
    const [forgotPasswordError, setForgotPasswordError] = useState(false)
    const [forgotPasswordErrorMessage, setForgotErrorMessage] = useState('')
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState('')
    const [isValidEmail, setIsValidEmail] = useState(false)
    const loginSectionRef = useRef(null)
    const forgotPasswordSectionRef = useRef(null)
    const passwordResetSectionRef = useRef(null)

    const isSmallScreen = useMediaQuery('(max-width: 992px)')

    useEffect(() => {
        const emailValid = isEmailValid(email)
        const isValid = emailValid && !!password

        setValidated(isValid)
        setValidateError({
            email: email ? (emailValid ? '' : 'Invalid email address format') : '',
        })
    }, [email, password])

    function handleLogin(event) {
        event?.preventDefault()
        loginUser(email, password, rememberMe)
            .then(() => {
                window.location.replace('/alerts')
            })
            .catch((error) => {
                setValidated(false)
                setLoginError(true)
                setLoginErrorMessage(error?.response?.data?.detail ?? 'Unknown error')
            })
    }

    function handleForgotPassword(event) {
        event?.preventDefault()
        forgotPassword(forgotPasswordEmail)
            .then(() => {
                setSection(SECTIONS.PasswordResetSection)
                setForgotErrorMessage('')
            })
            .catch((error) => {
                setIsValidEmail(false)
                setForgotPasswordError(true)
                setForgotErrorMessage(error?.response?.data?.detail ?? 'Unknown error')
            })
    }

    const handleForgotPasswordChange = (event) => {
        const newEmail = event.target.value
        const isNewEmailValid = isEmailValid(event.target.value)
        setForgotPasswordEmail(newEmail)
        setForgotPasswordError(!isNewEmailValid)
        setIsValidEmail(isNewEmailValid)
        setForgotErrorMessage('')
    }

    function loginSection(props) {
        return (
            <div
                className={'section'}
                {...props}
            >
                <div className={'sectionHeader'}>
                    <h3>
Welcome Back
                    </h3>
                </div>
                <Box
                    className={'loginFormContainer form'}
                    component="form"
                    onSubmit={handleLogin}
                >
                    <div
                        className={'row'}
                        style={{justifyContent: 'center'}}
                    >
                        <img
                            className={'baronLogo'}
                            src={logo}
                            alt={'baron_logo'}
                        />
                    </div>
                    <Typography
                        component={'span'}
                        variant={'label'}
                    >
                        Email
                        <TextField
                            data-cy={'login-email'}
                            autoComplete={'on'}
                            onChange={(event) => setEmail(event.target.value)}
                            onBlur={() => setShowErrorEmail(true)}
                            value={email}
                        />
                        {showErrorEmail && validateError.email && (
                            <Typography
                                color="error"
                                variant="caption"
                            >
                                {validateError.email}
                            </Typography>
                        )}
                    </Typography>
                    <Typography
                        component={'span'}
                        variant={'label'}
                    >
                        Password
                        <PasswordField
                            data-cy={'login-password'}
                            onChange={(event) => setPassword(event.target.value)}
                            error={loginError}
                            helperText={loginErrorMessage}
                        />
                    </Typography>
                    <div className={'forgotPasswordContainer'}>
                        <FormControlLabel
                            label={'Remember me'}
                            onChange={() => setRememberMe(!rememberMe)}
                            control={<CheckBox checked={rememberMe}/>}
                        />
                        <Link
                            data-cy={'forgot-password'}
                            type="button"
                            component={'button'}
                            onClick={() => setSection(SECTIONS.ForgotPasswordSection)}
                        >
                            Forgot your password?
                        </Link>
                    </div>
                    <Spacer/>
                    {isSmallScreen
                        ? <>
                            <div
                                className={'row'}
                                style={{flexDirection: 'column'}}
                            >
                                <div>
                                    <Button
                                        data-cy={'login'}
                                        type={'submit'}
                                        disabled={!validated}
                                        onClick={handleLogin}
                                        sx={{width: '160px'}}
                                    >
                                        Log in
                                    </Button>
                                </div>
                                <Divider/>
                                <div>
                                    <div
                                        className={'subtitle signup__text'}
                                        style={{color: '#48515B'}}
                                    >
                                        Welcome to
                                        {' '}
                                        <b>
Baron weather
                                        </b>
! To continue you will need to
                                        {' '}
                                        <br/>
                                        create a new account for the Baron Weather API site
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        href={'signup'}
                                        variant={'outlined'}
                                        sx={{width: '160px'}}
                                    >
                                        Sign up
                                    </Button>
                                </div>
                            </div>
                        </>
                        : <div
                            className={'row'}
                            style={{justifyContent: 'center'}}
                        >
                            <Button
                                data-cy={'login'}
                                type={'submit'}
                                disabled={!validated}
                                onClick={handleLogin}
                                sx={{width: '160px'}}
                            >
                                Log in
                            </Button>
                        </div>
                    }
                </Box>
            </div>
        )
    }

    function forgotPasswordSection(props) {
        const handleResetStates = () => {
            setForgotPasswordEmail('')
            setSection(SECTIONS.LoginSection)
        }
        return (
            <div
                className={'section'}
                {...props}
            >
                <div className={'sectionHeader'}>
                    <h3>
Forgot password?
                    </h3>
                    <div className={'subtitle'}>
                    Enter your login email below. We will send you an email with a link to reset
                        {' '}
                        <br/>
                    your account password.
                    </div>
                </div>
                <Box
                    className={'form loginForm'}
                    component="form"
                    onSubmit={handleForgotPassword}
                >
                    <div
                        className={'row'}
                        style={{justifyContent: 'center'}}
                    >
                        <img
                            className={'baronLogo'}
                            src={logo}
                            alt={'baron_logo'}
                        />
                    </div>
                    <Spacer/>
                    <Typography
                        component={'span'}
                        variant={'label'}
                    >
                    Email
                        <TextField
                            data-cy={'forgot-password-email'}
                            autoComplete={'on'}
                            error={forgotPasswordError}
                            helperText={forgotPasswordErrorMessage}
                            onChange={handleForgotPasswordChange}
                        />
                    </Typography>
                    <Spacer/>
                    <div
                        className={'row'}
                        style={{justifyContent: 'center'}}
                    >
                        <Button
                            data-cy={'forgot-password-submit'}
                            type={'submit'}
                            disabled={!isValidEmail}
                            onClick={handleForgotPassword}
                            sx={{width: '160px'}}
                        >
                        Send
                        </Button>
                        <Button
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={handleResetStates}
                            sx={{width: '160px'}}
                        >
                        Cancel
                        </Button>
                    </div>
                </Box>
            </div>
        )
    }

    function passwordResetSection(props) {
        return (
            <div
                className={'section'}
                {...props}
            >
                <Box className={'sectionHeader'}>
                    <h3>
Done!
                    </h3>
                    <div className={'subtitle'}>
                        Password reset was reqested successfully!
                        <br/>
                    </div>
                </Box>
                <Box
                    className={'form'}
                    component="form"
                >
                    <div
                        className={'row'}
                        style={{justifyContent: 'center'}}
                    >
                        <img
                            className={'baronLogo'}
                            src={logo}
                            alt={'baron_logo'}
                        />
                    </div>
                    <Spacer/>
                    <Box className={'sectionHeader'}>
                        <h3>
Check your email
                        </h3>
                        <div className={'subtitle'}>
                            If your email address exists in our database, you will receive a password
                            {' '}
                            <br/>
                            recovery link at your email address
                            {' '}
                            <b>
                                {forgotPasswordEmail}
                            </b>
                            {' '}
in a few minutes.
                        </div>
                    </Box>
                    <Spacer/>
                </Box>
            </div>
        )
    }

    return (
        <div className={'background'}>
            <div className={'dawnGradient'}>
                <div className={'leftGradient'}>
                    <img
                        className={'cloud_0'}
                        src={cloud}
                        alt={'cloud'}
                    />
                    <div className={'cloudMoonArea'}>
                        <img
                            className={'moon'}
                            src={moon}
                            alt={'moon'}
                        />
                        <img
                            className={'cloud_1'}
                            src={cloud_1}
                            alt={'cloud_1'}
                        />
                    </div>
                    <img
                        className={'cloud_2'}
                        src={cloud}
                        alt={'cloud'}
                    />
                    <img
                        className={'cloud_3'}
                        src={cloud}
                        alt={'cloud'}
                    />
                    <div className={'container'}>
                        {isSmallScreen
                            ? <div className={'leftContainer'}>
                                <CSSTransition
                                    in={section === SECTIONS.LoginSection}
                                    nodeRef={loginSectionRef}
                                    classNames={'section'}
                                    unmountOnExit
                                >
                                    {loginSection({ref: loginSectionRef})}
                                </CSSTransition>
                                <CSSTransition
                                    in={section === SECTIONS.ForgotPasswordSection}
                                    nodeRef={forgotPasswordSectionRef}
                                    timeout={1000}
                                    classNames={'section'}
                                    unmountOnExit
                                >
                                    {forgotPasswordSection({ref: forgotPasswordSectionRef})}
                                </CSSTransition>
                                <CSSTransition
                                    in={section === SECTIONS.PasswordResetSection}
                                    nodeRef={passwordResetSectionRef}
                                    timeout={1000}
                                    classNames={'section'}
                                    unmountOnExit
                                >
                                    {passwordResetSection({ref: passwordResetSectionRef})}
                                </CSSTransition>
                            </div>
                            : <>
                                <div className={'leftContainer'}>
                                    <CSSTransition
                                        in={section === SECTIONS.LoginSection}
                                        nodeRef={loginSectionRef}
                                        classNames={'section'}
                                        unmountOnExit
                                    >
                                        {loginSection({ref: loginSectionRef})}
                                    </CSSTransition>
                                    <CSSTransition
                                        in={section === SECTIONS.ForgotPasswordSection}
                                        nodeRef={forgotPasswordSectionRef}
                                        timeout={1000}
                                        classNames={'section'}
                                        unmountOnExit
                                    >
                                        {forgotPasswordSection({ref: forgotPasswordSectionRef})}
                                    </CSSTransition>
                                    <CSSTransition
                                        in={section === SECTIONS.PasswordResetSection}
                                        nodeRef={passwordResetSectionRef}
                                        timeout={1000}
                                        classNames={'section'}
                                        unmountOnExit
                                    >
                                        {passwordResetSection({ref: passwordResetSectionRef})}
                                    </CSSTransition>
                                </div>

                                <div className={'alreadySignedUpContainer'}>
                                    <div className={'sectionHeader alreadySignedUpSection'}>
                                        <h3 className={'white'}>
                                            Don’t have an account?
                                        </h3>
                                        <div
                                            className={'subtitle signup__text'}
                                            style={{marginTop: '25px', color: 'var(--palette-grey-100)'}}
                                        >
                                            To continue you will need to create an account.
                                        </div>
                                        <Spacer/>
                                        <Button
                                            className={'signup__btn'}
                                            href={'signup'}
                                            variant={'outlined'}
                                            sx={{width: '160px'}}
                                        >
                                            Sign up
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
