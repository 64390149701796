import React, {useEffect, useState} from 'react'
import {Button, IconButton, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import ArrowLeftSmallIcon from '../../../../../shared/assets/icons/ArrowLeftSmall'
import CopyIcon from '../../../../../shared/assets/icons/Copy'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../../shared/assets/icons/Trash'
import MoreIcon from '../../../../../shared/assets/icons/More'
import {DefinitionRenderLarge} from '../../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderLarge/DefinitionRenderLarge'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import AgreeModal from '../../../../../shared/ui/AgreeModal'
import ActionItemDetailsParameters from '../ActionItemsDetailsParameters/ActionItemsDetailsParameters'
import styles from './ActionItemDetails.module.scss'
import {clsx} from 'clsx'

const filtersType = {
    weather_definitions: 'Weather definitions',
    reports: 'Reports',
}

const ActionItemDetails = ({actionItem}) => {
    const {
        deleteActionItem,
        openActionItem,
        toggleEditingActionItem,
        toggleDuplication,
    } = useActionItemsStore((state) => state)

    const {
        fetchWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const [filter, setFilter] = useState(filtersType.weather_definitions)
    const [definition, setDefinition] = useState(null)
    const [deletingActionItem, setDeletingActionItem] = useState(null)

    useEffect(() => {
        fetchWeatherDefinition(actionItem.weather_definition.id).then((data) => {
            setDefinition(data)
        })
    }, [])

    const handleDeleteActionItem = () => {
        setDeletingActionItem(actionItem)
    }

    const handleEditActionItem = () => {
        toggleEditingActionItem(actionItem)
    }

    const handleDuplicateActionItem = () => {
        toggleDuplication(true)
        toggleEditingActionItem(actionItem)
    }

    const approveDeleteActionItem = (ActionItemId, agree) => {
        if (agree) {
            deleteActionItem(ActionItemId)
            handleClose()
        }
        setDeletingActionItem(null)
    }

    const handleClose = () => {
        openActionItem(null)
    }

    return (
        <>
            <div className={'fullHeight fullWidth'}>
                <div
                    className={'settings'}
                    style={{height: '100%'}}
                >
                    <div className={clsx('settings-toolbar', styles.toolbarBorder)}>
                        <IconButton
                            onClick={handleClose}
                            variant={'outlined'}
                            data-cy={'ai-left-button'}
                            size={'small'}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <h3>
                            {actionItem.name}
                        </h3>
                        <div style={{flexGrow: 1}}/>
                        <div style={{display: 'flex', gap: '10px'}}>
                            {<>
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    data-cy={'ai-details-copy-button'}
                                    startIcon={<CopyIcon/>}
                                    onClick={handleDuplicateActionItem}
                                >
                                    Copy
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    data-cy={'ai-details-edit-button'}
                                    startIcon={<PenIcon/>}
                                    onClick={handleEditActionItem}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    color={'error'}
                                    data-cy={'ai-details-delete-button'}
                                    startIcon={<TrashIcon/>}
                                    onClick={handleDeleteActionItem}
                                >
                                    Delete
                                </Button>
                            </>}
                        </div>
                    </div>
                    <div className={styles.contentWrapper}>
                        <ActionItemDetailsParameters
                            actionItems={actionItem}
                            definition={definition}
                            title={'Parameters'}
                        />

                        <div className={'actionItemsContentWrapper'}>
                            <ToggleButtonGroup
                                style={{width: '400px'}}
                                value={filter}
                                exclusive
                            >
                                <ToggleButton
                                    variant={'secondary'}
                                    value={filtersType.weather_definitions}
                                    onClick={() => setFilter(filtersType.weather_definitions)}
                                >
                                    Weather definitions
                                </ToggleButton>
                                <ToggleButton
                                    variant={'secondary'}
                                    value={filtersType.reports}
                                    onClick={() => setFilter(filtersType.reports)}
                                    disabled
                                >
                                    Reports
                                </ToggleButton>
                            </ToggleButtonGroup>
                            <div
                                className={'actionItemsContentWrapper'}
                                style={{padding: '16px', gap: '8px'}}
                            >
                                {filter === filtersType.weather_definitions && definition &&
                                    <>
                                        <div className={styles.parametersWrapper}>
                                            <Typography className={styles.parametersTitle}>
                                                Parameters
                                            </Typography>
                                            <IconButton
                                                data-cy={'more-button'}
                                                id="basic-button"
                                                variant={'outlined'}
                                                className={styles.iconButton}
                                            >
                                                <MoreIcon size={'small'}/>
                                            </IconButton>
                                        </div>
                                        <DefinitionRenderLarge definition={definition}/>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deletingActionItem &&
                <AgreeModal
                    data={{
                        message: 'Are you sure you want to delete this action item?',
                        title: 'Delete action item',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: (agree) => approveDeleteActionItem(deletingActionItem.id, agree),
                    }}
                />
            }
        </>
    )
}

export default ActionItemDetails
