import {useMapStore} from "../../../../app/store/MapStore";
import {NON_STANDARD_PROJECTION} from "../../ui/MapLibre";
import {TileLayer, SSRTileLayer} from './Tilev2';
import {WmsLayer} from './WMSv2';
import {TextProductHandler} from "features/textProducts/lib/TextProductsHandler";
import {TimeRangeMap} from "./TimeRangeMap";

export const TEXT_PRODUCTS_CODES = [
    'lightning',
    'metar',
    'metar-advanced',
    'nexrad',
    'nhc_tropical_tracks',
    'baron_storm_tracks',
    'baron_storm_tracks_tornado_high_probability',
    'baron_storm_tracks_tornado_low_probability',
    'baron_storm_tracks_high_winds',
    'baron_storm_tracks_extreme_hail',
    'baron_storm_tracks_hail',
    'baron_storm_tracks_storm',
];

export class ProductsController {

    constructor(options) {
        this.tileLayers = [];
        this.wmsLayers = [];
        this.signature = options.signature;
        this.map = options.map

        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.onChangeTimeRange = options.onChangeTimeRange; // temporary? time can be sets from hardcoded values
        this.time = options.time;

        this.opacity = options.opacity || 1;
        this.mode = false;
        this.setSelectedTime = options.setSelectedTime
        this.textProductController = new TextProductHandler(this.map, options.signature);
    }

    calculateRange(productCodes) {
        const times = productCodes.map(s => (s && s.split('/')[0])).map(h => TimeRangeMap[h]).filter(time => !isNaN(time));
        const min = Math.min(0, ...times) * 60 * 1000; // min <0 for current
        const max = Math.max(0, ...times) * 60 * 1000;
        const now = new Date().getTime();
        return {
            now,
            startDate: (min === max) ? now - 3600 * 1000 : now + min,
            endDate: now + max
        }
    }

    _detectFullRange(prods) {
        const times = prods.map(s => (s && s.split('/')[0])).map(h => TimeRangeMap[h]).filter(time => !isNaN(time));
        const min = Math.min(0, ...times) * 60 * 1000; // min <0 for current
        const max = Math.max(0, ...times) * 60 * 1000;
        const current = new Date().getTime();
        const stateDate = {
            startDate: (min === max) ? current - 3600 * 1000 : current + min,
            endDate: current + max
        }
        this.onChangeTimeRange(stateDate)
        this.time > stateDate.startDate && this.time < stateDate.endDate
            ? this.setSelectedTime(this.time)
            : this.setSelectedTime(current)
    }

    getProductCode(product) {
        const productCode = TEXT_PRODUCTS_CODES.includes(product.api_product_code)
            ? prod.api_product_code
            : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`

        return productCode
    }

    async setProducts2(newProducts) {
        const {products} = useMapStore.getState()
        const mapProducts = []
        const productObjects = [] // REALLY CRINGE!!! TODO: pass into entire product object, not only product code

        newProducts.forEach((p) => {
            const prod = products.find((pr) => (pr.id === p.id))
            if (prod) {
                const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                    ? prod.api_product_code
                    : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`
                mapProducts.push(productCode)
                productObjects.push(prod)
            } else {
                console.error(`Product with id ${p.id} not found.`)
            }
        })

        this.setProducts(mapProducts, productObjects)
    }

    setProducts(productList = [], productObjects = []) {
        const newTileLayers = [];
        this.tileLayers.forEach(l => {
            productList.includes(l.id) ?
                newTileLayers.push(l) :
                l.disable();
        });
        TEXT_PRODUCTS_CODES.forEach(p => {
            if (!productList.includes(p)) {
                this.textProductController.disableProduct(p);
            }
        });
        productList.forEach(p => {
            if (!newTileLayers.some(l => (l.id === p))) {
                TEXT_PRODUCTS_CODES.includes(p) ?
                    this.textProductController.enableProduct(p, productObjects) :
                    newTileLayers.push(this._createLayer(p));
            }
        })
        this._detectFullRange(productList);
        this.tileLayers = newTileLayers;
        // todo: do we allow it in wms mode ? looks like no
    }

    // for now 2 values. false - tile rendering, true - WMS animation mode
    switchWMSMode(mode = false, onWMSLoad) {
        this.mode = mode;
        if (mode) {
            let count = this.tileLayers.length;
            if (!count) {
                onWMSLoad && onWMSLoad();
                return;
            }
            const steps = [];
            for (let t = this.startDate; t < this.endDate; t += Math.round((this.endDate - this.startDate) * 0.01)) {
                steps.push(t);
            }
            this.wmsLayers.forEach(l => l.remove());
            this.wmsLayers = this.tileLayers.map(layer => {
                layer.hide();
                return new WmsLayer({
                    layer,
                    steps,
                    onReady: () => {
                        count--;
                        if (!count) {
                            onWMSLoad && onWMSLoad();
                            return;
                        }
                    }
                });
            });
            return;
        }

        this.wmsLayers.forEach(l => l.remove());
        this.wmsLayers = [];
        this.tileLayers.forEach(l => l.show());

    }

    setOpacity(opacity = 1) {
        this.tileLayers.forEach(l => l.setOpacity(opacity));
        this.wmsLayers.forEach(l => l.setOpacity(opacity));
    }

    setTime(time) {
        this.time = time;
        this.tileLayers.forEach(l => l.setTime(time));
        this.wmsLayers.forEach(l => l.setTime(time));
        this.textProductController.setTime(time);
    }

    setTimeRange({startDate, endDate}) {
        this.startDate = startDate || this.startDate;
        this.endDate = endDate || this.endDate;
        this.tileLayers.forEach(l => l.setTimeRange({startDate, endDate}));
    }

    async getPQ({lat, lng}) {
        const promises = [];
        for (let key in this.tileLayers) {
            const layer = this.tileLayers[key];
            let promise;
            if (layer.id === "alert-all-poly/Standard-Mercator") {
                promise = layer.getPointQueryForWatchesAndWarningsText(lat, lng, this.time)
            } else {
                promise = layer.getPointQuery(lat, lng, this.time);
            }
            promises.push(promise);
        }

        return Promise.all(promises).then(values => {
            const response = {};
            values.forEach(v => {
                if (!v) return;
                response[v.id] = v.data;
            });
            return response;
        });
    }

    _createLayer(layerId) {
        const obj = {
            id: layerId,
            map: this.map,
            firstLabelLayerId: layerId.includes('radar') ? 'tunnel-service-track-casing' : 'building-top',
            startDate: this.startDate,
            endDate: this.endDate,
            time: this.time,
            signature: this.signature,
            onInit: () => {
                this.mode && this.switchWMSMode(true, () => {
                });
            }
        };

        if (layerId.includes('{SSR}')) {
            return new SSRTileLayer(obj);
        }
        return new TileLayer(obj);
    }
}