import React, {useState} from 'react'
import {Box, Divider, FormControlLabel, FormGroup, Switch, ToggleButton, ToggleButtonGroup} from '@mui/material'
import InfoPanel from '../../../../../shared/ui/InfoPanel/InfoPanel'
import ActionItemsAdvancedList from '../../../../../pages/settings/ui/actionItems/ActionItemsAdvancedList/ActionItemsAdvancedList'

function ReportingStepScreen({isIncludeReport, includeReportOnChange, chosenActionItems, onChangeActionItem}) {
    const [viewMode, setViewMode] = useState('general') // general or advanced

    const handleChangeViewMode = (event, newVal) => {
        if (!newVal) {
            return
        }
        setViewMode(newVal)
    }

    return (
        <Box
            sx={{
                width: 1,
                boxSizing: 'border-box',
                borderRadius: '16xp',
                background: '#FFFFFF',
                display: 'flex',
                flexDirection: 'column',
                paddingTop: '2px',
                gap: '24px',
                height: '100%',
                overflow: 'auto',
            }}
        >
            <Box
                sx={{
                    maxWidth: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '16px',
                    gap: '12px',
                    border: '1px solid #f0f2f5',
                    borderRadius: '12px',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        padding: '5px 0',
                        border: '1px solid #f0f2f5',
                        borderRadius: '12px',
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            label="Include a detailed report with notification"
                            control={<Switch
                                onChange={includeReportOnChange}
                                checked={isIncludeReport}
                                     />}
                        />
                    </FormGroup>
                </Box>
                <InfoPanel
                    title={'Report setup'}
                    text={'These report settings are global for the selected locations and weather definitions. Each notification for this alert will include a report link.'}
                    isOpen={isIncludeReport}
                />
            </Box>
            {isIncludeReport &&
                <>
                    <Divider/>
                    <Box style={{display: 'flex', flexDirection: 'column', gap: '16px', height: '100%', overflow: 'hidden'}}>
                        <ToggleButtonGroup
                            style={{height: 'fit-content'}}
                            value={viewMode}
                            exclusive
                            size={'small'}
                            onChange={handleChangeViewMode}
                        >
                            <ToggleButton
                                style={{padding: '4px 12px', height: '32px', width: '192px'}}
                                variant={'secondary'}
                                value={'general'}
                                size={'small'}
                            >
                                General settings
                            </ToggleButton>
                            <ToggleButton
                                style={{padding: '4px 12px', height: '32px', width: '192px'}}
                                variant={'secondary'}
                                value={'advanced'}
                                size={'small'}
                                disabled
                            >
                                Advanced settings
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {viewMode==='general' ? <ActionItemsAdvancedList
                            chosenActionItems={chosenActionItems}
                            onChangeActionItem={onChangeActionItem}
                                                /> : null}
                    </Box>
                </>
            }

        </Box>
    )
}

export default ReportingStepScreen
