import {FormControlLabel, Radio, RadioGroup} from '@mui/material'
import './CurrentConditions.css'
import FormControl from '@mui/material/FormControl'
import React, {useEffect, useState} from 'react'
import {useMapStore} from '../../../../app/store/MapStore'

const MODE = {
    off: '',
    basic: 'basic',
    advanced: 'advanced',
}

const CurrentConditions = ({product, enabledProducts}) => {
    console.log('render current conditions')
    const toggleProduct = useMapStore((state) => state.toggleProduct)
    const currentConditions = useMapStore((state) => state.currentConditions)

    const [currentMode, setCurrentMode] = useState(MODE.off)

    useEffect(() => {
        const currentConditions = useMapStore.getState().createCurrentConditions()

        if (isProductEnabled(product)) {
            setCurrentMode(MODE.advanced)
            currentConditions.switchMode(MODE.advanced)
        }
    }, [])

    const isProductEnabled = (product) => enabledProducts.some((enabledProduct) => enabledProduct.id === product.id)

    const handleRadioClick = (newMode) => {
        if (currentMode === newMode) {
            newMode = MODE.off

            if (isProductEnabled(product)) {
                toggleProduct(product)
            }
        } else {
            if (!isProductEnabled(product)) {
                toggleProduct(product)
            }
        }

        setCurrentMode(newMode)
        currentConditions.switchMode(newMode)
    }

    return (
        <FormControl className={'productListCurrentConditionWrapper'}>
            <div className={'productListCurrentConditionLabel'}>
                Current conditions
            </div>
            <RadioGroup
                name="controlled-radio-buttons-group"
                value={currentMode}
            >
                <FormControlLabel
                    value="basic"
                    label="Basic"
                    control={<Radio
                        size={'small'}
                        onClick={() => handleRadioClick('basic')}
                    />}
                />
                <FormControlLabel
                    value="advanced"
                    label="Advanced"
                    control={<Radio
                        size={'small'}
                        onClick={() => handleRadioClick('advanced')}
                    />}
                />
            </RadioGroup>
        </FormControl>
    )
}

export default CurrentConditions
