import {MapLibre} from 'pages/map/ui/MapLibre'
import React from 'react'
import './Map.css'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import {EditLocationScreen} from '../../../widgets/location'

export function Map() {
    const {
        editingLocation,
        toggleEditingLocation,
    } = useLocationsStore((state) => state)

    if (editingLocation) {
        return (
            <EditLocationScreen
                sx={{width: '100%'}}
                closeOnSave={false}
                onClose={() => toggleEditingLocation(null)}
            />
        )
    }

    return (
        <div className='mapWrapper'>
            <MapLibre/>
        </div>
    )
}

