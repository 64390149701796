import React, {useState, useEffect} from 'react'
import {
    Box,
    Typography,
    IconButton,
} from '@mui/material'
import PenIcon from 'shared/assets/icons/Pen'
import CustomMessagePopup, {tokens, renderMessageWithTokens} from 'features/notification/CustomMessagePopup/CustomMessagePopup'
import styles from './DefaultNotifications.module.scss'

export default function DefaultNotifications({message, title, onChange, messageType}) {
    const [showMessagePopup, setShowMessagePopup] = useState(false)
    const [tokenizedContent, setTokenizedContent] = useState([])

    const handleChange = (newText) => {
        onChange(messageType, newText)
    }

    useEffect(() => {
        setTokenizedContent(renderMessageWithTokens(message, tokens))
    }, [message, tokens])

    return (
        <>
            <Box className={'column gap8 fullWidth'}>
                <Typography className={styles.title}>
                    {title}
                </Typography>
                <Box
                    className={'row'}
                    style={{justifyContent: 'space-between'}}
                >
                    <Typography>
                        {tokenizedContent}
                    </Typography>
                    <IconButton
                        variant={'outlined'}
                        size={'tiny'}
                        onClick={() => setShowMessagePopup(true)}
                    >
                        <PenIcon size={'small'}/>
                    </IconButton>

                </Box>
            </Box>

            <CustomMessagePopup
                visible={showMessagePopup}
                onCloseModal={() => setShowMessagePopup(false)}
                messageText={message}
                tokens={tokens}
                onChange={handleChange}
            />
        </>
    )
}
