import React, { useState, useEffect } from 'react';
import {Box, FormControlLabel, FormGroup, IconButton, Typography, MenuItem, Chip, Button} from '@mui/material';
import { CheckBox } from "shared/ui/CheckBox";
import PenIcon from "shared/assets/icons/Pen";
import CustomMessagePopup, {tokens, renderMessageWithTokens} from 'features/notification/CustomMessagePopup/CustomMessagePopup';
import { NumberInput } from 'shared/ui/NumberInput';
import Select from '@mui/material/Select';
import styles from"./NotificationCheckboxTimeWithMessage.module.scss"


function NotificationCheckboxTimeWithMessage({ checked, onNotificationDataChange, message='', customTimeSettings, onChange, isDefaultMessage, onSaveAsDefault }) {
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    
    const [customTimeDuration, setCustomTimeDuration] = useState('');
    const [customTimePeriod, setCustomTimePeriod] = useState('');
    const [customTimeHour, setCustomTimeHour] = useState('');

    const [content, setContent] = useState([]);

    const handleSaveAsDefault = () => {
        if(onSaveAsDefault) {
            onSaveAsDefault('custom_time', message);
        }
    };

    useEffect(() => {
        setContent(renderMessageWithTokens(message, tokens));
    }, [message, tokens]);
    

    useEffect(() => {
        if (customTimeSettings) {
            setCustomTimeDuration(customTimeSettings.customTimeDuration );
            setCustomTimePeriod(customTimeSettings.customTimePeriod );
            setCustomTimeHour(customTimeSettings.customTimeHour );
        }
    }, [customTimeSettings]);    

    const handleDurationChange = (newValue) => {
        setCustomTimeDuration(newValue);
        onNotificationDataChange('custom_duration', newValue);
    };

    const handlePeriodChange = (e) => {
        const newValue = e.target.value;
        setCustomTimePeriod(newValue);
        onNotificationDataChange('custom_period', newValue);
    };

    const handleHourChange = (e) => {
        const newValue = e.target.value;
        setCustomTimeHour(newValue);
        onNotificationDataChange('custom_hour', newValue);
    };

    const handleChange = (e) => {
        onNotificationDataChange('custom_time', e.target.checked);
    };
    
    return (
        <>
            <FormGroup className={styles.wrapper}>
                <FormControlLabel
                    control={<CheckBox checked={checked} onChange={handleChange} />}
                    label="Custom time"
                />
                {checked && (
                    <Box className={styles.contentWrapper}>
                        <Box className={styles.content}>
                            <Typography variant="body2">{content}</Typography>
                            <Box className={styles.messageWrapper}>
                                <IconButton
                                    variant={'outlined'}
                                    size={'tiny'}
                                    onClick={() => setShowMessagePopup(true)}
                                >
                                    <PenIcon size={'small'}/>
                                </IconButton>
                                {isDefaultMessage ? (
                                <Typography className={styles.defaultMessage}>
                                    Default message
                                </Typography>
                                ) : (
                                <Button variant={'outlined'} color={'primary'} className={styles.button} onClick={handleSaveAsDefault}>
                                    Save as default
                                </Button>
                                )}                     
                            </Box>
                        </Box>
                        <FormGroup className={styles.formWrapper}>
                            <NumberInput value={customTimeDuration} onChange={handleDurationChange}/>
                            <Select value={customTimePeriod} className={styles.select} onChange={handlePeriodChange} placeholder='period'>
                                <MenuItem value={'d'}>days</MenuItem>
                                <MenuItem value={'h'}>hours</MenuItem>
                                <MenuItem value={'m'}>minutes</MenuItem>
                            </Select>
                            <Typography>
                                before start of weather event
                            </Typography>
                        </FormGroup>
                    </Box>
                )}
            </FormGroup>
            <CustomMessagePopup 
                visible={showMessagePopup} 
                onCloseModal={() => setShowMessagePopup(false)} 
                messageText={message}
                tokens={tokens}
                onChange={onChange}
            />
        </>
    );
}

export default NotificationCheckboxTimeWithMessage;
