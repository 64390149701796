import {Box, Button, Divider, Drawer, IconButton, Chip} from '@mui/material'
import React, {useState, useEffect} from 'react'
import {Spacer} from 'shared/ui/Spacer'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import {CheckBox} from 'shared/ui/CheckBox'
import AlertsSettingsList from 'pages/settings/ui/alerts/AlertsSettingsList/AlertsSettingsList'
import AlertConfigurationDetails from 'entities/alertConfig/ui/AlertConfigurationDetails/AlertConfigurationDetails'
import {SearchInput} from 'shared/ui/SearchInput'
import {useLocationsStore} from '../../../../app/store/LocationsStore'
import {useWeatherDefinitionsStore} from '../../../../app/store/WeatherDefinitionsStore'
import {useAlertConfigurationsStore} from '../../../../app/store/AlertConfigurationsStore'
import BellIcon from '../../../../shared/assets/icons/Bell'
import styles from './AlertConfigsFilter.module.scss'
import {clsx} from 'clsx'

export function AlertConfigsFilter(
    {
        selectedConfigs,
        setSelectedConfigs,
        openButtonText = 'Alert Configurations',
    }) {
    const {
        alertConfigurations,
        alertConfigGroups,
        extendedAlertConfigs,
        preparedAlertConfigGroups,
        openedAlertConfiguration,
        fetchAllInfoAboutAlertConfigurations,
        getConfigurations,
    } = useAlertConfigurationsStore((state) => state)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const {
        weatherDefinitions,
        fetchWeatherDefinitions,
    } = useWeatherDefinitionsStore((state) => state)

    const [searchString, setSearchString] = useState('')
    const [selectedConfigsTemp, setSelectedConfigsTemp] = useState({configs: {}})
    const [isOpenConfigsPanel, setIsOpenConfigsPanel] = useState(false)
    const severity = 'All'

    useEffect(() => {
        fetchWeatherDefinitions()
        fetchAllInfoAboutAlertConfigurations()
        fetchLocations()
    }, [])

    useEffect(() => {
        getConfigurations(weatherDefinitions, locations, alertConfigurations, alertConfigGroups)
        if (!Object.keys(preparedAlertConfigGroups).length) return
        if (!selectedConfigs.configs || Object.keys(selectedConfigs.configs).length !== Object.keys(preparedAlertConfigGroups).length) {
            const configs = {}
            for (const key in preparedAlertConfigGroups) {
                configs[key] = {selected: true, group: preparedAlertConfigGroups[key]}
            }
            setSelectedConfigs({configs: copySelectedConfigs(configs)})
            setSelectedConfigsTemp({configs: configs})
        } else {
            const configs = copySelectedConfigs(selectedConfigs.configs)
            setSelectedConfigsTemp({configs: configs})
        }
    }, [weatherDefinitions, locations, alertConfigurations, alertConfigGroups, selectedConfigs.configs])

    const copySelectedConfigs = (configs) => {
        const configsCopy = {}
        if (!configs) return {}
        for (const key in configs) {
            configsCopy[key] = {...configs[key]}
        }
        return configsCopy
    }

    const onClose = () => setIsOpenConfigsPanel(false)

    const onCancel = () => {
        const configs = copySelectedConfigs(selectedConfigs.configs)
        setSelectedConfigsTemp({configs: configs})
        onClose()
    }

    const onChangeFilter = (action, alert) => {
        if (action === 'select') {
            const configs = selectedConfigsTemp.configs
            configs[alert.groupId].selected = !configs[alert.groupId].selected
            setSelectedConfigsTemp({configs: configs})
        }
        if (action === 'delete') {
            delete selectedConfigsTemp.configs[alert.groupId]
            setSelectedConfigsTemp({...selectedConfigsTemp})

            delete selectedConfigs.configs[alert.groupId]
            setSelectedConfigs({...selectedConfigs})
        }
    }

    const applySelected = () => {
        const configs = copySelectedConfigs(selectedConfigsTemp.configs)
        setSelectedConfigs({configs: configs})
        onClose()
    }

    const allSelected = () => {
        if (!Object.keys(selectedConfigsTemp.configs).length) return false
        let selected = true
        const configs = selectedConfigsTemp.configs
        for (const key in configs) {
            if (!configs[key].selected) {
                selected = false
                break
            }
        }
        return selected
    }

    const allIndeterminate = () => {
        if (!Object.keys(selectedConfigsTemp.configs).length) return false
        // let selected = true;
        const configs = selectedConfigsTemp.configs
        const state0 = configs[Object.keys(configs)[0]].selected
        let indet = false
        for (const key in configs) {
            if (configs[key].selected !== state0) {
                indet = true
                break
            }
        }
        return indet
    }

    const selectAll = () => {
        const state = !allSelected()
        const configs = selectedConfigsTemp.configs
        for (const key in configs) {
            configs[key].selected = state
        }
        setSelectedConfigsTemp({configs: configs})
    }

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim())
    }

    const countOfSelectedAlertConfigs = () => {
        if (!selectedConfigs.configs) return 0
        return Object.values(selectedConfigs.configs)
            .filter((config) =>
                config.selected &&
                Object.values(preparedAlertConfigGroups).some(
                    (prep) => prep.groupId === config.group.groupId,
                ),
            ).length
    }

    return (
        <>
            <Button
                data-cy={'alert-configurations-filter-button'}
                variant={'outlined'}
                color={'secondary'}
                startIcon={<BellIcon/>}
                endIcon={<Chip
                    label={countOfSelectedAlertConfigs()}
                    size={'small'}
                />}
                onClick={() => setIsOpenConfigsPanel(true)}
            >
                {openButtonText}
            </Button>

            <Drawer
                anchor={'right'}
                open={isOpenConfigsPanel}
                onClose={onCancel}
                ModalProps={{onBackdropClick: onCancel}}
            >
                {!openedAlertConfiguration && <div
                    className={styles.wrapper}
                >
                    <div className={styles.header}>
                        <IconButton
                            onClick={onCancel}
                            size={'small'}
                            variant={'outlined'}
                            style={{margin: '2px 0 0 2px'}}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <div className={'subtitle medium'}>
                        Alert configuration filter
                        </div>
                        <Spacer/>
                    </div>
                    <SearchInput
                        placeholder='Search for alert configuration'
                        value={searchString}
                        onChange={searchStringChanged}
                    />
                    <Box className={styles.wrapperContent}>
                        <div
                            className={styles.content}
                        >
                            <div className={styles.headerWrapper}>
                                <CheckBox
                                    checked={allSelected()}
                                    indeterminate={allIndeterminate()}
                                    onClick={selectAll}
                                />
                                <div className={clsx('paragraph', styles.header)}>
                                All configurations (
                                    {Object.keys(preparedAlertConfigGroups).length}
                                )
                                </div>
                            </div>
                            <Divider/>
                            <AlertsSettingsList
                                alertGroups={preparedAlertConfigGroups}
                                onChangeFilter={onChangeFilter}
                                selectedConfigsTemp={selectedConfigsTemp}
                                searchString={searchString.toLocaleLowerCase()}
                                severity={severity}
                                selectedLocations={{}}
                                extendedAlertConfigs={extendedAlertConfigs}
                                isFilter={true}
                            />
                            <Box className={styles.buttonWrapper}>
                                <Button
                                    className={'regular'}
                                    variant={'outlined'}
                                    color={'primary'}
                                    style={{width: '96px'}}
                                    onClick={onCancel}
                                >
                                Cancel
                                </Button>
                                <Button
                                    className={'regular'}
                                    variant={'contained'}
                                    color={'primary'}
                                    style={{width: '96px'}}
                                    onClick={applySelected}
                                >
                                Apply
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </div>}
                {openedAlertConfiguration && <AlertConfigurationDetails
                    alert={openedAlertConfiguration}
                    extendedAlertConfig={Object.values(extendedAlertConfigs).filter((alert) => alert.group_id === openedAlertConfiguration.groupId)}
                />}
            </Drawer>
        </>
    )
}
