import React, {useEffect, useState} from 'react'
import ModalComponent from '../../../../../shared/ui/ModalComponent'
import {Box, Button, FormHelperText, IconButton, Typography} from '@mui/material'
import CloseIcon from '../../../../../shared/assets/icons/Close'
import {isEmpty} from '../../../../../shared/libs/Utils'
import TextField from '@mui/material/TextField'
import {Select} from '../../../../../shared/ui/Select/Select'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import {IconMenuItem} from '../../../../../widgets/weatherDefinition/EditWeatherDefinitionPage/EditWeatherDefinitionPage'
import styles from './EditActionItemsCategory.module.scss'
import {clsx} from 'clsx'

const EditActionItemsCategory = ({defaultValue, onClose=()=>{}, onSave=(category)=>{}}) => {
    const {
        categoryIcons,
        fetchCategoryIcons,
        createCategory,
        editCategory,
    } = useActionItemsStore((state) => state)

    const [categoryName, setCategoryName] = useState(defaultValue.name || null)
    const [categoryIcon, setCategoryIcon] = useState(defaultValue.icon || null)

    useEffect(() => {
        fetchCategoryIcons()
    }, [])

    const handleSaveCategory = async () => {
        if (defaultValue.id) {
            const updatedCategory = {
                ...defaultValue,
                name: categoryName,
                icon: categoryIcon.id,
            }
            await editCategory(updatedCategory)
        } else {
            const newCategory = {
                name: categoryName,
                icon: categoryIcon.id,
            }
            const response = await createCategory(newCategory)
            if (response.id) {
                newCategory.id = response.id
                onSave(newCategory)
            }
        }
        onClose()
    }

    return (
        <ModalComponent visible={true}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h3>
                        {isEmpty(defaultValue) ? 'New' : 'Edit'}
                        {' '}
                        category
                    </h3>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        className={styles.iconWrapper}
                        onClick={onClose}
                    >
                        <CloseIcon className={styles.icon}/>
                    </IconButton>
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.nameHeader}>
                        <FormHelperText className={styles.name}>
                            Name of action item category
                        </FormHelperText>
                        <TextField
                            style={{gap: '8px', width: '100%'}}
                            type='text'
                            placeholder='Enter name'
                            value={categoryName}
                            data-cy={'category-enter-name'}
                            onChange={(e) => setCategoryName(e.target.value)}
                        >
                        </TextField>
                    </div>
                    <Typography variant={'label'}>
                        Icon
                        <Select
                            defaultValue={categoryIcon?.icon}
                            data-cy={'category-enter-icon'}
                            value={categoryIcon?.icon}
                            placeholder={''}
                            style={{width: '100%'}}
                            renderValue={(icon) => (
                                <Box className={styles.selectWrapper}>
                                    <Box className={clsx('row', styles.selectContent)}>
                                        <svg className={clsx('customSvg', styles.svgBackground)}>
                                            <use
                                                height="16"
                                                width="16"
                                                href={categoryIcon?.icon + '#svg2'}
                                            />
                                        </svg>
                                    </Box>
                                    <Box
                                        className={clsx(styles.titleOfSelect, {
                                            [styles.selectedTitle]: categoryIcon?.name,
                                            [styles.unselectedTitle]: !categoryIcon?.name,
                                        })}
                                    >
                                        {categoryIcon?.name || 'Select icon'}
                                    </Box>
                                </Box>
                            )}
                            MenuProps={{
                                sx: {
                                    '& ul': {
                                        padding: 0,
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(14, auto)',
                                    },
                                },
                            }}
                        >
                            {categoryIcons.map((iconItem) => (
                                <IconMenuItem
                                    key={`icon_${iconItem.id}`}
                                    data-cy={'category-icon-item'}
                                    value={iconItem.id}
                                    onClick={() => setCategoryIcon({...iconItem})}
                                >
                                    <svg
                                        className={clsx('customSvg', {
                                            [styles.selectedIcon]: categoryIcon?.id === iconItem.id,
                                        })}
                                    >
                                        <use
                                            height="16"
                                            width="16"
                                            href={`${iconItem.icon}#svg2`}
                                        />
                                    </svg>
                                </IconMenuItem>
                            ))}
                        </Select>
                    </Typography>
                </div>
                <div className={styles.buttonsWrapper}>
                    <Button
                        className={styles.button}
                        data-cy={'category-cancel-button'}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={styles.button}
                        data-cy={'category-save-button'}
                        disabled={!categoryIcon || !categoryName}
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleSaveCategory}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default EditActionItemsCategory
