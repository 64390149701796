import {Dialog, IconButton, Box, Button, Divider, Chip} from '@mui/material'
import CloseIcon from 'shared/assets/icons/Close'
import React from 'react'
import {ReactComponent as DefinitionsRectangle2} from '../../assets/svg/confirmationPopUp/DefinitionsRectangle2.svg'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import {ReactComponent as WDConfirmationIcons} from 'shared/assets/svg/confirmationPopUp/WDConfirmationIcons.svg'
import theme from '../../../app/Theme'
import styles from "./DefinitionConfirmationModal.module.scss"

export default function DefinitionConfirmationModal({
    definition,
    wait,
    agreeFunc,
    goToSettingsFunc,
}) {
    const content = (
        <Box className={styles.wrapper}>
            <Box className={styles.header}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    Well done, your weather definition is created!
                </Box>
                <IconButton
                    variant={'outlined'}
                    onClick={agreeFunc}
                    size={'small'}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </Box>
            <Box className={styles.imagesWrapper}>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: 'absolute', left: '-30px', top: '35px'}}>
                    <DefinitionsRectangle2/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '10px', top: '0px'}}>
                    <WDConfirmationIcons/>
                </Box>
            </Box>
            <Box className={styles.contentWrapper}>
                <div className={styles.content}>
                    {definition.name}
                </div>
                <Chip
                    variant={theme.palette.severity.variant[definition.severity]}
                    size={'large'}
                    label={definition.severity}
                />
            </Box>
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                You can view weather definition data on the definitions page. To manage its settings, go to Settings
                {' '}
                {'>'}
                {' '}
Weather definitions.
            </Box>
            <Box className={styles.footerWrapper}>
                <Divider/>
                <Box className={styles.buttonsWrapper}>
                    <Button
                        className={styles.button}
                        disabled={wait}
                        data-cy={'wd-success-creation-okay-button'}
                        onClick={agreeFunc}
                    >
                        Got it
                    </Button>
                    <Button
                        className={styles.button}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={goToSettingsFunc}
                        disabled={wait}
                    >
                        Go to Settings
                    </Button>
                </Box>
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
