import {Box} from '@mui/system'
import React, {useState, useEffect} from 'react'
import NewContactLine from 'entities/contact/ui/NewContactLine'
import {Button, Typography} from '@mui/material'
import PlusIcon from '../../../shared/assets/icons/Plus'
import styles from "./AddContactsForm.module.scss"
import {clsx} from "clsx";

export default function AddContactsForm({contacts, groups, onContactsChange}) {
    const [localContacts, setLocalContacts] = useState(contacts)
    const [createdContactsLength] = useState(contacts.length)

    useEffect(() => {
        addNewContact()
    }, [])

    useEffect(() => {
        onContactsChange(localContacts)
    }, [localContacts, onContactsChange])

    const handleContactChange = (updatedContact) => {
        const newContacts = localContacts.map((contact) =>
            (contact.id === updatedContact.id ? updatedContact : contact),
        )
        setLocalContacts(newContacts)
        onContactsChange(newContacts) // undate parent contacts
    }

    const addNewContact = () => {
        const newContact = {
            id: new Date().getTime(),
            name: '',
            email: '',
            phone: '',
            group: undefined,
        }
        setLocalContacts([...localContacts, newContact])
    }

    const removeContact = (contactId) => {
        setLocalContacts(localContacts.filter((contact) => contact.id !== contactId))
    }

    return (
        <Box className={clsx('column gap16 fullWidth fullHeight', styles.wrapper)}>
            <Box
                component="form"
                className={styles.contentWrapper}
            >
                <Box className={styles.content}>
                    <Typography className={styles.contentItem}>
                        Name
                    </Typography>
                    <Typography className={styles.contentItem}>
                        Email
                    </Typography>
                    <Typography className={styles.contentItem}>
                        Phone number
                    </Typography>
                    <Typography className={styles.contentItem}>
                        User group
                    </Typography>
                </Box>

                {localContacts.map((contact, index) => {
                    if (index >= createdContactsLength) {
                        return (
                            <NewContactLine
                                contact={contact}
                                groups={groups}
                                key={contact.id}
                                onRemove={() => removeContact(contact.id)}
                                onContactChange={handleContactChange}
                            />
                        )
                    }
                    return null
                })}
            </Box>
            <Button
                variant='outlined'
                color={'primary'}
                onClick={addNewContact}
                startIcon={<PlusIcon/>}
                sx={{width: 'max-content'}}
            >
                New contact
            </Button>
        </Box>
    )
}
