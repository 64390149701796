import {Button, Link, Menu, MenuItem} from '@mui/material'
import {Box} from '@mui/system'
import {useEffect, useState} from 'react'
import LogoutIcon from 'shared/assets/icons/Logout'
import MoreIcon from 'shared/assets/icons/More'
import SettingsIcon from 'shared/assets/icons/Settings'
import {logoutUser} from 'shared/libs/Auth'
import AccountMenuItem from 'widgets/mainMenu/ui/AccountMenuItem'
import {useUserStore} from '../../../app/store/UserStore'

const AccountButton = (props) => (
    <Button
        {...props}
        data-cy={'main-menu-button'}
        size={'small'}
        endIcon={<MoreIcon sx={{pointerEvents: 'none'}}/>}
        disableRipple
        sx={{
            ':hover': {
                '& .MuiSvgIcon-root': {
                    color: 'var(--palette-grey-500)',
                },
                backgroundColor: 'transparent',
                boxShadow: 'none',
                borderColor: 'transparent',
                color: 'var(--palette-grey-700)',
            },
            '& .MuiSvgIcon-root': {
                color: 'var(--palette-grey-500)',
            },
            backgroundColor: 'transparent',
            width: 'max-content',
            color: 'var(--palette-grey-700)',
        }}
    />
)

export function MainMenu() {
    const {user, fetchUser} = useUserStore((state) => state)
    const [anchorEl, setAnchorEl] = useState(null)
    const isOpen = !!anchorEl

    useEffect(() => {
        fetchUser()
    }, [])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleLogout = () => {
        logoutUser()
        handleClose()
    }

    return (
        <Box>
            <AccountButton
                data-cy={'account-menu-button'}
                onClick={handleClick}
                aria-haspopup="true"
                aria-controls={isOpen ? 'account-menu' : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
            >
                {user.username}
            </AccountButton>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={isOpen}
                onClose={handleClose}
                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                sx={{'& .MuiPaper-root': {width: 186}}}
            >
                <AccountMenuItem/>
                <MenuItem
                    component={Link}
                    href={'/settings#weather-definitions'}
                    underline={'none'}
                >
                    <SettingsIcon size={'small'}/>
                    Settings
                </MenuItem>
                <MenuItem
                    onClick={handleLogout}
                    color={'error'}
                >
                    <LogoutIcon size={'small'}/>
                    Log out
                </MenuItem>
            </Menu>
        </Box>
    )
}
