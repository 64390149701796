import {styled} from '@mui/material/styles'
import React, {memo, useState} from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsExporting from 'highcharts/modules/exporting'
import XRange from 'highcharts/modules/xrange'
import timeline from 'highcharts/modules/timeline'
import gantt from 'highcharts/modules/gantt'
import PatternFill from 'highcharts-pattern-fill'
import ExportData from 'highcharts/modules/export-data'
import {Box, IconButton} from '@mui/material'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import ArrowRightSmallIcon from 'shared/assets/icons/ArrowRightSmall'
import dayjs from 'dayjs'
import TimelineChartsTooltip from '../../../entities/alert/ui/TimeLineChartsPopup'
import {weatherColors, weatherBackgroundColors, weatherLabelColors} from '../TimelineChartsColors'
import {weatherIcons} from '../weatherIcons'
import './TimelineCharts.css'

Highcharts.AST.allowedTags.push('feFlood')
Highcharts.AST.allowedTags.push('feComposite')
Highcharts.AST.allowedTags.push('feMorphology')
Highcharts.AST.allowedAttributes.push('flood-color')
Highcharts.AST.allowedAttributes.push('operator')
Highcharts.AST.allowedAttributes.push('in2')
Highcharts.AST.allowedAttributes.push('radius')

HighchartsExporting(Highcharts)
timeline(Highcharts)
XRange(Highcharts)
gantt(Highcharts)
PatternFill(Highcharts)
ExportData(Highcharts)
window.moment = dayjs

const TimeLineCharts = ({
    alerts,
    period,
    timelinePos,
    navigatorExtent,
    timezone, chartRef,
    selectedLocation,
    severityFilter,
    definitionFilter,
    chartWidth,
    openAlertRef,
    setTimelineRange,
}) => {
    dayjs.tz.setDefault(timezone)
    const [tooltip, setTooltipState] = React.useState(false)
    const periodicUpdater = React.useRef(null)
    let chart_ref = chartRef.current

    const calculateChartHeight = () => Math.max(window.innerHeight - 180, 700)

    const [minChartHeight, setMinChartHeight] = useState(calculateChartHeight())

    const handleWindowResize = () => {
        setMinChartHeight(calculateChartHeight())
    }

    window.addEventListener('resize', handleWindowResize)

    let validAlerts = []
    if (selectedLocation) {
        for (const idx in alerts) {
            if (alerts[idx].location_id === selectedLocation.id) {
                validAlerts.push(alerts[idx])
            }
        }
        alerts = validAlerts
    } else alerts = []
    if (severityFilter === 'critical') {
        validAlerts = []
        for (const idx in alerts) {
            if (alerts[idx].severity === 'Critical') {
                validAlerts.push(alerts[idx])
            }
        }
        alerts = validAlerts
    }
    validAlerts = []
    for (const idx in alerts) {
        if (definitionFilter[alerts[idx].api_alert.wdef_id] === true) {
            validAlerts.push(alerts[idx])
        }
    }
    alerts = validAlerts

    let update = true
    let timelinepos = timelinePos.current
    const periodday = 24 * 3600 * 1000
    let navticks
    let ticks
    let tickInterval
    let tickLength
    let labels
    let navLabels
    let minorTicks
    let minorTickInterval
    let minorGridLineWidth = 2
    let minorTickWidth = 0
    let minorTickLength = 0
    const minorTickColor = '#e6e6e6'
    let chartExtraHeight // navigator + xaxis labels
    const navigatorHeight = 24

    const [min, max] = getPlotExtent(period, timelinepos, timezone)
    timelinepos = (min + max) / 2

    let periodseconds = 7 * periodday
    if (period === 'Day') {
        chartExtraHeight = 140
        periodseconds = periodday
        navticks = getNavDayTicks(timelinepos, timezone)
        navLabels = {
            align: 'center',
            formatter: function() {
                const ticksNum = Object.values(this.axis.ticks).length
                if ( ticksNum > 7 && this.isLast) return ''
                if ( ticksNum === 7 && this.isLast && !(this.value in this.axis.ticks)) return ''
                const label = dayjs(this.value).tz(timezone).format('ddd, MMM DD')
                return label
            },
            x: (70 / 1000) * (chartWidth - 150),
            y: 10,
            style: {
                color: '#6e7983',
                fontSize: '1em',
            },
        }
        labels = {
            align: 'center',
            x: 0,
            y: 0,
            overflow: 'allow',
            formatter: function() {
                const value = dayjs(this.value).tz(timezone)
                if (value.hour() === 1) {
                    return `<tspan x="0">${value.format('MMM D')}</tspan><tspan x="0" dx="-2" dy="30">${value.format('h A')}</tspan>`
                } else {
                    return value.format('h A')
                }
            },
        }
        tickInterval = 2 * 3600 * 1000
        tickLength = 0
        minorTicks = false
        minorTickInterval = 3600 * 1000
    } else if (period === 'Month') {
        chartExtraHeight = 165
        periodseconds = 31 * periodday
        tickInterval = 24 * 3600 * 1000
        tickLength = 0
        minorTicks = true
        minorTickLength = 60
        minorTickWidth = 2
        minorTickInterval = 7 * 24 * 3600 * 1000
        navticks = getNavMonthTicks(timelinepos, timezone)
        navLabels = {
            align: 'center',
            formatter: function() {
                const ticksNum = Object.values(this.axis.ticks).length
                if ( ticksNum > 7 && this.isLast) return ''
                if ( ticksNum === 7 && this.isLast && !(this.value in this.axis.ticks)) return ''
                const label = dayjs(this.value).tz(timezone).format('MMM YYYY')
                return label
            },
            x: (70 / 1000) * (chartWidth - 150),
            y: 10,
            style: {
                color: '#6e7983',
                fontSize: '1em',
            },
        }
        labels = {
            align: 'center',
            formatter: function() {
                const weekday = dayjs(this.value).tz(timezone).format('ddd')[0]
                const daynum = dayjs(this.value).tz(timezone).format('D')
                if (parseInt(daynum) < 10) {
                    return '<tspan x="0">' + weekday + '</tspan><tspan x="0" dx="-2" dy="30">' + daynum + '</tspan>'
                } else {
                    return '<tspan x="0">' + weekday + '</tspan><tspan x="0" dy="30">' + daynum + '</tspan>'
                }
            },
            x: (18 / 1150) * chartWidth,
            y: 4,
            overflow: 'allow',
            style: {
                color: '#333333',
                fontSize: '1em',
            },
        }
    } else { // Week
        navticks = getNavWeekTicks(timelinepos, timezone)
        navLabels = {
            align: 'center',
            formatter: function() {
                const ticksNum = Object.values(this.axis.ticks).length
                if ( ticksNum > 7 && this.isLast) return ''
                if ( ticksNum === 7 && this.isLast && !(this.value in this.axis.ticks)) return ''
                if (Object.values(this.axis.ticks).length > 7 && this.isLast) return ''
                const beg_date = dayjs(this.value).tz(timezone).format('MMM D')
                const end_date = dayjs(this.value).tz(timezone).endOf('week').format('MMM D')
                const label = beg_date + ' - ' + end_date
                return label
            },
            x: (70 / 1000) * (chartWidth - 150),
            y: 10,
            style: {
                color: '#6e7983',
                fontSize: '1em',
            },
        }
        labels = {
            align: 'center',
            formatter: undefined,
            format: '{value:%a, %b %d}',
            x: (80 / 1150) * chartWidth,
            y: -30,
            overflow: 'allow',
            style: {
                color: '#333333',
                fontSize: '1em',
            },
        }
        chartExtraHeight = 170
        tickInterval = 24 * 3600 * 1000
        tickLength = 60
        minorTicks = true
        minorTickInterval = 6 * 3600 * 1000
        minorGridLineWidth = 1
        minorTickWidth = 1
        minorTickLength = 8
    }

    // const extentseconds = 7 * periodseconds;
    if (!navigatorExtent.current) navigatorExtent.current = getNavExtent(period, timelinepos, timezone)
    const [extentmin, extentmax] = navigatorExtent.current

    const [fullSeries, yvalues] = alertsToChartOptions(alerts, extentmin, extentmax)
    const fullNavSeries = alertsToNavOptions(alerts, extentmin, extentmax)
    const [series, navseries] = filterSeries(fullSeries, fullNavSeries, extentmin, extentmax)

    const maxY = Math.max(...fullSeries.map((a) => a.y))
    // const rectPattern = `M 10 0 L 10 0`;

    React.useEffect(() => {
        window.addEventListener('mouseup', mouseUp)
        window.addEventListener('mousedown', handleMouseDown)
        return () => {
            window.removeEventListener('mouseup', mouseUp)
            window.removeEventListener('mousedown', handleMouseDown)
            destroyMinorTickLabels()
            delNavGrid()
        }
    })

    React.useEffect(() => () => {
        if (periodicUpdater.current === null) return
        clearInterval(periodicUpdater.current)
        periodicUpdater.current = null
        console.log('Timeline updater cleared')
    }, [])

    const onChartCreated = (newchart) => {
        console.log('New chart')
        chartRef.current = newchart
        chart_ref = newchart
        chart_ref.redraw()
    }

    const mouseUp = (event) => { // align chart window to begin/end period after sliding
        if (!chart_ref || !chart_ref.xAxis) return
        const current_median = (chart_ref.xAxis[0].min + chart_ref.xAxis[0].max) / 2
        if (chart_ref.xAxis[0].sliding_type === 'pan') {
            // let offset = 0;

            // uncomment to enable align
            //            if (timelinepos > current_median) offset = -1;
            //            else offset = 1;
            //            const [min, max] = getPlotExtent(period, timelinepos, timezone, offset);
            //            timelinepos = (min + max) / 2;
            // comment 2 lines below to disable align
            timelinepos = current_median
            const [min, max] = getPlotExtent(period, timelinepos, timezone)

            const axis = chart_ref.xAxis[0]
            const nav_axis = chart_ref.xAxis[1]
            let navMin = nav_axis.min
            let navMax = nav_axis.max
            let scrollVal = 0
            if (navMin > min) scrollVal = -1
            if (navMax <= max) scrollVal = 1
            if (scrollVal) {
                [navMin, navMax] = getNavExtent(period, (navMin + navMax) / 2, timezone, scrollVal)
                if (period === 'Month') {
                    nav_axis.update({tickPositions: getNavMonthTicks((navMin + navMax) / 2, timezone)})
                } else if (period === 'Week') {
                    nav_axis.update({tickPositions: getNavWeekTicks((navMin + navMax) / 2, timezone)})
                } else if (period === 'Day') {
                    nav_axis.update({tickPositions: getNavDayTicks((navMin + navMax) / 2, timezone)})
                }
                nav_axis.update({min: navMin, max: navMax})
                navigatorExtent.current = [navMin, navMax]
                const [series, navseries] = filterSeries(fullSeries, fullNavSeries, navMin, navMax)
                chart_ref.series[0].update({data: series})
                chart_ref.series[1].update({data: navseries})
            }
            axis.setExtremes(min, max, true, false, {trigger: ''})
            timelinePos.current = timelinepos
        } else if (chart_ref.xAxis[0].sliding_type === 'navigator') {
            timelinepos = current_median
            const axis = chart_ref.xAxis[0]
            const [min, max] = getPlotExtent(period, timelinepos, timezone)
            axis.setExtremes(min, max, true, false, {trigger: ''})
            timelinePos.current = timelinepos
        }
        chart_ref.xAxis[0].sliding_type = ''
    }

    const handleMouseDown = () => {
        if (document.getElementById('timelinecharts-tooltip')) {
            setTooltipState(false)
        }
    }

    let rangeset = false

    let minorTickLabels = []
    let navGrid = null

    const destroyMinorTickLabels = (chart) => {
        for (let i = 0; i < minorTickLabels.length; i++) minorTickLabels[i].destroy()
        minorTickLabels = []
    }

    const addMinorTickLabels = (chart) => {
        if (period === 'Day') return

        destroyMinorTickLabels(chart)
        const axis0 = chart.xAxis[0]
        if (period === 'Week') {
            for (const tick in axis0.minorTicks) {
                const el = axis0.minorTicks[tick]
                const text = dayjs(el.pos).tz(timezone).format('h A')
                if (text === '12 AM') continue
                el.mark.translate(0, -5)
                const pos = el.getPosition(true, el.pos, 0)
                const textItem = chart.renderer.text(text, pos.x, pos.y - 15)
                textItem.css({color: '#677A8E', fontSize: '11px'}).attr({zIndex: 6})
                textItem.add().translate(-textItem.getBBox().width * 0.5, 0)
                minorTickLabels.push(textItem)
            }
        } else if (period === 'Month') {
            for (var pos in axis0.minorTicks) {
                break
            }
            pos = parseInt(pos)
            const weekday = dayjs(pos).tz(timezone).format('d')
            if (!weekday) return
            const dpos = axis0.getMinorTickInterval() / 7 * (7 - weekday)
            const x0 = axis0.toPixels(pos)
            const x1 = axis0.toPixels(pos + dpos)
            const dx = x1 - x0
            for (const tick in axis0.minorTicks) {
                axis0.minorTicks[tick].mark.translate(dx, 0)
            }
        }
    }

    const alterTicks = (chart) => { // draw horizontal line between xaxis labels
        if (period === 'Day') return
        const xaxis_delim = chart.renderer.path()
        xaxis_delim.attr({zIndex: 6, opacity: '1', 'stroke-width': 1, stroke: minorTickColor, d: 'M 0 38.5 L ' + chartWidth -200 + ' 38.5'})
        xaxis_delim.add()
    }

    const diagonalPatternFill = (chart) => { // diagonal background fill for month view
        if (chart.diagonalFill) {
            for (const idx in chart.diagonalFill) chart.diagonalFill[idx].destroy()
            chart.diagonalFill = []
        }
        if (period !== 'Month') return
        const ticks = chart.xAxis[0].ticks
        chart.diagonalFill = []
        for (const idx in ticks) {
            const pos = ticks[idx].pos
            const weekday = dayjs(pos).tz(timezone).format('d')
            if (weekday !== '0' && weekday !== '6') continue
            const x1 = chart.xAxis[0].toPixels(pos, false)
            const x2 = chart.xAxis[0].toPixels(pos + periodday, false)
            const rect = chart.renderer.rect(x1, chart.plotTop, x2 - x1, chart.plotHeight)
            chart.diagonalFill.push(rect)
            rect.attr({zIndex: 0, fill: 'url(#diagonalHatch)'}).add()
        }
    }

    const alterNavLabels = (chart) => { // bold selected range label
        const nav_axis = chart.xAxis[1]
        const pos = chart.xAxis[0].min
        for (const val in nav_axis.ticks) {
            const tick = nav_axis.ticks[val]
            tick.label.element.style.fontWeight = 'normal'
            if (tick.pos === pos) {
                tick.label.element.style.fontWeight = 'bold'
            }
        }
    }

    const addNavGrid = (chart) => {
        delNavGrid()
        if (!chart || !chart.navigator) return
        const bbox = chart.navigator.navigatorGroup.getBBox()
        if (bbox.height !== navigatorHeight) {
            bbox.y -= (navigatorHeight - bbox.height) * 0.5
            bbox.height = navigatorHeight
        }
        navGrid = chart.renderer.rect(bbox.x, bbox.y, bbox.width, bbox.height, 0, 1)
        navGrid.add().attr({zIndex: 6, opacity: '1', 'stroke-width': 1, stroke: minorTickColor})
    }

    const delNavGrid = () => {
        if (!navGrid) {
            return
        }
        navGrid.destroy()
        navGrid = null
    }

    const alterDataLabels = (chart) => {
        if (!chart || !chart.series) return
        const points = chart.series[0].points
        const now = Date.now()
        let doRedraw = false
        for (const idx in points) {
            const point = points[idx]
            if (openAlertRef.current && point.alert_id === openAlertRef.current.id) {
                console.log(point)
                const event = new Event('click')
                event.point = point
                point.firePointEvent('click', event)
                openAlertRef.current = null
            }
            const options = point.options
            const span = point.dataLabel.text.element
            span.parentNode.style.opacity = '1'
            if (options.end_date < now && !options.triggered && span.style.opacity !== '50%') {
                span.style.opacity = '50%'
                point.graphic.element.style.opacity = 0.5
                options.category = 'Historical'
            } else if (point.x < now) {
                options.category = 'Live'
                if (options.triggered && point.x2 <= now) {
                    point.update({x2: now + 120000}, false)
                    doRedraw = true
                }
            } else {
                options.category = 'Forecast'
            }
            const graphicWidth = point.graphic.getBBox().width
            if (graphicWidth < 20) {
                if (span.innerHTML !== '') {
                    span.innerHTML = ''; options.graphicWidth = graphicWidth
                }
                continue
            }
            if (span.innerHTML.slice(0, 2) !== '<i' && options.graphicWidth === graphicWidth && options.spanWidth && options.spanWidth === span.style.width) {
                continue
            }
            options.graphicWidth = graphicWidth
            options.spanWidth = span.style.width
            span.style.width = null
            // const image_src = weatherIcons[point.options.type] || weatherIcons['General Caution']; // TODO: use jsx icons
            const color = weatherLabelColors[options.severity]
            const imageHtml = '<svg style="vertical-align:middle; margin-right:5px; max-height: 20px; max-width: 20px; fill: ' + color + '"><use height="20" width="20" href="' + options.icon + '#svg2" /></svg>'
            const text = '<span style="display: inline; vertical-align:middle;">' + options.type + '</span>'
            span.innerHTML = imageHtml + text
            if (span.scrollWidth < graphicWidth - 10) {
                span.style.width = (graphicWidth - 15) + 'px'
                continue
            }
            span.innerHTML = imageHtml
            // const imageWidth = span.scrollWidth;
            if (span.scrollWidth > graphicWidth - 10) {
                span.innerHTML = '\u2026'
                if (span.scrollWidth > graphicWidth - 10) {
                    span.innerHTML = ''
                }
            } else {
                if (graphicWidth < 10) {
                    span.innerHTML = ''
                    continue
                }
                span.innerHTML = imageHtml + '<span style="display: inline; vertical-align:middle;">' + options.type[0] + '</span>'
                if (span.scrollWidth < graphicWidth - 10) {
                    span.style.width = (graphicWidth - 15) + 'px'
                    span.innerHTML = imageHtml + text
                } else {
                    span.style.width = (graphicWidth - 15) + 'px'
                    span.innerHTML = imageHtml
                }
            }
        }
        if (doRedraw) {
            chartRef.current.redraw()
        }
    }

    const alterGridLines = (chart) => {
        if (!chart || !chart.xAxis) return
        // hide first and last grid line
        const min = '' + chart.xAxis[0].min
        const max = '' + chart.xAxis[0].max
        const ticks = chart.xAxis[0].ticks
        const minorTicks = chart.xAxis[0].minorTicks
        for (const pos in ticks) {
            if (pos === min || pos === max) {
                ticks[pos].gridLine.hide()
                if (ticks[pos].mark) ticks[pos].mark.hide()
            } else {
                ticks[pos].gridLine.show()
                if (ticks[pos].mark) ticks[pos].mark.show()
            }
        }
        for (const pos in minorTicks) {
            let real_pos = pos
            if (period === 'Month') {
                real_pos = '' + (minorTicks[pos])
            }
            if (real_pos === min || real_pos === max) {
                minorTicks[pos].gridLine.hide()
                if (minorTicks[pos].mark) minorTicks[pos].mark.hide()
            } else {
                minorTicks[pos].gridLine.show()
                if (minorTicks[pos].mark) minorTicks[pos].mark.show()
            }
        }
    }

    const currentTimeTick = () => { // draw vertical line for current time, alter background for past and future
        const chart_ref = chartRef.current
        if (!chart_ref || !chart_ref.xAxis) return
        const min = chart_ref.xAxis[0].min
        const max = chart_ref.xAxis[0].max
        setTimelineRange({
            startDate: min,
            endDate: max,
        })
        const minX = chart_ref.plotLeft
        const minY = chart_ref.plotTop
        const maxX = minX + chart_ref.plotWidth
        const maxY = minY + chart_ref.plotHeight
        const xpos = chart_ref.xAxis[0].toPixels(Date.now(), false)
        const backgroundPath = 'M ' + (maxX + 20) + ' ' + minY + ' h ' + (-maxX - 5) + ' q -10 0 -10 10 v ' + (maxY - minY - 20) + ' q 0 10 10 10 h ' + (maxX + 5) + ' z'
        const currentTickPath = (x, y1, dy) => 'M ' + x + ' ' + y1 + ' L ' + x + ' ' + (y1 + dy)
        const tooltipPath = (x, y) => 'M ' + x + ' ' + y + ' l 3 -5 h 27 q 5 0 5 -5 v -23 q 0 -5 -5 -5 h -60 q -5 0 -5 5 v 23 q 0 5 5 5 h 27 z'
        const currentTickUpdater = () => {
            console.log('Periodic update')
            const chart_ref = chartRef.current
            if (!chart_ref.xAxis) {
                clearInterval(periodicUpdater.current)
                periodicUpdater.current = null
                return
            }

            const tickX = chart_ref.xAxis[0].toPixels(Date.now(), false)
            chart_ref.currentTime.currentTick.attr({d: currentTickPath(tickX, chart_ref.plotTop, chart_ref.plotHeight)})
            chart_ref.currentTime.clipRect.attr({y: chart_ref.plotTop, width: tickX, height: chart_ref.plotHeight})
            chart_ref.currentTime.tooltipArea.attr({x: tickX - 5, y: chart_ref.plotTop, height: chart_ref.plotHeight})
            alterDataLabels(chart_ref)
        }
        if (!chart_ref.currentTime) { // draw all first time
            const renderer = chart_ref.renderer
            const currentTick = renderer.path()
            const tooltip = renderer.path()
            const tooltipText = renderer.text('')
            const tooltipArea = renderer.rect(xpos - 5, minY, 10, maxY)
            tooltip.attr({zIndex: 8, opacity: 0, visibility: 'hidden'}).add()
            tooltip.attr({'stroke-width': 1, stroke: '#000000', fill: 'rgb(51, 60, 71)', d: tooltipPath(xpos, minY)})
            tooltipText.attr({zIndex: 8, opacity: 0, visibility: 'hidden', fill: 'rgb(255, 255, 255)'}).css({fontSize: '14px'}).add()
            tooltipArea.attr({zIndex: 2, opacity: 0, fill: 'red'}).add()
            tooltipArea.on('mouseover', () => {
                const now = dayjs(Date.now()).tz(timezone).format('h:mm A')
                const x = chart_ref.xAxis[0].toPixels(Date.now(), false)
                const y = chart_ref.plotTop
                chart_ref.currentTime.tooltip.attr({opacity: 1, visibility: 'visible', d: tooltipPath(x, y)})
                chart_ref.currentTime.tooltipText.attr({opacity: 1, visibility: 'visible', text: now})
                const textBbox = chart_ref.currentTime.tooltipText.getBBox()
                chart_ref.currentTime.tooltipText.attr({x: x - textBbox.width / 2, y: y - 17})
            })
            tooltipArea.on('mouseout', () => {
                chart_ref.currentTime.tooltip.fadeOut(500)
                chart_ref.currentTime.tooltipText.fadeOut(500)
            })
            const clipRect = renderer.clipRect(0, minY, xpos, maxY - minY)
            const futureBackground = renderer.path()
            futureBackground.attr({zIndex: -1, opacity: '1', fill: '#ffffff', d: backgroundPath}).add()
            const pastBackground = renderer.path()
            pastBackground.attr({opacity: 0.1, fill: '#677A8E', d: backgroundPath}).clip(clipRect).add()
            currentTick.attr({opacity: '1', 'stroke-width': 1, stroke: '#5A6672'}).add()
            renderer.definition({ // inner shadow
                tagName: 'filter',
                id: 'innershadow',
                opacity: 1,
                children: [
                    {tagName: 'feFlood', 'flood-color': '#e8e8e8'},
                    {tagName: 'feComposite', operator: 'out', in2: 'SourceGraphic'},
                    {tagName: 'feMorphology', operator: 'dilate', radius: '3'},
                    {tagName: 'feGaussianBlur', stdDeviation: '4'},
                    {tagName: 'feComposite', operator: 'in', in2: 'SourceGraphic'},
                ],
            })
            futureBackground.css({filter: 'url(#innershadow)'})
            chart_ref.currentTime = {
                currentTick: currentTick,
                clipRect: clipRect,
                pastBackground: pastBackground,
                futureBackground: futureBackground,
                tooltip: tooltip,
                tooltipText: tooltipText,
                tooltipArea: tooltipArea,
            }
        }
        chart_ref.currentTime.futureBackground.attr({d: backgroundPath})
        chart_ref.currentTime.pastBackground.attr({d: backgroundPath})
        if (periodicUpdater.current === null) {
            periodicUpdater.current = setInterval(currentTickUpdater, 60000)
            console.log('Timeline updater created')
        }
        currentTickUpdater()
    }

    const onPointClick = (e) => { // show alert popup
        e.stopPropagation()
        if (document.getElementById('timelinecharts-tooltip')) {
            setTooltipState(false)
            return
        }
        const p = e.point
        const minX = p.series.chart.plotLeft
        const minY = p.series.chart.plotTop
        const maxX = minX + p.series.chart.plotWidth
        const maxY = minY + p.series.chart.plotHeight
        let pointX = p.plotX + minX
        let pointY = p.plotY + minY + 21
        const tooltipWidth = 430
        const tooltipHeight = 230
        let vOrigin = 'top'
        if (pointX < minX) pointX = minX
        if (pointX + tooltipWidth > maxX) pointX = maxX - tooltipWidth
        if (pointY + tooltipHeight > maxY) {
            pointY = maxY - pointY + 130; vOrigin = 'bottom'
        }
        const start = dayjs(p.options.x).tz(timezone)
        const end = dayjs(p.options.end_date).tz(timezone)
        let tz = start.format('ZZ')
        if (tz[1] === '0') tz = tz[0] + tz[2]; else tz = tz.slice(0, 3)
        tz = ' (GMT' + tz + ')'

        const now = Date.now()
        if (p.options.end_date < now && !p.options.triggered) {
            p.options.category = 'Historical'
        }

        setTooltipState({
            x: pointX,
            y: pointY,
            origin: vOrigin,
            type: p.options.type,
            category: p.options.category,
            start: start.format('MMM D, h:mm A'), // + tz, // Nov '8, 12:00 PM (GMT-5)'
            end: end.format('MMM D, h:mm A'), // + tz,
            conditions: p.options.conditions,
            location: p.options.location,
            severity: p.options.severity,
            location_type: p.options.location_type,
            options: p.options,
        })
    }

    const options = {
        chart: {
            type: 'xrange',
            height: Math.max((maxY + 1) * 50 + chartExtraHeight, minChartHeight),
            animation: false,
            style: {
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: '14px',
            },
            panning: {
                enabled: true,
                type: 'x',
            },
            events: {
                pan: (e) => {
                    const mousePos = e.originalEvent.chartX
                    const chart = e.target
                    const startPos = chart.mouseDownX
                    const axis = chart.xAxis[0]
                    const halfPointRange = axis.minPointOffset || 0
                    const pointRangeDirection = (axis.reversed && !chart.inverted) || (!axis.reversed && chart.inverted) ? -1 : 1
                    // var extremes = axis.getExtremes();
                    const panMin = axis.toValue(startPos - mousePos, true) + halfPointRange * pointRangeDirection
                    const panMax = axis.toValue(startPos + axis.len - mousePos, true) - ((halfPointRange * pointRangeDirection) || (axis.isXAxis && axis.pointRangePadding) || 0)
                    axis.setExtremes(panMin, panMax, true, false, {trigger: 'pan'})
                    return true
                },
                redraw: (e) => {
                    const chart = e.target
                    if (chart.first_update_done && !update) return
                    chart.first_update_done = true
                    update = false
                    const [min, max] = getPlotExtent(period, timelinepos, timezone)
                    e.target.xAxis[0].setExtremes(min, max, true, false, {trigger: ''})
                },
                load: (e) => {
                    alterTicks(e.target)
                },
                render: (e) => {
                    addMinorTickLabels(e.target)
                    addNavGrid(e.target)
                    alterNavLabels(e.target)
                    currentTimeTick()
                    diagonalPatternFill(e.target)
                    alterGridLines(e.target)
                },
                exportData: () => {
                },
            },
        },
        time: {
            timezone: timezone,
        },
        title: {
            text: '',
        },
        defs: {
            patterns: [
                {
                    id: 'diagonalHatch',
                    patternUnits: 'userSpaceOnUse',
                    width: '24',
                    height: '14',
                    path: {
                        d: 'M 0 0 l 24 14',
                        stroke: 'rgb(230, 230, 230)',
                        strokeWidth: 1,
                        fill: 'rgb(250, 251, 254)',
                    },
                },
            ],
        },

        tooltip: {
            enabled: false,
        },
        // colors: [{
        // gradientUnits: "userSpaceOnUse",
        // linearGradient: { x1: 0, x2: 250, y1: 0, y2: 40 },
        // stops: [ [0, '#ff7366'], // start  [20, '#ff7366'],  [20, '#fff3f2'], [250, '#fff3f2'] ] }, '#f2b50d', '#0c8ce9'],

        //        colors: colors,
        //        style: {
        // height: 50,

        //        },

        plotOptions: {
            xrange: {
                point: {
                    events: {
                        click: onPointClick,
                    },
                },
            },
            series: {
                borderColor: { // below gradient is overwrited by points settings
                    linearGradient: {x1: 0, x2: 0, y1: 0, y2: 1},
                    stops: [
                        [0, '#003399'], // start
                        [0.5, '#ffffff'], // middle
                        [1, '#3366AA'], // end
                    ],
                },
                dataLabels: {
                    enabled: true,
                    formatter: function() {
                        return this.point.custom_name
                    },
                },
            },
        },

        xAxis: {
            type: 'datetime',
            opposite: true,
            max: extentmax,
            min: extentmin,
            range: periodseconds,
            minPadding: 0,
            maxPadding: 0,
            tickPositions: ticks,
            tickInterval: tickInterval,
            tickLength: tickLength,
            tickWidth: 2,
            gridLineWidth: 2,
            labels: labels,
            minorTicks: minorTicks,
            minorTickInterval: minorTickInterval,
            minorTickLength: minorTickLength,
            minorTickWidth: minorTickWidth,
            minorTickColor: minorTickColor,
            minorGridLineWidth: minorGridLineWidth,
            minorGridLineColor: minorTickColor,
            tickPositioner: function() {
                if (period !== 'Day') return this.tickPositions
                const newPos = []
                for (let idx=0; idx<this.tickPositions.length; idx++) {
                    newPos.push(this.tickPositions[idx] + 3600 * 1000)
                }
                return newPos
            },
            events: {
                afterSetExtremes: (event) => {
                    event.target.sliding_type = event.trigger
                    if (rangeset) return
                    // alter zoom range after first chart render
                    rangeset = true
                    const axis = event.target
                    const [min, max] = getPlotExtent(period, timelinepos, timezone)
                    axis.setExtremes(min, max, true, false, {trigger: ''})
                },
            },
        },

        exporting: {
            enabled: false,
        },

        navigator: {
            enabled: true,
            liveRedraw: true,
            margin: 10,
            handles: {
                enabled: false,
            },
            series: {
                type: 'gantt',
                pointPlacement: 0.5,
                pointPadding: 0.25,
                accessibility: {
                    enabled: false,
                },
            },
            maskFill: {
                linearGradient: {
                    x1: 0,
                    x2: 1,
                    y1: 0,
                    y2: 1,
                },
                stops: [
                    [0, 'rgba(237, 246, 255, 1)'],
                    [0.5, 'rgba(237, 246, 255, 0)'],
                    [1, 'rgba(237, 246, 255, 1)'],
                ],
            },
            xAxis: {
                minPadding: 0,
                maxPadding: 0,
                max: extentmax,
                min: extentmin,
                tickPositions: navticks,
                labels: navLabels,
                width: chartWidth - 200,
                left: 75, // (1150 - 1000)/2
                tickWidth: 1,
                tickLength: 20,
            },
            yAxis: {
            },
        },

        yAxis: {
            title: {
                text: '',
            },
            min: 0,
            max: maxY,
            reversed: false,
            categories: yvalues,
            height: (maxY + 1) * 50,
            labels: {
                enabled: false,
            },
            gridLineWidth: 0,
        },

        scrollbar: {
            enabled: true,
            height: 0,
            buttonArrowColor: 'rgba(0, 0, 0, 0)',
        },

        rangeSelector: {
            enabled: false,
        },

        accessibility: {
            series: {

                point: {
                    descriptionFormatter: function(point) {
                        return Highcharts.format(
                            '{point.yCategory}, Start {point.x:%Y-%m-%d}, end {point.x2:%Y-%m-%d}.',
                            {point},
                        )
                    },
                },

                textOverflow: 'ellipsis',
                descriptionFormatter: function(series) {
                    return series.name
                },
            },
        },

        series: [{
            showInNavigator: false,
            showInLegend: false,
            name: '',
            borderRadius: 5,
            borderWidth: 1,
            pointWidth: 40,
            groupPadding: 0.5,
            stickyTracking: false,

            legend: {
                reversed: true,
            },
            plotOptions: {
                series: {
                    //                    stacking: "normal"
                },
            },
            data: series,
            dataLabels: {
                align: 'left',
                overflow: 'allow',
                inside: true,
                useHTML: true,
                className: 'weather-alert-bar',
                style: {
                    textOutline: 'none',
                    textOverflow: 'ellipsis',
                    fontSize: '0.9em',
                    fontWeight: 500,
                },
                formatter: function() {
                    const width = this.point.graphic.getBBox().width
                    if (!width) return ''
                    return '<img height="20px" alt={"weatherIcon"} style="vertical-align:middle" src=' + weatherIcons['Tornado Warning'] + '></img>'
                },
            },
        }, {
            showInNavigator: true,
            showInLegend: false,
            data: navseries,
            pointWidth: 0,
        }],

    }

    const navScrollLeft = () => {
        navScroll(-1)
    }

    const navScrollRight = () => {
        navScroll(1)
    }

    const navScroll = (scrollVal) => {
        const nav_axis = chart_ref.xAxis[1]
        const axis = chart_ref.xAxis[0]
        const [min, max] = getPlotExtent(period, timelinepos, timezone, scrollVal)
        timelinepos = (min + max) / 2
        const [navMin, navMax] = getNavExtent(period, (nav_axis.min + nav_axis.max) / 2, timezone, scrollVal)
        if (period === 'Month') {
            nav_axis.update({tickPositions: getNavMonthTicks((navMin + navMax) / 2, timezone)})
        } else if (period === 'Week') {
            nav_axis.update({tickPositions: getNavWeekTicks((navMin + navMax) / 2, timezone)})
        } else if (period === 'Day') {
            nav_axis.update({tickPositions: getNavDayTicks((navMin + navMax) / 2, timezone)})
        }
        nav_axis.update({min: navMin, max: navMax})
        const [series, navseries] = filterSeries(fullSeries, fullNavSeries, navMin, navMax)
        chart_ref.series[0].update({data: series})
        chart_ref.series[1].update({data: navseries})
        axis.setExtremes(min, max, true, false, {trigger: ''})
        nav_axis.update({min: navMin, max: navMax})
        navigatorExtent.current = [navMin, navMax]
        timelinePos.current = timelinepos
    }

    console.log('Refresh')
    return (
        <Box
            data-cy={'timeline-charts'}
            style={{
                overflow: 'auto',
                // flexGrow: 1,
                position: 'relative', // relative position is needed for proper tooltip positioning
            }}
        >
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
                callback={onChartCreated}
                containerProps={{
                    style: {
                    // height: Math.max((maxY + 1) * 50 + chartExtraHeight, 700),
                    //     minHeight: '100%',
                        width: chartWidth,
                    },
                }}
            />
            <Box
                className={'row arrow-buttons-container'}
                sx={{width: chartWidth - 48, justifyContent: 'space-between'}}
            >
                <ArrowButton onClick={navScrollLeft}>
                    <ArrowLeftSmallIcon size={'small'}/>
                </ArrowButton>
                <ArrowButton onClick={navScrollRight}>
                    <ArrowRightSmallIcon size={'small'}/>
                </ArrowButton>
            </Box>
            {tooltip &&
                <TimelineChartsTooltip
                    tooltip={tooltip}
                    setTooltipState={setTooltipState}
                >
                </TimelineChartsTooltip>
            }
        </Box>

    )
}
const ArrowButton = styled(IconButton)(({theme}) => ({
    pointerEvents: 'auto',
    boxShadow: '0px 1px 3px rgba(103, 122, 142, 0.24)',
    borderRadius: '30%',
    padding: '4px',
    width: '24px',
    height: '24px',
    '& .MuiTouchRipple-root .MuiTouchRipple-child': {borderRadius: '30%'},
    '&& .MuiTouchRipple-rippleVisible': {animationDuration: '0ms'},
}))

function getNavDayTicks(tpos, tz) {
    const date = dayjs(tpos).tz(tz).startOf('day')
    const days = []
    for (let day = -3; day <= 4; day++) {
        days.push(date.add(day, 'day').startOf('day'))
    }
    return days
}

function getNavWeekTicks(tpos, tz) {
    const date = dayjs(tpos).tz(tz).startOf('week')
    const weeks = []
    for (let dweek = -3; dweek <= 4; dweek++) {
        weeks.push(date.add(dweek, 'week').startOf('week'))
    }
    return weeks
}

function getNavMonthTicks(tpos, tz) {
    const date = dayjs(tpos).tz(tz).startOf('day')
    const months = []
    for (let dmonth = -3; dmonth <= 4; dmonth++) {
        months.push(date.add(dmonth, 'month').startOf('month'))
    }
    return months
}

function alertsToChartOptions(alertsArray, extentmin, extentmax) {
    const typeArrays = {}
    const minduration = (extentmax - extentmin) / 2000

    alertsArray.forEach((a)=>{
        const endTime = (!a.triggered)?new Date(a.end_date).getTime(): Date.now() + 60000
        const item = {...a,
            x: new Date(a.start_date).getTime(),
            x2: Math.max(endTime, new Date(a.start_date).getTime() + minduration),
            y: 1,
        }
        if (!typeArrays[a.severity]) {
            typeArrays[a.severity]=[item]
        } else {
            typeArrays[a.severity].push(item)
        }
    })
    const yValues = []

    const moderate = makeLevelsWithoutCollisions(typeArrays['Moderate'])
    const moderateMax = moderate.length ? Math.max(...moderate.map((a)=>a.y) ) + 1 : 0
    const severe = makeLevelsWithoutCollisions(typeArrays['Severe'], moderateMax)
    const severeMax =severe.length ? Math.max(...severe.map((a)=>a.y) ) + 1 : moderateMax
    const critical = makeLevelsWithoutCollisions(typeArrays['Critical'], severeMax)
    const criticalMax = critical.length ? Math.max(...critical.map((a)=>a.y) ): severeMax
    const all = [].concat(moderate).concat(severe).concat(critical)

    for (let i=0; i<=criticalMax; i++) {
        if (i<moderateMax) {
            yValues.push('Moderate')
        } else {
            if (i<severeMax) {
                yValues.push('Severe')
            } else {
                yValues.push('Critical')
            }
        }
    }
    return [all.map((a)=>
        // debugger
        ({
            ...a,
            x: a.x,
            x2: a.x2,
            y: a.y,
            //            custom_name: 'Weather event',  // 'a.type,
            borderColor: weatherColors[a.severity],
            color: weatherBackgroundColors[a.severity],
            dataLabels: {
                color: weatherLabelColors[a.severity],
                style: {
                    fontFamily: 'Roboto, "Helvetica Neue", Arial, Helvetica, sans-serif',
                    fontWeight: 500,
                },
            },
            // TODO: need to add id for alert popup info
        }),
    ), yValues]
}

function makeLevelsWithoutCollisions(arr, baseLevel=0) {
    if (!arr) return []
    const result = []
    let level = baseLevel
    let resultLevelBegin = 0
    let tempArr = arr
    while (tempArr.length) {
        const nextLevelArr = []
        if (tempArr.length === 1) {
            result.push({...tempArr[0], y: level})
            tempArr =[]
        } else {
            for (let i = 0; i < tempArr.length; i++) {
                const alert = tempArr[i]
                let isCrossing = false
                for (let j = resultLevelBegin; j < result.length; j++) {
                    const resultAlert = result[j]
                    const lineCross = !((alert.x > resultAlert.x2) || (alert.x2 < resultAlert.x))

                    if (lineCross) {
                        isCrossing = true
                        break
                    }
                }
                if (isCrossing) {
                    nextLevelArr.push(alert)
                } else {
                    result.push({...alert, y: level})
                }
            }
            level++
            resultLevelBegin = result.length
            tempArr = nextLevelArr
        }
    }
    const maxLevel = result.length ? Math.max(...result.map((a)=>a.y)): baseLevel
    if (maxLevel - baseLevel > 1) { // invert levels
        const sumLevels = maxLevel + baseLevel
        result.forEach((alert) => {
            alert.y = sumLevels - alert.y
        })
    }
    return result
}

function alertsToNavOptions(alertsArray, extentmin, extentmax) {
    const typeArrays = {Moderate: [], Severe: [], Critical: []}
    const duration = (extentmax - extentmin) / 1000

    if (!alertsArray) return []
    alertsArray.forEach((a) => {
        const item = {...a, x: new Date(a.start_date).getTime(), x2: new Date(a.start_date).getTime() + duration, y: 1}
        typeArrays[a.severity].push(item)
    })
    const all = [].concat(typeArrays['Moderate']).concat(typeArrays['Severe']).concat(typeArrays['Critical'])
    return all.map((a)=>({
        x: a.x,
        x2: a.x2,
        y: 1,
        color: weatherColors[a.severity],
    }))
}

function filterSeries(fullSeries, fullNavSeries, extentmin, extentmax) {
    const series = []
    for (const idx in fullSeries) {
        const item = fullSeries[idx]
        if (item.x2 < extentmin) continue
        if (item.x > extentmax) continue
        series.push(item)
    }
    const navseries = []
    for (const idx in fullNavSeries) {
        const item = fullNavSeries[idx]
        if (item.x2 < extentmin) continue
        if (item.x > extentmax) continue
        navseries.push(item)
    }
    return [series, navseries]
}

// function getPlotExtentAligned(period, pos, tz, offset = 0) {
//     const low_period = period.toLowerCase();
//     const dt = dayjs(pos).tz(tz).add(offset, low_period);
//     let min, max;
//     if (period === 'Day') {
//         min = dt.startOf('day').valueOf();
//         max = dt.endOf('day').valueOf();
//     }
//     else if (period === 'Week') {
//         min = dt.startOf('week').valueOf();
//         max = dt.endOf('week').valueOf();
//     }
//     else {
//         min = dt.startOf('month').valueOf();
//         max = dt.endOf('month').valueOf();
//     }
//     return [min, max];
// }

function getPlotExtent(period, pos, tz, offset = 0) {
    const low_period = period.toLowerCase()
    const dt = dayjs(pos).tz(tz).add(offset, low_period)
    let min; let max
    if (period === 'Day') {
        min = dt.subtract(12, 'hour').valueOf()
        max = dt.add(12, 'hour').valueOf()
    } else if (period === 'Week') {
        min = dt.subtract(84, 'hour').valueOf()
        max = dt.add(84, 'hour').valueOf()
    } else {
        min = dt.subtract(372, 'hour').valueOf()
        max = dt.add(372, 'hour').valueOf()
    }
    return [min, max]
}

function getNavExtent(period, pos, tz, offset = 0) {
    const low_period = period.toLowerCase()
    const dt = dayjs(pos).tz(tz).add(offset, low_period)
    let min; let max
    if (period === 'Day') {
        min = dt.subtract(3, 'day').startOf('day').valueOf()
        max = dt.add(3, 'day').endOf('day').valueOf()
    } else if (period === 'Week') {
        min = dt.subtract(3, 'week').startOf('week').valueOf()
        max = dt.add(3, 'week').endOf('week').valueOf()
    } else {
        min = dt.subtract(3, 'month').startOf('month').valueOf()
        max = dt.add(3, 'month').endOf('month').valueOf()
    }
    return [min, max]
}

export const TimeLineChartsMemoized = memo(TimeLineCharts)
