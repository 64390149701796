import React, {useState, useEffect, useCallback} from 'react'
import {Box, FormControlLabel, FormGroup, Switch} from '@mui/material'
import {CheckBox} from 'shared/ui/CheckBox'
import RecipientList from 'widgets/alertConfig/ui/RecipientList/RecipientList'
import NotificationCheckboxWithMessage from 'features/notification/NotificationCheckboxWithMessage/NotificationCheckboxWithMessage'
import NotificationCheckboxTimeWithMessage from 'features/notification/NotificationCheckboxTimeWithMessage/NotificationCheckboxTimeWithMessage'
import {useUserStore} from '../../../../../app/store/UserStore'

const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

function NotificationsStepScreen({notificationData, onNotificationDataChange, showSidebar, setShowSidebar, defaultMessages, setDefaultNotificationMessages}) {
    const {patchUser} = useUserStore((state) => state)
    const [isForecastAppearsChecked, setIsForecastAppearsChecked] = useState(notificationData.fc_appears_checked ?? true)
    const [customForecastAppearsMessage, setCustomForecastAppearsMessage] = useState(notificationData.fc_appears ?? defaultMessages.fc_appears)

    const [isForecastUpdatesChecked, setIsForecastUpdatesChecked] = useState(notificationData.fc_updates_checked ?? false)
    const [customForecastUpdatesMessage, setCustomForecastUpdatesMessage] = useState(notificationData.fc_updates ?? defaultMessages.fc_updates)

    const [isWeatherEventStartedChecked, setIsWeatherEventStartedChecked] = useState(notificationData.we_starts_checked ?? false)
    const [customWeatherEventStartedMessage, setCustomWeatherEventStartedMessage] = useState(notificationData.we_starts ?? defaultMessages.we_starts)

    const [isCustomTimeChecked, setIsCustomTimeChecked] = useState(notificationData.custom_time_checked ?? false)
    const [customTimeMessage, setCustomTimeMessage] = useState(notificationData.custom_time ?? defaultMessages.custom_time)

    const [showDays, setShowDays] = useState(notificationData.days_checked ?? false)
    const [selectedDays, setSelectedDays] = useState(notificationData.days ?? {})

    const [isForecastAppearsDefault, setIsForecastAppearsDefault] = useState(false)
    const [isForecastUpdatesDefault, setIsForecastUpdatesDefault] = useState(false)
    const [isWeatherEventStartedDefault, setIsWeatherEventStartedDefault] = useState(false)
    const [isCustomTimeDefault, setIsCustomTimeDefault] = useState(false)

    useEffect(() => {
        // console.log("any data change")
        onNotificationDataChange({
            ...notificationData,
            days_checked: showDays,
            fc_appears_checked: isForecastAppearsChecked,
            fc_appears: customForecastAppearsMessage,
            fc_updates_checked: isForecastUpdatesChecked,
            fc_updates: customForecastUpdatesMessage,
            we_starts_checked: isWeatherEventStartedChecked,
            we_starts: customWeatherEventStartedMessage,
            custom_time_checked: isCustomTimeChecked,
            custom_time: customTimeMessage,
        })
    }, [showDays,
        isForecastAppearsChecked,
        customForecastAppearsMessage,
        isForecastUpdatesChecked,
        customForecastUpdatesMessage,
        isWeatherEventStartedChecked,
        customWeatherEventStartedMessage,
        isCustomTimeChecked,
        customTimeMessage,
    ])

    useEffect(() => {
        // console.log("init hook , wait for notificationData")
        // Load the checkbox states and messages for all notifications
        const fcAppearsChecked = notificationData.fc_appears_checked ?? true
        const fcAppearsMessage = notificationData.fc_appears ?? defaultMessages.fc_appears
        setIsForecastAppearsChecked(fcAppearsChecked)
        setCustomForecastAppearsMessage(fcAppearsMessage)
        setIsForecastAppearsDefault(fcAppearsMessage === defaultMessages.fc_appears)

        const fcUpdatesChecked = notificationData.fc_updates_checked ?? false
        const fcUpdatesMessage = notificationData.fc_updates ?? defaultMessages.fc_updates
        setIsForecastUpdatesChecked(fcUpdatesChecked)
        setCustomForecastUpdatesMessage(fcUpdatesMessage)
        setIsForecastUpdatesDefault(fcUpdatesMessage === defaultMessages.fc_updates)

        const weStartsChecked = notificationData.we_starts_checked ?? false
        const weStartsMessage = notificationData.we_starts ?? defaultMessages.we_starts
        setIsWeatherEventStartedChecked(weStartsChecked)
        setCustomWeatherEventStartedMessage(weStartsMessage)
        setIsWeatherEventStartedDefault(weStartsMessage === defaultMessages.we_starts)

        const customTimeChecked = notificationData.custom_time_checked ?? false
        const customTimeMessage = notificationData.custom_time ?? defaultMessages.custom_time
        setIsCustomTimeChecked(customTimeChecked)
        setCustomTimeMessage(customTimeMessage)
        setIsCustomTimeDefault(customTimeMessage === defaultMessages.custom_time)

        setShowDays(notificationData.days_checked || false)
        setSelectedDays(notificationData.days ?? {})
    }, [notificationData, defaultMessages])

    const handleContactsDataChange = (newContactsData) => {
        onNotificationDataChange({...notificationData, contacts: newContactsData})
    }

    const handleSelfNotify = (selfNotifyData) => {
        onNotificationDataChange({...notificationData,
            allow_self_notification_email: selfNotifyData['allow_self_notification_email'],
            allow_self_notification_sms: selfNotifyData['allow_self_notification_sms']})
    }

    // Separated handler because needs to create state for each of the day
    const handleDayChange = useCallback((day) => {
        setSelectedDays((prevDays) => {
            const isSelected = !prevDays[day]
            const newSelectedDays = {...prevDays, [day]: isSelected}
            if (isSelected !== prevDays[day]) {
                onNotificationDataChange({...notificationData, days: newSelectedDays})
            }
            return newSelectedDays
        })
    }, [notificationData, onNotificationDataChange])

    // Generic function to handle checkbox state changes for all types
    const handleNotificationDataChange = useCallback((type, data) => {
        const updatedData = {...notificationData}

        if (typeof data === 'boolean') {
            // Handle checkbox state changes
            updatedData[`${type}_checked`] = data
        } else if (typeof data === 'object' && data !== null) {
            // Handle custom time data updates
            Object.assign(updatedData, data)
        } else {
            // Handle message updates
            updatedData[type] = data
        }

        // console.log("unified change handler")
        onNotificationDataChange(updatedData)
    }, [notificationData, onNotificationDataChange])

    // const updateIsDefaultStatus = (type, newDefaultStatus) => {
    //     switch (type) {
    //         case 'fc_appears':
    //             setIsForecastAppearsDefault(newDefaultStatus)
    //             break
    //         case 'fc_updates':
    //             setIsForecastUpdatesDefault(newDefaultStatus)
    //             break
    //         case 'we_starts':
    //             setIsWeatherEventStartedDefault(newDefaultStatus)
    //             break
    //         case 'custom_time':
    //             setIsCustomTimeDefault(newDefaultStatus)
    //             break
    //         default:
    //             break
    //     }
    // }

    const saveMessageAsDefault = (type, newMessage) => {
        const updatedDefaultMessages = {
            ...defaultMessages,
            [type]: newMessage,
        }

        const settingsData = {
            default_notification_messages: JSON.stringify(updatedDefaultMessages),
        }

        patchUser(settingsData)
            .then((response) => {
                setDefaultNotificationMessages(updatedDefaultMessages)
            })
            .catch((error) => {
                console.error('Error saving settings:', error)
            })
    }

    return (
        <Box style={{overflowY: showSidebar ? 'auto': 'hidden', display: 'flex', flexDirection: 'column', gap: '16px', height: '100%'}}>
            <Box style={{boxSizing: 'border-box', borderRadius: '16xp', background: '#FFFFFF'}}>
                {showSidebar && (<Box
                    sx={{
                        display: 'flex', flexDirection: 'column', alignItems: 'stretch', padding: '16px', gap: '12px',
                        border: '1px solid #f0f2f5', borderRadius: '12px',
                    }}
                                 >
                    <Box
                        className={'subtitle medium'}
                        sx={{textTransform: 'uppercase'}}
                    >
NOTIFICATIONS
                    </Box>

                    <NotificationCheckboxWithMessage
                        label="Forecast appears"
                        checked={isForecastAppearsChecked}
                        onNotificationDataChange={handleNotificationDataChange}
                        message={customForecastAppearsMessage}
                        type="fc_appears"
                        onChange={setCustomForecastAppearsMessage}
                        isDefaultMessage={isForecastAppearsDefault}
                        onSaveAsDefault={saveMessageAsDefault}
                    />
                    <NotificationCheckboxWithMessage
                        label="Forecast updates"
                        checked={isForecastUpdatesChecked}
                        onNotificationDataChange={handleNotificationDataChange}
                        message={customForecastUpdatesMessage}
                        type="fc_updates"
                        onChange={setCustomForecastUpdatesMessage}
                        isDefaultMessage={isForecastUpdatesDefault}
                        onSaveAsDefault={saveMessageAsDefault}
                    />
                    <NotificationCheckboxWithMessage
                        label="Weather event starts"
                        checked={isWeatherEventStartedChecked}
                        onNotificationDataChange={handleNotificationDataChange}
                        message={customWeatherEventStartedMessage}
                        type="we_starts"
                        onChange={setCustomWeatherEventStartedMessage}
                        isDefaultMessage={isWeatherEventStartedDefault}
                        onSaveAsDefault={saveMessageAsDefault}
                    />
                    <NotificationCheckboxTimeWithMessage
                        checked={isCustomTimeChecked}
                        onNotificationDataChange={handleNotificationDataChange}
                        message={customTimeMessage}
                        customTimeSettings={{
                            customTimeDuration: notificationData.custom_duration,
                            customTimePeriod: notificationData.custom_period,
                            customTimeHour: notificationData.custom_hour,
                        }}
                        onChange={setCustomTimeMessage}
                        isDefaultMessage={isCustomTimeDefault}
                        onSaveAsDefault={saveMessageAsDefault}
                    />

                    <Box
                        style={{
                            width: 'auto',
                            border: '1px solid var(--palette-grey-100)',
                            borderRadius: '8px',
                            background: 'var(--palette-grey-background)',
                            padding: '10px 12px 10px 16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: showDays ? '16px' : '0',

                        }}
                    >
                        <FormGroup>
                            <FormControlLabel
                                control={<Switch
                                    checked={showDays}
                                    onChange={(e) => setShowDays(e.target.checked)}
                                />}
                                label="Select days of the week"
                                labelPlacement={'start'}
                                style={{width: 'max-content', alignItems: 'center'}}
                            />
                        </FormGroup>

                        <FormGroup sx={{display: 'flex', flexDirection: 'row', gap: '14px'}}>
                            {days.map((day) => (
                                <FormControlLabel
                                    style={{
                                        width: '135px',
                                        height: showDays ? '40px' : '0px',
                                        padding: showDays ? '10px 12px 10px 8px' : '0',
                                        background: showDays ? 'white' : 'transparent',
                                        border: showDays ? '1px solid var(--palette-grey-100)' : 'none',
                                        borderRadius: showDays ? '8px' : '0',
                                        opacity: showDays ? '1' : '0',

                                    }}
                                    key={day}
                                    control={
                                        <CheckBox
                                            checked={selectedDays[day] || false}
                                            onChange={() => handleDayChange(day)}
                                        />
                                    }
                                    label={day}
                                />
                            ))}
                        </FormGroup>

                    </Box>
                </Box>)}
            </Box>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: showSidebar ? '16px' : '0',
                    gap: '12px',
                    border: '1px solid #f0f2f5',
                    borderRadius: showSidebar ? '12px' : '12px',
                    height: showSidebar ? 'auto' : '100%',
                }}
            >
                <RecipientList
                    contactsData={notificationData.contacts}
                    onContactsDataChange={handleContactsDataChange}
                    selfNotifyData={{
                        allow_self_notification_email: notificationData.allow_self_notification_email,
                        allow_self_notification_sms: notificationData.allow_self_notification_sms,
                    }}
                    onSelfNotify={handleSelfNotify}
                    showSidebar ={showSidebar}
                    setShowSidebar={setShowSidebar}
                />
            </Box>
        </Box>
    )
}

export default NotificationsStepScreen
