import {Box, Button, Chip, IconButton, MenuItem, Popover} from '@mui/material'
import List from '@mui/material/List'
import React, {useEffect, useState} from 'react'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import MoreIcon from 'shared/assets/icons/More'
import AgreeModal from 'shared/ui/AgreeModal'
import {Spacer} from 'shared/ui/Spacer'
import {useWeatherDefinitionsStore} from '../../../../app/store/WeatherDefinitionsStore'
import theme from '../../../../app/Theme'
import BellIcon from '../../../../shared/assets/icons/Bell'
import CopyIcon from '../../../../shared/assets/icons/Copy'
import MapPinIcon from '../../../../shared/assets/icons/MapPin'
import PenIcon from '../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../shared/assets/icons/Trash'
import {DefinitionRenderLarge} from '../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderLarge/DefinitionRenderLarge'
import EditWeatherDefinitionPage from '../../../../widgets/weatherDefinition/EditWeatherDefinitionPage/EditWeatherDefinitionPage'
import {useAlertConfigurationsStore} from '../../../../app/store/AlertConfigurationsStore'
import styles from './WeatherDefinitionDetails.module.scss'
import {clsx} from 'clsx'

export function WeatherDefinitionDetails({
    definition_id,
    onDelete = () => {},
    editable = false,
    disableAddAlertButton = false,
    onBack,
    setShowSidebar = () => {},
}) {
    const {
        toggleEditingAlertConfiguration,
        deleteAlertConfigurationByID,
    } = useAlertConfigurationsStore((state) => state)

    const {
        editingWeatherDefinition,
        fetchWeatherDefinition,
        deleteWeatherDefinition,
        openWeatherDefinition,
        toggleEditingWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)
    const [showMore, setShowMore] = useState(false)
    const [weatherDefinition, setWeatherDefinition] = useState(null)

    const [isDeleteWeatherDefinitionModalOpen, setIsDeleteWeatherDefinitionModalOpen] = useState(false)
    const [deleteConfig, setDeleteConfig] = useState(false)

    useEffect(() => {
        fetchWeatherDefinition(definition_id)
            .then((weatherDefinition) => {
                setWeatherDefinition(weatherDefinition)
            })
    }, [])

    useEffect(() => {
        fetchWeatherDefinition(definition_id)
            .then((weatherDefinition) => {
                setWeatherDefinition(weatherDefinition)
            })
    }, [editingWeatherDefinition])

    if (!weatherDefinition) return (<></>)

    const handleCopyWeatherDefinition =()=>{
        setShowSidebar(false)
        toggleEditingWeatherDefinition({
            ...weatherDefinition,
            id: null,
            name: weatherDefinition.name + ' Copy',
        })
    }

    const handleEditWeatherDefinition = () => {
        setShowSidebar(false)
        toggleEditingWeatherDefinition(weatherDefinition)
    }

    const addNewConfiguration = () => {
        toggleEditingAlertConfiguration({definition: weatherDefinition.id})
    }

    const copyConfiguration = (id) => {
        const locations = {}
        weatherDefinition.configurations[id].forEach((loc) => {
            locations[loc.id] = true
        })
        toggleEditingAlertConfiguration({back: true, locations: locations, definition: weatherDefinition.id})
        setShowMore(false)
    }

    const editConfiguration = (id) => {
        const locations = {}
        weatherDefinition.configurations[id].forEach((loc) => {
            locations[loc.id] = true
        })
        toggleEditingAlertConfiguration({back: true, locations: locations, definition: weatherDefinition.id, id: id})
        setShowMore(false)
    }

    const deleteConfiguration = (id) => {
        setDeleteConfig(id)
        setShowMore(false)
    }

    const doDeleteConfiguration = (agreed) => {
        if (agreed) {
            deleteAlertConfigurationByID(deleteConfig)
            delete weatherDefinition.configurations[deleteConfig]
        }
        setDeleteConfig(false)
        setShowMore(false)
    }

    const handleDeleteWeatherDefinition = (event) => {
        event.stopPropagation()
        setIsDeleteWeatherDefinitionModalOpen(true)
    }

    const confirmDeleteWeatherDefinition = async (agreed) => {
        if (agreed) {
            await deleteWeatherDefinition(weatherDefinition)
            onDelete()
        }
        setIsDeleteWeatherDefinitionModalOpen(false)
    }

    const handleGoBack = async () => {
        if (!!onBack) {
            onBack()
        } else {
            openWeatherDefinition(null)
        }
    }

    const configurations = []
    for (const key in weatherDefinition.configurations) {
        configurations.push(
            <Box className={styles.locationWrapper}>
                <Box>
                    {weatherDefinition.configurations[key].map((location) => (<>
                        <span className={styles.pinWrapper}>
                            <span style={{whiteSpace: 'nowrap'}}>
                                <MapPinIcon className={styles.pin}/>
                                {location.label}
                            </span>
                        </span>
                        {' '}
                    </>
                    ))}
                </Box>
                <Box className={styles.iconWrapper}>
                    <IconButton
                        data-cy={'alert-configuration-menu'}
                        onClick={({currentTarget}) => {
                            setShowMore({target: currentTarget, key: key})
                        }}
                        className={styles.icon}
                    >
                        <MoreIcon size={'small'}/>
                    </IconButton>
                </Box>
            </Box>,
        )
    }

    return (
        <Box
            data-cy={'wd-details'}
            className={'fullHeight fullWidth'}
        >
            {!editingWeatherDefinition &&
                <Box
                    className="WeatherDefinitionPanel fullWidth"
                    sx={{overflow: 'unset'}}
                >
                    <Box
                        data-cy={'wd-details-toolbar'}
                        className={clsx('toolbar padding', styles.header)}
                    >
                        <IconButton
                            data-cy={'wd-details-back-button'}
                            size={'small'}
                            variant={'outlined'}
                            onClick={handleGoBack}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <h3>
                            {weatherDefinition.name}
                        </h3>
                        <Chip
                            variant={theme.palette.severity.variant[weatherDefinition.severity]}
                            size={'small'}
                            label={weatherDefinition.severity}
                        />
                        <Spacer/>
                        {editable && !disableAddAlertButton &&
                            <Button
                                data-cy={'wd-details-add-alert-button'}
                                variant={'outlined'}
                                color={'secondary'}
                                onClick={addNewConfiguration}
                                startIcon={<BellIcon/>}
                            >
                                Add alert
                            </Button>
                        }
                        {editable && weatherDefinition.rules &&
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                data-cy={'wd-details-copy-button'}
                                onClick={handleCopyWeatherDefinition}
                                startIcon={<CopyIcon/>}
                            >
                                Copy
                            </Button>
                        }
                        {editable && weatherDefinition.rules && weatherDefinition.user && <>
                            <Button
                                variant={'outlined'}
                                data-cy={'wd-details-edit-button'}
                                onClick={handleEditWeatherDefinition}
                                startIcon={<PenIcon/>}
                            >
                                Edit
                            </Button>
                            <Button
                                variant={'outlined'}
                                color={'error'}
                                startIcon={<TrashIcon/>}
                                data-cy={'wd-details-delete-button'}
                                onClick={handleDeleteWeatherDefinition}
                            >
                                Delete
                            </Button>
                        </>}
                        {!editable &&
                            <Button
                                data-cy={'wd-details-details-button'}
                                variant={'outlined'}
                                href={`/settings?weather_definition_id=${definition_id}#weather-definitions`}
                            >
                                Details
                            </Button>
                        }
                    </Box>
                    <Box className={styles.contentWrapper}>
                        <Box className={styles.parametersWrapper}>
                            <Box className={clsx('paragraph medium', styles.title)}>
                                PARAMETERS
                            </Box>
                            <DefinitionRenderLarge definition={weatherDefinition}/>
                        </Box>
                        {editable &&
                            <Box className={styles.configsWrapper}>
                                <Box className={clsx('paragraph medium', styles.title)}>
                                    ALERT CONFIGURATIONS
                                </Box>
                                {configurations}
                                {showMore &&
                                    <Popover
                                        open={true}
                                        anchorEl={showMore.target}
                                        onClose={() => {
                                            setShowMore(false)
                                        }}
                                        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                        transformOrigin={{vertical: 'top', horizontal: 'right'}}
                                    >
                                        <List>
                                            <MenuItem
                                                onClick={() => copyConfiguration(showMore.key)}
                                            >
                                                <CopyIcon size={'small'}/>
                                                Copy
                                            </MenuItem>
                                            <MenuItem
                                                data-cy={'alert-configuration-menu-edit-button'}
                                                onClick={() => editConfiguration(showMore.key)}
                                            >
                                                <PenIcon size={'small'}/>
                                                Edit
                                            </MenuItem>
                                            <MenuItem
                                                color={'error'}
                                                onClick={() => deleteConfiguration(showMore.key)}
                                            >
                                                <TrashIcon size={'small'}/>
                                                Delete
                                            </MenuItem>
                                        </List>
                                    </Popover>
                                }
                            </Box>
                        }
                    </Box>
                </Box>
            }
            {editingWeatherDefinition &&
                <EditWeatherDefinitionPage setShowSidebar={setShowSidebar}/>
            }
            {isDeleteWeatherDefinitionModalOpen &&
                <AgreeModal
                    data={{
                        message:
                            <>
                                Are you sure you want to delete
                                {' '}
                                <span className={styles.deleteTitle}>
                                    {weatherDefinition.name}
                                </span>
                                {' '}
                                weather defintion and
                                {' '}
                                <span className={styles.deleteTitle}>
                                    all alert configurations and action items
                                </span>
                                {' '}
                                linked to this definition?
                            </>,
                        title: 'Delete weather definition',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: confirmDeleteWeatherDefinition,
                    }}
                />
            }
            {deleteConfig &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want delete "${weatherDefinition.name}" alert configuration?`,
                        title: 'Delete alert configuration',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: doDeleteConfiguration,
                    }}
                />
            }
        </Box>
    )
}
