import IconButton from "@mui/material/IconButton";
import CloseIcon from "shared/assets/icons/Close";
import React, {useState, useEffect} from 'react';
import ModalComponent from 'shared/ui/ModalComponent';
import {Box, Button, Chip, Typography} from '@mui/material';
import {Editor, EditorState, Modifier, CompositeDecorator, ContentState} from 'draft-js';
import styles from "./CustomMessagePopup.module.scss"
import {clsx} from "clsx";

export const tokens = [
    { id: 'alert_name', displayName: 'Alert name' },
    { id: 'severity', displayName: 'Severity' },
    { id: 'location', displayName: 'Location' },
    { id: 'start_date', displayName: 'Start date' },
    { id: 'start_time', displayName: 'Start time' },
    { id: 'end_date', displayName: 'End date' },
    { id: 'end_time', displayName: 'End time' },
    { id: 'issue_start_date', displayName: 'Issue date' },
    { id: 'issue_start_time', displayName: 'Issue time' },
    { id: 'timezone', displayName: 'Timezone' },
    { id: 'email_template', displayName: 'Email template:' },
    { id: 'date_value_compact', displayName: 'Date value compact' },
    { id: 'day_value_compact', displayName: 'Day value compact' },
    { id: 'date_value_verbose', displayName: 'Date value verbose' },
    { id: 'ellipsis', displayName: '...' },
];


export const renderMessageWithTokens = (message, tokens) => {
    message = message.replaceAll('\n', '{{br}}');
    const words = message.split("{{");
    const newContent = words.reduce((acc, word, index) => {
        const isToken = word.indexOf('}}') > 0;
        const textAfter = isToken ? word.slice(word.indexOf('}}') + 2) : null;
        const tokenId = isToken ? word.slice(0, word.indexOf('}}')).replaceAll(/[{}]/g, '') : null;
        const token = isToken ? tokens.find(t => t.id === tokenId) : null;
        if (!index)
            acc.push(word);
        else if (tokenId == 'br') {
            acc.push(<br/>);
            acc.push(textAfter);
        }
        else if (token) {
            acc.push(
                <Chip
                    key={`${tokenId}-${index}`}
                    style={{margin: '1px 3px', height: '25px'}}
                    contentEditable={false}
                    label={token.displayName}
                />
            );
            acc.push(textAfter);
        } else {
            // plain text
            acc.push('{{' + word);
        }
        return acc;
    }, []);
    return newContent;
}


const createEditorStateWithTokens = (messageText, tokens, decorator) => {
    // Transformations messageText to ContentState
    let contentState = ContentState.createFromText(messageText);

    tokens.forEach(token => {
        const regex = new RegExp(`\\{\\{${token.id}\\}\\}`, 'g');
        const tokenText = `{{${token.id}}}`;
        let matchArr;
        contentState.getBlockMap().forEach(block => {
            let offset = 0;
            const text = block.getText();
            while ((matchArr = regex.exec(text)) !== null) {
                const selection = contentState.getSelectionAfter();

                const selectionState = selection.merge({
                    anchorKey: block.key,
                    anchorOffset: matchArr.index + offset,
                    focusKey: block.key,
                    focusOffset: matchArr.index + tokenText.length + offset,
                });
                contentState = contentState.createEntity('TOKEN', 'IMMUTABLE', {
                    id: token.id,
                    displayName: token.displayName
                });
                const entityKey = contentState.getLastCreatedEntityKey();

                contentState = Modifier.replaceText(contentState, selectionState, tokenText, null, entityKey);

                offset += tokenText.length - matchArr[0].length;
            }
        })
    });

    return EditorState.createWithContent(contentState, decorator);
};


const tokenStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'TOKEN'
            );
        },
        callback
    );
};

const TokenComponent = (props) => {
    const {contentState, entityKey} = props;
    const {displayName} = contentState.getEntity(entityKey).getData();

    return <Chip entity-key={entityKey} data-offset-key={props.offsetKey} label={displayName} style={{margin: '4px 0', height:"24px", fontSize:"14px"}}/>;
};

const decorator = new CompositeDecorator([
    {
        strategy: tokenStrategy,
        component: TokenComponent,
    },
]);


function CustomMessagePopup({visible, onCloseModal, messageText = "", tokens, onChange}) {

    messageText = " " + messageText.trim() + " "
    const [editorState, setEditorState] = useState(() =>
        createEditorStateWithTokens(messageText, tokens, decorator)
    );

    useEffect(() => {
        if (visible) {
            setEditorState(createEditorStateWithTokens(messageText, tokens, decorator));
        }
    }, [visible, messageText, tokens, decorator]);


    const insertToken = (editorState, token) => {
        let contentState = editorState.getCurrentContent();
        let selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContentBlock = contentState.getBlockForKey(anchorKey);
        const startOffset = selectionState.getStartOffset();
        const currentEntityKey = currentContentBlock.getEntityAt(startOffset);

        if (currentEntityKey) {
            const entity = contentState.getEntity(currentEntityKey);
            if (entity.getType() === 'TOKEN') {
                return;
            }
        }

        // Insert space before entity
        let textBefore = "";
        contentState = Modifier.insertText(
            contentState,
            selectionState,
            textBefore,
            null, // без сущности
        );

        // Update selectionState for insert entity
        let newOffset = selectionState.getAnchorOffset() + textBefore.length;
        selectionState = selectionState.merge({
            anchorOffset: newOffset,
            focusOffset: newOffset,
        });

        // Create entity and insert
        const textWithEntity = `{{${token.id}}}`;
        contentState = contentState.createEntity(
            'TOKEN',
            'IMMUTABLE',
            {id: token.id, displayName: token.displayName}
        );
        const entityKey = contentState.getLastCreatedEntityKey();
        contentState = Modifier.insertText(
            contentState,
            selectionState,
            textWithEntity,
            null,
            entityKey
        );

        // Update selectionState for insert space after the entity
        newOffset += textWithEntity.length;
        selectionState = selectionState.merge({
            anchorOffset: newOffset,
            focusOffset: newOffset,
        });

        // Insert space
        let textAfter = "";
        contentState = Modifier.insertText(
            contentState,
            selectionState,
            textAfter,
            null, //
        );

        // Update editor state
        const newState = EditorState.push(editorState, contentState, 'insert-characters');
        setEditorState(newState);
    };


    const handleBeforeInput = (chars, editorState) => {
        const selection = editorState.getSelection();

        const contentState = editorState.getCurrentContent();
        const blockKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const block = contentState.getBlockForKey(blockKey);
        const entityKey = block.getEntityAt(startOffset);

        if (entityKey && findEntityRange(block, entityKey).start !== startOffset) {
            const entity = contentState.getEntity(entityKey);
            if (entity.getType() === 'TOKEN') {

                return 'handled';
            }
        }

        return 'not-handled';
    };


    const handleKeyCommand = (command, editorState, {setEditorState}) => {
        const selection = editorState.getSelection();


        const contentState = editorState.getCurrentContent();
        const blockKey = selection.getStartKey();
        const block = contentState.getBlockForKey(blockKey);
        let selectionStart = selection.getStartOffset();
        let selectionEnd = selection.getEndOffset();

        if (blockKey !== selection.getEndKey()) return 'handled';  // disable multiblock changes
        if (command === 'split-block'){
            if (selectionStart !== selectionEnd)
                return 'handled';  // do nothing
            if (block.getEntityAt(selectionStart))
                return 'handled';  // do nothing
            return 'not-handled';
        }
        if (command !== 'backspace' && command !== 'delete') return 'not-handled';  // propagate handling

        if (selectionStart === selectionEnd) {
            if (command === 'backspace') {
                const entityKey = selectionStart > 0 ? block.getEntityAt(selectionStart - 1) : null;
                if (!entityKey)
                    return 'not-handled';
                selectionStart = selectionEnd = findEntityRange(block, entityKey).start;
            }
            else if (!block.getEntityAt(selectionStart))
                return 'not-handled';
        }

        const startEntityKey = block.getEntityAt(selectionStart);
        if (startEntityKey) {
            const entityRange = findEntityRange(block, startEntityKey);
            selectionStart = entityRange.start;
        }
        const endEntityKey = block.getEntityAt(selectionEnd);
        if (endEntityKey) {
            const entityRange = findEntityRange(block, endEntityKey);
            selectionEnd = entityRange.end;
        }
        const removeSelection = selection.merge({ anchorOffset: selectionStart, focusOffset: selectionEnd, isBackward: false });
        const newContentState = Modifier.removeRange(contentState, removeSelection, 'forward');
        const newState = EditorState.push(editorState, newContentState, 'remove-range');
        setEditorState(EditorState.forceSelection(newState, newContentState.getSelectionAfter()));
        return 'handled';
    };


    function findEntityRange(block, entityKey) {
        let start = undefined;
        let end = undefined;

        block.findEntityRanges(
            (character) => character.getEntity() === entityKey,
            (startOffset, endOffset) => {
                if (start === undefined || end === undefined) {
                    start = startOffset;
                    end = endOffset;
                }
            }
        );

        if (start !== undefined && end !== undefined) {
            return {start, end};
        }

        return null;
    }


    const stripHtml = (html) => {
        let tmp = document.createElement("textarea");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const processTextBeforeSave = (text) => {
        let processedText = stripHtml(text)
        // Add a space before and after the curly braces of tokens if needed
        processedText = processedText
//            .replace(/{{/g, ' {{') // Adding a space before {{
//            .replace(/}}/g, '}} ') // Adding a space after }}
            .trim(); // Remove extra spaces from the beginning and end of the string

        // Remove duplicate spaces, except for cases inside curly braces
        return processedText.replace(/[ ]{2,}/g, ' ');
    };


    const saveMessage = () => {
        const contentState = editorState.getCurrentContent();
        const rawText = contentState.getPlainText();
        console.log(rawText)
        const processedText = processTextBeforeSave(rawText);
        onChange(processedText);
        console.log(processedText)
        onCloseModal();
    };

    return (
        <ModalComponent
            visible={visible}
            onCloseModal={onCloseModal}
        >
            <Box className={styles.wrapper}>
                <Box className={clsx('row fullWidth', styles.header)}>
                    <h4>
                        Notification message
                    </h4>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        onClick={onCloseModal}
                    >
                        <CloseIcon size={'small'}/>
                    </IconButton>
                </Box>
                <Box className={styles.messageWrapper}>
                    <Typography className={styles.messageTitle}>
                        Message
                    </Typography>
                    <Box className={styles.message}>
                        <Editor
                            editorState={editorState}
                            onChange={setEditorState}
                            handleBeforeInput={handleBeforeInput}
                            handleKeyCommand={(command, editorState) => handleKeyCommand(command, editorState, {setEditorState})}
                            onCut={(_, e) => e.preventDefault()}  // cutting is too complicated to handle for tokens
                        />
                    </Box>
                </Box>
                <Box className={clsx('row gap8', styles.tokensWrapper)}>
                    {tokens.map((token) => (
                        <Chip
                            key={token.id}
                            onClick={() => insertToken(editorState, token)}
                            label={token.displayName}
                            className={styles.tokenItem}
                        />
                    ))}
                </Box>
                <Box className={clsx('row fullWidth', styles.footerWrapper)}>
                    <Button variant="contained" onClick={saveMessage} className={styles.button}>Save message</Button>
                    <Button variant="outlined" color='secondary' onClick={onCloseModal} className={styles.button}>Cancel</Button>
                </Box>
            </Box>
        </ModalComponent>
    );
}

export default CustomMessagePopup;
