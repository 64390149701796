import maplibregl from "maplibre-gl";
import {create} from 'zustand'
import {devtools} from 'zustand/middleware'
import {CurrentConditions, Nexrad} from "../../features/textProducts";
import BaronStormTracks from "../../features/textProducts/ui/baronStormTracks/BaronStormTracks";
import {fetchMapProducts} from "../../pages/alerts/api/FetchAlerts";
import {prepApiAlert} from "../../pages/alerts/ui/AlertsPage/AlertsPage";
import ADDITIONAL_PRODUCTS from "../../pages/map/model/Tile/AdditionalProducts";
import {ProductsController} from "../../pages/map/model/Tile/ProductsController";
import {transformer} from "../../pages/map/model/utils/urltransformer";
import {productNamesByApiType} from "../../shared/libs/AlertMapping";
import requester from "../../shared/libs/requester/baron-signature";
import {Signature} from "../../shared/libs/requester3/baron-signature";
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker'
import {useLocationsStore} from "./LocationsStore";

maplibregl.workerClass = maplibreglWorker

const signature = new Signature()

export const useMapStore = create(devtools((set, get) => ({
    map: null,
    productsController: null,

    products: [],
    enabledProducts: [],
    locations: [],
    enabledLocations: [],

    singleSiteRadar: null,
    currentConditions: null,
    baronStormTracks: null,

    initialize(productsControllerOptions) {
        const map = new maplibregl.Map({
            container: 'map',
            style: 'https://cdnmaps.velocityweather.com/styles/planet-full/style.json',
            hash: true,
            transformRequest: transformer(requester),
            minZoom: 2,
            dragRotate: false,
            touchZoomRotate: false,
        })
        window.map = map // remove later

        map.addControl(new maplibregl.ScaleControl(), 'bottom-right')
        map.addControl(new maplibregl.ScaleControl({
            unit: 'imperial',
        }), 'bottom-right')
        map.addControl(new maplibregl.AttributionControl({
            customAttribution: ['Lightning data provided by ENTLN Lightning',
                '<a href="https://baronweather.com/" target="_blank">Baron Weather</a>'],
        }))
        map.addControl(new maplibregl.FullscreenControl())

        const productsController = new ProductsController({
            signature,
            map,
            ...productsControllerOptions,
        })
        const newState = {map, productsController}
        set(newState)

        return newState
    },

    setProducts(update) {
        set((state) => {
            const newProducts = typeof update === 'function'
                ? update(state.products)
                : update

            return {products: newProducts}
        });
    },

    async fetchProducts() {
        const products = await fetchMapProducts()
        ADDITIONAL_PRODUCTS.forEach((p) => products.push(p))

        set({products})

        return products
    },

    setEnabledProducts(update) {
        set((state) => {
            let newEnabledProducts = typeof update === 'function'
                ? update(state.enabledProducts)
                : update

            state.productsController.setProducts2(newEnabledProducts)

            return {enabledProducts: newEnabledProducts}
        });

    },

    toggleProduct(product, beforeToggle = (state) => state) {
        const {enabledProducts, productsController} = beforeToggle(get())
        let newEnabledProducts = []

        const isProductAlreadyEnabled = enabledProducts.some(
            (enabledProduct) => {
                return enabledProduct.id === product.id
            }
        )

        if (isProductAlreadyEnabled) {
            newEnabledProducts = enabledProducts.filter(enabledProduct => enabledProduct.id !== product.id)
            set({enabledProducts: newEnabledProducts})
            productsController.setProducts2(newEnabledProducts)

            return {toggled: false, enabledProducts: newEnabledProducts}
        }

        if (enabledProducts.length + 1 > 5) {
            return {toggled: false, enabledProducts}
        }

        newEnabledProducts = [...enabledProducts, product]
        set({enabledProducts: newEnabledProducts})
        productsController.setProducts2(newEnabledProducts)

        return {toggled: true, enabledProducts: newEnabledProducts}
    },

    toggleLocation(objectID) {
        const activeIds = get().enabledLocations
        const isChecked = activeIds.includes(objectID)
        const newActiveIds = isChecked
            ? activeIds.filter((id) => id !== objectID)
            : [...activeIds, objectID]

        set({
            enabledLocations: newActiveIds
        })
    },

    toggleLocationGroup(group) {
        const enabledLocations = get().enabledLocations

        if (group.some((el) => !enabledLocations.includes(el.id))) {
            group.forEach((obj) => {
                if (!enabledLocations.includes(obj.id)) {
                    enabledLocations.push(obj.id)
                }
            })
            set({
                enabledLocations: [...enabledLocations]
            })
        } else {
            set({
                enabledLocations: enabledLocations.filter((enabledLocation) => !group.some((el) => el.id === enabledLocation))
            })
        }
    },

    setEnabledLocations(update) {
        set((state) => {
            let newEnabledLocations = typeof update === 'function'
                ? update(state.enabledProducts)
                : update

            return {enabledLocations: newEnabledLocations}
        });
    },

    loadView(view) {
        if (!view?.enabledProducts) {
            return
        }

        const newState = {}

        if (view.enabledProducts) {
            const {products, productsController} = get()
            const enabledProducts = products.filter((product) =>
                product.available
                && view.enabledProducts.some(p => p.id === product.id)
            )

            newState.enabledProducts = enabledProducts
            productsController.setProducts2(enabledProducts)
        }

        if (view.enabledLocations) {
            const {locations} = useLocationsStore.getState()
            const enabledLocations = view.enabledLocations.filter((locationId) =>
                locations.some((location) => location.id === locationId)
            )

            newState.enabledLocations = enabledLocations
        }


        if (view.mapPosition) {
            window.location.hash = view.mapPosition

        }
        set(newState)
    },

    loadViewFromAlert(alert, aid) {
        if (!alert) {
            return
        }

        const alert_idx = (aid.indexOf('_') > 0) ? parseInt(aid.split('_')[1]) : 0
        alert = prepApiAlert(alert, true)[alert_idx]

        if (!alert) {
            return
        }

        const {products, productsController} = get()
        const enabledLocations = alert.location_id ? [alert.location_id] : []
        const dates = {
            startDate: (new Date(alert.start_date)).valueOf(),
            endDate: (new Date(alert.end_date)).valueOf(),
        }
        const productNames = mapProducts(alert.api_alert)
        const enabledProducts = productNames
            .map((name) => products.find((p) => (p.name === name)))
            .filter((prod) => !!prod)

        set({
            enabledLocations,
            enabledProducts,
        })
        productsController.setProducts2(enabledProducts)

        return dates
    },

    createSingleSiteRadar() {
        const state = get()

        if (state.singleSiteRadar) {
            return state.singleSiteRadar
        }

        const singleSiteRadar = new Nexrad({map: state.map})
        set({
            singleSiteRadar
        })

        return singleSiteRadar
    },

    createCurrentConditions() {
        const state = get()

        if (state.currentConditions) {
            return state.currentConditions
        }

        const currentConditions = new CurrentConditions({
            textProductsHandler: state.productsController.textProductsHandler,
            map: state.map,
            signature,
        })

        set({
            currentConditions
        })

        return currentConditions
    },

    createBaronStormTracks() {
        const state = get()

        if (state.baronStormTracks) {
            return state.baronStormTracks
        }

        const baronStormTracks = new BaronStormTracks({
            textProductsHandler: state.productsController.textProductController,
            map: state.map,
            signature,
        })

        set({
            baronStormTracks
        })

        return baronStormTracks
    },
})))

function mapProducts(data) {
    if (data && data.alert_type !== 'usereventsalert') {
        return productNamesByApiType[data.alert_type] || []
    } else {
        try {
            const rules = data.weather_definition.rules
            if (!rules) return []
            const productNames = new Set()
            rules.forEach((rule) => {
                if (rule.lines) {
                    rule.lines.forEach((l) => {
                        if (l.product_name !== 'Weekdays' && l.product_name !== 'Months' && l.product_name !== 'Time Range') {
                            productNames.add(l.product_name)
                        }
                    })
                }
            })
            return Array.from(productNames)
        } catch (err) {
            console.log(err)
            return []
        }
    }
}