import {
    createActionItem, createActionItemsCategory,
    deleteActionItem, deleteActionItemsCategory,
    editActionItem,
    fetchActionItems,
    fetchActionItemsCategories, fetchActionItemsCategoryIcons, putActionItemsCategory
} from "../../pages/alerts/api/FetchAlerts";
import {create} from "zustand";
import {devtools} from "zustand/middleware";

export const useActionItemsStore = create(devtools((set, get) => ({
        actionItems: [],
        selectedActionItems: [],
        categories: [],
        categoryIcons: [],
        openedActionItem: null,
        editingActionItem: null,
        isDuplicating: false,
        isLoading: false,

        fetchActionItems: async (actionItemsArray) => {
            set({
                selectedActionItems: [],
                openedActionItem: null,
                editingActionItem: null,
                isDuplicating: false
            });
            const response = await fetchActionItems(actionItemsArray);
            set((state) => ({
                actionItems: response
            }));
            return response
        },

        fetchCategories: async () => {
            const response = await fetchActionItemsCategories();
            set((state) => ({
                categories: response
            }));
        },

        fetchCategoryIcons: async () => {
            const response = await fetchActionItemsCategoryIcons();
            set((state) => ({
                categoryIcons: response
            }));
        },

        selectActionItem: (actionItem) => {
            set((state) => {
                const isSelected = state.selectedActionItems.some(item => item.id === actionItem.id);
                return {
                    selectedActionItems: isSelected
                        ? state.selectedActionItems.filter(item => item.id !== actionItem.id)
                        : [...state.selectedActionItems, actionItem]
                };
            });
        },

        clearSelectedActionItems: () => {
            set((state) => {
                return {
                    selectedActionItems: []
                }
            })
        },

        selectAllActionItems: () => {
            set((state) => {
                const allSelected = state.actionItems.length === state.selectedActionItems.length;
                return {
                    selectedActionItems: allSelected ? [] : [...state.selectedActionItems, ...state.actionItems.filter(item => !state.selectedActionItems.some(selected => selected.id === item.id))]
                };
            });
        },

        openActionItem: (actionItem) => {
            set((state) => ({
                openedActionItem: actionItem,
                selectedActionItems: []
            }))
        },

        toggleEditingActionItem: (actionItem) => {
            set((state) => ({
                editingActionItem: actionItem
            }))
        },

        toggleDuplication: (value) => {
            set((state) => ({
                isDuplicating: value
            }))
        },

        deleteActionItem: async (id) => {
            set({isLoading: true})
            try {
                const response = await deleteActionItem(id);
                if (response) {
                    set((state) => ({
                        actionItems: state.actionItems.filter(ai => ai.id !== id),
                        selectedActionItems: state.selectedActionItems.filter(ai => ai.id !== id)
                    }));
                } else {
                    console.error("Error with deleting action item");
                }
            } catch (error) {
                console.error("Error during deletion:", error);
            } finally {
                set({isLoading: false})
            }
        },

        createActionItem: async (actionItem) => {
            try {
                const response = await createActionItem(actionItem);
                if (response && response.id) {
                    await fetchActionItems().then((createdItem) => {
                        set({actionItems: createdItem, selectedActionItems: []});
                    });
                    return response.id;
                } else {
                    console.error("Error with creating action item");
                }
            } catch (error) {
                console.error("Error during creation:", error);
            }
        },

        editActionItem: async (actionItem) => {
            const {openedActionItem} = get();
            try {
                const response = await editActionItem(actionItem);
                if (response && response.id) {
                    await fetchActionItems().then((updatedItem) => {
                        set({actionItems: updatedItem, selectedActionItems: []});
                        if (openedActionItem) {
                            const currentOpenedActionItem = updatedItem.find(ai => ai.id === openedActionItem.id);
                            set({openedActionItem: currentOpenedActionItem});
                        }
                    });
                } else {
                    console.error("Error with editing action item");
                }
            } catch (error) {
                console.error("Error during editing:", error);
            }
        },

        createCategory: async (obj) => {
            const {fetchCategories} = get()
            try {
                const response = await createActionItemsCategory(obj);
                if (response && response.id) {
                    await fetchCategories()
                    return response
                } else {
                    console.error("Error with creating category");
                }
            } catch (error) {
                console.error("Error during editing:", error);
            }
        },

        editCategory: async (obj) => {
            const {fetchCategories} = get()
            try {
                const response = await putActionItemsCategory(obj);
                if (response && response.id) {
                    await fetchCategories()
                } else {
                    console.error("Error with editing category");
                }
            } catch (error) {
                console.error("Error during editing:", error);
            }
        },

        deleteCategory: async (id) => {
            try {
                await deleteActionItemsCategory(id);
                set((state) => ({
                    categories: state.categories.filter(category => category.id !== id)
                }));
            } catch (error) {
                console.error("Error during deletion:", error);
            }
        },
    })));