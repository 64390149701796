import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Button,
    Chip, IconButton, Radio,
} from '@mui/material'
import ArrowDownSmallIcon from 'shared/assets/icons/ArrowDownSmall'
import React from 'react'
import {useWeatherDefinitionsStore} from '../../../app/store/WeatherDefinitionsStore'
import {DefinitionRenderLarge} from '../definitionRender/DefinitionRenderLarge/DefinitionRenderLarge'
import CopyIcon from '../../../shared/assets/icons/Copy'
import LinkSquareIcon from '../../../shared/assets/icons/LinkSquare'
import theme from '../../../app/Theme'
import {CheckBox} from '../../../shared/ui/CheckBox'
import styles from './WeatherDefinitionLine.module.scss'
import {clsx} from 'clsx'

export default function WeatherDefinitionLine({definition, selectedDefinitions, isAlertEditingMode, onChange, setShowSidebar}) {
    const {
        openWeatherDefinition,
        toggleEditingWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const handleDuplicate = (event) => {
        setShowSidebar(false)
        toggleEditingWeatherDefinition({
            ...definition,
            id: null,
            name: definition.name + ' Copy',
        })
    }

    const handleOpenWeatherDefinition = () => {
        openWeatherDefinition(definition)
    }

    return (
        <Accordion
            data-cy={'edit-alert-wd-line'}
            disableGutters
            elevation={0}
            square
            className={styles.wrapper}
            sx={{'&:before': {display: 'none'}}}
        >
            <AccordionSummary
                expandIcon={
                    <IconButton
                        size='tiny'
                        variant='outlined'
                    >
                        <ArrowDownSmallIcon size={'small'}/>
                    </IconButton>}
                aria-controls="panel1a-content"
                className={styles.summaryWrapper}
            >
                <Box className={styles.summaryContent}>
                    {isAlertEditingMode
                        ? <Radio
                            data-cy={'edit-alert-wd-line-radio'}
                            style={{padding: '0'}}
                            checked={selectedDefinitions.some((item) => item.id === definition.id)}
                            onChange={() => {
                                onChange('select', definition)
                            }}
                            onClick={(ev) => {
                                ev.stopPropagation()
                            }}
                        />
                        : <CheckBox
                            data-cy={'edit-alert-wd-line-checkbox'}
                            checked={selectedDefinitions.some((item) => item.id === definition.id)}
                            onChange={() => {
                                onChange('select', definition)
                            }}
                            onClick={(ev) => {
                                ev.stopPropagation()
                            }}
                        />
                    }
                    <Typography sx={{display: 'flex', flexGrow: 1}}>
                        {definition.name}
                    </Typography>
                    <Box sx={{maxWidth: '100px', marginLeft: 'auto', lineHeight: '16px'}}>
                        <Chip
                            size={'small'}
                            label={definition.severity}
                            variant={theme.palette.weather.variant[definition.severity]}
                        />
                    </Box>
                </Box>
            </AccordionSummary>
            <AccordionDetails className={clsx('column fullWidth', styles.detailsWrapper)}>
                <DefinitionRenderLarge definition={definition}/>
                <Box className={'row gap8'}>
                    {definition.rules &&
                        <Button
                            data-cy={'edit-alert-wd-line-copy-button'}
                            startIcon={<CopyIcon size={'small'}/>}
                            size={'small'}
                            onClick={handleDuplicate}
                        >
                            Copy
                        </Button>}
                    <Button
                        data-cy={'edit-alert-wd-line-details-button'}
                        startIcon={<LinkSquareIcon size={'small'}/>}
                        size={'small'}
                        variant={'outlined'}
                        onClick={handleOpenWeatherDefinition}
                    >
                        View Details
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}
