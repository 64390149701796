import {Box} from '@mui/material'
import React from 'react'
import {availableToRenderDefinition} from '../availableToRenderDefinition'
import {Operand} from '../Operand/Operand'
import styles from "./DefinitionRenderLarge.module.scss"

export function DefinitionRenderLarge({definition}) {
    if (!definition.rules || !definition.groups) {
        return definition.description || 'This is a fixed weather definition'
    }

    if (!availableToRenderDefinition(definition)) {
        return <Box></Box>
    }

    return (
        <Box
            className={'column gap8'}
            style={{width: '100%'}}
        >
            {definition.groups.map((group, index) =>
                (<RenderGroupLarge
                    key={index}
                    group={{
                        ...group,
                        isFirst: !index,
                    }}
                />),
            )}
        </Box>
    )
}

function RenderGroupLarge({group}) {
    return (
        <div
            id={'groupCompact'}
            className={'row gap8'}
            style={{flexWrap: 'wrap'}}
        >
            {group.lines.map((line, index) =>
                (<RenderLineLarge
                    key={index}
                    line={{
                        ...line,
                        isFirst: !index,
                        isLast: index === group.lines.length - 1,
                    }}
                    group={group}
                />),
            )}
        </div>
    )
}

function RenderLineLarge({line, group}) {
    return (
        <Box
            id={'lineCompact'}
            className={'row gap8'}
        >
            {!group.isFirst && line.isFirst &&
                <label className={'medium'}>
                    {group.union}
                </label>
            }
            {!line.isFirst &&
                <label
                    className={'medium'}
                    style={{marginRight: 'auto'}}
                >
                    {line.union}
                </label>
            }
            {line.isFirst &&
                <div className={styles.bracket}>
                    (
                </div>
            }
            <Operand
                line={line}
                group={group}
            />
            {line.isLast &&
                <div className={styles.bracket}>
                    )
                </div>
            }
        </Box>
    )
}
