import React, {useRef, useState, useEffect} from 'react'
import 'pages/map/ui/timeline/timeline.css'
import icons from 'pages/map/model/svgcollector'
import {useStoreWithEqualityFn} from "zustand/traditional";
import {useMapStore} from "../../../../app/store/MapStore";

const dayjs = require('dayjs')
const formatTime = 'ddd MMM D, YYYY h:mm A'

/**
 * TimeLineMap component for managing and displaying a timeline for map-based animations.
 *
 * @param {boolean} isPlaying - Indicates if the timeline animation is currently playing.
 * @param {boolean} isLoading - Indicates if the WMS (Web Map Service) is currently loading.
 * @param {number} startDate - The start date of the timeline, represented as a timestamp in milliseconds.
 * @param {number} endDate - The end date of the timeline, represented as a timestamp in milliseconds.
 * @param {number} selectedTime - The currently selected time on the timeline, represented as a timestamp in milliseconds.
 * @param {function} onChangeTime - Callback function invoked when the timeline value changes.
 * @param {function} onPlayBtn - Callback function invoked when the play button is toggled.
 * @param {function} onChangePlaying - Callback function invoked to change the playing state of the timeline.
 */
export function TimeLineMap({
                                preloadTiles,
                                isPlaying,
                                isLoading,
                                startDate,
                                endDate,
                                onChangeTime,
                                onPlayBtn,
                                onChangePlaying,
                                selectedTime,
                                onNow
                            }) {
    const map = useMapStore(state => state.map)
    const enabledProducts = useStoreWithEqualityFn(
        useMapStore,
        state => state.enabledProducts,
        (prev, next) => {
            const isPrevEnabledProductsEmpty = prev.length === 0
            const isNextEnabledProductsEmpty = next.length === 0

            return !(isPrevEnabledProductsEmpty || isNextEnabledProductsEmpty)
        }
    )

    const speedValues = [1100, 1000, 900, 800, 700, 600, 500, 400, 300, 200, 100]
    const animationIndex = useRef()
    const stepAnimation = useRef(1)

    const [currentSpeed, setCurrentSpeed] = useState(5)
    const [currentValueTime, setCurrentValueTime] = useState(50)

    useEffect(() => {
        if (isPlaying && !isLoading) {
            onChangeTimeLineValue(currentValueTime)
            animationIndex.current = window.setTimeout(_animateStep, (currentValueTime === 99 ? 2000 : speedValues[currentSpeed]))
        } else {
            clearTimeout(animationIndex.current)
        }
    }, [isPlaying, isLoading])

    useEffect(() => {
        const currentPosition = (selectedTime - startDate) * 100 / (endDate - startDate)
        onTimeLineClick()
        setCurrentValueTime(currentPosition)
        onChangeTimeLineValue(currentPosition)
    }, [selectedTime, startDate, endDate])

    useEffect(() => {
        if (isPlaying && !isLoading) {
            onChangeTimeLineValue(currentValueTime)
            animationIndex.current = window.setTimeout(_animateStep, (currentValueTime === 99 ? 2000 : speedValues[currentSpeed]))
        }
    }, [currentValueTime, isPlaying, isLoading])

    function changeSpeed(step) {
        const newValue = currentSpeed + step
        if (newValue < 0) return
        if (newValue === speedValues.length) return
        setCurrentSpeed(newValue)
    }

    function handleSwitch() {
        if (currentValueTime > 97 && !isPlaying) {
            setCurrentValueTime(0)
        }
        onPlayBtn(isPlaying)
        onChangePlaying(!isPlaying)
    }

    function _onChangeTimeLineValyByVal(event) {
        const val = event.target.value
        let currentPosition = parseInt(val)
        if (isNaN(currentPosition)) {
            return
        }
        if (currentPosition > 99) {
            currentPosition -= 100
        }
        if (currentPosition < 0) {
            currentPosition += 100
        }
        onTimeLineClick()
        setCurrentValueTime(currentPosition)
        onChangeTimeLineValue(currentPosition)
    }

    function _animateStep() {
        let newVal = currentValueTime
        if (currentValueTime === 99) {
            newVal = 0
        } else {
            newVal += stepAnimation.current
            newVal = Math.min(99, newVal)
        }
        setCurrentValueTime(newVal)
    }

    const handleTimelineMouseDown = () => {
        preloadTiles()
        onTimeLineClick()
    }

    function onTimeLineClick() {
        onChangePlaying(false)
        clearTimeout(animationIndex.current)
    }

    function onChangeTimeLineValue(val) {
        if (isNaN(val) || !endDate || !startDate) return
        const timeInMs = startDate + (val / 100) * (endDate - startDate)
        setCurrentValueTime(val)
        onChangeTime(timeInMs, val)
    }

    function _makeFillStyleBackGround(percent) {
        const timelineColor = 'rgba(255, 255, 255, 0)'
        return 'linear-gradient(to right,' + timelineColor + (percent - 0.3) + '%, var(--palette-primary-dark) ' + (percent - 1) + '% ' + (percent + 1) + '%,' + timelineColor + (percent + 0.3) + '% 100%)'
    }

    function _makeFillFutureOrPast() {
        const currentTime = dayjs()
        const percentage = ((currentTime - startDate) / (endDate - startDate)) * 100
        return 'linear-gradient(to right, rgba(2, 28, 55, 0.055)' + percentage + '%, rgba(255, 255, 255, 0) ' + percentage + '%)'
    }

    function getDateValue() {
        const timeInMs = startDate + (currentValueTime / 100) * (endDate - startDate)
        return dayjs(timeInMs).format(formatTime)
    }

    if (!enabledProducts.length || !map) {
        return
    }

    return (
        <div
            style={{
                display: 'flex',
                width: '100%', height: '120px',
                position: 'absolute', bottom: '0px',
                zIndex: '3',
                alignItems: 'center',
                backgroundColor: 'rgba(240, 242, 245, 0.1)',
                boxShadow: 'rgba(0, 0, 0, 0.12) 0px -2px 12px 0px',
                backdropFilter: 'blur(2px)',
            }}
        >
            <div
                className={'timeline' + (isPlaying ? ' timelineplaying' : '')}
                style={{position: 'static', display: 'flex', gap: '8px', padding: '0 8px'}}
            >
                <div className="timelinespeed">
                    <div
                        className={currentSpeed >= speedValues.length - 1 ? 'disabled' : ''}
                        onClick={() => changeSpeed(1)}
                        aria-label="Increase Speed"
                        tabIndex="0"
                        role="button"
                        title="Increase Speed"
                    >
                        +
                    </div>
                    <div
                        className={currentSpeed <= 0 ? 'disabled' : ''}
                        onClick={() => changeSpeed(-1)}
                        aria-label="Decrease Speed"
                        tabIndex="0"
                        role="button"
                        title="Decrease Speed"
                    >
                        -
                    </div>
                </div>
                <div
                    className="timelineplaybtn"
                    onClick={handleSwitch}
                    dangerouslySetInnerHTML={{__html: icons[isPlaying ? 'pause' : 'play']}}
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                    role="button"
                    tabIndex="0"
                ></div>
                <div className="timelinewrapperline">
                    <input
                        disabled={isLoading}
                        type="range"
                        min="0"
                        max="99"
                        style={{background: _makeFillStyleBackGround(currentValueTime)}}
                        className="timelinecursor"
                        onMouseDown={handleTimelineMouseDown}
                        onChange={_onChangeTimeLineValyByVal}
                        value={currentValueTime}
                        aria-label="Time Slider"
                        role="slider"
                        tabIndex="0"
                    />
                    <div
                        className="futureorpast"
                        style={{background: _makeFillFutureOrPast()}}
                    />
                    <div className="timelinetime">
                        {isLoading ? 'Loading...' : getDateValue()}
                    </div>
                </div>
                <div
                    className="timelineplaybtn"
                    onClick={onNow}
                    aria-label="Current Time"
                    tabIndex="0"
                    role="button"
                    title="Current Time"
                >
                    NOW
                </div>
            </div>
        </div>
    )
}

