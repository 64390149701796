import React from 'react'
import {Divider, Typography} from '@mui/material'
import Urgency from '../Urgency'
import {DefinitionRenderLarge} from '../../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderLarge/DefinitionRenderLarge'
import styles from './ActionItemsDetailsParameters.module.scss'

const ActionItemDetailsParameters = ({actionItems, title = null}) => {
    const normalizedActionItems = Array.isArray(actionItems) ? actionItems : [actionItems]

    return (
        <div className={'actionItemsContentWrapper'}>
            {normalizedActionItems.map((ai, index) => (
                <>
                    <div className={styles.titleWrapper}>
                        <Typography className={styles.title}>
                            {title || ai.name}
                        </Typography>
                    </div>
                    <div
                        style={{display: 'flex', flexDirection: 'column', gap: '16px'}}
                        key={index}
                    >
                        <div
                            className={'actionItemsContentWrapper'}
                            style={{padding: '16px'}}
                        >
                            <div className={styles.mainContentWrapper}>
                                <div className={styles.categoryWrapper}>
                                    <Typography className={'actionItemsTitles'}>
                                        Category:
                                    </Typography>
                                    <div className={styles.nameOfCategoryWrapper}>
                                        <div>
                                            <span className={styles.nameOfCategory}>
                                                <svg
                                                    className={'customSvg'}
                                                >
                                                    <use
                                                        height="14"
                                                        width="14"
                                                        href={`${ai?.category?.icon?.icon}#svg2`}
                                                    />
                                                </svg>
                                                {ai?.category.name}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                    <Typography className={'actionItemsTitles'}>
                                        Urgency level:
                                    </Typography>
                                    <Urgency Urgency={ai?.urgency}/>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                    <Typography className={'actionItemsTitles'}>
                                        Weather definition:
                                    </Typography>
                                    <Typography className={styles.wdName}>
                                        {ai?.weather_definition.name}
                                    </Typography>
                                </div>
                            </div>
                            <Divider/>
                            <DefinitionRenderLarge definition={ai.weather_definition}/>
                        </div>
                        <div className={styles.descriptionWrapper}>
                            <Typography className={styles.titleOfWdDescription}>
                                Description of action item
                            </Typography>
                            <Typography className={styles.bodyOfWdDescription}>
                                {ai?.description}
                            </Typography>
                        </div>
                    </div>
                    {index < normalizedActionItems.length - 1 && <Divider />}
                </>
            ))}
        </div>
    )
}

export default ActionItemDetailsParameters
