import React, {useState, useEffect, useRef} from 'react'
import {Box, IconButton, Link, Breadcrumbs} from '@mui/material'
import {ManageLocationScreen} from 'widgets/location/ui/manageLocationScreen/ManageLocationScreen'
import NotificationsStepScreen
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/NotificationsStepScreen'
import WeatherDefinitionStepScreen
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/WeatherDefinitionStepScreen'
import {WeatherDefinitionDetails} from 'entities/weatherDefinition/ui/WeatherDefinitionDetails/WeatherDefinitionDetails'
import EditWeatherDefinitionPage from 'widgets/weatherDefinition/EditWeatherDefinitionPage/EditWeatherDefinitionPage'
import {
    fetchConfigurationGroup,
} from 'pages/alerts/api/FetchAlerts'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import {Spacer} from 'shared/ui/Spacer'
import 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/StepperScreens.css'
import AgreeModal from 'shared/ui/AgreeModal'
import ReportingStepScreen
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/stepperScreens/ReportingStepScreen'
import AlertConfirmationModal from '../../../../../shared/ui/AlertConfirmationModal/AlertConfirmationModal'
import {useUserStore} from '../../../../../app/store/UserStore'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import {useLocationsStore} from '../../../../../app/store/LocationsStore'
import EditActionItemPage from '../../../../../pages/settings/ui/actionItems/EditActionItemPage/EditActionItemPage'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'

export default function StepperScreens({
    currentStep,
    onChange,
    configGroup,
    showSidebar,
    setShowSidebar,
    isPressedSaveButton,
    fromTimeLine = false,
    fromSettings = false,
}) {
    const {
        toggleEditingAlertConfiguration,
        saveAlertConfiguration,
        fetchAllInfoAboutAlertConfigurations,
    } = useAlertConfigurationsStore((state) => state)

    const {
        fetchLocations,
    } = useLocationsStore((state) => state)
    const {
        weatherDefinitions,
        openedWeatherDefinition,
        editingWeatherDefinition,
        fetchWeatherDefinitions,
    } = useWeatherDefinitionsStore((state) => state)

    const {
        editingActionItem,
    } = useActionItemsStore((state) => state)

    const {fetchUser} = useUserStore((state) => state)

    const [selectedLocations, setSelectedLocations] = useState(new Map())
    const [disableSaveConfigs, setDisableSaveConfigs] = useState(false)
    const [isIncludeReport, setIsIncludeReport] = useState(false)
    const [chosenActionItems, setChosenActionItems] = useState([])
    const [defaultNotificationMessages, setDefaultNotificationMessages] = useState(false)
    const mapPositionRef = useRef({position: [-97, 38], zoom: 4})
    const [lastCreatedLocs, setLastCreatedLocs] = useState([])
    const [selectedDefinitions, setSelectedDefinitions] = useState(weatherDefinitions.filter((wd) => wd.id === configGroup.wd) || [])
    const [openModal, setOpenModal] = useState(false)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

    const [notificationData, setNotificationData] = useState({
        allow_self_notification_email: true,
        allow_self_notification_sms: true,
        contacts: {},
        report: false,
        report_settings: {action_items: []},
    })

    const [isCreatingLocationModeNow, setIsCreatingLocationModeNow] = useState(true)

    useEffect(() => {
        if (configGroup && configGroup.id) {
            fetchConfigurationGroup(configGroup.id).then((data) => {
                if (data && data.notification) {
                    setNotificationData(data.notification)
                    setIsIncludeReport(data.notification.report)
                    setChosenActionItems(data.notification?.report_settings?.action_items || [])
                    setNotificationData({...data.notification, report: data.notification.report})
                }
            })
        }

        fetchUser().then((user) => {
            setDefaultNotificationMessages(user.default_notification_messages)
        })
            .catch((error) => {
                console.error('Error receiving user data:', error)
            })
    }, [configGroup])

    const handleBackButtonClick = () => {
        setOpenModal(true)
    }
    const handleModalClose = (confirmed) => {
        setOpenModal(false)
        setIsSuccessModalOpen(false)
        if (confirmed) {
            if (fromTimeLine || fromSettings) {
                toggleEditingAlertConfiguration(null)
                fetchAllInfoAboutAlertConfigurations()
            } else {
                window.location.href = `${configGroup.wd ? '/settings' : '/alerts'}` // Сhecking where the alert was created
            }
        }
    }

    useEffect(() => {
        if (!configGroup || !configGroup.locations) {
            setSelectedLocations(new Map())
        } else {
            fetchLocations().then((data) => {
                const selectedLocationsId = Object.keys(configGroup.locations).filter((key) => configGroup.locations[key] === true)
                const locs = new Map()
                selectedLocationsId.forEach((id) => {
                    const loc = data.find((e) => e.id === ~~id)
                    if (loc) locs.set(loc.id, loc.coordinates)
                })
                setSelectedLocations(locs)
            })
        }
        if (configGroup) {
            fetchWeatherDefinitions().then((weatherDefinitions) => {
                const filteredWeatherDefinitions = weatherDefinitions.filter((wd) => wd.id === configGroup.definition || wd.id === configGroup.wd)
                setSelectedDefinitions(filteredWeatherDefinitions)
            })
        }
    }, [configGroup])

    const handleGoToSettings = (agreed) => {
        if (agreed) {
            if (configGroup.back) {
                toggleEditingAlertConfiguration(null)
                fetchAllInfoAboutAlertConfigurations()
                return
            }
            window.location.href = '/settings#alerts'
        }
        setIsSuccessModalOpen(false)
    }

    const onSaveDone = () => {
        setDisableSaveConfigs(false)
        setIsSuccessModalOpen(true)
    }

    const onSave = async () => {
        if (isPressedSaveButton) {
            const locs = Array.from(selectedLocations.keys())
            const definitions = selectedDefinitions.map((definition) => definition.id)
            setDisableSaveConfigs(true)
            await saveAlertConfiguration(locs, definitions, notificationData, defaultNotificationMessages, configGroup.id).then(() => {
                onSaveDone()
            },
            )
        }
    }

    useEffect(() => {
        onSave() // listener of press button in NewAlertStepper
    }, [isPressedSaveButton])

    const checkIsAlertConfigurationValid = () => {
        const isAnyLocationSelected = selectedLocations.size !== 0

        onChange({
            isAnyDefinitionSelected: selectedDefinitions.length > 0,
            isAnyLocationSelected: isAnyLocationSelected,
            isDisableCreationLocationMode: isCreatingLocationModeNow,
            isValid: selectedDefinitions.length > 0 && isAnyLocationSelected && isCreatingLocationModeNow,
        })
    }

    useEffect(checkIsAlertConfigurationValid, [selectedDefinitions, selectedLocations, isCreatingLocationModeNow, disableSaveConfigs, onChange])

    if (!selectedLocations) return (<></>)

    const onDefinitionChange = (action, value) => {
        if (action === 'select') {
            if (configGroup.id) {
                setSelectedDefinitions(selectedDefinitions.length >= 1 ? [value] : [...selectedDefinitions, value])
            } else {
                if (selectedDefinitions.some((item) => item.id === value.id)) {
                    const updatedDefinitions = selectedDefinitions.filter((item) => item.id !== value.id)
                    setSelectedDefinitions(updatedDefinitions)
                } else {
                    setSelectedDefinitions([...selectedDefinitions, value])
                }
            }
        }
    }

    const onActionItemChange = (id) => {
        setChosenActionItems((prevState) => {
            const updatedActionItems = prevState.includes(id)
                ? prevState.filter((item) => item !== id)
                : [...prevState, id]

            setNotificationData((prevData) => ({
                ...prevData,
                report_settings: {
                    ...prevData.report_settings,
                    action_items: updatedActionItems,
                },
            }))

            return updatedActionItems
        })
    }

    const handleCreatingLocationChange = (isCreatingLocation) => {
        setIsCreatingLocationModeNow(isCreatingLocation)
    }

    const locationCreated = (locations) => {
        setLastCreatedLocs(locations)
    }

    return (
        <Box
            data-cy={'edit-alert-panel'}
            className={'NWAPanel'}
            sx={{
                overflow: 'hidden',
                flex: '1 1 auto',
                background: 'white',
                padding: showSidebar && !editingActionItem ? '24px' : '0',
            }}
        >
            {!openedWeatherDefinition && !editingWeatherDefinition && !editingActionItem &&
                <Box
                    className={'column gap16 fullWidth'}
                    sx={{
                        alignItems: 'stretch',
                        height: showSidebar ? '100%' : '105%',
                        marginTop: showSidebar ? '0' : '-19px',
                    }}
                >
                    {showSidebar && (<Box
                        className={'toolbar'}
                        style={{padding: '2px 0 0 2px'}}
                                     >
                        {!configGroup.back &&
                            <>
                                <IconButton
                                    data-cy={'edit-alert-back-button'}
                                    variant={'outlined'}
                                    onClick={handleBackButtonClick}
                                >
                                    <ArrowLeftSmallIcon size={'small'}/>
                                </IconButton>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="none"
                                        href={'/alerts'}
                                    >
                                        <h3>
                                            {configGroup.id ? 'Edit alert configuration' : 'New alert configuration'}
                                        </h3>
                                    </Link>
                                    <h3 style={{color: 'var(--palette-primary-dark)'}}>
                                        {currentStep}
                                    </h3>
                                </Breadcrumbs>
                            </>
                        }
                        {configGroup.back && <>
                            <IconButton
                                data-cy={'edit-alert-back-button'}
                                onClick={handleBackButtonClick}
                                variant={'outlined'}
                            >
                                <ArrowLeftSmallIcon size={'small'}/>
                            </IconButton>
                            <Breadcrumbs aria-label="breadcrumb">
                                <h3
                                    style={{cursor: 'pointer'}}
                                    onClick={handleBackButtonClick}
                                >
                                    {configGroup.id ? 'Edit alert configuration' : 'New alert configuration'}
                                </h3>
                                <h3 style={{color: 'var(--palette-primary-dark)'}}>
                                    {currentStep}
                                </h3>
                            </Breadcrumbs>
                        </>
                        }
                        <Spacer/>
                    </Box>)}
                    {
                        (currentStep === 'Locations') && (
                            <ManageLocationScreen
                                onChange={setSelectedLocations}
                                showLocations={true}
                                selectLocations={true}
                                selectedLocationsResult={selectedLocations}
                                mapPositionRef={mapPositionRef}
                                onCreatingLocation={handleCreatingLocationChange}
                                stepOfAlert={true}
                                lastCreatedLocs={lastCreatedLocs}
                                onLocationCreated={locationCreated}
                            />
                        )
                    }
                    {
                        (currentStep === 'Weather definition') && (
                            <WeatherDefinitionStepScreen
                                onChange={onDefinitionChange}
                                selectedDefinitions={selectedDefinitions}
                                isEditingMode={!!configGroup.id}
                                setShowSidebar={setShowSidebar}
                            />
                        )
                    }
                    {
                        (currentStep === 'Reporting') && (
                            <ReportingStepScreen
                                isIncludeReport={isIncludeReport}
                                chosenActionItems={chosenActionItems}
                                onChangeActionItem={onActionItemChange}
                                includeReportOnChange={() => {
                                    setNotificationData({...notificationData, report: !isIncludeReport})
                                    setIsIncludeReport(!isIncludeReport)
                                }}
                            />
                        )
                    }
                    {
                        (currentStep === 'Notifications') && (
                            <NotificationsStepScreen
                                notificationData={notificationData}
                                onNotificationDataChange={setNotificationData}
                                configGroupId={configGroup.id}
                                showSidebar={showSidebar}
                                setShowSidebar={setShowSidebar}
                                defaultMessages={defaultNotificationMessages}
                                setDefaultNotificationMessages={setDefaultNotificationMessages}
                            />
                        )
                    }
                </Box>}

            {openedWeatherDefinition && !editingWeatherDefinition && !editingActionItem &&
                <WeatherDefinitionDetails
                    onDelete={() => setSelectedDefinitions([])}
                    definition_id={openedWeatherDefinition.id}
                    editable={true}
                    disableAddAlertButton={true}
                    setShowSidebar={setShowSidebar}
                />
            }

            {editingWeatherDefinition && !editingActionItem &&
                <EditWeatherDefinitionPage
                    onSave={(definition) => {
                        setSelectedDefinitions((prevState) => {
                            if (configGroup.id) {
                                return [definition]
                            }
                            const selectedDefinitions = prevState.filter((wd) => wd.id !== definition.id)
                            selectedDefinitions.push(definition)

                            return selectedDefinitions
                        })
                    }}
                    setShowSidebar={setShowSidebar}
                />
            }

            {editingActionItem &&
                <EditActionItemPage onChangeActionItem={onActionItemChange}/>
            }

            {openModal &&
                <AgreeModal
                    data={{
                        agreeFunc: handleModalClose,
                        title: 'Discard weather alert',
                        message: 'Are you sure you want discard your changes?',
                        agreeMsg: 'Discard',
                        disAgreeMsg: 'Go back',
                    }}
                />
            }
            {isSuccessModalOpen && (
                <AlertConfirmationModal
                    agreeFunc={handleModalClose}
                    goToSettingsFunc={handleGoToSettings}
                    selectedDefinitions={selectedDefinitions}
                    selectedLocations={selectedLocations}
                />
            )}
        </Box>
    )
}

