import {IconButton} from '@mui/material'
import maplibregl from 'maplibre-gl'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useMapStore} from "../../../../app/store/MapStore";
import {formatPqResponse} from '../../../../pages/map/model/utils/pqdataformatter'
import PointIcon from '../../../../shared/assets/icons/Point'

const PointQueryTool = ({clear, activeTool, setActiveTool}) => {
    const map = useMapStore((state)=>state.map)
    const productsController = useMapStore((state)=>state.productsController)

    const enabledProducts = useMapStore((state)=>state.enabledProducts)

    const [isActive, setIsActive] = useState(false)
    const isActiveRef = useRef(false)
    const pointQueriesPopups = useRef({})
    const objectContentPointQuery = useRef({})
    const [lastClickedLocations, setLastClickedLocations] = useState([])

    useEffect(() => {
        clearInfo()
    }, [clear])

    useEffect(() => {
        if (activeTool !== 'PointQuery') {
            setIsActive(false)
        }
    }, [activeTool])

    useEffect(() => {
        if (isActive) {
            setActiveTool()
            window?.map?.on?.('click', handleMapClick)
        } else {
            window?.map?.off?.('click', handleMapClick)
        }
    }, [isActive])

    const updatePopups = () => {
        lastClickedLocations.forEach(({coords, popupId}) => {
            prepareForRenderPopup(coords, popupId)
        })
    }

    useEffect(() => {
        map?.on?.('selectedTime', updatePopups)
        map?.on?.('layerLoaded', updatePopups)
        return () => {
            map?.off?.('selectedTime', updatePopups)
            map?.off?.('layerLoaded', updatePopups)
        }
    })

    useEffect(() => {
        updatePopups()
    }, [isActive, lastClickedLocations])

    const handleClickOnButton = () => {
        setIsActive((prevState) => {
            const newIsActive = !prevState
            isActiveRef.current = newIsActive

            return newIsActive
        })
    }

    const handleMapClick = useCallback((event) => {
        if (!isActiveRef.current) return

        const popupId = Math.random().toString(36).substring(2, 15)
        pointQueriesPopups.current[popupId] = new maplibregl.Popup({
            closeOnClick: false,
            closeButton: false,
        })

        setLastClickedLocations((prevLocations) => [
            ...prevLocations,
            {coords: event.lngLat, popupId},
        ])
    }, [])

    const prepareForRenderPopup = (coords, popupId) => {
        const popup = pointQueriesPopups.current[popupId]
        if (!popup) return // CGT-5093
        objectContentPointQuery.current = '<div>Loading...</div>'

        productsController.getPQ(coords).then((values) => {
            objectContentPointQuery.current = values
            _functionRenderPopup(objectContentPointQuery.current, popupId)
        })

        popup.setLngLat(coords)
        popup.addTo(window.map)
    }

    const _functionRenderPopup = (payloadObj, popupId) => {
        const popup = pointQueriesPopups.current[popupId]

        if (typeof payloadObj === 'string') {
            popup.setHTML(`<div class="custom-popup">${payloadObj}</div>`)
            return
        }

        payloadObj = handleEmptyData(payloadObj)

        const popupContent = createPopupContent(payloadObj)
        const closeButton = createCloseButton(popup, popupId)

        const popupContainer = document.createElement('div')
        popupContainer.appendChild(popupContent)
        popupContainer.appendChild(closeButton)

        if (popup) {
            popup.setDOMContent(popupContainer)
                .getElement()
                .setAttribute('data-cy', 'point-query-tool-popup')
        }
    }

    const handleEmptyData = (payloadObj) => {
        if (Object.values(payloadObj).length === 0) {
            return {Warning: {text: 'No data'}}
        }

        if (enabledProducts.length === 0) {
            return {Warning: {text: 'No layers selected'}}
        }

        return payloadObj
    }

    const createPopupContent = (payloadObj) => {
        const popupContent = document.createElement('div')
        let stringValueHTML = ''

        for (const key in payloadObj) {
            const matchedProduct = enabledProducts.find((product) => key.includes(product.api_product_code))
            let titleHTML = ''
            let textHTML = ''
            let buttonHTML = ''

            if (matchedProduct) {
                titleHTML = `<span class="custom-popup-title">${matchedProduct.name}:</span>`
            }

            textHTML = `<span class="custom-popup-text">${formatPqResponse(payloadObj[key])}</span>`

            if (key === 'alert-all-poly/Standard-Mercator' && enabledProducts.includes(56) && payloadObj[key]) {
                buttonHTML = '<button class="extra-button">View Full Text</button>'
            }

            stringValueHTML += `<div class="custom-popup-text-wrapper">
                            ${titleHTML}
                            ${textHTML}
                            ${buttonHTML}
                        </div>`
        }

        popupContent.innerHTML = stringValueHTML

        const seeFullTextBtn = popupContent.querySelector('.extra-button')
        if (seeFullTextBtn) {
            seeFullTextBtn.addEventListener('click', () => {
                const seeFullTextPopupHtml = createExtraTextModal(payloadObj['alert-all-poly/Standard-Mercator'])
                showExtraTextModal(seeFullTextPopupHtml)
            })
        }

        return popupContent
    }

    const createCloseButton = (popup, popupId) => {
        const button = document.createElement('div')
        button.classList.add('custom-popup-button')
        button.setAttribute('data-cy', 'close-button')
        button.textContent = '×'

        button.addEventListener('click', () => {
            deletePopup(popup, popupId)
        })

        return button
    }

    const createExtraTextModal = (data) => {
        const formattedText = data.text.replace(/\n/g, '<br>')

        return `
        <div class="modal">
            <div class="modal_inner">
                <div class="modal_header">Full text of ${data.type}</div>
                <div class="modal_text">${formattedText}</div>
            </div>
        </div>
    `
    }

    const showExtraTextModal = (html) => {
        const modal = document.createElement('div')
        modal.classList.add('modal_wrapper')
        modal.innerHTML = html

        document.body.appendChild(modal)

        const closeModal = (event) => {
            if (event.target === modal) {
                modal.remove()
                modal.removeEventListener('click', closeModal)
            }
        }

        modal.addEventListener('click', closeModal)
    }

    const deletePopup = (popup, id) => {
        delete pointQueriesPopups.current[id]
        setLastClickedLocations((prevLocations) =>
            prevLocations.filter((location) => location.popupId !== id),
        )
        popup.remove()
    }

    const clearInfo = () => {
        Object.values(pointQueriesPopups.current).forEach((popup) => {
            popup.remove()
        })
        pointQueriesPopups.current = {}
        setLastClickedLocations([])
    }

    return (
        <IconButton
            data-cy={'point-query-tool'}
            onClick={handleClickOnButton}
            variant={'outlined'}
            size={'small'}
            className={`productpqswitcher ${isActive ? 'active' : ''}`}
        >
            <PointIcon size={'small'}/>
        </IconButton>
    )
}

export default PointQueryTool
