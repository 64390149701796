import {productsDict} from '../../../../entities/weatherDefinition/api/Products'
import {Chip} from '@mui/material'
import React from 'react'
import styles from './Operand.module.scss'
import {clsx} from 'clsx'

export function Operand({line, group}) {
    const units = line.units || ''
    return (
        <span
            className={clsx('row gap4', styles.wrapper)}
            style={{
                ...(line.isFirst && group.isFirst && {
                    flexGrow: 1,
                }),
            }}
        >
            {productsDict && productsDict[line.product_name] && productsDict[line.product_name].icon &&
                    <svg className={clsx('customSvg', styles.iconWrapper)}>
                        <use
                            height="16"
                            width="16"
                            href={`${productsDict[line.product_name].icon}#svg2`}
                        />
                    </svg>
            }
            {line.product_name}
            <Chip
                size={'small'}
                label={
                    <div className={'paragraph'}>
                        {line.values.length === 2
                            ? `${line.values[0]} to ${line.values[1]} ${units}`
                            : `${line.relation} ${line.values[0]} ${units}`
                        }
                    </div>
                }
                sx={{marginLeft: 'auto'}}
            />
        </span>
    )
}
