import {Box} from '@mui/system'
import AlertSettingsLine from '../AlertSettingsLine/AlertSettingsLine'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'
import ProgressModal from '../../../../../shared/ui/ProgressModal'
import React from 'react'
import styles from './AlertsSettingsList.module.scss'
import {clsx} from "clsx";

const AlertsSettingsList = ({
                                alertGroups,
                                extendedAlertConfigs,
                                onChangeFilter = () => {
                                },
                                selectedConfigsTemp = {configs: {}},
                                searchString,
                                severity,
                                selectedLocations,
                                lineSx = {},
                                isNewAlert = false,
                                isFilter = false,
                            }) => {
    const {
        selectedAlertConfigurations,
        isLoading,
    } = useAlertConfigurationsStore((state) => state)

    const filterByLocations = (alert) => {
        for (const idx in alert.locations) {
            if (selectedLocations[alert.locations[idx].id] !== false) return true
        }
        return false
    }

    return (
        <>
            <Box
                className={clsx(styles.AlertsSettingsList, {
                    [styles.removePadding]: isFilter
                })}
            >
                {Object.keys(alertGroups).map((key) => {
                    const alert = alertGroups[key]
                    const isEnableExtendedAlert = extendedAlertConfigs.find((exAlert) => exAlert.group_id === alert.groupId)?.enabled
                    if ((!searchString || alert.type.toLocaleLowerCase().indexOf(searchString) >= 0) &&
                        (alert.severity === severity || severity === 'All') &&
                        filterByLocations(alert) !== false) {
                        return (
                            <AlertSettingsLine
                                alert={alert}
                                isEnable={isEnableExtendedAlert}
                                onChangeFilter={onChangeFilter}
                                key={alert.groupId}
                                isNewAlert={isNewAlert}
                                isFilter={isFilter}
                                selected={selectedAlertConfigurations.includes(alert) || !!selectedConfigsTemp.configs[alert.groupId]?.selected}
                                sx={lineSx}
                            />
                        )
                    }
                    return undefined
                })}
            </Box>
            <ProgressModal visible={isLoading}/>
        </>
    )
}

export default AlertsSettingsList
