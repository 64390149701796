import ActionItemsSettingsLine from "../ActionItemsSettingsLine/ActionItemsSettingsLine";
import {useActionItemsStore} from "../../../../../app/store/ActionItemsStore";
import styles from "./ActionItemsSettingsList.module.scss";


const ActionItemsSettingsList = ({searchString, urgency, chosenCategory}) => {

    const {actionItems, selectedActionItems} = useActionItemsStore((state) => state)

    return (<div className={styles.list}>
        {actionItems.map(actionItem => {
            {
                if ((!searchString || actionItem.name.toLocaleLowerCase().indexOf(searchString) >= 0) &&
                    (actionItem.urgency === urgency || urgency === 'All') &&
                    (actionItem.category.name === chosenCategory || chosenCategory === 'All')) {
                    return (<ActionItemsSettingsLine actionItem={actionItem} key={actionItem.id} selected={selectedActionItems.includes(actionItem)}/>);
                }
            }
            return null;
        })}
    </div>)
};

export default ActionItemsSettingsList;