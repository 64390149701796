import React, {useState} from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Chip,
    Dialog,
    Divider,
    IconButton,
} from '@mui/material'
import CloseIcon from '../../assets/icons/Close'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {ReactComponent as LocationsRectangle1} from 'shared/assets/svg/confirmationPopUp/LocationsRectangle.svg'
import {ReactComponent as ConfirmationLocationsMap} from 'shared/assets/svg/confirmationPopUp/ConfirmationLocationsMap.svg'
import {ReactComponent as LocationsPin} from 'shared/assets/svg/confirmationPopUp/LocationsPin.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import MapPinIcon from '../../assets/icons/MapPin'
import MapMarkerIcon from '../../assets/icons/MapMarker'
import styles from "./LocationsConfirmationModal.module.scss"
import {clsx} from "clsx";

export default function LocationsConfirmationModal({
    locations,
    agreeFunc,
}) {
    const [isSingleLocation] = useState(locations.length === 1)

    const content = (
        <Box className={styles.wrapper}>
            <Box className={styles.header}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    {`Well done, your ${isSingleLocation ? 'location is' : 'locations are'} created!`}
                </Box>
                <IconButton
                    variant={'outlined'}
                    onClick={agreeFunc}
                    size={'small'}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </Box>
            <Box className={styles.imagesWrapper}>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: 'absolute', left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: 'absolute', left: '-27px', top: '35px'}}>
                    <LocationsRectangle1/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '9px', top: '0px'}}>
                    <ConfirmationLocationsMap/>
                </Box>
                <Box sx={{position: 'absolute', right: '102px', top: '4px'}}>
                    <LocationsPin/>
                </Box>
            </Box>
            {isSingleLocation ? (
                <Box className={styles.contentWrapper}>
                    <div className={clsx(styles.bigTitle, styles.titleWrapper)}>
                        <MapPinIcon size={'small'}/>
                        {locations[0].label}
                    </div>
                    <div className={styles.bigTitle}>
                        {locations[0].location_group}
                    </div>
                </Box>
            ) : (<Box className={'fullWidth'}>
                <Accordion
                    className={'fullWidth'}
                    variant={'light'}
                >
                    <AccordionSummary
                        variant={'light'}
                    >
                        <Box className={'row gap4'}>
                            <div className={styles.listWrapper}>
                                {isSingleLocation ? <MapPinIcon size={'small'}/> : <MapMarkerIcon size={'small'}/>}
                                Locations (
                                {locations.length}
                                )
                            </div>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails
                        className={'gap8 locationGroup'}
                        variant={'light'}
                    >
                        <Box className={styles.list}>
                            {locations?.map((location) => (
                                <Chip
                                    icon={<MapPinIcon size={'small'}/>}
                                    label={location.label}
                                    size={'medium'}
                                    key={location.id}
                                />
                            ))}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>)}
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                You can view location data in the list of Saved Locations. To manage data for a location, go to its settings page.
            </Box>
            <Box className={styles.buttonWrapper}>
                <Divider/>
                <Button
                    className={styles.button}
                    data-cy={'wd-success-creation-okay-button'}
                    onClick={agreeFunc}
                >
                    Got it
                </Button>
            </Box>
        </Box>
    )
    return (
        <Dialog
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
