import React, {useEffect, useState} from 'react'
import {Box, Button, Typography} from '@mui/material'
import {Spacer} from '../../../../../shared/ui/Spacer'
import {SearchInput} from '../../../../../shared/ui/SearchInput'
import {isEmpty} from '../../../../../shared/libs/Utils'
import EmptyStateActionItems from '../../../../../shared/ui/emptyStates/EmptyStateActionItems'
import ActionItemsSettingsList from '../ActionItemsSettingsList/ActionItemsSettingsList'
import UrgencySelector from '../UrgencySelector/UrgencySelector'
import CategorySelector from '../CategorySelector/CategorySelector'
import ActionItemDetails from '../ActionItemDetails/ActionItemDetails'
import EditActionItemPage from '../EditActionItemPage/EditActionItemPage'
import '../ActionItems.css'
import ItemsSelectTopBar from '../../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import ChangeUrgencyModal from '../ChangeUrgencyModal/ChangeUrgencyModal'
import AgreeModal from '../../../../../shared/ui/AgreeModal'
import ProgressModal from '../../../../../shared/ui/ProgressModal'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import styles from './ActionItemsSettings.module.scss'
import {clsx} from 'clsx'

const ActionItemsSettings = () => {
    const {
        actionItems,
        selectedActionItems,
        isLoading,
        openedActionItem,
        editingActionItem,
        fetchActionItems,
        fetchCategories,
        deleteActionItem,
        toggleEditingActionItem,
    } = useActionItemsStore((state) => state)

    const [searchString, setSearchString] = useState('')
    const [urgency, setUrgency] = useState('All')
    const [chosenCategory, setChosenCategory] = useState('All')
    const [openChangeUrgency, setOpenChangeUrgency] = useState(false)
    const [deletingMultipleActionItems, setDeletingMultipleActionItems] = useState(null)

    const allActionItemsSelected = actionItems.length === selectedActionItems.length
    const someActionItemsSelected = actionItems.some((val) => val) && !allActionItemsSelected

    useEffect(() => {
        fetchActionItems()
        fetchCategories()
    }, [])

    const deleteMultipleActionItemsFunc = async (agree) => {
        if (agree) {
            await Promise.all(selectedActionItems.map((ai) => deleteActionItem(ai.id)))
        }
        setDeletingMultipleActionItems(null)
    }

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim().toLocaleLowerCase())
    }

    const handleUrgencyFilterChange = (event) => {
        setUrgency(event.target.value)
    }

    const handleCategoryFilterChange = (event) => {
        setChosenCategory(event.target.value)
    }

    const handleNewActionItem = () => {
        toggleEditingActionItem({})
    }

    const handleActionsWithTopBar = (actionItem, action) => {
        if (action === 'delete') { // mass deleting
            setDeletingMultipleActionItems(selectedActionItems)
        } else if (action === 'mass_change_urgency') {
            setOpenChangeUrgency(true)
        }
    }

    return (
        <>
            <div
                className="settings"
                style={{display: (openedActionItem || editingActionItem) ? 'none' : 'flex'}}
            >
                <div className={clsx('settings-toolbar', styles.header)}>
                    <h3>
                        Action items
                    </h3>

                    <div className={styles.toolsWrapper}>
                        <SearchInput
                            style={{width: '304px'}}
                            data-cy={'search-ai'}
                            placeholder='Search'
                            value={searchString}
                            onChange={searchStringChanged}
                        />
                        <UrgencySelector
                            urgency={urgency}
                            onChange={handleUrgencyFilterChange}
                        />
                        <CategorySelector
                            category={chosenCategory}
                            onChange={handleCategoryFilterChange}
                        />
                    </div>

                    <Spacer/>

                    <Button
                        onClick={handleNewActionItem}
                        data-cy={'new-action-item-button'}
                    >
                        New action item
                    </Button>
                </div>

                <ItemsSelectTopBar
                    type={'actionitems'}
                    onAction={handleActionsWithTopBar}
                    visible={selectedActionItems.length > 0}
                    allSelected={allActionItemsSelected}
                    indeterminate={someActionItemsSelected}
                />

                {isEmpty(actionItems)
                    ? <div style={{width: '100%', height: '100%'}}>
                        <EmptyStateActionItems
                            title={'Action items will appear here'}
                            text={'You will see action items for reports here once they are created'}
                        />
                    </div>
                    : <ActionItemsSettingsList
                        searchString={searchString}
                        urgency={urgency}
                        chosenCategory={chosenCategory}
                      />
                }
            </div>

            {openedActionItem && !editingActionItem &&
                <ActionItemDetails actionItem={openedActionItem}/>
            }

            {editingActionItem &&
                <EditActionItemPage/>
            }

            <ChangeUrgencyModal
                visible={openChangeUrgency}
                onChange={() => setOpenChangeUrgency(false)}
            />

            {deletingMultipleActionItems &&
                <AgreeModal
                    data={{
                        message: <Box className={clsx('column', styles.massDeleteModal)}>
                            <Typography sx={{fontSize: '18px'}}>
Are you sure you want
                                delete
                                <b>
                                    {' '}
                                    {`${deletingMultipleActionItems.length}`}
                                </b>
                                {' '}
selected action
                                items?
                            </Typography>
                            <Box className={styles.deleteModalList}>
                                {deletingMultipleActionItems.map((actionItem) =>
                                    (<span>
•
                                        {' '}
                                        {actionItem.name}
                                        <br/>
                                    </span>))}
                            </Box>
                        </Box>,
                        title: 'Delete multiple action items',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: deleteMultipleActionItemsFunc,
                    }}
                />
            }

            <ProgressModal visible={isLoading}/>
        </>
    )
}

export default ActionItemsSettings
