import MoreIcon from 'shared/assets/icons/More'
import React, {useState} from 'react'
import theme from 'app/Theme'
import {CheckBox} from 'shared/ui/CheckBox'
import {Box, IconButton, Menu, MenuItem, Typography, Chip, Popover} from '@mui/material'
import MapPinIcon from '../../../../../shared/assets/icons/MapPin'
import TrashIcon from '../../../../../shared/assets/icons/Trash'
import CopyIcon from '../../../../../shared/assets/icons/Copy'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import MapMarkerIcon from '../../../../../shared/assets/icons/MapMarker'
import CircleIcon from '../../../../../shared/assets/icons/Circle'
import PausedChip from '../../../../../widgets/alertConfig/ui/PausedChip/PausedChip'
import PauseIcon from '../../../../../shared/assets/icons/PauseIcon'
import RestartIcon from '../../../../../shared/assets/icons/RestartIcon'
import AgreeModal from '../../../../../shared/ui/AgreeModal'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'
import styles from './AlertSettingsLine.module.scss'
import {clsx} from 'clsx'

const AlertSettingsLine = ({alert, onChangeFilter=()=>{}, selected, isEnable, isNewAlert = false, isFilter= false}) => {
    const {
        selectAlertConfiguration,
        restartOrPauseAlertConfig,
        openAlertConfig,
        deleteAlertIdFromSessionStorage,
        deleteAlertConfigurationByID,
        toggleEditingAlertConfiguration,
    } = useAlertConfigurationsStore((state) => state)

    const [pauseAlertConfig, setPauseAlertConfig] = useState(null)
    const [restartAlertConfig, setRestartAlertConfig] = useState(null)
    const [deleteAlertConfig, setDeleteAlertConfig] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorElLocs, setAnchorElLocs] = React.useState(null)
    const open = Boolean(anchorEl)

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }
    const openLocations = (event) => {
        event.stopPropagation()
        setAnchorElLocs(event.currentTarget)
    }
    const handleClose = (event) => {
        event?.stopPropagation()
        setAnchorEl(null)
        setAnchorElLocs(null)
    }

    const handleEdit = () =>{
        handleClose()
        const locations = {}
        alert.locations.forEach((loc) => {
            locations[loc.id] = true
        })
        toggleEditingAlertConfiguration({
            back: true,
            locations: locations,
            definition: alert.definition_id,
            id: alert.groupId,
            report: alert.report,
        })
    }

    const handleCopy = () =>{
        handleClose()
        const locations = {}
        alert.locations.forEach((loc) => {
            locations[loc.id] = true
        })
        toggleEditingAlertConfiguration({
            back: true,
            locations: locations,
            definition: alert.definition_id,
            report: alert.report,
        })
    }

    const handleSelect = (event) => {
        event.preventDefault()
        event.stopPropagation()
        isFilter ? onChangeFilter('select', alert, !selected) : selectAlertConfiguration(alert)
    }

    const handlePauseOrRestartAlertConfig = (agreed, id, isRestart) => {
        if (agreed) {
            restartOrPauseAlertConfig(id, isRestart)
        }
        setAnchorEl(null)
        setAnchorElLocs(null)
        setPauseAlertConfig(null)
        setRestartAlertConfig(null)
    }

    const approveDeleteAlertConfiguration = (agree) => {
        if (agree) {
            if (isFilter) {
                onChangeFilter('delete', alert)
            }
            deleteAlertConfigurationByID(alert.groupId)
        }
        setDeleteAlertConfig(null)
    }

    return (
        <>
            <Box
                data-cy={'alert-configuration-line'}
                onClick={() => {
                    openAlertConfig(alert); deleteAlertIdFromSessionStorage(alert.groupId)
                }}
                className={clsx(styles.alertsSettingsLine, {
                    [styles.alertsSettingsLineNew]: isNewAlert,
                })}
            >
                <CheckBox
                    checked={selected || false}
                    onClick={handleSelect}
                />
                {isNewAlert && <CircleIcon
                    size={'tiny'}
                    className={styles.circleIcon}
                />}
                <Box className={styles.titleWrapper}>
                    <Typography className={styles.title}>
                        {alert.type}
                    </Typography>
                    <PausedChip isEnable={isEnable}/>
                </Box>
                <Box className={styles.contentWrapper}>
                    <Chip
                        icon={<MapMarkerIcon size={'small'}/>}
                        label={alert.locations[0].label}
                        size={'medium'}
                    />
                    {alert.locations.length > 1 &&
                        <>
                            <span
                                className={clsx(styles.locationsLengthIcon, 'paragraph')}
                                onClick={openLocations}
                            >
                            +
                                {alert.locations.length - 1}
                            </span>
                            <Popover
                                open={!!anchorElLocs}
                                anchorEl={anchorElLocs}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setAnchorElLocs(null)
                                }}
                                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                            >
                                <Box className={styles.locationsList}>
                                    {alert.locations.map((loc) => (
                                        <Chip
                                            icon={<MapPinIcon size={'small'}/>}
                                            label={loc.label}
                                            size={'small'}
                                            key={loc.id}
                                        />
                                    ))}
                                </Box>
                            </Popover>
                        </>
                    }
                    <Chip
                        label={alert.severity}
                        variant={theme.palette.weather.variant[alert.severity]}
                        size={'medium'}
                    />
                </Box>

                <div>
                    <IconButton
                        data-cy={'alert-configuration-menu'}
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        variant={'outlined'}
                        className={styles.moreIcon}
                    >
                        <MoreIcon size={'small'}/>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClick={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            data-cy={'alert-configuration-menu-edit-button'}
                            onClick={handleEdit}
                            key="edit"
                        >
                            <PenIcon size={'small'}/>
                            Edit
                        </MenuItem>
                        <MenuItem
                            onClick={handleCopy}
                            key="duplicate"
                        >
                            <CopyIcon size={'small'}/>
                            Copy
                        </MenuItem>
                        {isEnable &&
                            <MenuItem
                                onClick={() => setPauseAlertConfig(alert)}
                                key="pause"
                            >
                                <PauseIcon size={'small'}/>
                                Pause
                            </MenuItem>
                        }
                        {!isEnable &&
                            <MenuItem
                                onClick={() => setRestartAlertConfig(alert)}
                                key="restart"
                            >
                                <RestartIcon size={'small'}/>
                                Restart
                            </MenuItem>
                        }
                        <MenuItem
                            onClick={()=>setDeleteAlertConfig(alert)}
                            key="single_delete"
                            color={'error'}
                        >
                            <TrashIcon size={'small'}/>
                            Delete
                        </MenuItem>
                    </Menu>
                </div>
            </Box>
            {pauseAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Pausing this alert will prevent it from being triggered, but will not remove previously triggered instances. Are you sure you want to pause "${pauseAlertConfig.type}" alert?`,
                        title: 'Pause alert',
                        agreeMsg: 'Pause',
                        mode: 'pausing',
                        agreeFunc: (agreed) => handlePauseOrRestartAlertConfig(agreed, pauseAlertConfig.groupId, false),
                    }}
                />
            }

            {restartAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want to restart "${restartAlertConfig.type}" alert?`,
                        title: 'Restart alert',
                        agreeMsg: 'Restart',
                        mode: 'restarting',
                        agreeFunc: (agreed) => handlePauseOrRestartAlertConfig(agreed, restartAlertConfig.groupId, true),
                    }}
                />
            }

            {deleteAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Deleting this alert will remove it permanently from the system, and delete all previously triggered instances of the alert as well. 
                        Are you sure you want to delete "${deleteAlertConfig.type}" alert?`,
                        title: 'Delete alert configuration',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: approveDeleteAlertConfiguration,
                    }}
                />
            }
        </>
    )
}

export default AlertSettingsLine
