import {MenuItem, Typography} from '@mui/material'
import dayjs from 'dayjs'
import React, {useEffect, useState} from 'react'

import {Select} from 'shared/ui/Select/Select'
import {DatePicker} from 'shared/ui/datePickers/DatePicker'
import {RangeTextField} from 'shared/ui/datePickers/RangeTextField'
import {WeekPickersDay} from 'shared/ui/datePickers/WeekPickersDay'

/**
 * @param {string} label
 * @param {'Day' | 'Week' | 'Month'} defaultPeriod
 * @param {dayjs} dateRef
 * @param {function} onChange
 * @param {function} onPeriodChange
 * @param {any} other
 * @return {JSXElement}
 */
export function DatePeriodPicker(
    {
        label,
        defaultPeriod = 'Day',
        dateRef,
        onChange,
        onPeriodChange,
        ...other
    },
) {
    const [period, setPeriod] = useState(defaultPeriod)
    const value = dateRef ? dayjs(dateRef.current) : dayjs()
    const [_value, _setValue] = useState(value)

    useEffect(() => {
        setPeriod(defaultPeriod)
    }, [defaultPeriod])

    function handlePeriod(event) {
        setPeriod(event.target.value)
        onPeriodChange?.(event.target.value)
    }

    const handleAccept = () => {
        onChange?.(_value)
    }

    const handleClear = () => {
        _setValue(null)
    }

    return (
        <div
            className={'row'}
            style={{alignItems: 'end'}}
        >
            {period === 'Day' &&
                <DatePicker
                    // data-cy={'date-period-picker'}
                    className={'iconOnly'}
                    value={_value}
                    onChange={_setValue}
                    onAccept={handleAccept}
                    onClear={handleClear}
                    {...other}
                />
            }
            {period === 'Week' &&
                <DatePicker
                    data-cy={'date-period-picker'}
                    className={'iconOnly'}
                    defaultView={'day'}
                    value={_value}
                    onChange={_setValue}
                    onAccept={handleAccept}
                    onClear={handleClear}
                    slots={{
                        day: WeekPickersDay,
                        textField: RangeTextField,
                    }}
                    slotProps={{
                        day: {
                            dayRange: _value ? [_value.startOf('week'), _value.endOf('week')] : [],
                        },
                    }}
                    {...other}
                />
            }
            {period === 'Month' &&
                <DatePicker
                    data-cy={'date-period-picker'}
                    className={'iconOnly'}
                    defaultView={'month'}
                    format={'MMM'}
                    availableViews={{month: ['month'], year: ['year']}}
                    value={_value}
                    onChange={_setValue}
                    onAccept={handleAccept}
                    onClear={handleClear}
                    {...other}
                />
            }
            <Typography className={'period-select label'}>
                {label}
                <Select
                    data-cy={'period-select'}
                    value={period}
                    onChange={handlePeriod}
                >
                    <MenuItem value={'Day'}>
                        Day
                    </MenuItem>
                    <MenuItem value={'Week'}>
                        Week
                    </MenuItem>
                    <MenuItem value={'Month'}>
                        Month
                    </MenuItem>
                </Select>
            </Typography>
        </div>
    )
}

