import axios from 'axios'
import mem from 'mem'
import qs from 'qs'

axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' })
const base_url = ''
const api_url = base_url + '/api/v1'

export const api = axios.create({
    baseURL: api_url,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const checkIsLogged = () => {
    if (!localStorage.getItem('accessToken') || !localStorage.getItem('refreshToken')) {
        window.location.replace('/login')
    }
}

const refreshTokenFn = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken')
        const response = await axios.post(api_url + '/token/refresh', {
            refresh: refreshToken,
        })

        return response.data
    } catch (error) {
        console.log('refreshTokenFn error')
    }
}

const maxAge = 20000
const memoizedRefreshToken = mem(refreshTokenFn, {
    maxAge,
})

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken')
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => Promise.reject(error),
)

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                const result = await memoizedRefreshToken()

                localStorage.setItem('accessToken', result.access)
                localStorage.setItem('refreshToken', result.refresh)

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${result.access}`
                return axios(originalRequest)
            } catch (error) {
                // Handle refresh token error or redirect to login
                console.log('refresh token error')
                logoutUser()
            }
        }

        return Promise.reject(error)
    },
)

export const signupUser = async (firstName, lastName, email, phone, organization, industry, onSignup) => {
    const url = api_url + '/user'
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const data = {
        first_name: firstName,
        last_name: lastName,
        username: `${firstName}.${lastName}`,
        email: email,
        phone: phone,
        organization: organization,
        industry: industry,
        timezone: timezone,
    }
    await axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const setPassword = async (password, uid, token) => {
    const url = `${base_url}/set-password/${uid}/${token}`
    const data = {
        password: password,
    }
    try {
        const response = await axios.put(url, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return response.data
    } catch (error) {
        throw error
    }
}

export const resetPassword = async (password, uid, token, onSucces) => {
    const url = `${base_url}/reset-password`
    const data = {
        new_password: password,
        uid: uid,
        token: token,
    }

    await axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const loginUser = async (email, password, rememberMe=false, handleSuccess, handleError) => {
    console.log(email)
    await api
        .post('/token', {
            email: email,
            password: password,
            remember_me: rememberMe,
        })
        .then((response) => {
            if (response.status === 200) {
                localStorage.setItem('accessToken', response.data.access)
                localStorage.setItem('refreshToken', response.data.refresh)
            } else {
                throw {
                    response: response,
                }
            }
        })
}

export const forgotPassword = async (email) => {
    const url = `${base_url}/forgot-password`
    const data = {
        email: email,
    }
    await axios.post(url, data, {
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

export const logoutUser = async () => {
    const refreshToken = localStorage.getItem('refreshToken')

    if (refreshToken && (window.location.href.toString().split(window.location.host)[1] !== '/login'
        || window.location.href.toString().split(window.location.host)[1] !== '/createpassword'
        || window.location.href.toString().split(window.location.host)[1] !== '/resetpassword'
        || window.location.href.toString().split(window.location.host)[1] !== '/activate'
        || window.location.href.toString().split(window.location.host)[1] !== '/forgot-password'
        || window.location.href.toString().split(window.location.host)[1] !== '/signup' )) {
        try {
            api.post('/token/blacklist', {
                refresh: localStorage.getItem('refreshToken'),
            })
                .then(() => {
                    console.log('Token blacklisted')
                })
                .catch((error) => {
                    console.error('error occurred while logging out:', error)
                })
                .then(() => {
                    window.location.replace('/login')
                    console.log('Log out OK')
                })
        } catch (error) {
            console.error('error occurred while logging out:', error)
        } finally {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
        }
    }
}
