import {CheckBox} from '../../../../../shared/ui/CheckBox'
import React, {useState} from 'react'
import {IconButton, Menu, MenuItem, Typography} from '@mui/material'
import {Spacer} from '../../../../../shared/ui/Spacer'
import MoreIcon from '../../../../../shared/assets/icons/More'
import CopyIcon from '../../../../../shared/assets/icons/Copy'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../../shared/assets/icons/Trash'
import Urgency from '../Urgency'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import AgreeModal from '../../../../../shared/ui/AgreeModal'
import styles from "./ActionItemsSettingsLine.module.scss"

const ActionItemsSettingsLine = ({actionItem, selected}) => {
    const {
        selectActionItem,
        deleteActionItem,
        openActionItem,
        toggleEditingActionItem,
        toggleDuplication,
    } = useActionItemsStore((state) => state)

    const [anchorEl, setAnchorEl] = useState(null)
    const isOpen = Boolean(anchorEl)
    const [deletingActionItem, setDeletingActionItem] = useState(null)

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (action) => (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    const approveDeleteActionItem = (ActionItemId, agree) => {
        if (agree) {
            deleteActionItem(ActionItemId)
        }
        setDeletingActionItem(null)
    }

    const handleEditActionItem = () => {
        setAnchorEl(null)
        toggleEditingActionItem(actionItem)
    }

    const handleDuplicateActionItem = () => {
        setAnchorEl(null)
        toggleDuplication(true)
        toggleEditingActionItem(actionItem)
    }

    const handleDeleteActionItem = () => {
        setAnchorEl(null)
        setDeletingActionItem(actionItem)
    }

    const handleSelect = (event) => {
        event.preventDefault()
        event.stopPropagation()
        selectActionItem(actionItem)
    }

    const handleOpen = () => {
        openActionItem(actionItem)
    }

    return (
        <>
            <div
                className={styles.wrapper}
                onClick={handleOpen}
                data-cy={'ai-line'}
            >
                <CheckBox
                    checked={selected || false}
                    data-cy={'ai-line-checkbox'}
                    onClick={handleSelect}
                />
                <Typography className={styles.title}>
                    {actionItem.name}
                </Typography>
                <Spacer/>

                <Urgency Urgency={actionItem.urgency}/>

                <div data-cy={'ai-line-menu'}>
                    <IconButton
                        data-cy={'more-button'}
                        id="basic-button"
                        aria-controls={isOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isOpen ? 'true' : undefined}
                        onClick={handleClick}
                        variant={'outlined'}
                        className={styles.button}
                    >
                        <MoreIcon size={'small'}/>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={isOpen}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        onClose={handleClose('')}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            onClick={handleDuplicateActionItem}
                            data-cy={'ai-line-copy-button'}
                            key="duplicate"
                        >
                            <CopyIcon size={'small'}/>
                            Copy
                        </MenuItem>
                        <MenuItem
                            onClick={handleEditActionItem}
                            data-cy={'ai-line-edit-button'}
                            key="edit"
                        >
                            <PenIcon size={'small'}/>
                            Edit
                        </MenuItem>
                        <MenuItem
                            onClick={handleDeleteActionItem}
                            key="delete"
                            data-cy={'ai-line-delete-button'}
                            color={'error'}
                        >
                            <TrashIcon size={'small'}/>
                            Delete
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            {deletingActionItem &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want to delete ${deletingActionItem?.name} action item?`,
                        title: 'Delete action item',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: (agree) => approveDeleteActionItem(deletingActionItem.id, agree),
                    }}
                />
            }
        </>
    )
}

export default ActionItemsSettingsLine
