import {Link} from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React from 'react'
import logoTabMenu from 'shared/assets/png/logo.png'
import {Spacer} from 'shared/ui/Spacer'
import 'widgets/header/Header.css'
import {MainMenu} from 'widgets/mainMenu/ui/MainMenu'
import ShowNotificationsPopupButton from 'widgets/notification/ShowNotificationsPopupButton'

export default function Header() {
    const activeTab = new URL(document.URL).pathname

    const tabNumbers = {
        '/': 1,
        '/timeline': 0,
        '/alerts': 1,
        '/map': 2,
        '/settings': 4,
        '/settings/': 4,
        '/alerts/newalert': 5,
        '/alerts/edit-alert': 5,
        '/reports': 3,
    }
    if (tabNumbers[activeTab] === undefined) {
        return <></>
    }

    return (
        <div
            className={'row'}
            style={{justifyContent: 'left'}}
        >
            <div className={'row gap0 header'}>
                <Link
                    className={'baronLogo'}
                    href={'/timeline'}
                >
                    <img
                        src={logoTabMenu}
                        alt={'logo'}
                    />
                </Link>
                <div style={{width: '135px'}}/>
                <Tabs value={tabNumbers[activeTab]} >
                    <Tab
                        variant={'primary'}
                        label="Timeline"
                        href="/timeline"
                        style={{minWidth: 'unset'}}
                    />
                    <Tab
                        label="Alerts"
                        href="/alerts"
                        style={{minWidth: 'unset'}}
                    />
                    <Tab
                        label="Map"
                        href="/map"
                        style={{minWidth: 'unset'}}
                    />
                </Tabs>
                <Spacer/>
                <ShowNotificationsPopupButton/>
                <MainMenu />
            </div>
        </div>
    )
}
