import maplibregl from '!maplibre-gl' // ! is important here
import {Box} from '@mui/material'
import 'maplibre-gl/dist/maplibre-gl.css'
import 'pages/map/ui/css/map.css'
import 'pages/map/ui/css/conds.css'
import 'pages/map/ui/css/style.css'
import CircularProgress from '@mui/material/CircularProgress'
import {DistanceTool, PinPointTool, PointQueryTool} from 'features/mapTools'
import {MapSidebar} from 'pages/map/ui/MapSidebar'
import {TimeLineMap} from 'pages/map/ui/timeline/timeline'
import React, {useEffect, useRef, useState} from 'react'
import requester from 'shared/libs/requester/baron-signature.js'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import {useMapStore} from '../../../app/store/MapStore'
import {useViewsStore} from '../../../app/store/ViewsStore'
import {clamp} from '../../../shared/libs/Utils'
import {fetchRealAlertByAid} from '../../alerts/api/FetchAlerts'
import {TEXT_PRODUCTS_CODES} from '../model/Tile/ProductsController'
import {Legends} from './legends/legends'
import MapMenu from './MapMenu'

export const NON_STANDARD_PROJECTION = {
    'north-american-radar': 'Mask1-Mercator',
}

export const MapLibre = () => {
    console.log('render maplibre')
    const map = useMapStore((state) => state.map)
    const productsController = useMapStore((state) => state.productsController)

    const enabledProducts = useMapStore((state) => state.enabledProducts)
    const setEnabledProducts = useMapStore((state) => state.setEnabledProducts)
    const products = useMapStore((state) => state.products)
    const fetchProducts = useMapStore((state) => state.fetchProducts)
    const enabledLocations = useMapStore((state) => state.enabledLocations)

    const fetchDefaultMapView = useViewsStore((state) => state.fetchDefaultMapView)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    // timeline
    const currentDateInMs = new Date().getTime()

    const [isLoading, setIsLoading] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [selectedTime, setSelectedTime] = useState(currentDateInMs)
    const [stateDate, setStateDate] = useState({
        startDate: currentDateInMs - 3600000,
        endDate: currentDateInMs,
    })

    // ???
    const [locationsLayers, setLocationLayers] = useState({})
    const [clearPQ, setClearPQ] = useState(false)
    const [activeTool, setActiveTool] = useState(null)

    // timeline
    const nowDate = useRef(currentDateInMs)
    const timelineTime = useRef(currentDateInMs)
    const timelineValue = useRef(50)

    useEffect(() => {
        requester.isready(() => {
            const productsControllerOptions = {
                startDate: stateDate.startDate,
                endDate: stateDate.endDate,
                time: currentDateInMs,
                setSelectedTime: setSelectedTime,
                onChangeTimeRange: setStateDate,
            }

            const {map} = useMapStore.getState().initialize(productsControllerOptions)
            map.on('movestart', () => {
                setIsPlaying(false)
                productsController?.switchWMSMode(false)
            })
            map.on('disableProducts', handleDisableProducts)
            map.on('moveend', () => {
                updateMapDefaultView()
            })

            const urlParams = new URLSearchParams(window.location.search)
            const aid = urlParams.get('alert_id')

            if (aid) {
                const promises = [fetchLocations(), fetchProducts(), fetchRealAlertByAid(aid)]

                Promise.all(promises)
                    .then((responses) => {
                        const alert = responses[2]
                        const dates = useMapStore.getState().loadViewFromAlert(alert, aid)

                        setSelectedTime(dates.startDate)
                        setStateDate(dates)
                    })
            } else {
                const promises = [fetchLocations(), fetchProducts(), fetchDefaultMapView(locations)]
                Promise.all(promises)
                    .then((responses) => {
                        const defaultMapView = responses[2]
                        useMapStore.getState().loadView(defaultMapView)
                    })
            }
        })
    }, [])

    useEffect(() => {
        const interval = setInterval(updateDataIfAvailable, 60000)

        return () => {
            clearInterval(interval)
        }
    }, [isLoading, isPlaying, enabledProducts])

    // remove later
    useEffect(() => {
        productsController?.setTimeRange(stateDate)
    }, [stateDate])

    useEffect(() => {
        enabledLocations.forEach((id) => {
            if (locationsLayers[id]) return
            const loc = locations.find((p) => (p.id === id))
            if (!loc) {
                return
            }
            locationsLayers[id] = new maplibregl.Marker({color: 'var(--palette-primaryRed-light)', scale: 0.5})
                .setLngLat(loc.coordinates)
                .addTo(map)
            locationsLayers[id].getElement().setAttribute('title', loc.label)
        })

        const _clearedLocations = Object.assign({}, locationsLayers)
        for (const id in locationsLayers) {
            if (!enabledLocations.includes(+id)) {
                _clearedLocations[id] && _clearedLocations[id].remove()
                delete _clearedLocations[id]
            }
        }

        setLocationLayers(_clearedLocations)
    }, [enabledLocations])

    useEffect(() => {
        updateMapDefaultView()
    }, [enabledProducts, enabledLocations])

    useEffect(() => {
        if (productsController) {
            reEnableWmsIfNeed()
        }
    }, [enabledProducts])

    const updateDataIfAvailable = () => {
        let nowPosition = dateMsToPosition(nowDate.current, stateDate.startDate, stateDate.endDate)
        nowPosition = clamp(0, nowPosition, 100)

        const productCodes = getProductCodes()
        const newRange = productsController.calculateRange(productCodes)
        const selectedTimePosition = dateMsToPosition(timelineTime.current, newRange.startDate, newRange.endDate)

        const isSelectedTimeOutOfRange = selectedTimePosition < -2 || 100 < selectedTimePosition
        const isTimelineValueCloseToNow = timelineValue.current <= nowPosition && nowPosition <= (timelineValue.current + 2)
        const isAvailableToUpdate = (isSelectedTimeOutOfRange || isTimelineValueCloseToNow) && !isPlaying && !isLoading

        if (isAvailableToUpdate) {
            updateData()
            preloadTiles(true)
        }
    }

    const dateMsToPosition = (date, start, end) => (date - start) * 100 / (end - start)

    const updateData = () => {
        const productCodes = getProductCodes()
        const range = productsController.calculateRange(productCodes)

        timelineTime.current = range.now
        nowDate.current = range.now
        setSelectedTime(range.now)
        setStateDate({
            startDate: range.startDate,
            endDate: range.endDate,
        })
    }

    const getProductCodes = () => {
        const productCodes = []

        enabledProducts.forEach((p) => {
            const prod = products.find((pr) => (pr.id === p.id))
            if (prod) {
                const productCode = TEXT_PRODUCTS_CODES.includes(prod.api_product_code)
                    ? prod.api_product_code
                    : `${prod.api_product_code}/${NON_STANDARD_PROJECTION[prod.api_product_code] || 'Standard-Mercator'}`
                productCodes.push(productCode)
            }
        })
        return productCodes
    }

    const reEnableWmsIfNeed = () => {
        const isNeedRePlay = isPlaying
        productsController.switchWMSMode(false)

        setIsPlaying(false)
        setIsLoading(false)

        if (isNeedRePlay) {
            setIsLoading(true)
            productsController.switchWMSMode(true, () => {
                setIsLoading(false)
                isNeedRePlay && setIsPlaying(true)
            })
        }
    }

    const preloadTiles = (force = false) => {
        const isNeedRePlay = isPlaying
        if (productsController.mode && !force) {
            return
        }

        setIsLoading(true)
        productsController.switchWMSMode(true, () => {
            setIsLoading(false)
            isNeedRePlay && setIsPlaying(true)
        })
    }

    const handleDisableProducts = ({productsToBeDisabled}) => {
        setEnabledProducts((prevState) => prevState.filter(
            (product) => !productsToBeDisabled.includes(product),
        ))
    }

    function clearPQPopups() {
        setClearPQ(!clearPQ)
    }

    const updateMapDefaultView = () => {
        const {map, enabledProducts, enabledLocations} = useMapStore.getState()
        const {createDefaultMapView} = useViewsStore.getState()

        if (!map) {
            return
        }

        // control where we get to the map
        const urlParams = new URLSearchParams(window.location.search)
        const alertId = urlParams.get('alert_id')
        if (!alertId) {
            createDefaultMapView({
                name: 'defaultmapview',
                order: 1,
                data_json: {
                    activeProducts: enabledProducts,
                    activeLocations: enabledLocations,
                    locationHash: window.location.hash,
                },
            })
        }
    }

    const _handleProductSwitch = (prods) => {
        setEnabledProducts(prods)
    }

    const handlePlay = (isPause) => {
        if (!isPause) {
            setIsLoading(true)
            productsController.switchWMSMode(true, () => {
                setIsLoading(false)
            })
        }
    }

    const handleChangeTime = (selectedTime, value) => {
        timelineTime.current = selectedTime
        timelineValue.current = value
        productsController?.setTime(selectedTime)
        map?.fire('selectedTime')
    }

    const handleNowClick = () => {
        updateData()
        preloadTiles(true)
    }

    if (!locations || !products) {
        return (
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                    flexDirection: 'column',
                }}
            >
                <CircularProgress/>
                <div>
                    Loading...
                </div>
            </div>
        )
    }

    return (
        <Box className={'row gap0 fullHeight fullWidth'}>
            <MapSidebar
                onSwitchProduct={_handleProductSwitch}
                reEnableWmsIfNeed={reEnableWmsIfNeed}
                products={products}
                locations={locations}
            />
            <Box
                className="MapLibre spacer"
                style={{
                    height: '100%', display: 'flex',
                    flexDirection: 'column', alignItems: 'stretch',
                    borderRadius: '16px 0 0 16px', overflow: 'hidden',
                    boxShadow: '-4px 0px 24px 0px #677A8E3D',
                }}
            >
                <MapMenu
                    onClearPqPopups={() => clearPQPopups()}
                />
                <div
                    className="baronmap"
                    id="baronmapwrapper"
                >
                    <div id="map"/>
                    <TimeLineMap
                        // need to add inital value or keep time on map element
                        preloadTiles={preloadTiles}
                        isLoading={isLoading}
                        startDate={stateDate.startDate}
                        endDate={stateDate.endDate}
                        onChangeTime={handleChangeTime}
                        onPlayBtn={handlePlay}
                        onChangePlaying={setIsPlaying}
                        isPlaying={isPlaying}
                        selectedTime={selectedTime}
                        onNow={handleNowClick}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '138px',
                            width: 'fit-content',
                            height: 'fit-content',
                            left: '24px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '12px',
                        }}
                    >
                        <DistanceTool
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('DistanceTool')}
                        />
                        <PinPointTool
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('PinPointTool')}
                        />
                        <PointQueryTool
                            clear={clearPQ}
                            activeTool={activeTool}
                            setActiveTool={() => setActiveTool('PointQuery')}
                        />
                    </div>
                </div>
                <Legends
                    nonStandartProjection={NON_STANDARD_PROJECTION}
                />
            </Box>
        </Box>
    )
}
