import React, {useState} from 'react'
import {Box, Button, IconButton} from '@mui/material'
import {ManageLocationScreen} from 'widgets/location/ui/manageLocationScreen/ManageLocationScreen'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import LocationsConfirmationModal from '../../../../shared/ui/LocationsConfirmationModal/LocationsConfirmationModal'
import styles from "./EditLocationScreen.module.scss"
import {clsx} from "clsx";

export default function EditLocationScreen({
    defaultValue = {},
    selectLocations = false,
    closeOnSave = false,
    onClose = () => {
    },
}) {
    const [lastCreatedLocs, setLastCreatedLocs] = useState([])
    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(null)

    const isLocationHasID = () => !!defaultValue?.options?.id

    const locationCreated = (locations) => {
        setLastCreatedLocs(locations)
    }

    const handleComplete = () => {
        setIsOpenSuccessModal(lastCreatedLocs)
    }

    const handleCloseSuccessModal = () => {
        setIsOpenSuccessModal(null)
        onClose(!selectLocations)
    }

    return (
        <Box className={clsx("fullHeight", styles.wrapper)}>

            <Box className={clsx('toolbar padding', styles.header)}>
                <Box className={styles.headerText}>
                    <IconButton
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => onClose(!selectLocations)}
                    >
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3>
                        {isLocationHasID() ? 'Edit' : 'Add'}
                        {' '}
                        location
                    </h3>
                </Box>
                <Button
                    className={styles.completeButton}
                    disabled={!lastCreatedLocs || lastCreatedLocs.length < 1}
                    onClick={handleComplete}
                >
Complete
                </Button>
            </Box>

            <Box className={styles.contentWrapper}>
                <ManageLocationScreen
                    style={{padding: 0}}
                    showLocations={!isLocationHasID()}
                    selectLocations={selectLocations}
                    defaultLocation={defaultValue}
                    closeOnSave={closeOnSave}
                    onChange={onClose}
                    onLocationCreated={locationCreated}
                />
            </Box>
            {isOpenSuccessModal && <LocationsConfirmationModal
                locations={lastCreatedLocs}
                agreeFunc={handleCloseSuccessModal}
                                   />}
        </Box>
    )
};
