import React, {useEffect, useState} from 'react';
import {Box, Button, FormControlLabel, FormGroup, IconButton, Typography} from '@mui/material';
import { CheckBox } from "shared/ui/CheckBox";
import PenIcon from "shared/assets/icons/Pen";
import CustomMessagePopup, {tokens, renderMessageWithTokens} from 'features/notification/CustomMessagePopup/CustomMessagePopup';
import styles from "./NotificationCheckboxWithMessage.module.scss"


function NotificationCheckboxWithMessage({ label, checked, onNotificationDataChange, message='', type, onChange, isDefaultMessage, onSaveAsDefault}) {
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [content, setContent] = useState([]);

    const handleSaveAsDefault = () => {
        if(onSaveAsDefault) {
            onSaveAsDefault(type, message);
        }
    };


    useEffect(() => {
        setContent(renderMessageWithTokens(message, tokens));
    }, [message, tokens]);


    const handleChange = (e) => {
        onNotificationDataChange(type, e.target.checked);
    };

    return (
        <>
            <FormGroup>
                <FormControlLabel
                    control={
                        <CheckBox 
                            checked={checked} 
                            onChange={handleChange}
                        />
                    } 
                    label={label}
                />
                {checked && (
                    <Box className={styles.checkedContentWrapper}>
                        <Typography variant="body2">{content}</Typography>
                        <Box className={styles.checkedContent}>
                            <IconButton
                                variant={'outlined'}
                                size={'tiny'}
                                onClick={() => setShowMessagePopup(true)}
                            >
                                <PenIcon size={'small'}/>
                            </IconButton>
                            {isDefaultMessage ? (
                                <Typography className={styles.defaultMessage}>
                                    Default message
                                </Typography>
                            ) : (
                            <Button variant={'outlined'} color={'primary'} className={styles.button} onClick={handleSaveAsDefault}>
                                Save as default
                            </Button>
                            )}
                        </Box>
                    </Box>
                )}
            </FormGroup>
            <CustomMessagePopup
                visible={showMessagePopup}
                onCloseModal={() => setShowMessagePopup(false)}
                messageText={message}
                tokens={tokens}
                onChange={onChange}
            />
        </>
    );
}

export default NotificationCheckboxWithMessage;
