import React, { useEffect, useState } from 'react';
import 'pages/map/ui/legends/legends.css';
import {useMapStore} from "../../../../app/store/MapStore";

const EXCEPTIONAL_LIST = ['alert-all-poly']; // banned

export function Legends({nonStandartProjection}) {
	const products = useMapStore((state) => state.products)
	const enabledProducts = useMapStore((state) => state.enabledProducts)

	const [indexLegend, setIndexLegend] = useState(0);
	const [allLegends, setAllLegends] = useState({});
	const [activeLegends, setActiveLegends] = useState([]);

	useEffect(()=>{
		const newActiveLegends = [];	
		enabledProducts.forEach(product => {
			if (allLegends[product.id] === undefined) {
				_loadImage(product.id, (urlLegend)=>{
					setActiveLegends([...activeLegends,urlLegend]);
				});
			}else{
				if (allLegends[product.id]){
					newActiveLegends.push(allLegends[product.id]);
				}
			}
		});
		setActiveLegends(newActiveLegends);
	},[enabledProducts])

	useEffect(() => {
		setIndexLegend((currentIndex) => {
			const lastActiveLegendIndex = activeLegends.length - 1
			if (lastActiveLegendIndex >= 0) {
				return lastActiveLegendIndex	
			}

			return currentIndex
		})
	}, [activeLegends])

	const 	_url = "//staticbaronwebapps.velocityweather.com/digitial_wx/widgets/legends/";

	function _loadImage(id, onLoad) {
		const prod = products.find((e)=>{return(id===e.id)})
		if (!prod) {
			console.error(`Product with id ${id} not found.`);
			return;
		}
		let imgElement = document.createElement('img');
		let urlLegend =`${_url}${prod.api_product_code}_${nonStandartProjection[prod.api_product_code] || 'Standard-Mercator'}.svg`;

		let newLegends = Object.assign({},allLegends);
		newLegends[id] = false;
		setAllLegends(newLegends);
		if (EXCEPTIONAL_LIST.includes(prod.api_product_code)) return;
		imgElement.addEventListener('load', e => {
			let newLegends = Object.assign({}, allLegends);
			newLegends[id] = urlLegend;
			setAllLegends(newLegends);
			onLoad(urlLegend);
		});
		imgElement.src = urlLegend;
	}


	function handleChangeIndex(s) {
		let newIndex = (indexLegend + s + activeLegends.length) % activeLegends.length;
		setIndexLegend(newIndex);
	}

	if (!activeLegends.length) {
		return <div></div>;
	}
	const src = activeLegends[indexLegend];

	return (
		<div className="legendswrapper">
			{(activeLegends.length > 1) && <div className="legendsprevbtn"
				onClick={() => handleChangeIndex(-1)} aria-label="Previous Legend" ></div>}
			<img className="legendimage" src={src} alt="" />
			{(activeLegends.length > 1) && <div className="legendsnextbtn"
				onClick={() => handleChangeIndex(1)} aria-label="Next Legend" ></div>}
		</div>
	);
}
