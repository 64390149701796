import {createSvgIcon} from '@mui/material/utils'

export default function RulerIcon(props) {
    const Icon = createSvgIcon(
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.3333 12.6667H12.6667V9.33333H6.66667V3.33333H3.33333V4.66667H4.66667V6H3.33333V7.33333H5.33333V8.66667H3.33333V10H4.66667V11.3333H3.33333V12.6667H4.66667V11.3333H6V12.6667H7.33333V10.6667H8.66667V12.6667H10V11.3333H11.3333V12.6667ZM8 8H13.3333C13.5101 8 13.6797 8.07024 13.8047 8.19526C13.9298 8.32029 14 8.48986 14 8.66667V13.3333C14 13.5101 13.9298 13.6797 13.8047 13.8047C13.6797 13.9298 13.5101 14 13.3333 14H2.66667C2.48986 14 2.32029 13.9298 2.19526 13.8047C2.07024 13.6797 2 13.5101 2 13.3333V2.66667C2 2.48986 2.07024 2.32029 2.19526 2.19526C2.32029 2.07024 2.48986 2 2.66667 2H7.33333C7.51014 2 7.67971 2.07024 7.80474 2.19526C7.92976 2.32029 8 2.48986 8 2.66667V8Z"
                fill="#677A8E"
            />
        </svg>, 'Ruler')
    return (
        <Icon
            {...props}
            data-cy="ruler-icon"
        />
    )
}

