import {Button, Menu, MenuItem} from '@mui/material'
import React, {useState} from 'react'
import TrashIcon from 'shared/assets/icons/Trash'
import {useMapStore} from '../../../../app/store/MapStore'
import CleanIcon from '../../../../shared/assets/icons/Clean'
import './ClearButton.css'
import LayerIcon from '../../../../shared/assets/icons/Layer'
import MapMarkerIcon from '../../../../shared/assets/icons/MapMarker'
import PointIcon from '../../../../shared/assets/icons/Point'

const ClearButton = ({clearPq}) => {
    const setEnabledProducts = useMapStore((state) => state.setEnabledProducts)
    const setEnabledLocations = useMapStore((state) => state.setEnabledLocations)

    const [isOpen, setIsOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setIsOpen(true)
    }

    const handleClearLocations = () => {
        setEnabledLocations([])
        handleClose()
    }

    const handleClearLayers = () => {
        setEnabledProducts([])
        handleClose()
    }

    const handleClearAll = () => {
        setEnabledProducts([])
        setEnabledLocations([])
        clearPq()
        handleClose()
    }

    const handleClose = () => {
        setAnchorEl(null)
        setIsOpen(false)
    }

    return (
        <div>
            <Button
                variant={'outlined'}
                color={'secondary'}
                startIcon={<CleanIcon/>}
                onClick={handleClick}
                aria-haspopup="true"
                aria-controls={isOpen ? 'clear-button-menu' : undefined}
                aria-expanded={isOpen ? 'true' : undefined}
            >
                Clear
            </Button>
            <Menu
                id="clear-button-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={handleClearLocations}
                >
                    <MapMarkerIcon size={'small'}/>
                    Locations
                </MenuItem>
                <MenuItem
                    onClick={handleClearLayers}
                >
                    <LayerIcon size={'small'}/>
                    Layers
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        clearPq()
                        handleClose()
                    }}
                >
                    <PointIcon size={'small'}/>
                    Point queries
                </MenuItem>
                <MenuItem
                    color={'error'}
                    onClick={handleClearAll}
                >
                    <TrashIcon size={'small'}/>
                    Clear all
                </MenuItem>
            </Menu>
        </div>
    )
}

export default ClearButton
