import maplibregl from "!maplibre-gl";
import {AbstractTextProduct} from '../AbstractTextProduct';
import stations from './stations.json';
import './SingleSiteRadar.scss'

export default class Nexrad extends AbstractTextProduct {

    constructor(opts) {
        opts = Object.assign({
            name: "nexrad",
            events: ['dragend', 'zoomend'],
            params: {}
        }, opts)
        super(opts)
        this.handleStationChange = this.handleStationChange.bind(this)
        this.handleActualDataNotFound = this.handleActualDataNotFound.bind(this)
        this.handleDataFound = this.handleDataFound.bind(this)
        this.showMarkersIfEnabled = this.showMarkersIfEnabled.bind(this)

        this.markers = []
        this.stations = []
        for (const [name, lng, lat] of stations) {
            this.stations.push({name, lng, lat})
        }
    }

    handleStationChange({stationName, selected}) {
        for (const marker of this.markers) {
            marker.setPopup(null)
            if (marker.station.name === stationName) {
                const markerElement = marker.getElement()
                markerElement.classList.remove('error')
                markerElement.classList.toggle('selected', selected)
                return
            }
        }
    }

    handleActualDataNotFound({site}) {
        for (const marker of this.markers) {
            if (marker.station.name !== site) {
                continue
            }

            const markerElement = marker.getElement()
            markerElement.classList.remove('selected')
            markerElement.classList.add('error')

            const popup = marker.getPopup()
            if (!popup) {
                const popup = new maplibregl.Popup({
                    offset: {
                        top: [0, 16],
                        bottom: [0, -16],
                        left: [16, 0],
                        right: [-16, 0],
                        center: [0, 0]
                    }
                })
                    .setHTML('<div>No data</div>')
                marker.setPopup(popup)
                    .togglePopup()
            } else {

            }
        }
    }

    handleDataFound({site}) {
        for (const marker of this.markers) {
            marker.setPopup(null)

            if (marker.station.name !== site) {
                continue
            }

            const markerElement = marker.getElement()
            markerElement.classList.remove('error')
            markerElement.classList.add('selected')
        }
    }

    async showMarkersIfEnabled() {
        if (this.options.isEnabled) {
            await this.showMarkers()
        }
    }

    async enable() {
        if (this.options.isEnabled) {
            return;
        }
        super.enable()

        this.options.map.on('stationStateChange', this.handleStationChange)
        this.options.map.on('actualDataNotFoundNexrad', this.handleActualDataNotFound)
        this.options.map.on('dataFoundNexrad', this.handleDataFound)
        this.options.map.on('dragend', this.showMarkersIfEnabled)
        this.options.map.on('zoomend', this.showMarkersIfEnabled)

        await this.showMarkers()
    }

    async disable() {
        super.disable()
        await this.hideMarkers()

        this.options.map.off('stationStateChange', this.handleStationChange)
        this.options.map.off('actualDataNotFoundNexrad', this.handleActualDataNotFound)
        this.options.map.off('dataFoundNexrad', this.handleDataFound)
        this.options.map.off('dragend', this.showMarkersIfEnabled)
        this.options.map.off('zoomend', this.showMarkersIfEnabled)
    }

    async createMarkers() {
        if (this.markers.length > 0) {
            return
        }

        const markerPromises = this.stations.map(async (station) => {
            const htmlElement = await this.createMarkerHtmlElement(station);
            const marker = await this.createMarker(station, htmlElement);
            this.markers.push(marker);
        });

        await Promise.all(markerPromises)
    }


    async showMarkers() {
        const markers = this.getVisibleMarkers()
        for (const marker of markers) {
            marker.getElement().classList.remove('hidden')
        }
    }

    async hideMarkers() {
        for (const marker of this.markers) {
            marker.getElement().classList.add('hidden')
        }
    }

    getVisibleMarkers() {
        const screenBounds = this.options.map.getBounds()
        const visibleMarkers = []

        for (const marker of this.markers) {
            const {lng, lat} = marker.station
            const stationPoint = new maplibregl.LngLat(lng, lat)
            const isStationVisible = screenBounds.contains(stationPoint)

            if (isStationVisible) {
                visibleMarkers.push(marker)
            }
        }

        return visibleMarkers
    }

    createMarkerHtmlElement(station) {
        const htmlElement = document.createElement('div')
        htmlElement.setAttribute('data-cy', 'station')
        htmlElement.className = 'marker hidden impmarker impmarker-header marker-body'
        htmlElement.innerHTML = `
            <div class="marker-label">
                ${station.name}
            </div>        
        `

        htmlElement.addEventListener('click', (event) => {
            event.stopPropagation();
            this.handleMarkerClick(station, htmlElement);
        })

        return htmlElement
    }

    createMarker(station, element) {
        const marker = new maplibregl.Marker({
            element,
        }).setLngLat([station.lng, station.lat])
            .addTo(this.options.map)
        marker.station = station
        return marker
    }

    handleMarkerClick(station, htmlElement) {
        for (const marker of this.markers) {
            marker.setPopup(null);
            if (marker.station.name !== station.name) {
                marker.getElement().classList.remove('selected', 'error');
            }
        }

        const hasError = htmlElement.classList.contains('error');
        const selected = hasError ? htmlElement.classList.toggle('error') : htmlElement.classList.toggle('selected');

        this.options.map.fire('stationClick', {
            station,
            selected,
            product: this,
        });
    }

}