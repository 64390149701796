import React from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    IconButton,
    Typography,
} from '@mui/material'
import ArrowDownSmallIcon from '../../../../../shared/assets/icons/ArrowDownSmall'
import CopyIcon from '../../../../../shared/assets/icons/Copy'
import {Spacer} from '../../../../../shared/ui/Spacer'
import Urgency from '../Urgency'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import {CheckBox} from '../../../../../shared/ui/CheckBox'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import styles from "./ActionItemsAdvancedLine.module.scss"
import {clsx} from "clsx";

const ActionItemsAdvancedLine = ({actionItem, chosenActionItems, onChangeActionItem}) => {
    const {
        toggleDuplication,
        toggleEditingActionItem,
    } = useActionItemsStore((state) => state)

    const handleEdit = () => {
        toggleEditingActionItem(actionItem)
    }

    const handleCopy = () => {
        toggleDuplication(true)
        toggleEditingActionItem(actionItem)
    }

    return (
        <Accordion
            disableGutters
            elevation={0}
            square
            className={styles.wrapper}
        >
            <AccordionSummary
                expandIcon={
                    <IconButton
                        variant='outlined'
                    >
                        <ArrowDownSmallIcon size={'small'}/>
                    </IconButton>}
                aria-controls="panel1a-content"
                className={styles.accordionSummary}
            >
                <Box className={styles.infoWrapper}>
                    <CheckBox
                        checked={chosenActionItems.includes(actionItem.id)}
                        onChange={() => onChangeActionItem(actionItem.id)}
                        onClick={(ev) => {
                            ev.stopPropagation()
                        }}
                    />
                    <Typography className={styles.info}>
                        {actionItem.name}
                    </Typography>
                    <Spacer/>

                    <Urgency Urgency={actionItem.urgency}/>
                </Box>
            </AccordionSummary>
            <AccordionDetails className={clsx('column fullWidth', styles.accordionDetails)}>
                <Box>
                    {actionItem.description}
                </Box>
                <Box className={styles.buttonWrapper}>
                    <Button
                        startIcon={<PenIcon/>}
                        variant={'outlined'}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                    <Button
                        startIcon={<CopyIcon/>}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={handleCopy}
                    >
                        Copy
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    )
}

export default ActionItemsAdvancedLine
