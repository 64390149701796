import {Accordion, AccordionDetails, AccordionSummary, Box, FormControlLabel, Typography} from '@mui/material'
import React from 'react'
import {useStoreWithEqualityFn} from 'zustand/traditional'

import {useMapStore} from '../../../app/store/MapStore'
import {CheckBox} from '../../../shared/ui/CheckBox'

const ProductGroup = ({groupName, group, groups, FIELDS}) => {
    console.log('render ProductGroup')
    const enabledProducts = useStoreWithEqualityFn(
        useMapStore,
        (state) => state.enabledProducts,
        (prev, next) => {
            const isPrevGroup = prev.some((product) => product.product_group === groupName)
            const isNextGroup = next.some((product) => product.product_group === groupName)

            return !(isPrevGroup || isNextGroup)
        },
    )
    const toggleProduct = useMapStore((state) => state.toggleProduct)

    const isProductEnabled = (product) => !!enabledProducts.find((enabledProduct) => enabledProduct.id === product.id)

    return (
        <Accordion
            variant={'dark'}
            defaultExpanded
            key={groupName}
            data-cy={groupName}
        >
            <AccordionSummary
                variant={'dark'}
                sx={{height: '32px'}}
            >
                <Box className={'row gap4 fullWidth'}>
                    <Typography style={{color: 'var(--palette-grey-900)'}}>
                        {`${groupName} (${group.length})`}
                    </Typography>
                </Box>
            </AccordionSummary>
            <AccordionDetails variant={'dark'}>
                {groups[groupName].map((product) =>
                    <Box
                        className={'row gap4 fullWidth'}
                        sx={{paddingLeft: '24px', paddingRight: '8px'}}
                        key={`${FIELDS.nameLine}${product.id}`}
                    >
                        <FormControlLabel
                            label={product[FIELDS.nameLine]}
                            control={
                                <CheckBox
                                    checked={isProductEnabled(product)}
                                    onChange={() => toggleProduct(product)}
                                />
                            }
                        />
                    </Box>,
                )}
            </AccordionDetails>
        </Accordion>
    )
}

export default ProductGroup
