import {MenuItem} from '@mui/material'
import {Select} from '../../../shared/ui/Select/Select'
import React from 'react'
import styles from './SeveritySelector.module.scss'
import {clsx} from 'clsx'

export const SeveritySelector = ({severity, onChange}) => (<Select
    data-cy={'severity-filter'}
    placeholder={''}
    defaultValue={severity}
    onChange={onChange}
    InputProps={{
        startAdornment: <div
            className={clsx('paragraph', styles.title)}
                        >
                    Severity:
        </div>,
    }}
                                                           >
    <MenuItem
        value={'All'}
        data-cy={'severity-filter-all'}
    >
All
    </MenuItem>
    <MenuItem
        value={'Critical'}
        data-cy={'severity-filter-critical'}
    >
Critical
    </MenuItem>
    <MenuItem
        value={'Severe'}
        data-cy={'severity-filter-severe'}
    >
Severe
    </MenuItem>
    <MenuItem
        value={'Moderate'}
        data-cy={'severity-filter-moderate'}
    >
Moderate
    </MenuItem>
</Select>)
