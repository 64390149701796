import React from 'react'
import {Button} from '@mui/material'
import {clsx} from 'clsx'
import styles from './SettingsMenuButton.module.scss'

const SettingsMenuButton = ({selected, className, ...props}) => (
    <Button
        className={clsx(styles.settingsMenuButton, styles.customSettingsMenuButton, {[styles.selected]: selected})}
        {...props}
    />
)

SettingsMenuButton.defaultProps = {
    variant: '',
    color: '',
}

export default SettingsMenuButton
