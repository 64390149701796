import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import {Chip} from '@mui/material';
import React, {useState} from 'react';
import theme from 'app/Theme';
import {Spacer} from 'shared/ui/Spacer';
import MapPinIcon from 'shared/assets/icons/MapPin'
import {AlertPopup} from '../AlertPopup/AlertPopup';
import {Box} from "@mui/material";
import {ReactComponent  as BlockLocation} from "shared/assets/svg/alertIconBlock/blockLocation.svg";
import {ReactComponent  as BlockLine} from "shared/assets/svg/alertIconBlock/blockLine.svg";
import {ReactComponent  as BlockArea} from "shared/assets/svg/alertIconBlock/blockArea.svg";
import 'pages/alerts/ui/AlertsPage.css';
import {ShowReportButton} from "../../../../features/alert/ShowReportButton";
import RenderIfVisible from 'react-render-if-visible'
import styles from "./AlertCard.module.scss"
import {clsx} from "clsx";


export default function AlertCard({alert, alertMoreButton}) {
    const [isOpenAlertModal, setIsOpenAlertPopup] = useState(false);

    const handleOpenAlertPopup = event => {
        event.stopPropagation()
        setIsOpenAlertPopup(true)
    }
    const handleCloseAlertPopup = event => {
        event.stopPropagation()
        setIsOpenAlertPopup(false)
    }

    const renderLocationTypeImage = () => {
        switch (alert.location_type) {
            case "point":
                return <BlockLocation/>;
            case "line":
                return <BlockLine/>;
            case "area":
                return <BlockArea/>;
            default:
                return null;
        }
    }

    return (
        <RenderIfVisible defaultHeight={216}>
        <Box
            className={'alertGridItem'}
            onClick={handleOpenAlertPopup}
        >
            <Modal
                open={isOpenAlertModal}
                onClose={handleCloseAlertPopup}
            >
                <AlertPopup
                    alert={alert}
                    onClose={handleCloseAlertPopup}
                    alertMoreButton={alertMoreButton}
                />
            </Modal>
            <div className={'row'}>
                <div className={'subtitle medium'}>{alert.type}</div>
                <div className={'paragraph'}
                         style={{color: theme.palette.notification.status[alert.category]}}>
                        {alert.category}
                </div>
                <Spacer/>
                {alertMoreButton}
            </div>
            <div className={clsx('row', styles.contentWrapper)}>
                <div className={clsx('alertImage', styles.iconWrapper)}>
                        {renderLocationTypeImage()}
                        <div className={styles.iconContent}>
                            <svg className={styles.icon}>
                                <use width={'100%'} height={'100%'} href={`${alert.icon}#svg2`}/>
                            </svg>
                        </div>
                </div>
                <div className={'column gap8 spacer'} style={{overflowX: "hidden"}}>
                    <span className={'field'}>
                        <div className={'paragraph fieldLabel'}>
                            Start:
                        </div>
                        <div className={'paragraph fieldText'}>
                            {dayjs(alert.start_date).format('MMM D, h:mm A')}
                        </div>
                    </span>
                    <span className={'field'}>
                        <div className={'paragraph fieldLabel'}>
                            End:
                        </div>
                        <div className={'paragraph fieldText'}>
                            {alert.triggered ? "Ongoing" : dayjs(alert.end_date).format('MMM D, h:mm A')}
                        </div>
                    </span>
                    {alert.api_alert.report &&
                        <ShowReportButton reportLink={alert.api_alert.report}/>
                    }
                    <div className={'row'}>
                        <Chip
                            icon={<MapPinIcon size={'small'}/>}
                            label={alert.location}
                            size={'small'}
                        />
                        <Chip
                            label={alert.severity}
                            variant={theme.palette.weather.variant[alert.severity]}
                            size={'small'}
                        />
                    </div>
                </div>
            </div>
        </Box>
        </RenderIfVisible>

    )
}