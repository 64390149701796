import {Box} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import BellIcon from 'shared/assets/icons/Bell'
import MapPinIcon from 'shared/assets/icons/MapPin'
import SlidersIcon from 'shared/assets/icons/Sliders'
import UsersThreeIcon from 'shared/assets/icons/UsersThree'
import WeatherIcon from 'shared/assets/icons/Weather'
import ManageAlertConfigurationPanel
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel'
import {EditLocationScreen} from 'widgets/location'
import {useLocationsStore} from '../../../../app/store/LocationsStore'
import {useWeatherDefinitionsStore} from '../../../../app/store/WeatherDefinitionsStore'
import DashboardIcon from '../../../../shared/assets/icons/Dashboard'
import ActionItemsSettings from '../actionItems/ActionItemsSettings/ActionItemsSettings'
import AlertsSettings from '../alerts/AlertSettings/AlertsSettings'
import PreferencesSetting from '../preferences/PreferencesSetting/PreferencesSetting'
import SavedLocationsSetting from '../savedLocations/SavedLocationsSetting/SavedLocationsSetting'
import UserAndContactsSetting from '../usersAndContacts/UserAndContactsSetting'
import WeatherDefinitionsSettings from '../weatherDefinitions/WeatherDefinitionsSettings/WeatherDefinitionsSettings'
import EditWeatherDefinitionPage from '../../../../widgets/weatherDefinition/EditWeatherDefinitionPage/EditWeatherDefinitionPage'
import {useAlertConfigurationsStore} from '../../../../app/store/AlertConfigurationsStore'
import SettingsMenuButton from '../../../../shared/ui/SettingsMenuButton/SettingsMenuButton'
import {clsx} from 'clsx'
import styles from './SettingsPage.module.scss'

export const SETTINGS_SECTION = {
    weatherDefinition: '#weather-definitions',
    savedLocations: '#saved-locations',
    alerts: '#alerts',
    actionItems: '#action-items',
    usersAndContacts: '#users-and-contacts',
    preferences: '#preferences',
}

const getInitialSettingsSection = (location) => {
    const section = location.hash

    if (Object.values(SETTINGS_SECTION).includes(section)) {
        return section
    } else {
        return SETTINGS_SECTION.weatherDefinition
    }
}

export default function SettingsPage() {
    const {
        editingAlertConfiguration,
    } = useAlertConfigurationsStore((state) => state)

    const {
        editingLocation,
        toggleEditingLocation,
    } = useLocationsStore((state) => state)

    const {
        editingWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const [hideSettingsMenu, setHideSettingsMenu] = useState(false)
    const location = useLocation()
    const settingsSection = getInitialSettingsSection(location)

    useEffect(() => {
        setHideSettingsMenu(false)
    }, [location])

    const showSettingPage = () => !editingLocation && !editingAlertConfiguration && !editingWeatherDefinition

    const handleMenuButtonClick = (settingName) => {
        window.location.hash = settingName
    }

    const isLocationHasID = () => !!editingLocation.location.options.id

    return (
        <>
            {
                <Box
                    className={clsx(styles.wrapper, {
                        [styles.hidden]: !showSettingPage(),
                    })}
                >
                    <Box
                        data-cy={'settings-menu'}
                        className={clsx('gap8', styles.buttonsWrapper, {
                            [styles.hiddenButtons]: hideSettingsMenu,
                        })}
                    >
                        <SettingsMenuButton
                            data-cy={'settings-menu-weather-definitions'}
                            selected={settingsSection === SETTINGS_SECTION.weatherDefinition}
                            onClick={() => handleMenuButtonClick(SETTINGS_SECTION.weatherDefinition)}
                            startIcon={<WeatherIcon size={'small'}/>}
                        >
                            Weather definitions
                        </SettingsMenuButton>
                        <SettingsMenuButton
                            data-cy="settings-menu-locations"
                            selected={settingsSection === SETTINGS_SECTION.savedLocations}
                            onClick={() => handleMenuButtonClick(SETTINGS_SECTION.savedLocations)}
                            startIcon={<MapPinIcon size={'small'}/>}
                        >
                            Saved locations
                        </SettingsMenuButton>
                        <SettingsMenuButton
                            data-cy="settings-menu-alert-configs"
                            selected={settingsSection === SETTINGS_SECTION.alerts}
                            onClick={() => handleMenuButtonClick(SETTINGS_SECTION.alerts)}
                            startIcon={<BellIcon size={'small'}/>}
                        >
                            Alert configurations
                        </SettingsMenuButton>
                        <SettingsMenuButton
                            data-cy={'settings-menu-action-items'}
                            selected={settingsSection === SETTINGS_SECTION.actionItems}
                            onClick={() => handleMenuButtonClick(SETTINGS_SECTION.actionItems)}
                            startIcon={<DashboardIcon size={'small'}/>}
                        >
                            Action items
                        </SettingsMenuButton>
                        <SettingsMenuButton
                            data-cy="settings-menu-contacts"
                            selected={settingsSection === SETTINGS_SECTION.usersAndContacts}
                            onClick={() => handleMenuButtonClick(SETTINGS_SECTION.usersAndContacts)}
                            startIcon={<UsersThreeIcon size={'small'}/>}
                        >
                            Users and contacts
                        </SettingsMenuButton>
                        <SettingsMenuButton
                            data-cy="settings-menu-preferences"
                            selected={settingsSection === SETTINGS_SECTION.preferences}
                            onClick={() => handleMenuButtonClick(SETTINGS_SECTION.preferences)}
                            startIcon={<SlidersIcon size={'small'}/>}
                        >
                            Preferences
                        </SettingsMenuButton>
                    </Box>

                    <Box className={styles.contentWrapper}>
                        {settingsSection === SETTINGS_SECTION.weatherDefinition &&
                            <WeatherDefinitionsSettings
                                editAlertConfig={editingAlertConfiguration}
                            />
                        }
                        {settingsSection === SETTINGS_SECTION.savedLocations &&
                            <SavedLocationsSetting/>
                        }
                        {settingsSection === SETTINGS_SECTION.alerts && (
                            <AlertsSettings/>)}
                        {settingsSection === SETTINGS_SECTION.actionItems &&
                            <ActionItemsSettings/>
                        }
                        {settingsSection === SETTINGS_SECTION.usersAndContacts && (
                            <UserAndContactsSetting setShowAddContacts={setHideSettingsMenu}/>)}
                        {settingsSection === SETTINGS_SECTION.preferences && (<PreferencesSetting/>)}
                    </Box>
                </Box>}

            {editingWeatherDefinition && !editingAlertConfiguration &&
                <EditWeatherDefinitionPage/>
            }
            {
                editingAlertConfiguration &&
                <ManageAlertConfigurationPanel
                    args={editingAlertConfiguration}
                    fromSettings={true}
                />
            }
            {
                editingLocation &&
                <EditLocationScreen
                    sx={{width: '100%'}}
                    onClose={() => {
                        toggleEditingLocation(null)
                    }}
                    defaultValue={isLocationHasID() ? editingLocation.location : {}}
                />
            }
        </>
    )
}
