import React, {useEffect, useState} from 'react'
import {Box} from '@mui/system'
import {Snackbar, Alert} from '@mui/material'
import AddContactsForm from 'widgets/contact/AddContactsForm/AddContactsForm'
import {Button, IconButton} from '@mui/material'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import ImportCSVModal from '../../../shared/ui/ImportCSVModal/ImportCSVModal'
import {useContactsStore} from '../../../app/store/ContactsStore'
import AgreeModal from '../../../shared/ui/AgreeModal'
import ContactsConfirmationModal from '../../../shared/ui/ContactsConfirmationModal/ContactsConfirmationModal'
import styles from "./Contacts.module.scss"
import {clsx} from "clsx";

export default function Contacts({onHideContacts}) {
    const {
        contacts,
        groups,
        createContacts,
        deleteContactByID,
        addContactsIdsToSessionStorage,
    } = useContactsStore((state) => state)

    const [localContacts, setLocalContacts] = useState(contacts)
    const [localGroups] = useState(groups)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState('')
    const [snackbarSeverity, setSnackbarSeverity] = useState('error') // 'error' or 'success'
    const [validContactsCount, setValidContactsCount] = useState(0)
    const [isValid, setIsValid] = useState(false)
    const [isImporting, setIsImporting] = useState(false)
    const [showLeaveModal, setShowLeaveModal] = useState(false)
    const [showSuccessModal, setSuccessModal] = useState(false)

    const checkIsValidEmail = (emailAddress) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        return emailRegex.test(emailAddress) || emailAddress === ''
    }
    const checkIsValidPhoneNumber = (phoneNumber) => phoneNumber === 'Invalid'

    useEffect(() => {
        let count = 0
        localContacts.forEach((contact) => {
            if (checkIsValidEmail(contact.email) && !checkIsValidPhoneNumber(contact.phone) && contact.name.trim() !== '' && (contact.email || contact.phone)) {
                count++
            }
        })
        if (count !== validContactsCount) {
            setValidContactsCount(count)
        }

        setIsValid(validContactsCount === localContacts.length && contacts.length !== localContacts.length)
    }, [localContacts, validContactsCount])

    const openSnackbar = (message, severity = 'error') => {
        setSnackbarMessage(message)
        setSnackbarSeverity(severity)
        setSnackbarOpen(true)
    }

    const closeSnackbar = () => {
        setSnackbarOpen(false)
    }

    const handleServerError = (errorResponse) => {
        let errorMessage = 'An error occurred. Please try again.'
        if (errorResponse.errors) {
            const errorMessages = errorResponse.errors.map((error) => Object.values(error).join(', ')).join(' ')
            errorMessage = errorMessages
        }
        openSnackbar(errorMessage, 'error')
    }

    const saveContacts = async () => {
        const validContacts = localContacts.filter((contact) =>
            contact.name.trim() !== '' || contact.email.trim() !== '' || contact.phone.trim() !== '',
        )

        const contactsToDelete = contacts.filter((contactInSystem) =>
            !validContacts.some((validContact) => validContact.id === contactInSystem.id),
        )

        const contactsToDeleteIds = contactsToDelete.map((contact) => contact.id)
        if (contactsToDeleteIds.length > 0) {
            await deleteContactByID({data: {ids: contactsToDeleteIds}})
        }

        try {
            const response = await createContacts(validContacts)
            const newContacts = response.data.filter((contact) =>
                !contacts.some((existingContact) => existingContact.id === contact.id),
            )
            addContactsIdsToSessionStorage(newContacts)
            setSuccessModal(newContacts)
        } catch (error) {
            handleServerError(error.response.data)
        }
    }

    const importContactsActionHandler = async (file, groupName, onSuccess, onError) => {
        setIsImporting(true)
        if (!file) {
            console.log('Import cancelled or failed.')
            openSnackbar('Import cancelled or failed', 'error')
            setIsImporting(false)
            return
        }
        console.log('Processing file:', file.name, 'for group:', groupName)

        const formData = new FormData()
        formData.append('file', file)
        formData.append('group', groupName)

        try {
            const response = await createContacts(formData, true)
            addContactsIdsToSessionStorage(response.data)
            setSuccessModal(response.data)
            onSuccess(response.data)
        } catch (error) {
            onError(error.response ? error.response.data : {error: error.message})
        } finally {
            setIsImporting(false)
        }
    }
    const handleModalClose = (agreed) => {
        if (agreed) {
            onHideContacts()
        }
        setShowLeaveModal(false)
    }

    return (
        <Box className={clsx('settings', styles.wrapper)}>
            <Box className={clsx('settings-toolbar', styles.header)}>
                <IconButton
                    variant={'outlined'}
                    onClick={() => setShowLeaveModal(true)}
                >
                    <ArrowLeftSmallIcon size={'small'}/>
                </IconButton>
                <h3>
                    Add contacts
                </h3>
                <div style={{marginLeft: 'auto'}}>
                    <ImportCSVModal
                        actionHandler={importContactsActionHandler}
                        initialGroups={localGroups}
                        typeOfImportingFiles={'contacts'}
                        buttonTitle={'Import'}
                        modalTitle={'Import contacts'}
                        isImporting={isImporting}
                    />
                </div>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={saveContacts}
                    disabled={!isValid}
                >
                    Save contacts
                </Button>
            </Box>

            <Box className={styles.contentWrapper}>
                <Box sx={{overflow: 'auto', flexGrow: '1'}}>
                    <AddContactsForm
                        contacts={localContacts}
                        groups={localGroups}
                        onContactsChange={setLocalContacts}
                    />
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={closeSnackbar}
                    >
                        <Alert
                            onClose={closeSnackbar}
                            severity={snackbarSeverity}
                        >
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Box>
            {showLeaveModal && (
                <AgreeModal
                    data={{
                        agreeFunc: handleModalClose,
                        title: 'Discard contacts',
                        message: 'Are you sure you want discard your changes?',
                        agreeMsg: 'Discard',
                        disAgreeMsg: 'Go back',
                    }}
                />
            )}
            {showSuccessModal && <ContactsConfirmationModal
                contacts={showSuccessModal}
                agreeFunc={() => {
                    setSuccessModal(false)
                    onHideContacts()
                }}
            />}
        </Box>
    )
}
