import {Button, ToggleButton, ToggleButtonGroup} from '@mui/material'
import React from 'react'
import {Spacer} from 'shared/ui/Spacer'
import DashboardIcon from 'shared/assets/icons/Dashboard'
import {DateRangePicker} from 'shared/ui/datePickers/DateRangePicker'
import DownloadIcon from 'shared/assets/icons/Download'
import ListIcon from 'shared/assets/icons/List'
import PlusIcon from 'shared/assets/icons/Plus'
import {AlertConfigsFilter} from 'widgets/alertConfig'
import {LocationFilter} from 'widgets/location'
import {SeveritySelector} from '../../../../features/weatherDefinition/SeveritySelector/SeveritySelector'
import {api} from 'shared/libs/Auth'
import {saveAs} from 'file-saver'
import {useAlertConfigurationsStore} from '../../../../app/store/AlertConfigurationsStore'
import styles from './AlertsToolbar.module.scss'

export default function AlertsToolbar(
    {
        view,
        onChangeView,
        onChangeDateRange,
        severity,
        onChangeSeverity,
        selectedLocations,
        onChangeSelectedLocations,
        selectedConfigs,
        setSelectedConfigs,
    }) {
    const {
        toggleEditingAlertConfiguration,
    } = useAlertConfigurationsStore((state) => state)

    const handleDownloadAlertsCsv = () => {
        const data = {
            action: 'download',
            email: 'john_doe@gmail.com',
            locations: [1, 2, 3],
            alertConfigurations: [1],
            severity: 'Moderate',

        }

        api.post('download/alerts', data, {responseType: 'blob'})
            .then((response) => {
                const filename = response
                    .headers['content-disposition']
                    .split('filename=')[1]
                    .replace(';', '')
                saveAs(response.data, filename)
            })
    }

    return (

        <div className={'toolbar padding'}>
            <h3>
                Alerts
            </h3>
            <LocationFilter
                selectedLocations={selectedLocations}
                setSelectedLocations={onChangeSelectedLocations}
            />
            <AlertConfigsFilter
                anchor={'right'}
                selectedConfigs={selectedConfigs}
                setSelectedConfigs={setSelectedConfigs}
            />

            <SeveritySelector
                severity={severity}
                onChange={({target}) => onChangeSeverity(target.value)}
            />

            <DateRangePicker
                onChange={onChangeDateRange}
                label={'Date range:'}
                placeholder={''}
                fullWidth={false}
            />
            <ToggleButtonGroup
                exclusive
                value={view}
            >
                <ToggleButton
                    value={'Grid'}
                    onClick={() => onChangeView('Grid')}
                    variant={'secondary blue'}
                    sx={{padding: '8px'}}
                >
                    <DashboardIcon size={'small'}/>
                </ToggleButton>
                <ToggleButton
                    value={'Line'}
                    onClick={() => onChangeView('Line')}
                    variant={'secondary blue'}
                    sx={{padding: '8px'}}
                >
                    <ListIcon size={'small'}/>
                </ToggleButton>
            </ToggleButtonGroup>
            <Spacer/>
            <Button
                onClick={handleDownloadAlertsCsv}
                variant={'outlined'}
                color={'secondary'}
                startIcon={<DownloadIcon className={styles.csvButton}/>}
                disabled
            >
                CSV
            </Button>
            <Button
                data-cy={'new-alert-button'}
                startIcon={<PlusIcon/>}
                onClick={() => {
                    toggleEditingAlertConfiguration({})
                }}
            >
                New alert
            </Button>
        </div>
    )
}
