import React, {useEffect, useState} from 'react'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import {Spacer} from '../../../../../shared/ui/Spacer'
import {Button, Divider, IconButton, Radio, Typography} from '@mui/material'
import ArrowLeftSmallIcon from '../../../../../shared/assets/icons/ArrowLeftSmall'
import TextField from '@mui/material/TextField'
import PlusIcon from '../../../../../shared/assets/icons/Plus'
import PenIcon from '../../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../../shared/assets/icons/Trash'
import {SearchInput} from '../../../../../shared/ui/SearchInput'
import DefinitionPreviewImage from '../../../../../shared/assets/png/DefinitionPreview.png'
import {RenderGroup} from '../../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderPreview'
import ActionItemConfirmationModal from '../../../../../shared/ui/ActionItemConfirmationModal/ActionItemConfirmationModal'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import AgreeModal from '../../../../../shared/ui/AgreeModal'
import EditActionItemsCategory from '../EditActionItemsCategory/EditActionItemsCategory'
import EditWeatherDefinitionPage from '../../../../../widgets/weatherDefinition/EditWeatherDefinitionPage/EditWeatherDefinitionPage'
import styles from './EditActionItemPage.module.scss'
import {clsx} from 'clsx'

const urgencyOptions = [
    {label: 'Low', value: 'Low'},
    {label: 'Medium', value: 'Medium'},
    {label: 'High', value: 'High'},
]

const EditActionItemPage = ({
    onChangeActionItem = () => {
    },
}) => {
    const {
        categories,
        editingActionItem,
        isDuplicating,
        createActionItem,
        editActionItem,
        toggleEditingActionItem,
        openActionItem,
        toggleDuplication,
        deleteCategory,
        fetchCategories,
    } = useActionItemsStore((state) => state)

    const {
        weatherDefinitions,
        editingWeatherDefinition,
        fetchWeatherDefinitions,
        toggleEditingWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const [nameOfActionItem, setNameOfActionItem] = useState(isDuplicating ? editingActionItem.name + ' Copy' : editingActionItem.name || null)
    const [selectedUrgency, setSelectedUrgency] = useState(editingActionItem.urgency || null)
    const [selectedCategory, setSelectedCategory] = useState(editingActionItem.category || categories[0])
    const [selectedWeatherDefinition, setSelectedWeatherDefinition] = useState(editingActionItem.weather_definition || null)
    const [description, setDescription] = useState(editingActionItem.description || null)

    const [weatherDefinitionFilter, setWeatherDefinitionFilter] = useState(null)
    const [isOpenSearching, setIsOpenSearching] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [editingCategory, setEditingCategory] = useState(null)
    const [deletingCategory, setDeletingCategory] = useState(null)
    const [showLeaveModal, setShowLeaveModal] = useState(false)

    useEffect(() => {
        fetchWeatherDefinitions()
        fetchCategories()
    }, [])

    const handleChangeName = (event) => {
        setNameOfActionItem(event.target.value)
    }

    const handleChangeCategory = (category) => {
        setSelectedCategory(category)
    }

    const handleChangeUrgency = (urgency) => {
        setSelectedUrgency(urgency)
    }

    const handleChangeSelectedWeatherDefinition = (definition) => {
        setSelectedWeatherDefinition(definition)
        setIsOpenSearching(false)
        setWeatherDefinitionFilter(definition.name)
    }

    const handleChangeDescription = (event) => {
        setDescription(event.target.value)
    }

    const handleWeatherDefinitionFilter = (event) => {
        setWeatherDefinitionFilter(event.target.value)
    }

    const handleEditCategory = (event, category) => {
        event.stopPropagation()
        setEditingCategory(category)
    }

    const handleDeleteCategory = (event, category) => {
        event.stopPropagation()
        setDeletingCategory(category)
    }

    const handleSaveActionItem = () => {
        const actionItem = {
            user: editingActionItem.user,
            id: isDuplicating ? null : editingActionItem.id,
            name: nameOfActionItem,
            category: selectedCategory.id,
            urgency: selectedUrgency,
            weather_definition: selectedWeatherDefinition.id,
            description: description,
        }
        actionItem.id ? editActionItem(actionItem) : createActionItem(actionItem).then((id) => onChangeActionItem(id))
        setShowSuccessModal(true)
    }

    const handleClose = () => {
        setShowSuccessModal(false)
        toggleDuplication(false)
        toggleEditingActionItem(null)
    }

    const goToSettingsFunc = () => {
        openActionItem(null)
        window.location.href = '/settings#action-items'
        handleClose()
    }

    const renderRules = (rules) => rules.map((rule, index) =>
        (<RenderGroup
            group={{
                ...rule, isFirst: !index,
            }}
        />),
    )

    const handleModalClose = (agreed) => {
        if (agreed) {
            handleClose()
        }
        setShowLeaveModal(false)
    }

    const handleNewWeatherDefinition = () => {
        toggleEditingWeatherDefinition({})
    }

    const approveDeleteCategory = (categoryId, agree) => {
        if (agree) {
            deleteCategory(categoryId).then(() => {
                if (selectedCategory?.id === categoryId) {
                    handleChangeCategory(categories[0])
                }
            })
        }
        setDeletingCategory(null)
    }

    return (
        <>
            <div className={'fullHeight fullWidth'}>
                <div
                    className={'settings'}
                    style={{height: '100%'}}
                >
                    <div className={clsx('settings-toolbar', styles.toolbarBorder)}>
                        <IconButton
                            onClick={() => setShowLeaveModal(true)}
                            data-cy={'edit-action-item-left-arrow-button'}
                            variant={'outlined'}
                            size={'small'}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <h3>
                            {editingActionItem.id
                                ? (isDuplicating ? 'Copy' : 'Edit')
                                : 'New'}
                            {' '}
                            action item
                        </h3>
                        <Spacer/>
                        <Button
                            disabled={!nameOfActionItem || !selectedUrgency || !selectedCategory || !selectedWeatherDefinition || !description}
                            data-cy={'save-ai'}
                            onClick={handleSaveActionItem}
                        >
                            Save action item
                        </Button>
                    </div>
                    <div className={styles.wrapper}>
                        <div className={'actionItemsContentWrapper'}>
                            <div className={styles.sectionWrapper}>
                                <Typography className={styles.section}>
                                    Name of action item
                                </Typography>
                                <TextField
                                    style={{width: '640px'}}
                                    data-cy={'ai-creating-input-name'}
                                    placeholder={'Enter action item title'}
                                    value={nameOfActionItem}
                                    onChange={handleChangeName}
                                />
                            </div>
                            <div
                                className={styles.sectionWrapper}
                                style={{paddingTop: '16px'}}
                            >
                                <Typography className={styles.section}>
                                    Category
                                </Typography>
                                <div className={styles.content}>
                                    {categories?.map((category, index) => (
                                        <div
                                            className={styles.categoryContent}
                                            key={index}
                                            onClick={() => handleChangeCategory(category)}
                                        >
                                            <Radio
                                                value={index}
                                                data-cy={'ai-creating-category'}
                                                checked={selectedCategory?.id === category.id}
                                            />
                                            <span
                                                className={styles.categoryNameWrapper}
                                                data-cy={'ai-category-name'}
                                            >
                                                <svg className={'customSvg'}>
                                                    <use
                                                        height="18"
                                                        width="18"
                                                        href={category.icon.icon + '#svg2'}
                                                    />
                                                </svg>
                                                {category.name}
                                            </span>
                                            <Spacer/>
                                            <IconButton
                                                id="basic-button"
                                                variant={'outlined'}
                                                className={styles.iconButton}
                                                data-cy={'ai-edit-category-button'}
                                                onClick={(event) => handleEditCategory(event, category)}
                                            >
                                                <PenIcon size={'small'}/>
                                            </IconButton>
                                            <IconButton
                                                id="basic-button"
                                                variant={'contained'}
                                                className={styles.iconButton}
                                                color={'error'}
                                                data-cy={'ai-delete-category-button'}
                                                disabled={categories.length <= 1}
                                                onClick={(event) => handleDeleteCategory(event, category)}
                                            >
                                                <TrashIcon size={'small'}/>
                                            </IconButton>
                                        </div>))}
                                </div>
                            </div>
                            <Divider/>
                            <Button
                                className={styles.AddButton}
                                startIcon={<PlusIcon size={'small'}/>}
                                variant={'text'}
                                color={'primary'}
                                size={'small'}
                                data-cy={'new-category-button'}
                                onClick={() => setEditingCategory({})}
                            >
                                New category
                            </Button>
                        </div>
                        <div className={'actionItemsContentWrapper'}>
                            <div className={'newActionItemTitlesWrapper'}>
                                <Typography className={'newActionItemTitles'}>
                                    Urgency level
                                </Typography>
                                <div style={{display: 'flex', gap: '40px'}}>
                                    {urgencyOptions.map((option) => (
                                        <div
                                            key={option.value}
                                            className="actionItemsRadio"
                                            onClick={() => handleChangeUrgency(option.value)}
                                        >
                                            <Radio
                                                value={option.value}
                                                data-cy={'ai-creating-urgency'}
                                                checked={selectedUrgency === option.value}
                                            />
                                            <Typography className="actionItemsRadioTitle">
                                                {option.label}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className={'actionItemsContentWrapper'}>
                            <div className={'newActionItemTitlesWrapper'}>
                                <div className={styles.wdSectionWrapper}>
                                    <div className={styles.wdSection}>
                                        <Typography className={'newActionItemTitles'}>
                                            Weather definition
                                        </Typography>
                                        <SearchInput
                                            defaultValue={selectedWeatherDefinition?.name}
                                            value={weatherDefinitionFilter}
                                            data-cy={'search-products-input'}
                                            style={{width: '100%'}}
                                            placeholder='Search for weather definitions'
                                            onChange={handleWeatherDefinitionFilter}
                                            onClick={() => setIsOpenSearching(!isOpenSearching)}
                                        />
                                        <div
                                            className={clsx(styles.productsList, {
                                                productsVisible: isOpenSearching,
                                                productsInvisible: !isOpenSearching,
                                                [styles.isOpenSearching]: isOpenSearching,
                                            })}
                                        >
                                            <Typography className={styles.listTitle}>
                                                {' '}
                                                Select an option
                                                {' '}
                                            </Typography>

                                            {weatherDefinitions && weatherDefinitions.map((wd) => {
                                                {
                                                    if (wd.name.toLocaleLowerCase().indexOf(weatherDefinitionFilter) >= 0 || !weatherDefinitionFilter) {
                                                        return (
                                                            <div
                                                                key={wd.id}
                                                                className={styles.wdItem}
                                                                onClick={() => handleChangeSelectedWeatherDefinition(wd)}
                                                            >
                                                                {wd.name}
                                                            </div>
                                                        )
                                                    }
                                                }
                                                return null
                                            })}
                                        </div>
                                    </div>
                                    {selectedWeatherDefinition && selectedWeatherDefinition.rules && !selectedWeatherDefinition.rules.category && // check for fixed weather definition
                                        <div className={styles.wdSection}>
                                            <Typography className={'newActionItemTitles'}>
                                                Parameters
                                            </Typography>

                                            <div
                                                className={'definitionPreview'}
                                                style={{backgroundImage: `url(${DefinitionPreviewImage})`}}
                                            >
                                                <div
                                                    className={'definitionGrid'}
                                                    style={{
                                                        minHeight: '153px',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    {selectedWeatherDefinition.groups && renderRules(selectedWeatherDefinition.groups)}
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <Divider/>
                                <Button
                                    className={styles.AddButton}
                                    startIcon={<PlusIcon size={'small'}/>}
                                    variant={'text'}
                                    color={'primary'}
                                    size={'small'}
                                    onClick={handleNewWeatherDefinition}
                                >
                                    New weather definition
                                </Button>
                            </div>
                        </div>
                        <div className={'actionItemsContentWrapper'}>
                            <div className={'newActionItemTitlesWrapper'}>
                                <Typography className={'newActionItemTitles'}>
                                    Description of action item
                                </Typography>
                                <TextField
                                    style={{width: '640px'}}
                                    placeholder={'Enter action item description'}
                                    multiline
                                    inputProps={{maxLength: 1000}}
                                    data-cy={'ai-creating-input-description'}
                                    minRows={2}
                                    maxRows={5}
                                    value={description}
                                    onChange={handleChangeDescription}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {editingWeatherDefinition &&
                <EditWeatherDefinitionPage/>
            }
            {editingCategory &&
                <EditActionItemsCategory
                    defaultValue={editingCategory}
                    onClose={() => setEditingCategory(null)}
                    onSave={(category) => handleChangeCategory(category)}
                />
            }
            {showSuccessModal && (
                <ActionItemConfirmationModal
                    name={nameOfActionItem}
                    urgency={selectedUrgency}
                    agreeFunc={handleClose}
                    goToSettingsFunc={goToSettingsFunc}
                />
            )}
            {deletingCategory &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want to ${deletingCategory.name} category?`,
                        title: 'Delete category',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: (agree) => approveDeleteCategory(deletingCategory.id, agree),
                    }}
                />
            }
            {showLeaveModal && (
                <AgreeModal
                    data={{
                        agreeFunc: handleModalClose,
                        title: 'Discard action items',
                        message: 'Are you sure you want discard your changes?',
                        agreeMsg: 'Discard',
                        disAgreeMsg: 'Go back',
                    }}
                />
            )}
        </>
    )
}

export default EditActionItemPage
