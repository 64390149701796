import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import React, {useEffect, useState} from 'react'
import {Box, Button, IconButton, Typography} from '@mui/material'
import {ChipSeverity} from 'shared/ui/SpanSeverity'
import {useUserStore} from '../../../../app/store/UserStore'
import {useWeatherDefinitionsStore} from '../../../../app/store/WeatherDefinitionsStore'
import CopyIcon from '../../../../shared/assets/icons/Copy'
import PenIcon from '../../../../shared/assets/icons/Pen'
import TrashIcon from '../../../../shared/assets/icons/Trash'
import {DefinitionRenderCompact} from '../../../../widgets/weatherDefinition/definitionRender/DefinitionRenderCompact/DefinitionRenderCompact'
import {fetchConfigurationGroup} from '../../../../pages/alerts/api/FetchAlerts'
import MapPinIcon from '../../../../shared/assets/icons/MapPin'
import PausedChip from '../../../../widgets/alertConfig/ui/PausedChip/PausedChip'
import {useContactsStore} from '../../../../app/store/ContactsStore'
import {useActionItemsStore} from '../../../../app/store/ActionItemsStore'
import ActionItemDetailsParameters from '../../../../pages/settings/ui/actionItems/ActionItemsDetailsParameters/ActionItemsDetailsParameters'
import {useAlertConfigurationsStore} from '../../../../app/store/AlertConfigurationsStore'
import AgreeModal from '../../../../shared/ui/AgreeModal'
import styles from './AlertConfigurationDetails.module.scss'
import {clsx} from 'clsx'

const AlertConfigurationDetails = ({alert, extendedAlertConfig}) => {
    const {
        deleteAlertConfigurationByID,
        toggleEditingAlertConfiguration,
        openAlertConfig,
    } = useAlertConfigurationsStore((state) => state)

    const {user, fetchUser} = useUserStore((state) => state)

    const {
        contacts,
        fetchContacts,
    } = useContactsStore((state) => state)

    const {
        fetchWeatherDefinition,
    } = useWeatherDefinitionsStore((state) => state)

    const {
        fetchActionItems,
    } = useActionItemsStore((state) => state)

    const [definition, setDefinition] = useState(null)
    const [actionItems, setActionItems] = useState(null)
    const [deleteAlertConfig, setDeleteAlertConfig] = useState(false)
    const [reportIncluded, setReportIncluded] = useState(false)
    const [isEnable] = useState(extendedAlertConfig[0].enabled)
    const hasActionItems = actionItems && (Array.isArray(actionItems) ? actionItems.length > 0 : true)

    const isAlertHaveActionItems = () => !!(extendedAlertConfig[0]?.notification?.report_settings?.action_items?.length)

    useEffect(() => {
        fetchWeatherDefinition(alert.definition_id).then((data) => {
            setDefinition(data)
        })
        if (isAlertHaveActionItems()) {
            fetchActionItems(extendedAlertConfig[0].notification.report_settings?.action_items).then((data) => {
                setActionItems(data)
            })
        }
        fetchContacts()
        fetchUser()
    }, [])

    useEffect(() => {
        if (alert.groupId) {
            fetchConfigurationGroup(alert.groupId).then((data) => {
                if (data && data.notification.report) {
                    setReportIncluded(data.notification.report)
                }
            })
        }
    }, [alert.groupId])

    const getDays = () => {
        if (!extendedAlertConfig[0]?.notification?.days_checked) return null

        const days = extendedAlertConfig[0]?.notification?.days
            ? Object.entries(extendedAlertConfig[0].notification.days)
                .filter(([day, isActive]) => isActive)
                .map(([day]) => day)
            : []

        return days.length > 0 ? days.join(', ') : null
    }

    const getNotificationContacts = (type = 'email' || 'phone') => contacts?.filter((contact) => {
        const config = extendedAlertConfig?.[0]?.notification?.contacts?.[contact?.id]
        return config && config[type] === true
    })
        .map((contact) => renderNotificationContacts(contact.name))
        .concat(extendedAlertConfig[0].notification[`allow_self_notification_${type === 'phone' ? 'sms' : 'email'}`] ? renderNotificationContacts(user.username) : [])

    const renderNotificationContacts = (name) => (
        <div className={styles.contactWrapper}>
            {name}
        </div>
    )

    const approveDeleteAlertConfiguration = (agree) => {
        if (agree) {
            deleteAlertConfigurationByID(alert.groupId)
        }
        setDeleteAlertConfig(null)
    }

    const getCustomMessages = () => {
        const messagesConfig = [
            {key: 'fc_appears_checked', message: 'Forecast appears'},
            {key: 'fc_updates_checked', message: 'Forecast updates'},
            {key: 'we_starts_checked', message: 'Weather events starts'},
            {key: 'custom_time_checked', message: 'Custom time'},
        ]

        const customMessages = messagesConfig
            .filter(({key}) => extendedAlertConfig[0].notification[key])
            .map(({message}) => message)

        return customMessages.length > 0 ? customMessages.join(', ') : null
    }

    const handleEdit = () =>{
        const locations = {}
        alert.locations.forEach((loc) => {
            locations[loc.id] = true
        })
        toggleEditingAlertConfiguration({
            back: true,
            locations: locations,
            definition: alert.definition_id,
            id: alert.groupId,
            report: alert.report,
        })
    }

    const handleCopy = () =>{
        const locations = {}
        alert.locations.forEach((loc) => {
            locations[loc.id] = true
        })
        toggleEditingAlertConfiguration({
            back: true,
            locations: locations,
            definition: alert.definition_id,
            report: alert.report,
        })
    }

    if (!definition) return (<></>)

    const locations = []
    alert.locations.forEach((location) => {
        locations.push(
            <Box className={styles.locationChipWrapper}>
                <Box>
                    {<>
                        <span className={styles.locationChip}>
                            <span style={{whiteSpace: 'nowrap'}}>
                                <MapPinIcon className={styles.chipIcon}/>
                                {location.label}
                            </span>
                        </span>
                        {' '}
                    </>
                    }
                </Box>
            </Box>,
        )
    })

    return (
        <>
            <Box className={'fullHeight fullWidth settings'}>
                <Box className={clsx('AlertConfigurationDetails', styles.detailsHeader)}>
                    <Box className={'settings-toolbar'}>
                        <IconButton
                            onClick={()=>openAlertConfig(null)}
                            variant={'outlined'}
                            size={'small'}
                        >
                            <ArrowLeftSmallIcon size={'small'}/>
                        </IconButton>
                        <Box className={styles.title}>
                            {alert.type}
                        </Box>
                        <ChipSeverity
                            severity={alert.severity}
                            size="medium"
                        />
                        <PausedChip isEnable={isEnable}/>
                        <Box sx={{flexGrow: 1}}/>
                        <Box sx={{display: 'flex', gap: '10px'}}>
                            {
                                <Button
                                    variant={'outlined'}
                                    color={'secondary'}
                                    startIcon={<CopyIcon/>}
                                    onClick={handleCopy}
                                >
                                    Copy
                                </Button>
                            }
                            {<>
                                <Button
                                    variant={'outlined'}
                                    startIcon={<PenIcon/>}
                                    onClick={handleEdit}
                                >
                                    Edit
                                </Button>
                                <Button
                                    variant={'outlined'}
                                    color={'error'}
                                    startIcon={<TrashIcon/>}
                                    onClick={() => {
                                        setDeleteAlertConfig(alert)
                                    }}
                                >
                                    Delete
                                </Button>
                            </>}
                        </Box>
                    </Box>
                </Box>
                <Box className={styles.detailsWrapper}>
                    <Box className={styles.content}>
                        <Typography className={styles.bigTitle}>
                            Parameters
                        </Typography>
                        <Box className={clsx('paragraph medium', styles.sectionWrapper, styles.locationsWrapper)}>
                            <div
                                className={styles.smallTitle}
                                style={{marginRight: '16px'}}
                            >
                                LOCATIONS:
                            </div>
                            {locations}
                        </Box>
                        <Box className={clsx('paragraph medium', styles.sectionWrapper, styles.wdWrapper)}>
                            <div className={clsx('row', styles.wdContent)}>
                                <div className={styles.smallTitle}>
                                    WEATHER DEFINITION:
                                </div>
                                {definition.name}
                                <ChipSeverity
                                    severity={alert.severity}
                                    size="small"
                                />
                            </div>
                            <DefinitionRenderCompact definition={definition}/>
                        </Box>
                        <Box className={clsx('paragraph medium', styles.sectionWrapper, styles.contactsWrapper)}>
                            <div className={styles.contactContent}>
                                <div className={'row'}>
                                    <div className={styles.smallTitle}>
                                        WHEN:
                                    </div>
                                    <div className={styles.middleTitle}>
                                        {getCustomMessages()}
                                    </div>
                                </div>
                                {getDays() &&
                                    <div className={'row'}>
                                        <div className={styles.smallTitle}>
                                            ON:
                                        </div>
                                        <div className={styles.middleTitle}>
                                            {getDays()}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={styles.contactContent}>
                                <div className={'row'}>
                                    <div className={styles.smallTitle}>
                                        EMAIL:
                                    </div>
                                    <div className={styles.notificationChip}>
                                        {getNotificationContacts('email')}
                                    </div>
                                </div>
                                <div className={'row'}>
                                    <div className={styles.smallTitle}>
                                        SEND SMS:
                                    </div>
                                    <div className={styles.notificationChip}>
                                        {getNotificationContacts('phone')}
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <Typography className={styles.bigTitle}>
                            Reports
                        </Typography>
                        <Box className={clsx('paragraph medium', styles.sectionWrapper, styles.reportWrapper)}>
                            Report included?
                            {' '}
                            {reportIncluded ? 'Yes' : 'No'}
                        </Box>
                    </Box>
                    {hasActionItems
                        ? <>
                            <Box className={styles.actionItemsWrapper}>
                                <Typography className={styles.bigTitle}>
                                    Action Items
                                </Typography>
                                <ActionItemDetailsParameters
                                    actionItems={actionItems}
                                />

                            </Box>
                        </> : null}
                </Box>
            </Box>
            {
                deleteAlertConfig &&
                <AgreeModal
                    data={{
                        message: `Deleting this alert will remove it permanently from the system, and delete all previously triggered instances of the alert as well. 
                        Are you sure you want to delete "${deleteAlertConfig.type}" alert?`,
                        title: 'Delete alert configuration',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: approveDeleteAlertConfiguration,
                    }}
                />
            }
        </>
    )
}

export default AlertConfigurationDetails
