import React from 'react'
import BellSlashIcon from '../../../../shared/assets/icons/BellSlashIcon'
import styles from './PausedChip.module.scss'

const PausedChip = ({isEnable = false}) => (
    <div>
        {isEnable ? <></> : <div className={styles.wrapper}>
            <BellSlashIcon size={'small'}/>
                Paused
        </div>}
    </div>
)

export default PausedChip
