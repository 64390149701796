import React from 'react'

export function ErrorMessages({error, errorData}) {
    const renderHTML = (htmlString) => (
        <div
            dangerouslySetInnerHTML={{__html: htmlString}}
            className="paragraph"
            style={{color: 'var(--palette-error-main)'}}
        />
    )

    if (error && errorData) {
        if (typeof errorData === 'string') {
            // Check if errorData is HTML
            const isHTML = errorData.startsWith('<!DOCTYPE') || /<\/?[a-z][\s\S]*>/i.test(errorData)
            return isHTML ? renderHTML(errorData)
                : <div
                    className={'paragraph'}
                    style={{color: 'var(--palette-error-main)'}}
                  >
                    <p>
                        {errorData}
                    </p>
                </div>
        }

        return (
            <div
                className={'paragraph'}
                style={{color: 'var(--palette-error-main)'}}
            >
                {
                    Object.entries(errorData).map(([key, errorObject]) => (
                        Array.isArray(errorObject)
                            ? (errorObject.map((message) => (
                                <p key={key}>
                                    {message}
                                </p>)))
                            : (<p key={key}>
                                {errorObject}
                            </p>)
                    ))}
            </div>
        )
    }
}
