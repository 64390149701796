import {Box, MenuItem, FormControlLabel} from '@mui/material'
import {CheckBox} from 'shared/ui/CheckBox'
import TrashIcon from 'shared/assets/icons/Trash'
import 'shared/ui/ItemSelectTopBar/ItemsSelectTopBar.css'
import {Spacer} from 'shared/ui/Spacer'
import ArrowRightIcon from 'shared/assets/icons/ArrowRight'
import PenIcon from '../../assets/icons/Pen'
import {useActionItemsStore} from '../../../app/store/ActionItemsStore'
import {useContactsStore} from '../../../app/store/ContactsStore'
import {useLocationsStore} from '../../../app/store/LocationsStore'
import {useAlertConfigurationsStore} from '../../../app/store/AlertConfigurationsStore'
import {useWeatherDefinitionsStore} from '../../../app/store/WeatherDefinitionsStore'

export default function ItemsSelectTopBar({onAction = () => {}, visible, allSelected, indeterminate, type = undefined}) {
    const {selectAllWeatherDefinitions} = useWeatherDefinitionsStore((state) => state)
    const {selectAllLocations} = useLocationsStore((state) => state)
    const {selectAllAlertConfigurations} = useAlertConfigurationsStore((state) => state)
    const {selectAllActionItems} = useActionItemsStore((state) => state)
    const {selectAllContacts} = useContactsStore((state) => state)

    const handleToggle = () => {
        selectAllWeatherDefinitions()
        selectAllLocations()
        selectAllAlertConfigurations()
        selectAllActionItems()
        selectAllContacts()
    }

    const handleMove = () => {
        onAction(undefined, 'openMovePopup')
    }

    const handleChangeUrgency = () => {
        onAction(undefined, 'mass_change_urgency')
    }

    return (
        <Box className={visible ? 'row itemsDeleteVisible' : 'row itemsDeleteInvisible'}>
            <FormControlLabel
                control={
                    <CheckBox
                        checked={allSelected}
                        data-cy={'mass-delete-select-all'}
                        onChange={(ev) => {
                            if (ev) ev.stopPropagation(); handleToggle()
                        }}
                        indeterminate={indeterminate}
                    />
                }
                label="Select all"
            />

            <Spacer />

            {type === 'usercontacts' &&
                <MenuItem
                    key={'move to group'}
                    onClick={handleMove}
                >
                    <ArrowRightIcon size={'small'}/>
                    Move to group...
                </MenuItem>
            }

            {type === 'actionitems' &&
                <MenuItem
                    key={'move to group'}
                    data-cy={'mass-change-urgency'}
                    onClick={handleChangeUrgency}
                >
                    <PenIcon size={'small'}/>
                    Change urgency
                </MenuItem>
            }

            <MenuItem
                key={'delete'}
                className={'regular'}
                variant={'outlined'}
                color={'error'}
                data-cy={'mass-delete-button'}
                onClick={(ev) => {
                    if (ev) ev.stopPropagation()
                    onAction(undefined, 'delete')
                }}
            >
                <TrashIcon size={'small'}/>
                Delete
            </MenuItem>
        </Box>
    )
}
