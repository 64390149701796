import React, {useState} from 'react'
import {Autocomplete, Button, IconButton, TextField} from '@mui/material'
import ModalComponent from '../../../../../shared/ui/ModalComponent'
import CloseIcon from '../../../../../shared/assets/icons/Close'
import {useActionItemsStore} from '../../../../../app/store/ActionItemsStore'
import styles from "./ChangeUrgencyModal.module.scss"

const allUrgency = [
    {label: 'Low', value: 'Low'},
    {label: 'Medium', value: 'Medium'},
    {label: 'High', value: 'High'}]

const ChangeUrgencyModal = ({visible, onChange}) => {
    const {selectedActionItems, editActionItem, clearSelectedActionItems} = useActionItemsStore((state) => state)

    const [chosenUrgency, setChosenUrgency] = useState('Low')

    const handleChangeUrgency = async () => {
        try {
            const promises = selectedActionItems.map(async (ai) => {
                const changedActionItem = {
                    ...ai,
                    urgency: chosenUrgency,
                    category: ai.category.id,
                    weather_definition: ai.weather_definition.id,
                }
                return await editActionItem(changedActionItem)
            })
            await Promise.all(promises)
        } catch (error) {
            console.error('Error during urgency update:', error)
        } finally {
            clearSelectedActionItems()
            onChange()
        }
    }

    return (
        <ModalComponent visible={visible}>
            <div className={styles.wrapper}>
                <div className={styles.header}>
                    <h3>
Change urgency
                    </h3>
                    <IconButton
                        variant={'outlined'}
                        size={'small'}
                        className={styles.iconWrapper}
                        onClick={onChange}
                    >
                        <CloseIcon className={styles.icon}/>
                    </IconButton>
                </div>
                <div className={styles.title}>
Select the new urgency level for
                    selected action
                    items
                </div>
                <div className={styles.contentWrapper}>
                    <div className={styles.content}>
                        Urgency
                    </div>
                    <Autocomplete
                        options={allUrgency}
                        style={{width: '100%'}}
                        data-cy={'mass-change-urgency-selector'}
                        value={allUrgency.find((option) => option.value === chosenUrgency)}
                        onChange={(event, newValue) => {
                            setChosenUrgency(newValue.value)
                        }}
                        renderInput={(params) => <TextField {...params}/>}
                    />
                </div>

                <div className={styles.buttonsWrapper}>
                    <Button
                        className={styles.button}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={onChange}
                    >
                        Go back
                    </Button>
                    <Button
                        className={styles.button}
                        data-cy={'mass-change-urgency-agree-button'}
                        onClick={handleChangeUrgency}
                    >
                        Change urgency
                    </Button>
                </div>
            </div>
        </ModalComponent>
    )
}

export default ChangeUrgencyModal
