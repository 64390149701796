import React from 'react'
import {MenuItem} from '@mui/material'
import {Select} from '../../../../../shared/ui/Select/Select'
import {clsx} from 'clsx'
import styles from './UrgencySelector.module.scss'

const UrgencySelector = ({urgency, onChange}) => (
    <Select
        placeholder={''}
        data-cy={'urgency-filter'}
        defaultValue={urgency}
        onChange={onChange}
        InputProps={{
            startAdornment: <div className={clsx('paragraph', styles.input)}>
                Urgency:
            </div>,
        }}
    >
        <MenuItem
            value={'All'}
            data-cy={'urgency-filter-all'}
        >
All
        </MenuItem>
        <MenuItem
            value={'Low'}
            data-cy={'urgency-filter-low'}
        >
Low
        </MenuItem>
        <MenuItem
            value={'Medium'}
            data-cy={'urgency-filter-medium'}
        >
Medium
        </MenuItem>
        <MenuItem
            value={'High'}
            data-cy={'urgency-filter-high'}
        >
High
        </MenuItem>
    </Select>
)

export default UrgencySelector
