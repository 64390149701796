import React, {useState, useEffect} from 'react'
import {
    Box,
    Button,
    Typography,
    Accordion,
    FormControlLabel,
} from '@mui/material'
import {CheckBox} from 'shared/ui/CheckBox'
import RecipientLine from 'entities/contact/ui/RecipientLine'
import Contacts from 'widgets/contact/Contacts/Contacts'
import {SearchInput} from 'shared/ui/SearchInput'
import PlusIcon from 'shared/assets/icons/Plus'
import AccordionSummaryUserGroup from 'shared/ui/Accordion/AccordionSummaryUserGroup'
import AccordionDetailsUserGroup from 'shared/ui/Accordion/AccordionDetailsUserGroup'
import {useContactsStore} from '../../../../app/store/ContactsStore'
import {useUserStore} from '../../../../app/store/UserStore'
import styles from "./RecipientList.module.scss"
import {clsx} from "clsx";

export default function RecipientList({contactsData = {}, onContactsDataChange, selfNotifyData = {}, onSelfNotify, showSidebar, setShowSidebar}) {
    const {
        contacts,
        groups,
        fetchContacts,
    } = useContactsStore((state) => state)
    const {
        user,
        fetchUser,
    } = useUserStore((state)=>state)

    const [searchQuery, setSearchQuery] = useState('')
    const [showContacts, setShowContacts] = useState(false)

    const [allowSelfNotificationEmail, setAllowSelfNotificationEmail] = useState(selfNotifyData.allow_self_notification_email !== undefined ? selfNotifyData.allow_self_notification_email : true)
    const [allowSelfNotificationSms, setAllowSelfNotificationSms] = useState(selfNotifyData.allow_self_notification_sms !== undefined ? selfNotifyData.allow_self_notification_sms : true)

    useEffect(() => {
        onSelfNotify({allow_self_notification_email: allowSelfNotificationEmail, allow_self_notification_sms: allowSelfNotificationSms})
    }, [allowSelfNotificationEmail, allowSelfNotificationSms])

    useEffect(() => {
        fetchContacts()
        fetchUser()
    }, [])

    const calculateGroupCheckboxState = (groupName, type, contacts, contactsData) => {
        const groupContacts = contacts.filter((contact) => contact.group === groupName)
        const activeGroupContacts = groupContacts.filter((contact) => (type === 'email' ? contact.email : contact.phone))
        if (activeGroupContacts.length === 0) {
            return {checked: false, indeterminate: false, disabled: true}
        }

        const areAllChecked = activeGroupContacts.every((contact) => contactsData[contact.id] && contactsData[contact.id][type])
        const areSomeChecked = activeGroupContacts.some((contact) => contactsData[contact.id] && contactsData[contact.id][type])

        return {
            checked: areAllChecked,
            indeterminate: areSomeChecked && !areAllChecked,
            disabled: false,
        }
    }

    const handleGroupCheckboxClick = (e, groupName, type) => {
        e.stopPropagation()
        // Toggling the group checkbox state
        const isGroupChecked = e.target.checked

        let updatedContactsData = {
            ...contactsData,
        }
        contacts.forEach((contact) => {
            if (contact.group === groupName) {
                const id = contact.id
                const updatedValue = isGroupChecked ? (type === 'email' ? !!contact.email : !!contact.phone) : false
                updatedContactsData = { // new contact data calculation
                    ...updatedContactsData,
                    [id]: {
                        ...updatedContactsData[id], // current condition of the contact
                        [type]: updatedValue,
                    },
                }
            }
        })
        onContactsDataChange(updatedContactsData)
    }

    const calculateGlobalCheckboxState = (type) => {
        const activeContacts = contacts.filter((contact) => (type === 'email' ? contact.email : contact.phone))
        if (activeContacts.length === 0 && !user) {
            return {checked: false, indeterminate: false, disabled: true}
        }

        const areAllChecked = activeContacts.every((contact) => Boolean(contactsData[contact.id]?.[type])) && (type === 'email' ? allowSelfNotificationEmail : allowSelfNotificationSms)
        const areSomeChecked = activeContacts.some((contact) => Boolean(contactsData[contact.id]?.[type])) || (type === 'email' ? allowSelfNotificationEmail : allowSelfNotificationSms)

        return {
            checked: areAllChecked,
            indeterminate: areSomeChecked && !areAllChecked,
            disabled: false,
        }
    }

    const handleGlobalCheckboxClick = (type) => {
        const isAllChecked = calculateGlobalCheckboxState(type).checked
        const newContactsData = {...contactsData}

        contacts.forEach((contact) => {
            const contactFieldExists = type === 'email' ? !!contact.email : !!contact.phone
            if (contactFieldExists) {
                newContactsData[contact.id] = {...newContactsData[contact.id], [type]: !isAllChecked}
            }
        })

        handleUserCheckedChange(!isAllChecked, type === 'email' ? 'email' : 'phone')
        onContactsDataChange(newContactsData)
    }

    const handleNotificationCheckedChange = (contact, isChecked, type) => {
        const id = contact.id
        const updatedValue = isChecked ? (type === 'email' ? !!contact.email : !!contact.phone) : false

        // new contact data calculation
        const updatedContactsData = {
            ...contactsData, // current condition
            [id]: {
                ...contactsData[id], // current condition of the contact
                [type]: updatedValue,
            },
        }

        onContactsDataChange(updatedContactsData)
    }

    const handleUserCheckedChange = (isChecked, type) => {
        if (type === 'email') {
            setAllowSelfNotificationEmail(isChecked)
            onSelfNotify({allow_self_notification_email: isChecked, allow_self_notification_sms: allowSelfNotificationSms})
        } else {
            setAllowSelfNotificationSms(isChecked)
            onSelfNotify({allow_self_notification_email: allowSelfNotificationEmail, allow_self_notification_sms: isChecked})
        }
    }

    // Handler for show Contacts
    const handleShowContacts = () => {
        setShowContacts(true)
        setShowSidebar(false)
    }
    const handleHideContacts = () => {
        setShowContacts(false)
        setShowSidebar(true)
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase())
    }

    const getFilteredNoGroupContacts = contacts.filter((contact) =>
        !contact.group &&
        (contact.name.toLowerCase().includes(searchQuery.trim())
        || (contact.email && contact.email.toLowerCase().includes(searchQuery.trim()))
        || (contact.phone && contact.phone.toString().toLowerCase().includes(searchQuery.trim()))),
    )

    const getFilteredUserInfo = (
        (user.username && user.username.toLowerCase().includes(searchQuery.trim()))
        || (user.email && user.email.toLowerCase().includes(searchQuery.trim()))
        || (user.phone && user.phone.toLowerCase().includes(searchQuery.trim()))
    )

    const getFilteredGroupContacts = () => groups.map(({groupName, contacts}) => {
        const filteredContacts = contacts.filter((contact) =>
            contact.name.toLowerCase().includes(searchQuery.trim())
                || (contact.email && contact.email.toLowerCase().includes(searchQuery.trim()))
                || (contact.phone && contact.phone.toString().toLowerCase().includes(searchQuery.trim())),
        )

        return {groupName, contacts: filteredContacts}
    }).filter((group) => group.contacts.length > 0)

    const emailGlobalCheckboxState = calculateGlobalCheckboxState('email')
    const phoneGlobalCheckboxState = calculateGlobalCheckboxState('phone')

    return (
        <>
            {showSidebar && (
                <>
                    <Box
                        className={'row fullWidth'}
                        sx={{justifyContent: 'space-between'}}
                    >
                        <Box
                            className={'subtitle medium'}
                            sx={{textTransform: 'uppercase'}}
                        >
                        Recipients
                        </Box>

                        <Button
                            variant={'outlined'}
                            color={'primary'}
                            onClick={handleShowContacts}
                            startIcon={<PlusIcon/>}
                        >
                        Add contacts
                        </Button>
                    </Box>
                </>
            )}

            {showContacts ? (
                <Contacts
                    sx={{width: '100%'}}
                    onHideContacts={handleHideContacts}
                />
            ) : (<>
                <SearchInput
                    className={'fullWidth'}
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder={'Search for contact'}
                />
                {/* <NotificationRecipients recipients={Object.values(userContacts).filter(contact => !!contactsData[contact.id]?.email)}>
                    <Typography className={'label'}>
                        Email:
                    </Typography>
                </NotificationRecipients>
                <NotificationRecipients recipients={Object.values(userContacts).filter(contact => !!contactsData[contact.id]?.phone)}>
                    <Typography className={'label'}>
                        Send SMS:
                    </Typography>
                </NotificationRecipients> */}

                <Box className={clsx('globalRecipientCheckboxes fullWidth', styles.titlesWrapper)}>
                    <Box className={clsx('row gap4', styles.titleWrapper)}>
                        <Typography className={styles.title}>
                            Select all
                        </Typography>
                    </Box>
                    <Box className={clsx('row gap8', styles.emailTitleWrapper)}>
                        <FormControlLabel
                            control={
                                <CheckBox
                                    onClick={() => handleGlobalCheckboxClick('email')}
                                    checked={emailGlobalCheckboxState.checked}
                                    indeterminate={emailGlobalCheckboxState.indeterminate}
                                    disabled={emailGlobalCheckboxState.disabled}
                                />
                            }
                            label={
                                <Typography className={clsx('medium', styles.title)}>
                                    All emails
                                </Typography>
                            }
                        />
                    </Box>
                    <Box className={clsx('row gap8', styles.phoneTitleWrapper)} >
                        <FormControlLabel
                            control={
                                <CheckBox
                                    onClick={() => handleGlobalCheckboxClick('phone')}
                                    checked={phoneGlobalCheckboxState.checked}
                                    indeterminate={phoneGlobalCheckboxState.indeterminate}
                                    disabled={phoneGlobalCheckboxState.disabled}
                                />
                            }
                            label={
                                <Typography className={clsx('medium', styles.title)}>
                                    All phones
                                </Typography>
                            }
                        />
                    </Box>
                </Box>

                <Box
                    data-cy={'recipient-list'}
                    className={clsx('recipientList', styles.recipientsListWrapper)}>
                    {
                        getFilteredUserInfo &&
                        <Box className={'row gap8'}>
                            <RecipientLine
                                key={user.id}
                                contact={user}
                                emailChecked={allowSelfNotificationEmail}
                                smsChecked={allowSelfNotificationSms}
                                onCheckedChange={(isChecked, type) => handleUserCheckedChange(isChecked, type)}
                                isUser={true}
                            />
                        </Box>
                    }
                    {getFilteredGroupContacts().map(({groupName, contacts}) => {
                        const emailCheckboxState = calculateGroupCheckboxState(groupName, 'email', contacts, contactsData)
                        const phoneCheckboxState = calculateGroupCheckboxState(groupName, 'phone', contacts, contactsData)

                        return (
                            <Accordion key={groupName}>
                                <AccordionSummaryUserGroup
                                    aria-controls={`panel-${groupName}-content`}
                                    id={`panel-${groupName}-header`}
                                >
                                    <Box className={clsx('row gap4', styles.groupNameWrapper)}>
                                        <Typography className={styles.groupTitle}>
                                            {groupName}
                                            {' '}
(
                                            {contacts.length}
)
                                        </Typography>
                                    </Box>
                                    <Box className={clsx('row gap8', styles.groupEmailWrapper)}>
                                        <CheckBox
                                            onClick={(e) => handleGroupCheckboxClick(e, groupName, 'email')}
                                            checked={emailCheckboxState.checked}
                                            indeterminate={emailCheckboxState.indeterminate}
                                            disabled={emailCheckboxState.disabled}
                                        />
                                        <Typography className={styles.groupTitle}>
                                        Email
                                        </Typography>
                                    </Box>
                                    <Box className={clsx('row gap8', styles.groupPhoneWrapper)}>
                                        <CheckBox
                                            onClick={(e) => handleGroupCheckboxClick(e, groupName, 'phone')}
                                            checked={phoneCheckboxState.checked}
                                            indeterminate={phoneCheckboxState.indeterminate}
                                            disabled={phoneCheckboxState.disabled}
                                        />
                                        <Typography className={styles.groupTitle}>
                                        Phone
                                        </Typography>
                                    </Box>
                                </AccordionSummaryUserGroup>
                                <AccordionDetailsUserGroup className={'gap8'}>
                                    <Box className={'column gap8 fullWidth'}>
                                        {contacts.map((contact) => (
                                            <RecipientLine
                                                key={contact.id}
                                                contact={contact}
                                                emailChecked={!!contactsData[contact.id]?.email}
                                                smsChecked={!!contactsData[contact.id]?.phone}
                                                onCheckedChange={(isChecked, type) => handleNotificationCheckedChange(contact, isChecked, type)}
                                                editable={true}
                                            />
                                        ))}
                                    </Box>
                                </AccordionDetailsUserGroup>
                            </Accordion>
                        )
                    })}
                    {/* Add ungrouped users */}
                    <Box className={'column gap8 fullWidth'}>
                        {getFilteredNoGroupContacts.map((contact) => (
                            <RecipientLine
                                key={contact.id}
                                contact={contact}
                                emailChecked={!!contactsData[contact.id]?.email}
                                smsChecked={!!contactsData[contact.id]?.phone}
                                onCheckedChange={(isChecked, type) => handleNotificationCheckedChange(contact, isChecked, type)}
                                editable={true}
                            />
                        ))}
                    </Box>
                </Box>
            </>)}
        </>
    )
}
