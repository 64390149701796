import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {
    deleteConfigurationGroup,
    fetchAlertConfigurations,
    fetchAlertConfigurationsExtended,
    fetchConfigurationGroups,
    postConfigurationGroup,
    putConfigurationGroup,
    togglePauseConfigurationGroup
} from "../../pages/alerts/api/FetchAlerts";

export const useAlertConfigurationsStore = create(devtools((set, get) => ({
    alertConfigurations: [],
    alertConfigGroups: [],
    extendedAlertConfigs: [], // contains paused alert data or not
    preparedAlertConfigGroups: [],

    //===============================

    newestAlertConfigsID: [],
    selectedAlertConfigurations: [],
    openedAlertConfiguration: null,
    editingAlertConfiguration: null,
    isLoading: false,

    getConfigurations: (weatherDefinitions, locations, alertConfigs, alertConfigGroups) => {
        if (!weatherDefinitions?.length || !locations?.length || !alertConfigs?.length || !alertConfigGroups?.length) {
            set((state) => ({
                preparedAlertConfigGroups: []
            }));
            return {}
        }

        const wds = {}
        weatherDefinitions.forEach((wd) => {
            wds[wd.id] = wd
            wd.configurations = {}
        })

        const locs = {}
        locations.forEach((loc) => {
            locs[loc.id] = loc
        })

        const cfgs = {}
        alertConfigs.forEach((cfg) => {
            cfgs[cfg.id] = cfg
        })

        const alrt = {}
        alertConfigGroups.forEach((relation) => {
            const config = cfgs[relation[1]]
            if (!config) return
            const definition = wds[config.weather_definition_id]
            const location = locs[config.location_id]
            if (!definition || !location) return
            if (!alrt[relation[0]]) {
                alrt[relation[0]] = {
                    groupId: relation[0],
                    locations: [],
                    definition_id: definition.id,
                    severity: definition.severity ?? 'Severe',
                    type: definition.name,
                }
            }
            alrt[relation[0]].locations.push(location)
        })
        set((state) => ({
            preparedAlertConfigGroups: alrt
        }));
        return alrt
    },

    fetchAllInfoAboutAlertConfigurations: async () => {
        set({
            selectedAlertConfigurations: [],
            openedAlertConfiguration: null,
            editingAlertConfiguration: null,
        });

        try {
            const [alertConfigurations, alertConfigGroups, extendedAlertConfigs] = await Promise.all([
                fetchAlertConfigurations(),
                fetchConfigurationGroups(),
                fetchAlertConfigurationsExtended(),
            ]);

            set((state) => ({
                alertConfigurations,
                alertConfigGroups,
                extendedAlertConfigs,
            }));

            return {alertConfigurations, alertConfigGroups, extendedAlertConfigs};
        } catch (error) {
            console.error("Error fetching alert configurations:", error);
            throw error;
        }
    },

    selectAlertConfiguration: (alertConfig) => {
        set((state) => {
            const isSelected = state.selectedAlertConfigurations.some(item => item.groupId === alertConfig.groupId);
            return {
                selectedAlertConfigurations: isSelected
                    ? state.selectedAlertConfigurations.filter(item => item.groupId !== alertConfig.groupId)
                    : [...state.selectedAlertConfigurations, alertConfig]
            };
        });
    },

    selectAllAlertConfigurations: () => {
        const {preparedAlertConfigGroups, selectedAlertConfigurations} = get();

        set((state) => {
            const allSelected = Object.values(preparedAlertConfigGroups).length === selectedAlertConfigurations.length
            return {
                selectedAlertConfigurations: allSelected ? [] : [...selectedAlertConfigurations, ...Object.values(preparedAlertConfigGroups).filter(item => !selectedAlertConfigurations.some(selected => selected.groupId === item.groupId))]
            };
        });
    },

    restartOrPauseAlertConfig: async (id, isRestart) => {
        const {extendedAlertConfigs} = get();

        const data = JSON.stringify({enabled: isRestart})
        togglePauseConfigurationGroup(id, data).then((response) => {
            if (response?.success) {
                const newExtendedAlertConfigs = extendedAlertConfigs.map((alertConf) => {
                    if (alertConf.group_id === id) {
                        return {...alertConf, enabled: isRestart}
                    }
                    return alertConf
                })
                set((state) => ({
                    extendedAlertConfigs: newExtendedAlertConfigs
                }));
            } else {
                console.log('Error with pause/restart alert configuration')
            }
        })
    },

    openAlertConfig: (alertConfig) => {
        set((state) => ({
            openedAlertConfiguration: alertConfig,
            selectedAlertConfigurations: []
        }))
    },

    getNewestAlertsIdsFromSessionStorage: () => {
        const {preparedAlertConfigGroups, deleteAlertIdFromSessionStorage} = get();

        const successIdsString = sessionStorage.getItem('newestAlerts')
        const successIds = JSON.parse(successIdsString)
        if (successIds && successIds.length > 0) {
            const alertConfigIds = Object.values(preparedAlertConfigGroups).map((config) => config.groupId)
            const validSuccessIds = []
            successIds.forEach((id) => {
                if (alertConfigIds.includes(id)) {
                    validSuccessIds.push(id)
                } else {
                    deleteAlertIdFromSessionStorage(id)
                }
            })
            set((state) => ({
                newestAlertConfigsID: validSuccessIds
            }));
        } else {
            set((state) => ({
                newestAlertConfigsID: []
            }));
        }
    },

    writeIdsToSessionStorage: (alertConfigs) => {
        const existingIdsFromStorage = sessionStorage.getItem('newestAlerts')
        const existingIds = existingIdsFromStorage ? JSON.parse(existingIdsFromStorage) : []

        const newSuccessIds = alertConfigs
            .filter((config) => config.success === true)
            .map((config) => config.group_id)

        const updatedIds = Array.from(new Set([...existingIds, ...newSuccessIds]))
        sessionStorage.setItem('newestAlerts', JSON.stringify(updatedIds))
    },

    deleteAlertIdFromSessionStorage: (id) => {
        const {newestAlertConfigsID} = get();

        if (!newestAlertConfigsID || newestAlertConfigsID.length === 0) return

        const updatedIds = newestAlertConfigsID.filter((alertId) => alertId !== id)
        sessionStorage.setItem('newestAlerts', JSON.stringify(updatedIds))

        set({newestAlertConfigsID: updatedIds})
    },

    deleteAlertConfigurationByID: async (id) => {
        const {
            openAlertConfig,
            fetchAllInfoAboutAlertConfigurations,
            deleteAlertIdFromSessionStorage
        } = get();

        set({isLoading: true})
        try {
            const response = await deleteConfigurationGroup(id);
            if (response) {
                deleteAlertIdFromSessionStorage(id)
                fetchAllInfoAboutAlertConfigurations()
            } else {
                console.error("Error with deleting alert configuration");
            }
        } catch (error) {
            console.error("Error during deletion:", error);
        } finally {
            openAlertConfig(false)
            set({isLoading: false})
        }
    },

    deleteMultipleAlertConfigurations: async (ids) => {
        const {
            deleteAlertIdFromSessionStorage,
            fetchAllInfoAboutAlertConfigurations,
        } = get();

        set({isLoading: true});
        try {
            await Promise.all(
                ids.map(async (id) => {
                    await deleteConfigurationGroup(id);
                    deleteAlertIdFromSessionStorage(id);
                })
            )
        } catch (error) {
            console.error("Error during multiple deletions:", error);
        } finally {
            fetchAllInfoAboutAlertConfigurations()
            set({isLoading: false});
        }
    },

    toggleEditingAlertConfiguration: (alertConfig) => {
        set((state) => ({
            editingAlertConfiguration: alertConfig
        }))
    },

    saveAlertConfiguration: async (locations, definitions, notificationData, defaultNotificationMessages, configGroupID) => {
        const {writeIdsToSessionStorage} = get();

        const requests = definitions.map((definitionId) => {
            const dataToSend = {
                locations: locations,
                definition: definitionId,
                notification: notificationData,
            }

            // Set Forecast appears checkbox active for the new alert
            if (notificationData.fc_appears_checked === undefined) {
                dataToSend.notification.fc_appears_checked = true
                dataToSend.notification.fc_appears = defaultNotificationMessages.fc_appears
            }

            if (!configGroupID) {
                return postConfigurationGroup(dataToSend)
            } else {
                return putConfigurationGroup(configGroupID, dataToSend)
            }
        })
        await Promise.all(requests)
            .then((data) => {
                if (!configGroupID){
                    writeIdsToSessionStorage(data)
                }
            })
            .catch((error) => {
                console.error('Error while saving configurations:', error)
            })
    },

})));