import {isEmpty} from 'shared/libs/Utils'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Box} from '@mui/system'
import {Button, Typography} from '@mui/material'
import {useWeatherDefinitionsStore} from '../../../../../app/store/WeatherDefinitionsStore'
import AlertsSettingsList from '../AlertsSettingsList/AlertsSettingsList'
import AlertConfigurationDetails from '../../../../../entities/alertConfig/ui/AlertConfigurationDetails/AlertConfigurationDetails'
import {Spacer} from 'shared/ui/Spacer'
import {SearchInput} from 'shared/ui/SearchInput'
import AgreeModal from 'shared/ui/AgreeModal'
import ItemsSelectTopBar from '../../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar'
import EmptyStatesAlerts from '../../../../../shared/ui/emptyStates/EmptyStateAlerts'
import {LocationFilter} from 'widgets/location'
import {SeveritySelector} from '../../../../../features/weatherDefinition/SeveritySelector/SeveritySelector'
import ProgressModal from '../../../../../shared/ui/ProgressModal'
import {useLocationsStore} from '../../../../../app/store/LocationsStore'
import {useAlertConfigurationsStore} from '../../../../../app/store/AlertConfigurationsStore'
import styles from './AlertSettings.module.scss'
import {clsx} from 'clsx'

const AlertsSettings = ({}) => {
    const {
        alertConfigurations,
        alertConfigGroups,
        extendedAlertConfigs,
        selectedAlertConfigurations,
        preparedAlertConfigGroups,
        newestAlertConfigsID,
        openedAlertConfiguration,
        isLoading,
        fetchAllInfoAboutAlertConfigurations,
        getConfigurations,
        getNewestAlertsIdsFromSessionStorage,
        deleteMultipleAlertConfigurations,
        toggleEditingAlertConfiguration,
    } = useAlertConfigurationsStore((state) => state)

    const {
        locations,
        fetchLocations,
    } = useLocationsStore((state) => state)

    const {
        weatherDefinitions,
        fetchWeatherDefinitions,
    } = useWeatherDefinitionsStore((state) => state)

    const [severity, setSeverity] = useState('All')
    const [searchString, setSearchString] = useState('')
    const [selectedLocations, setSelectedLocations] = useState({})
    const [deleteMultipleAlertConfigs, setDeleteMultipleAlertConfigs] = useState(false)

    useLayoutEffect(() => {
        fetchAllInfoAboutAlertConfigurations()
        fetchWeatherDefinitions()
        fetchLocations().then((data) => {
            const locs = {}
            data.forEach((loc) => {
                locs[loc.id] = true
            })
            setSelectedLocations(locs)
        })
    }, [])

    useEffect(() => {
        getConfigurations(weatherDefinitions, locations, alertConfigurations, alertConfigGroups)
    }, [weatherDefinitions, locations, alertConfigurations, alertConfigGroups])

    useEffect(() => {
        getNewestAlertsIdsFromSessionStorage()
    }, [preparedAlertConfigGroups])

    const allAlertsSelected
        = Object.values(preparedAlertConfigGroups).every((group) =>
            selectedAlertConfigurations.some((config) => config.groupId === group.groupId),
        )
    const someAlertsSelected = Object.values(selectedAlertConfigurations).some((val) => val) && !allAlertsSelected

    const idsToDelete = selectedAlertConfigurations.map((config) => config.groupId)

    const alertsToDeleteArray = Object.values(preparedAlertConfigGroups)
        .filter((alert) => idsToDelete.includes(alert.groupId))

    const handleActionsWithTopBar = (alert, action) => {
        if (action === 'delete') {
            setDeleteMultipleAlertConfigs(true)
        }
    }

    const deleteAlerts = (agree) => {
        if (agree) {
            deleteMultipleAlertConfigurations(idsToDelete)
        }
        setDeleteMultipleAlertConfigs(false)
    }

    const handleSeverityChange = (event) => {
        setSeverity(event.target.value)
    }

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim())
    }

    const s = searchString.toLocaleLowerCase()

    return (
        <>
            <Box
                className="AlertsSetting settings"
                sx={{display: openedAlertConfiguration ? 'none' : 'flex'}}
            >
                <Box
                    className={clsx('settings-toolbar', styles.header)}
                >
                    <h3>
                        Alert configurations
                    </h3>
                    <SearchInput
                        placeholder='Search'
                        value={searchString}
                        onChange={searchStringChanged}
                    />
                    <LocationFilter
                        selectedLocations={selectedLocations}
                        setSelectedLocations={setSelectedLocations}
                    />

                    <SeveritySelector
                        severity={severity}
                        onChange={handleSeverityChange}
                    />

                    <Spacer/>

                    <Button
                        data-cy={'new-alert-button'}
                        onClick={() => {
                            toggleEditingAlertConfiguration({back: true})
                        }}
                    >
                        New alert configuration
                    </Button>
                </Box>

                <ItemsSelectTopBar
                    onAction={handleActionsWithTopBar}
                    visible={Object.keys(selectedAlertConfigurations).length > 0}
                    allSelected={allAlertsSelected}
                    indeterminate={someAlertsSelected}
                />

                {isEmpty(preparedAlertConfigGroups)
                    ? <Box sx={{width: '100%', height: '100%'}}>
                        <EmptyStatesAlerts
                            title={'Configured alerts will appear here'}
                            text={'You will see alerts here once they are created'}
                        />
                    </Box>
                    : <Box>
                        {newestAlertConfigsID?.length > 0 && <>
                            <Box className={styles.newAlerts}>
                                New alerts
                            </Box>
                            <AlertsSettingsList
                                alertGroups={Object.values(preparedAlertConfigGroups).filter((alert) => newestAlertConfigsID?.includes(alert.groupId))}
                                searchString={s}
                                severity={severity}
                                selectedLocations={selectedLocations}
                                extendedAlertConfigs={extendedAlertConfigs}
                                isNewAlert={true}
                            />
                        </>
                        }
                        <Box className={styles.allAlerts}>
                            All alerts
                        </Box>
                        <AlertsSettingsList
                            alertGroups={Object.values(preparedAlertConfigGroups).filter((alert) => !newestAlertConfigsID?.includes(alert.groupId))}
                            searchString={s}
                            severity={severity}
                            selectedLocations={selectedLocations}
                            extendedAlertConfigs={extendedAlertConfigs}
                        />
                    </Box>
                }
            </Box>

            {
                openedAlertConfiguration &&
                <AlertConfigurationDetails
                    alert={openedAlertConfiguration}
                    extendedAlertConfig={Object.values(extendedAlertConfigs).filter((alert) => alert.group_id === openedAlertConfiguration.groupId)}
                />
            }

            {deleteMultipleAlertConfigs &&
                <AgreeModal
                    data={{
                        message: <Box className={clsx('column', styles.deleteModalWrapper)}>
                            <Typography
                                sx={{fontSize: '18px'}}
                            >
                                {(Object.keys(selectedAlertConfigurations).length === 1) ? 'Are you sure you want to delete this alert configuration?'
                                    : `Are you sure you want delete ${Object.keys(selectedAlertConfigurations).length} alerts configurations?`}
                            </Typography>
                            <Box className={styles.deleteModalList}>
                                {alertsToDeleteArray.map((alert) =>
                                    (<span key={alert.groupId}>
•
                                        {' '}
                                        {alert.type}
                                        <br/>
                                    </span>))}
                            </Box>
                        </Box>,
                        title: 'Delete multiple alerts configurations',
                        agreeMsg: 'Delete',
                        mode: 'deleting',
                        agreeFunc: deleteAlerts,
                    }}
                />
            }
            <ProgressModal visible={isLoading}/>
        </>
    )
}

export default AlertsSettings
