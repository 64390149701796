import {Box, Button} from '@mui/material'
import dayjs from 'dayjs'
import {WeatherDefinitionDetails} from 'entities/weatherDefinition/ui/WeatherDefinitionDetails/WeatherDefinitionDetails'
import OpenAlertMenuButton from 'features/alert/OpenAlertMenuButton'
import 'pages/alerts/ui/AlertsPage.css'
import React, {useEffect, useState} from 'react'
import {isEmpty} from 'shared/libs/Utils'
import ManageAlertConfigurationPanel
    from 'widgets/alertConfig/ui/manageAlertConfigurationPanel/ManageAlertConfigurationPanel'
import {useWeatherDefinitionsStore} from '../../../../app/store/WeatherDefinitionsStore'
import AlertCard from '../../../../entities/alert/ui/AlertCard/AlertCard'
import AlertListItem from '../../../../entities/alert/ui/AlertListItem'
import generalCautionIcon from '../../../../shared/assets/weatherIcons/general_caution.svg'
import EmptyStatesAlerts from '../../../../shared/ui/emptyStates/EmptyStateAlerts'
import {
    fetchRealLocations,
    postAlerts,
} from '../../api/FetchAlerts'
import {AlertCardGroup} from '../AlertCardGroup'
import AlertsToolbar from '../AlertsToolbar/AlertsToolbar'
import {ListGroup} from '../ListGroup'
import {useAlertConfigurationsStore} from '../../../../app/store/AlertConfigurationsStore'
import styles from './AlertsPage.module.scss'
import {clsx} from 'clsx'

export function prepApiAlert(apiAlert, showCleared = false) {
    const alert = {
        alert_id: '' + apiAlert.id,
        aid: apiAlert.aid,
        start_date: dayjs(apiAlert.issue_time).valueOf(),
        end_date: dayjs(apiAlert.expiration_time).valueOf(),
        severity: apiAlert?.weather_definition?.severity ?? 'Severe',
        category: 'Live',
        type: apiAlert.weather_definition.name,
        conditions: [
            {name: apiAlert.weather_definition.description, value: ''},
        ],
        location: apiAlert.location.label,
        location_type: 'point',
        location_id: apiAlert.loc_id,
        icon: apiAlert.weather_definition.icon_obj.url,
        triggered: apiAlert.triggered,
        api_alert: apiAlert,
        cleared: false,
    }
    const validAlerts = []
    if (apiAlert.alert_type === 'usereventsalert') {
        const conds = {}
        const eres = apiAlert.triggered_rules.split('\n')
        for (const idx in eres) {
            const prodval = eres[idx].split('::')
            const product_type = prodval.pop()
            if (product_type === 'f' && !showCleared && apiAlert.cleared) continue // exclude cleared intervals
            const product_name = prodval.shift()
            const rule = prodval.shift() // extract triggered rule
            prodval.forEach((interval) => {
                interval = interval.split(',')
                const product_line = {
                    name: product_name,
                    value: interval.slice(2).join(','),
                    type: product_type,
                    rule: rule,
                }
                interval = interval[0] + ',' + interval[1]
                if (!conds[interval]) conds[interval] = []
                conds[interval].push(product_line)
            })
        }
        const intervals = Object.keys(conds).sort()
        let prev_alert = null
        for (const interval_idx in intervals) {
            const interval = intervals[interval_idx]
            const alert_copy = {...alert}
            const interval_conds = {}
            conds[interval].forEach((cond) => { // WL-265 remove duplicate rows, WL-293 - combine rules for forecasts min/max
                if (cond.value.indexOf('[')) { // current product value
                    interval_conds[cond.name] = cond
                } else {
                    if (!interval_conds[cond.name]) {
                        interval_conds[cond.name] = cond
                    } else {
                        interval_conds[cond.name].rule += ':' + cond.rule
                    }
                }
            })
            alert_copy.conditions = Object.values(interval_conds)
            const begend = interval.split(',')
            alert_copy.start_date = dayjs(begend[0]).valueOf()
            alert_copy.end_date = dayjs(begend[1]).valueOf()
            alert_copy.alert_id += '_' + interval_idx
            alert_copy.aid += '_' + interval_idx
            validAlerts.push(alert_copy)
            if (alert.triggered) {
                let has_current = false
                for (const idx in alert_copy.conditions) {
                    if (alert_copy.conditions[idx].type === 'c') {
                        has_current = true
                        break
                    }
                }
                if (has_current && prev_alert) prev_alert.triggered = false
                if (!has_current) alert_copy.triggered = false
                prev_alert = alert_copy
            }
            if (apiAlert.cleared) {
                let has_current = false
                for (const idx in alert_copy.conditions) {
                    if (alert_copy.conditions[idx].type === 'c') {
                        has_current = true
                        break
                    }
                }
                if (!has_current) alert_copy.cleared = true
            }
        }
    } else validAlerts.push(alert)
    return validAlerts
}

export function calcGroups(locations) {
    if (!locations) {
        return {}
    }
    let groups = {}
    locations.forEach((location) => {
        if (!groups[location.location_group]) groups[location.location_group] = []
        groups[location.location_group].push(location)
    })
    const groupsList = []
    let ungrouped = []
    for (const key in groups) {
        if (!key) {
            ungrouped = groups[key]
            continue
        }
        groupsList.push({
            group: key,
            locations: groups[key],
        })
    }
    groupsList.sort((a, b) => a.group.localeCompare(b.group))
    if (ungrouped.length) {
        groupsList.push({group: '', locations: ungrouped})
    }
    groups = {}
    for (const idx in groupsList) {
        const g = groupsList[idx]
        const locs = {}
        for (const idx in g.locations) {
            const l = g.locations[idx]
            locs[l.id] = {
                id: l.id,
                label: l.label,
                forecast_max: undefined,
                forecast_min: undefined,
                Critical: 0,
                Severe: 0,
                Moderate: 0,
                type: 'point',
            }
        }
        groups[g.group] = locs
    }
    return groups
}

export function AlertsPage() {
    const {
        editingAlertConfiguration,
    } = useAlertConfigurationsStore((state) => state)

    const {
        openedWeatherDefinition,
        editingWeatherDefinition,
        fetchWeatherDefinitions,
        fetchWeatherDefinitionIcons,
    } = useWeatherDefinitionsStore((state) => state)

    const [severity, setSeverity] = useState('All')
    const [dateRange, setDateRange] = useState([])
    const [view, setView] = useState('Grid')

    const apiAlertsRef = React.useRef({})
    const apiLocationsRef = React.useRef([])
    const locationGroupsRef = React.useRef()
    const apiWeatherDefinitionsRef = React.useRef([])
    const apiWeatherIconsRef = React.useRef(null)
    const apiUpdatedRef = React.useRef(false)
    const alertsRef = React.useRef([])
    const [selectedLocations, setSelectedLocations] = useState({})

    const [updated, setUpdated] = React.useState(false) // invert this value to force rerender
    const [renderLimit, setRenderLimit] = React.useState()
    const [selectedConfigs, setSelectedConfigs] = useState({configs: null})
    const apiLimits = React.useRef({limit: 2000, loaded: 0, range: [], filters: {}})

    const applyFilters = () => {
        let newFilteredAlerts = alertsRef.current

        newFilteredAlerts = filterBySeverity(newFilteredAlerts)
        newFilteredAlerts = filterByDate(newFilteredAlerts)
        newFilteredAlerts = filterByLocation(newFilteredAlerts)
        newFilteredAlerts = filterByConfigs(newFilteredAlerts)

        return newFilteredAlerts
    }

    const filterBySeverity = (alerts) => {
        if (severity === 'All') {
            return alerts
        }

        return alerts.filter((alert) => alert.severity === severity)
    }

    const filterByConfigs = (alerts) => {
        const merged = {}
        const configs = selectedConfigs.configs
        if (!configs) return alerts
        for (const key in configs) {
            const conf = configs[key]
            if (!conf.selected) continue
            conf.group.locations.forEach((loc) => {
                merged[conf.group.definition_id + '_' + loc.id] = 1
            })
        }
        return alerts.filter((alert) => merged[alert.api_alert.wdef_id + '_' + alert.location_id] === 1)
    }

    const filterByDate = (alerts) => {
        if (!dateRange.length) {
            return alerts
        }

        if (dateRange.length === 1) {
            return alerts.filter((alert) => {
                const date = dayjs(alert.start_date)
                return date.isBetween(dateRange[0], dateRange[0], 'day', '[]')
            })
        }

        return alerts.filter((alert) => {
            const date = dayjs(alert.start_date)
            return date.isBetween(dateRange[0], dateRange[1], 'day', '[]')
        })
    }

    const filterByLocation = (alerts) => alerts.filter((alert) => selectedLocations[alert.location_id] !== false)

    const apiRangeByDate = () => {
        if (!dateRange.length) {
            return []
        }

        if (dateRange.length === 1) {
            return [dateRange[0].subtract(7, 'day').unix(), dateRange[0].add(1, 'day').unix()]
        }
        return [dateRange[0].subtract(7, 'day').unix(), dateRange[1].add(1, 'day').unix()]
    }

    const selectedLocationsList = () => Object.keys(selectedLocations).filter((lid) => selectedLocations[lid] !== false)

    const selectedConfigsList = () => Object.keys(selectedConfigs.configs).filter((cid) => selectedConfigs.configs[cid].selected !== false)

    useEffect(() => {
        apiLimits.current.range = apiRangeByDate()
        apiAlertsRef.current.ts_after = 0
        periodicalUpdate()
    }, [dateRange])

    const updateOnChanges = (stateRef) => (new_state) => {
        if (!new_state) {
            return
        }
        const state = stateRef.current
        let changed = false
        if (new_state.location) {
            new_state = new_state.location
            console.log('updateOnChanges.locations', new_state)
        }
        if (!state) { // first update
            stateRef.current = new_state
            apiUpdatedRef.current = true
            setUpdated(Date.now())
            return
        }
        for (const idx in new_state) {
            if (!state[idx] || state[idx].id !== new_state[idx].id) {
                changed = true
                break
            }
            if (state[idx].changed && state[idx].changed !== new_state[idx].changed) {
                changed = true
                break
            }
            /* What is it?
                for (let key in new_state) {
                    if (state[idx][key] !== new_state[idx][key]) {
                        changed = true;
                        break
                    }
                }
                if (changed) break;
                */
        }
        if (changed) {
            stateRef.current = new_state
        }
        return changed
    }

    const updateApiAlerts = (data) => {
        const ts_after_cur = apiAlertsRef.current.ts_after
        if (!data || (!data.length && ts_after_cur)) return
        let ts_after = (!data.length) ? 0 : [...data.map((a) => a.update_time)].sort()[data.length - 1]
        const aids = {}
        let changed = false
        if (!apiAlertsRef.current.data || !ts_after_cur) {
            apiAlertsRef.current.data = data
            apiLimits.current.loaded = data.length
            console.log('Loaded alert ' + data.length)
            changed = true
        } else {
            for (const idx in apiAlertsRef.current.data) {
                const a = apiAlertsRef.current.data[idx]
                aids[a.aid] = idx
            }
            for (const idx in data) {
                const a = data[idx]
                if (aids[a.aid]) {
                    if (apiAlertsRef.current.data[aids[a.aid]].update_time !== a.update_time) {
                        apiAlertsRef.current.data[aids[a.aid]] = a // alert exists already, updating
                        changed = true
                    }
                } else {
                    apiAlertsRef.current.data.push(a)
                    apiLimits.current.limit += 1
                    apiLimits.current.loaded += 1
                    changed = true
                }
            }
        }
        const min_ts_after = dayjs.utc().subtract(15, 'minute').toISOString().split('.')[0] + 'Z'
        if (!ts_after || ts_after < min_ts_after) ts_after = min_ts_after
        apiAlertsRef.current.ts_after = ts_after
        if (!changed) return
        apiUpdatedRef.current = true
        setUpdated(Date.now())
    }

    const getAlertCategory = (alert, now) => {
        let category = 'Forecast'
        if (alert.cleared) {
            category = 'Cleared'
        } else if (alert.end_date < now && !alert.triggered) {
            category = 'Historical'
        } else if (alert.start_date < now) {
            category = 'Live'
        }
        return category
    }

    const updateAlertCategory = () => {
        const alerts = alertsRef.current
        const now = Date.now()
        let do_rerender = false
        for (const idx in alerts) {
            const alert = alerts[idx]
            if (alert.category === 'Historical' || alert.category === 'Cleared') continue
            const new_category = getAlertCategory(alert, now)
            if (new_category !== alert.category) {
                alert.category = new_category
                alerts[idx] = {...alert}
                do_rerender = true
            }
        }
        if (do_rerender) {
            setUpdated(Date.now())
        }
    }

    const periodicalUpdate = () => {
        const {openedWeatherDefinition, editingWeatherDefinition} = useWeatherDefinitionsStore.getState()
        if (openedWeatherDefinition || editingWeatherDefinition) {
            console.log('***** periodicalUpdate cancel')
            return
        }

        const range = apiLimits.current.range
        const limit = range.length ? 0 : apiLimits.current.limit
        const realAlertsPromise = apiAlertsRef.current.ts_after
            ? postAlerts(dayjs(apiAlertsRef.current.ts_after).unix(), range[0], range[1], limit, apiLimits.current.filters)
            : postAlerts(0, range[0], range[1], limit, apiLimits.current.filters)
        console.log('***** periodicalUpdate begin')
        Promise.allSettled([fetchRealLocations(), fetchWeatherDefinitions(), realAlertsPromise]).then(([locs, defs, alerts]) => {
            let r
            let r2
            let r3
            //            console.log("***** periodicalUpdate checking", locs, defs, alert);
            if (locs.status === 'fulfilled') {
                r = updateOnChanges(apiLocationsRef)(locs.value)
            }
            if (defs.status === 'fulfilled') {
                r2 = updateOnChanges(apiWeatherDefinitionsRef)(defs.value)
            }
            if (alerts.status === 'fulfilled') {
                r3 = updateApiAlerts(alerts.value)
            }
            console.log('***** periodicalUpdate end', r, r2, r3)
            if (r || r2 || r3) {
                apiUpdatedRef.current = true
                setUpdated(Date.now())
                console.log('***** periodicalUpdate triggering update')
            }
        })
        fetchWeatherDefinitionIcons().then(updateOnChanges(apiWeatherIconsRef))
        updateAlertCategory()
    }

    useEffect(() => {
        let updater = null
        updater = setInterval(periodicalUpdate, 60000)
        console.log('Timeline alert updater started')
        //        periodicalUpdate();
        return () => {
            console.log('Timeline alert updater stopped')
            clearInterval(updater)
        }
    }, [])

    const prepAlerts = () => {
        const apiAlerts = apiAlertsRef.current.data || []
        const apiWeatherIcons = apiWeatherIconsRef.current
        const apiLocations = apiLocationsRef.current
        const apiWeatherDefinitions = apiWeatherDefinitionsRef.current
        let groups
        if (apiLocations.length) {
            groups = calcGroups(apiLocations)
            locationGroupsRef.current = groups
        }
        if (!apiAlerts.length || !apiWeatherIcons || !apiLocations.length || !apiWeatherDefinitions.length) {
            if (alertsRef.current) alertsRef.current = []
            return
        }
        if (!apiUpdatedRef.current) return
        apiUpdatedRef.current = false
        const icons = {}
        for (const idx in apiWeatherIcons) {
            icons[apiWeatherIcons[idx].id] = apiWeatherIcons[idx]
        }
        const wds = {}
        for (const idx in apiWeatherDefinitions) {
            apiWeatherDefinitions[idx].icon_obj = icons[apiWeatherDefinitions[idx].icon]
            if (!apiWeatherDefinitions[idx].icon_obj) apiWeatherDefinitions[idx].icon_obj = {url: generalCautionIcon}
            wds[apiWeatherDefinitions[idx].id] = apiWeatherDefinitions[idx]
        }
        const locs = {}
        for (const idx in apiLocations) {
            locs[apiLocations[idx].id] = apiLocations[idx]
        }
        const validAlerts = []
        const now = dayjs().valueOf()
        const currentAlertsCount = alertsRef.current.length
        for (const idx in apiAlerts) {
            const apiAlert = apiAlerts[idx]
            apiAlert.location = locs[apiAlert.loc_id]
            apiAlert.weather_definition = wds[apiAlert.wdef_id]
            if (!apiAlert.location || !apiAlert.weather_definition) continue
            if (apiAlert.alert_type === 'usereventsalert' && !apiAlert.triggered_rules) continue
            const last_alerts_length = validAlerts.length
            prepApiAlert(apiAlert, true).forEach((newAlert, indx) => {
                const pos = last_alerts_length + indx
                if (pos < currentAlertsCount) {
                    const currentAlert = alertsRef.current[pos]
                    if (currentAlert.alert_id === newAlert.alert_id &&
                        currentAlert.api_alert.update_time === newAlert.api_alert.update_time) {
                        validAlerts.push(currentAlert)
                    } else {
                        validAlerts.push(newAlert)
                    }
                } else validAlerts.push(newAlert)
            })

            // aggregating loctions into groups with alert counts
            /*
            if (!groups[apiAlert.location.location_group])
                groups[apiAlert.location.location_group] = {};
            if (!groups[apiAlert.location.location_group][apiAlert.loc_id]) {
                groups[apiAlert.location.location_group][apiAlert.loc_id] = {
                    id: apiAlert.loc_id,
                    label: apiAlert.location.label,
                    forecast_max: alert.end_date,
                    forecast_min: alert.start_date,
                    Critical: 0,
                    Severe: 0,
                    Moderate: 0,
                    type: 'point',
                };
            }
            const alert_stats = groups[apiAlert.location.location_group][apiAlert.loc_id];
            for (let new_idx = last_alerts_length; new_idx < validAlerts.length; new_idx++) {
                const new_alert = validAlerts[new_idx];
                if (new_alert.end_date > now || new_alert.triggered) alert_stats[new_alert.severity] += 1
                if (alert_stats.forecast_max < new_alert.end_date) alert_stats.forecast_max = new_alert.end_date;
            }
            */
            // Now all existing locations should be shown
            const locgroup = apiAlert.location.location_group
            //            console.log("prepAlerts locgroup", locgroup, groups[locgroup]);
            const alert_stats = groups[locgroup][apiAlert.loc_id]
            if (!alert_stats.inited) {
                alert_stats.forecast_min = alert.start_date
                alert_stats.forecast_max = alert.end_date
                alert_stats.inited = true
            }
            for (let new_idx = last_alerts_length; new_idx < validAlerts.length; new_idx++) {
                const new_alert = validAlerts[new_idx]
                if (new_alert.end_date > now || new_alert.triggered) alert_stats[new_alert.severity] += 1
                if (alert_stats.forecast_max < new_alert.end_date) alert_stats.forecast_max = new_alert.end_date
            }
        }
        for (const idx in validAlerts) validAlerts[idx].category = getAlertCategory(validAlerts[idx], now)
        alertsRef.current = validAlerts
        // setUpdated(Date.now());
        // console.log("**** prepAlerts", validAlerts);
    }
    prepAlerts()
    const filteredAlerts = applyFilters()

    useEffect(() => {
        if (!Object.keys(selectedLocations).length && apiLocationsRef.current.length) {
            const locations = {}
            apiLocationsRef.current.forEach((loc) => {
                locations[loc.id] = true
            })
            setSelectedLocations(locations)
        }
    }, [updated])

    useEffect(() => {
        if (!Object.keys(selectedLocations).length
            || !selectedConfigs.configs
            || !Object.keys(selectedConfigs.configs).length) {
            return
        }
        apiAlertsRef.current.ts_after = 0
        apiLimits.current.filters = {
            locations: selectedLocationsList(),
            groups: selectedConfigsList(),
        }
        periodicalUpdate()
    }, [selectedLocations, selectedConfigs])

    function seeMore(groups) {
        const keys = Object.keys(groups)
        if (!keys.length) return
        const limit = renderLimit ? renderLimit : keys[0]
        const newIdx = Math.min(keys.indexOf(limit) + 7, keys.length - 1)
        setRenderLimit(keys[newIdx])
        if (keys.length - newIdx < 20 && apiLimits.current.limit === apiLimits.current.loaded) {
            apiLimits.current.limit += 2000
            console.log('New api limit: ' + apiLimits.current.limit)
            apiLimits.current.loaded = 0
            apiAlertsRef.current.ts_after = 0
            periodicalUpdate()
        }
    }

    function renderGroups(groups) {
        const arr = []
        if (!renderLimit) {
            seeMore(groups)
            return []
        }

        for (const key in groups) {
            const renderedAlerts = groups[key].map((alert, i) => {
                if (!alert) return null

                return (view === 'Grid'
                    ? <AlertCard
                        key={'alertItem' + i}
                        alert={alert}
                        alertMoreButton={
                            <OpenAlertMenuButton
                                alert={alert}
                            />}
                    />
                    : <AlertListItem
                        key={'alertItem' + i}
                        alert={alert}
                        alertMoreButton={
                            <OpenAlertMenuButton
                                alert={alert}
                            />}
                    />
                )
            })
            arr.push(
                <div
                    key={key}
                    className={'column'}
                    style={{alignItems: 'start', width: '100%'}}
                >
                    <div className={'paragraph'}>
                        {key}
                    </div>
                    {view === 'Grid'
                        ? <AlertCardGroup>
                            {renderedAlerts}
                        </AlertCardGroup>
                        : <ListGroup>
                            {renderedAlerts}
                        </ListGroup>
                    }
                </div>)
            if (key === renderLimit) break
        }
        if (Object.keys(groups).indexOf(renderLimit)
            !== Object.keys(groups).length - 1
            || apiLimits.current.limit === apiLimits.current.loaded) {
            arr.push(
                <Button
                    key="see_more"
                    className={'spacer'}
                    style={{width: '442px', margin: '24px auto'}}
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => {
                        seeMore(groups)
                    }}
                >
                    See more
                </Button>,
            )
        }
        return arr
    }

    function groupByDate(arr) {
        arr.sort((a, b) => b.start_date - a.start_date) // Sorting alerts by start date before grouping
        const groups = {}

        arr.forEach((a) => {
            const dateKey = dayjs(a.start_date).format('D MMMM YYYY')
            if (groups[dateKey]) {
                groups[dateKey].push(a)
            } else {
                groups[dateKey] = [a]
            }
        })
        return groups
    }

    return (
        <>
            {!openedWeatherDefinition && !editingAlertConfiguration &&
                <Box className={styles.wrapper}>
                    <AlertsToolbar
                        view={view}
                        onChangeView={setView}
                        onChangeDateRange={setDateRange}
                        severity={severity}
                        onChangeSeverity={setSeverity}
                        selectedLocations={selectedLocations}
                        onChangeSelectedLocations={setSelectedLocations}
                        selectedConfigs={selectedConfigs}
                        setSelectedConfigs={setSelectedConfigs}
                    />
                    <Box className={styles.content}>
                        {isEmpty(Object.values(filteredAlerts))
                            ? <Box className={styles.emptyWrapper}>
                                <EmptyStatesAlerts
                                    title={'Triggered alerts will appear here'}
                                    text={'You will see alerts here once they are triggered by actual weather data'}
                                />
                            </Box>
                            : <Box className={clsx('column', styles.renderGroupsWrapper)}>
                                {renderGroups(groupByDate(filteredAlerts))}
                            </Box>}
                    </Box>
                </Box>}
            {openedWeatherDefinition && !editingWeatherDefinition && !editingAlertConfiguration &&
                <WeatherDefinitionDetails
                    definition_id={openedWeatherDefinition.id}
                />
            }
            {editingAlertConfiguration &&
                <ManageAlertConfigurationPanel args={editingAlertConfiguration}/>}
        </>
    )
}
