import {Accordion, AccordionDetails, AccordionSummary, FormControlLabel} from '@mui/material'
import React from 'react'
import {Spacer} from 'shared/ui/Spacer'
import MapPinIcon from 'shared/assets/icons/MapPin'
import {CheckBox} from 'shared/ui/CheckBox'
import styles from "./LocationsTree.module.scss"
import {clsx} from "clsx";

const calcLocations = (locations, selectedLocations, searchString) => {
    const s = searchString && searchString.trim().toLocaleLowerCase()
    let selected = 0
    let unselected = 0
    for (const i in locations) {
        const loc = locations[i]
        if (!s || loc.label.toLocaleLowerCase().indexOf(s) >= 0) {
            if (selectedLocations[loc.id] === false) {
                unselected++
            } else {
                selected++
            }
        }
    }
    return [selected, unselected]
}

export function calcSelected(locationGroups, searchString, selectedLocations) {
    let selected = 0
    let unselected = 0

    if (!locationGroups) {
        return [0, 0]
    }

    const s = searchString.trim().toLocaleLowerCase()

    locationGroups.forEach((groupObj) => {
        const [groupSelected, groupUnselected] = calcLocations(groupObj.locations, selectedLocations, s)
        selected += groupSelected
        unselected += groupUnselected
    })

    return [selected, unselected]
}

export function LocationsTree(
    {
        locationGroups,
        selectedLocations,
        handleSelectedLocations,
        searchString,
        variant = 'light',
    }) {
    function filterLocations(group, searchString) {
        const s = searchString.trim().toLocaleLowerCase()
        return group.locations
            .filter((loc) => loc.label.toLocaleLowerCase().indexOf(s) >= 0)
            .sort((a, b) => a.label.localeCompare(b.label))
    }

    const toggleLocation = (loc) => {
        const state = selectedLocations[loc.id]
        selectedLocations[loc.id] = state === false
        handleSelectedLocations({...selectedLocations})
    }
    const LocationItems = ({locations}) => locations.map((location, key) => {
        const checked = selectedLocations[location.id] !== false
        return (
            <div
                key={location.id}
                className={'row'}
                style={{gap: '4px', justifyContent: 'flex-start'}}
            >
                <FormControlLabel
                    label={
                        <div className={'paragraph'}>
                            {location.label}
                        </div>}
                    control={
                        <CheckBox
                            checked={checked}
                            onClick={(event) => {
                                event.stopPropagation()
                                toggleLocation(location)
                            }}
                        />}
                />
                <Spacer/>
                <MapPinIcon size={'small'}/>
            </div>
        )
    })
    const groupSelected = (locations) => {
        const [, unselected] = calcLocations(locations, selectedLocations)
        return unselected === 0
    }
    const groupIndeterminate = (locations) => {
        const [selected, unselected] = calcLocations(locations, selectedLocations)
        return unselected > 0 && selected > 0
    }
    const handleSelectGroup = (locations) => {
        const selected = groupSelected(locations)
        for (const i in locations) {
            const loc = locations[i]
            selectedLocations[loc.id] = !selected
        }
        handleSelectedLocations({...selectedLocations})
    }

    return (
        locationGroups.map((groupObj) => {
            const groupName = groupObj.group
            const locations = filterLocations(groupObj, searchString)

            if (locations.length === 0) {
                return null
            }

            return (
                <Accordion
                    key={groupName}
                    defaultExpanded
                    variant={variant}
                >
                    <AccordionSummary variant={variant}>
                        <div className={clsx("row", styles.accordionSummary)}>
                            <CheckBox
                                checked={groupSelected(locations)}
                                indeterminate={groupIndeterminate(locations)}
                                onClick={(event) => {
                                    event.stopPropagation()
                                    handleSelectGroup(locations)
                                }}
                            />
                            <div className={clsx("paragraph", styles.title)}>
                                {`${groupName || 'Ungrouped locations'} (${locations.length})`}
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails
                        className={clsx("column fullWidth", styles.accordionDetails)}
                        variant={variant}
                    >
                        <LocationItems
                            locations={locations}
                        />
                    </AccordionDetails>
                </Accordion>
            )
        })
    )
}
